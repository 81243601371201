import React, { useMemo } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton } from 'components/QuestionButton'
import { TitleContainer } from 'components/TitleContainer'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledPhysicalClose as S } from './PhysicalClose.styles'

const QUESTION = 'Do you feel the need to be physically close to your partner?'
const OPTION_VALUES = {
  always: 'Always',
  sometimes: 'Sometimes',
  rarely: 'Rarely',
  never: 'Never',
}

export const PhysicalCloseVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, handleContinue, pageId, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <TitleContainer
        title={
          <S.QuestionTitle>
            Do you feel the need to be physically close <br />
            to your partner?
          </S.QuestionTitle>
        }
      />
      <S.Column>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.always}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.always}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.sometimes}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.sometimes}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.rarely}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.rarely}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.never}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.never}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
