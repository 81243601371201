import { TagsValue } from 'root-constants/common'

export const TRANSLATION_FAMILY = 'onboarding.tagsV1'
export const OPTIONS = [
  {
    key: 'sex',
    value: TagsValue.SEX,
  },
  {
    key: 'relationships',
    value: TagsValue.PAST_RELATIONSHIPS,
  },
  {
    key: 'career',
    value: TagsValue.CAREER,
  },
  {
    key: 'plans',
    value: TagsValue.FUTURE_PLANS,
  },
  {
    key: 'children',
    value: TagsValue.CHILDREN,
  },
  {
    key: 'personal',
    value: TagsValue.PERSONAL_BOUNDARIES,
  },
  {
    key: 'toxic',
    value: TagsValue.TOXIC_BEHAVIOR,
  },
  {
    key: 'fetishes',
    value: TagsValue.FETISHES,
  },
  {
    key: 'jealousy',
    value: TagsValue.JEALOUSY,
  },
  {
    key: 'finances',
    value: TagsValue.FINANCES,
  },
  {
    key: 'kinks',
    value: TagsValue.KINKS,
  },
  {
    key: 'family',
    value: TagsValue.FAMILY_AND_INLAWS,
  },
]

export const CUSTOM_OPTIONS = [
  { key: 'none', value: TagsValue.NONE_OF_THE_ABOVE },
]
