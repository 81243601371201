import React, { useMemo, useState } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { StickyContainer } from 'components/StickyContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledPhysicalTouchPriority as S } from './PhysicalTouchPriority.styles'

const QUESTION =
  'How do you prioritize physical touch and affection in your relationship?'
const OPTION_VALUES = {
  hugsAndKisses: 'Hugs and kisses every day',
  holdingHands: 'Holding hands when out together',
  sexualIntimacy: 'Sexual intimacy',
  all: 'All of the above',
}

export const PhysicalTouchPriorityVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          value === OPTION_VALUES.all
            ? setAnswers([value])
            : setAnswers([...answers, value])
        } else {
          const newValues = answers.filter((item) => item !== value)
          setAnswers(newValues)
        }
      },
    }),
    [pageId, answers],
  )

  const isButtonsDisabled = useMemo(
    () => answers.includes(OPTION_VALUES.all),
    [answers],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.Title>{QUESTION}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.hugsAndKisses}
            disabled={isButtonsDisabled}
            checked={answers.includes(OPTION_VALUES.hugsAndKisses)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.hugsAndKisses}
            </S.QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.holdingHands}
            disabled={isButtonsDisabled}
            checked={answers.includes(OPTION_VALUES.holdingHands)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.holdingHands}
            </S.QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.sexualIntimacy}
            disabled={isButtonsDisabled}
            checked={answers.includes(OPTION_VALUES.sexualIntimacy)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.sexualIntimacy}
            </S.QuestionButton>
          </Option>

          <Option {...optionProps} value={OPTION_VALUES.all}>
            <S.QuestionButton>{OPTION_VALUES.all}</S.QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
      <StickyContainer>
        <S.Button
          onClick={() => handleContinue(answers)}
          disabled={!answers.length}
        >
          Continue
        </S.Button>
      </StickyContainer>
    </PageContainer>
  )
}
