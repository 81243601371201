import styled, { css } from 'styled-components'

import periodBgImage from 'assets/images/price-bg.svg'
import checkIcon from 'assets/images/success.svg'

import { Color } from 'root-constants/common'

const activePlanItemStyles = css`
  border-color: #7879f1;

  ::before {
    background: center url(${checkIcon}) no-repeat;
    background-size: 41px;
  }
`

const PlanItem = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px 24px 56px;
  border-radius: 16px;
  border: 2px solid transparent;
  background-color: ${Color.LILAC_110};
  transition: all 0.2s ease-out;

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 16px;
    width: 24px;
    height: 24px;
    margin: auto;
    border: 2px solid #140e4d;
    border-radius: 100%;
  }

  &[data-is-selected='true']::before {
    border-color: ${Color.GREEN};
  }
`

export const StyledPlanItemVariant2 = {
  PlanItem: styled(PlanItem)`
    &[data-is-selected='true'] {
      ${activePlanItemStyles};
    }
  `,
  PlanTitle: styled.div`
    flex-grow: 1;
    font-size: 16px;
    font-weight: 800;
    line-height: 22px;
    text-transform: capitalize;
  `,
  OldPlanPrice: styled.div`
    margin-right: 6px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    text-decoration: line-through;
    color: ${Color.LIGHT_TEXT};
    opacity: 0.5;

    [data-is-selected='true'] & {
      color: ${Color.ERROR};
    }
  `,
  PlanPrice: styled.div`
    position: relative;
    flex-shrink: 0;
    width: 90px;
    height: 40px;
    color: #ffffff;
    background: top right url(${periodBgImage}) no-repeat;

    > strong {
      position: absolute;
      left: 26px;
      bottom: 2px;
      font-size: 32px;
      font-weight: 800;
      line-height: 36px;

      ::before {
        content: '$';
        display: block;
        position: absolute;
        left: -8px;
        top: 6px;
        font-size: 12px;
        font-weight: 800;
        line-height: 12px;
      }

      ::after {
        content: attr(data-fractional);
        display: block;
        position: absolute;
        right: -16px;
        top: 6px;
        font-size: 12px;
        font-weight: 800;
        line-height: 12px;
      }
    }
  `,

  Period: styled.sub`
    position: absolute;
    right: 5px;
    bottom: 7px;
    font-size: 9px;
    font-weight: 500;
    line-height: 11px;
  `,
}
