import styled from 'styled-components'

import { Color } from 'root-constants/common'

export const StyledTimer = {
  TimerContainer: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${Color.LILAC_130};
    border-radius: 16px;
  `,
  TimerContent: styled.div`
    height: 70px;
    width: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 22px;
    font-weight: 700;
    line-height: 40px;
    color: #0a082f;

    strong {
      font-size: 38px;
    }
  `,
}
