import styled from 'styled-components'

import { PageContainer } from 'components/PageContainer'
import { StickyButton } from 'components/StickyButton'

import { QuestionTitle, baseColumnStyles } from 'common-styles'
import { Color, MAX_PHONE_WIDTH } from 'root-constants/common'

export const StyledSocialProof = {
  Wrapper: styled(PageContainer)`
    color: var(--base-text-color);
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 32px 0;

    @media (min-height: 640px) {
      padding: 54px 0 32px;
    }
  `,
  Title: styled(QuestionTitle)`
    display: flex;
    flex-direction: column;
    margin-bottom: 9px;
    text-align: center;

    strong {
      color: ${Color.LILAC_130};
      font-size: 48px;
      line-height: 65px;
    }
  `,
  Title2: styled(QuestionTitle)`
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 16px;
  `,
  Banner: styled.div`
    aspect-ratio: 375 / 338;
    margin-bottom: 36px;
    max-width: ${MAX_PHONE_WIDTH}px;
    width: 100vw;
  `,
  Img: styled.div`
    aspect-ratio: 215 / 136;
    margin-bottom: 25px;
    width: 215px;

    &[data-variant='3'] {
      aspect-ratio: 215 / 168;

      @media (min-height: 550px) {
        margin-bottom: 52px;
      }
    }
  `,
  Percent: styled.strong`
    font-weight: 700;
    font-size: 48px;
    line-height: 24px;
    margin-bottom: 24px;
  `,
  Text: styled.p`
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 31px;
  `,
  Text2: styled.p`
    font-size: 16px;
    line-height: 22px;
    text-align: center;
  `,
  Bold: styled.strong`
    font-weight: 900;
  `,
  FeedbackWrapper: styled.div`
    position: relative;
    text-align: center;
    padding: 47px 17px 17px;
    margin-bottom: 35px;
    color: ${Color.DEFAULT_TEXT};
    background-color: ${Color.LILAC_110};
    border-radius: 16px;
  `,
  Photo: styled.img`
    position: absolute;
    top: -35px;
    left: 50%;
    transform: translateX(-50%);
  `,
  Name: styled.strong`
    display: inline-block;
    font-weight: 900;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 14px;
  `,
  FeedbackText: styled.p`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  `,
  Note: styled.span`
    font-style: italic;
    font-size: 12px;
    line-height: 22px;
    color: rgba(237, 237, 255, 0.5);
    margin: 14px 0;

    @media (min-height: 550px) {
      margin-top: 42px;
    }
  `,
  StickyButton: styled(StickyButton)`
    @media (max-height: 640px) {
      position: static;
    }
  `,
}
