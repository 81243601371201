import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton } from 'components/QuestionButton'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'

import { useDelayedContinue } from 'hooks/useDelayedContinue'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'

import { StyledDiscuss as S } from './Discuss.styles'

const QUESTION = 'Can you discuss all your feelings, needs,and desires openly?'
const OPTION_VALUES = {
  yes: 'Yes',
  no: 'No',
}

export const DiscussVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
  alternativePagePath,
}) => {
  const dispatch = useDispatch()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useCallback(
    (answer) => {
      dispatch(
        setAnswersAction({
          answers: answer,
          pageId,
        }),
      )

      dispatch(sendUserAnswersAction())

      eventLogger.logQuestion({
        question: QUESTION,
        answers: answer,
        pageName: pageId,
      })

      answer === OPTION_VALUES.yes
        ? goTo(alternativePagePath)
        : goTo(nextPagePath)
    },
    [alternativePagePath, dispatch, nextPagePath, pageId],
  )

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, handleContinue, pageId, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.QuestionTitle>
          Can you discuss all your feelings, needs,
          <br />
          and desires openly?
        </S.QuestionTitle>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.yes}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.yes}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.no}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.no}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
