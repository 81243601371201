import React, { useMemo } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton2 } from 'components/QuestionButton2'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledJealousy as S } from './Jealousy.styles'

const QUESTION =
  'How do you handle feelings of jealousy or insecurity in your relationship?'
const OPTION_VALUES = {
  communicateOpenly: 'Communicate openly and honestly',
  practiceReflection: 'Practice self-reflection and self-care',
  trustEachOther: 'Trust and respect each other',
  buildConfidence: 'Work on building self-confidence',
  seekProfessionalHelp: 'Seek professional help or counseling',
}

export const JealousyVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.Title>{QUESTION}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.communicateOpenly}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.communicateOpenly}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.practiceReflection}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>
              {OPTION_VALUES.practiceReflection}
            </QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.trustEachOther}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.trustEachOther}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.buildConfidence}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.buildConfidence}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.seekProfessionalHelp}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>
              {OPTION_VALUES.seekProfessionalHelp}
            </QuestionButton2>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
