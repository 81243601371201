import styled from 'styled-components'

export const StyledReviewsSlider = {
  Wrapper: styled.div`
    margin: 0 0 18px 0;

    .swiper-wrapper {
      display: flex;
      padding-top: 33px;
    }

    .swiper-slide {
      position: relative;
      width: 265px;
    }
  `,
  Card: styled.div`
    position: relative;
    padding: 40px 10px 24px;
    height: 228px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--reviews-slider-card-background-color);
    border-radius: var(--reviews-slider-card-border-radius);
    border: 4px solid #eed0e4;
  `,
  Avatar: styled.img`
    position: absolute;
    top: -33px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
  `,
  CardTitle: styled.h3`
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  `,
  CardText: styled.p`
    margin-top: 5px;
    font-size: 12px;
    font-weight: 500;
    font-style: italic;
    line-height: 16px;
    text-align: center;
  `,
}
