import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SupportAction } from 'components/SupportAction'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { DISCLAIMER_V2_TEXT } from 'modules/payment/components/Disclaimer/constants'
import {
  selectSubscriptionMainPrice,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPrice,
} from 'modules/payment/redux/selects'

import { StyledDisclaimerV2 as S } from './DisclaimerV2.styles'

type TProps = {
  className?: string
}

export const DisclaimerV2: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  const mainPrice = useSelector(selectSubscriptionMainPrice)
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)
  const trialPrice = useSelector(selectSubscriptionTrialPrice)

  return (
    <div className={className}>
      <S.Disclaimer>
        <Trans
          i18nKey={DISCLAIMER_V2_TEXT[trialPeriodDays]}
          values={{
            trialPrice,
            fullPrice: mainPrice,
          }}
        />
        <TermsOfUseLink />
        {t('commonComponents.disclaimerV2.contactUs')}
        <SupportAction />
      </S.Disclaimer>
    </div>
  )
}
