import React, { useMemo } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton2 } from 'components/QuestionButton2'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledINewActivities as S } from './NewActivities.styles'

const QUESTION =
  'How often do you and your partner try new activities or experiences together?'
const OPTION_VALUES = {
  everyWeek: 'Every week',
  fewTimesAMonth: 'A few times a month',
  onceAMonth: 'Once a month',
  rarely: 'Rarely',
  never: 'Never',
}

export const NewActivitiesVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.QuestionTitle>
          How often do you and your partner try new activities
          <br />
          or experiences together?
        </S.QuestionTitle>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.everyWeek}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.everyWeek}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.fewTimesAMonth}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.fewTimesAMonth}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.onceAMonth}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.onceAMonth}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.rarely}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.rarely}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.never}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.never}</QuestionButton2>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
