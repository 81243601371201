import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import backIcon from 'assets/images/back-icon.svg'

import { goBack } from 'browser-history'

import { StyledProgressBar as S } from './ProgressBar.styles'

export const ProgressBar: React.FC = () => {
  const {
    pagesWithProgressBar,
    currentPageWithProgressBarIndex,
    hasProgressBar,
  } = useGetPageInfo()

  const pagesWithProgressBarLength = useMemo(
    () => pagesWithProgressBar.length + 1,
    [pagesWithProgressBar],
  )

  const progressValue = useMemo(
    () =>
      ((currentPageWithProgressBarIndex + 1) * 100) /
      pagesWithProgressBarLength,
    [currentPageWithProgressBarIndex, pagesWithProgressBarLength],
  )

  return !hasProgressBar ? null : (
    <S.Wrapper>
      <S.Back onClick={goBack}>
        <img src={backIcon} alt="back" />
      </S.Back>
      <S.Progress progressValue={progressValue} />
      <S.PageProgress>
        <Trans
          i18nKey="header.progress"
          values={{
            currentPageIndex: currentPageWithProgressBarIndex + 1,
            pagesLength: pagesWithProgressBarLength,
          }}
          components={{ span: <span /> }}
        />
      </S.PageProgress>
    </S.Wrapper>
  )
}
