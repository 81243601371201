import React, { useMemo } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton2 } from 'components/QuestionButton2'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledMainGoals as S } from './MainGoals.styles'

const QUESTION =
  'What is your main goal in improving your intimacy with your partner?'
const OPTION_VALUES = {
  feelMoreConnected: 'To feel more connected to my partner emotionally',
  increasePhysicalPleasure: 'To increase physical pleasure with my partner',
  haveDeeperConversations:
    'To have deeper conversations and discussions with my partner',
  feelSpirituallyAligned: 'To feel more spiritually aligned with my partner',
  growIntellectually: 'To learn and grow intellectually with my partner',
  exploreNewWays:
    'To explore new ways of experiencing intimacy with my partner',
}

export const MainGoalsVariant5: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.QuestionTitleVariant2>{QUESTION}</S.QuestionTitleVariant2>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.feelMoreConnected}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.feelMoreConnected}</QuestionButton2>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.increasePhysicalPleasure}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>
              {OPTION_VALUES.increasePhysicalPleasure}
            </QuestionButton2>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.haveDeeperConversations}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>
              {OPTION_VALUES.haveDeeperConversations}
            </QuestionButton2>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.feelSpirituallyAligned}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>
              {OPTION_VALUES.feelSpirituallyAligned}
            </QuestionButton2>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.growIntellectually}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>
              {OPTION_VALUES.growIntellectually}
            </QuestionButton2>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.exploreNewWays}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.exploreNewWays}</QuestionButton2>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
