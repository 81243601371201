import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { PageContainer } from 'components/PageContainer'
import { StickyContainer } from 'components/StickyContainer'

import { useNextStep } from 'hooks/useNextStep'
import { useUserData } from 'hooks/useUserData'

import { TPageProps } from 'models/common.model'

import graph1 from 'assets/images/restrictive-graph-1.png'
import graph2 from 'assets/images/restrictive-graph-2.png'

import { RateRelationshipValue } from 'root-constants/common'

import { StyledRestrictive as S } from './Restrictive.styles'

export const RestrictiveVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { rateRelationship } = useUserData()

  const graphSrc = useMemo(() => {
    switch (rateRelationship) {
      case RateRelationshipValue.PERFECT:
      case RateRelationshipValue.FINE:
      case RateRelationshipValue.ROOM:
        return graph1

      case RateRelationshipValue.IMPROVEMENT:
      case RateRelationshipValue.ISSUES:
        return graph2

      default:
        return graph1
    }
  }, [rateRelationship])

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.restrictiveV1.title', { lng: 'en' }),
    nextPagePath,
  })

  return (
    <PageContainer>
      <S.Column>
        <S.Title>
          <Trans i18nKey="onboarding.restrictiveV1.title" />
        </S.Title>
        <S.Subtitle marginBottom={graphSrc === graph1 ? 8 : 32}>
          <Trans i18nKey="onboarding.restrictiveV1.subtitle" />
        </S.Subtitle>
        <S.GraphContainerV2>
          <img src={graphSrc} alt="graph" />
        </S.GraphContainerV2>
        <S.GraphDescription>
          <Trans i18nKey="onboarding.restrictiveV1.graphDescription" />
        </S.GraphDescription>
        <StickyContainer>
          <S.Button onClick={() => handleContinue('')}>
            {t('actions.continue')}
          </S.Button>
        </StickyContainer>
      </S.Column>
    </PageContainer>
  )
}
