import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import firebase from 'firebase/app'

import { Modal } from 'components/Modal'
import { PageContainer } from 'components/PageContainer'
import { Spinner } from 'components/Spinner'

import { resetErrorAction } from 'root-redux/actions/common'
import { bindUserAction } from 'root-redux/actions/user'
import { selectError, selectIsFetching } from 'root-redux/selects/common'

import { useAuthObserver } from 'hooks/useAuthObserver'
import { useGetRedirectResult } from 'hooks/useGetRedirectResult'
import { useInitFirebase } from 'hooks/useInitFirebase'

import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'

import { eventLogger } from 'services/eventLogger.service'

import appleIcon from 'assets/images/continue-with-apple.svg'
import emailIcon from 'assets/images/continue-with-email.svg'

import { LoginMethod, PlatformOS } from 'root-constants/common'

import { StyledAccount as S, StyledAccountButton } from './Account.styles'
import { EmailAccount } from './components/EmailAccount'

export const Account: React.FC = () => {
  const signInFirebase = (provider) =>
    firebase.auth().signInWithRedirect(provider)
  const location = useLocation()
  const dispatch = useDispatch()
  const error = useSelector(selectError)
  const isFetching = useSelector(selectIsFetching)

  const [isModalShown, setIsModalShown] = useState(false)
  const [isEmailLoginShown, setIsEmailLoginShown] = useState<boolean>(false)
  const [isFirebaseDataLoading, setIsFirebaseDataLoading] = useState(false)

  const isAndroid = useMemo(
    () => getMobileOperatingSystem() === PlatformOS.ANDROID,
    [],
  )

  useEffect(() => {
    eventLogger.logCreateAccountShown()
  }, [])

  useEffect(() => {
    error && setIsModalShown(true)
  }, [error])

  useEffect(() => {
    if (location.pathname === '/account') {
      setIsEmailLoginShown(false)
    }
  }, [location.pathname])

  const handleContinueWithApple = useCallback(async () => {
    eventLogger.logLoginMethodSelected({ method: LoginMethod.APPLE })

    await signInFirebase(new firebase.auth.OAuthProvider('apple.com'))
  }, [])

  const handleContinueWithEmail = useCallback(() => {
    setIsEmailLoginShown(true)

    eventLogger.logLoginMethodSelected({ method: LoginMethod.EMAIL })
  }, [])

  const handleButtonsClick = useCallback(
    (event) => {
      if (error) {
        event.stopPropagation()
        setIsModalShown(true)
      }
    },
    [error],
  )

  const authStateChangeHandler = useCallback(
    (token: string) => {
      dispatch(bindUserAction(token))
    },
    [dispatch],
  )

  useInitFirebase()
  useGetRedirectResult(authStateChangeHandler, setIsFirebaseDataLoading)
  useAuthObserver(authStateChangeHandler)

  return (
    <PageContainer>
      {(isFetching || isFirebaseDataLoading) && <Spinner />}
      {isEmailLoginShown ? (
        <EmailAccount
          onBack={() => {
            setIsEmailLoginShown(false)
          }}
        />
      ) : (
        <S.Column>
          <S.Title>Welcome to UpLuv!</S.Title>
          <S.Text>
            Sign up and create an account
            <br />
            to track your progress
          </S.Text>
          <S.Buttons onClickCapture={handleButtonsClick}>
            <StyledAccountButton.Email onClick={handleContinueWithEmail}>
              <img src={emailIcon} width={20} alt="email" />
              <span>Continue with Email</span>
            </StyledAccountButton.Email>
            {!isAndroid && (
              <StyledAccountButton.Apple onClick={handleContinueWithApple}>
                <img src={appleIcon} width={24} alt="apple" />
                <span>Continue with Apple</span>
              </StyledAccountButton.Apple>
            )}
          </S.Buttons>
        </S.Column>
      )}

      <Modal
        onClose={() => {
          setIsModalShown(false)
          dispatch(resetErrorAction())
        }}
        isShown={isModalShown}
      >
        {error}
      </Modal>
    </PageContainer>
  )
}
