import React, { useMemo, useState } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { StickyContainer } from 'components/StickyContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledSexualExperiences as S } from './SexualExperiences.styles'

const QUESTION = 'How do you and your partner explore new sexual experiences?'
const OPTION_VALUES = {
  newIdeas: 'Researching and discussing new ideas together',
  pornTogether: 'Watching porn together',
  usingToys: 'Role-playing or using toys',
  notInterested: 'Not interested in exploring new experiences',
  notToSay: 'I prefer not to say',
}

export const SexualExperiencesVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          value === OPTION_VALUES.notInterested ||
          value === OPTION_VALUES.notToSay
            ? setAnswers([value])
            : setAnswers([...answers, value])
        } else {
          const newValues = answers.filter((item) => item !== value)
          setAnswers(newValues)
        }
      },
    }),
    [pageId, answers],
  )

  const isButtonsDisabled = useMemo(
    () =>
      answers.some(
        (answer) =>
          answer === OPTION_VALUES.notInterested ||
          answer === OPTION_VALUES.notToSay,
      ),
    [answers],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.Title>{QUESTION}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.newIdeas}
            disabled={isButtonsDisabled}
            checked={answers.includes(OPTION_VALUES.newIdeas)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.newIdeas}
            </S.QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.pornTogether}
            disabled={isButtonsDisabled}
            checked={answers.includes(OPTION_VALUES.pornTogether)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.pornTogether}
            </S.QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.usingToys}
            disabled={isButtonsDisabled}
            checked={answers.includes(OPTION_VALUES.usingToys)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.usingToys}
            </S.QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.notInterested}
            disabled={answers.includes(OPTION_VALUES.notToSay)}
          >
            <S.QuestionButton>{OPTION_VALUES.notInterested}</S.QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.notToSay}
            disabled={answers.includes(OPTION_VALUES.notInterested)}
          >
            <S.QuestionButton>{OPTION_VALUES.notToSay}</S.QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
      <StickyContainer>
        <S.Button
          onClick={() => handleContinue(answers)}
          disabled={!answers.length}
        >
          Continue
        </S.Button>
      </StickyContainer>
    </PageContainer>
  )
}
