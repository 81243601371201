import React from 'react'
import { useTranslation } from 'react-i18next'

import listPoint from 'assets/images/list-point.svg'

import { StyledBenefitsV1 as S } from './BenefitsV1.styles'

type TProps = {
  benefits: string[]
  title?: string
  className?: string
}

export const BenefitsV1: React.FC<TProps> = ({
  title,
  benefits,
  className,
}) => {
  const { t } = useTranslation()

  return (
    <div className={className}>
      {title && <S.Title>{t(title)}</S.Title>}
      <ul>
        {benefits.map((benefit) => (
          <S.ListItem key={benefit}>
            <S.ListPoint src={listPoint} alt="list-point" />
            <S.ListText>{t(benefit)}</S.ListText>
          </S.ListItem>
        ))}
      </ul>
    </div>
  )
}
