import styled, { css } from 'styled-components'

import { Color } from 'root-constants/common'

const priceColumnBase = css`
  align-items: center;
  column-gap: 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  span {
    width: 100%;
  }

  strong {
    font-weight: 900;
    white-space: nowrap;
  }
`

export const StyledMixedPaymentSummaryV2 = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    width: 100%;
  `,
  SelectedPrice: styled.div`
    ${priceColumnBase};
    color: ${Color.LILAC_120};
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 12px;

    span {
      font-weight: 400;
    }
  `,
  TrialPrice: styled.div`
    ${priceColumnBase};
    color: ${Color.LILAC_120};
    font-size: 13px;
    line-height: 18px;

    strong {
      color: ${Color.ERROR};
    }
  `,
  Divider: styled.div`
    height: 33px;
    position: relative;
    width: 100%;

    :after {
      content: '';
      background-color: #362f99;
      height: 1px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      width: 100%;
    }
  `,
  TotalPerDay: styled.div`
    ${priceColumnBase};
    background-color: ${Color.GREEN};
    border-radius: 12px;
    color: ${Color.BLUE_100};
    font-size: 14px;
    height: 56px;
    line-height: 16px;
    margin-bottom: 16px;
    padding: 0 16px;

    span {
      font-weight: 700;
    }
  `,
  Total: styled.div`
    ${priceColumnBase};
    color: ${Color.LILAC_120};
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 6px;

    strong {
      width: initial;

      :first-of-type {
        width: 100%;
      }
    }
  `,
  YouSave: styled.p`
    color: ${Color.ERROR};
    font-size: 13px;
    font-weight: 800;
    line-height: 18px;
    text-align: right;
  `,
}

export const StyledMixedPaymentSummary = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 24px 0;
    color: ${Color.LILAC_120};
    font-size: 13px;
    line-height: 18px;
  `,
  TrialPriceContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    strong {
      font-weight: 900;
      line-height: 16px;
    }
  `,
  Discount: styled.strong`
    font-weight: 900;
    color: ${Color.GREEN};
  `,
  DiscountDescription: styled.span`
    text-align: right;
    color: ${Color.GREEN};
    margin-top: -6px;
  `,
  Container: styled.div`
    display: flex;
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 20px;
    color: var(--base-text-color);
    margin-top: 16px;
  `,
  TitleContainer: styled.span`
    margin-right: 10px;
  `,
  PriceContainer: styled.span`
    font-weight: 700;
  `,
}
