import { configApi, subscriptionsApi, variantsApi } from 'api'
import { AnyAction } from 'redux'

import { selectCurrentVariantCohort } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { getAppConfigFromConfigRaw } from 'helpers/getAppConfigFromConfigRaw'
import { getPaymentConfigFromConfigRaw } from 'helpers/getPaymentConfigFromConfigRaw'
import { getSubscriptionListFromRawSubscriptionList } from 'helpers/getSubscriptionListFromRawSubscriptionList'
import { getVariantFromRawVariant } from 'helpers/getVariantFromRawVariant'

import { setSelectedSubscriptionAction } from 'modules/payment/redux/actions'

import { TAnswer } from 'models/common.model'
import { IAppConfig, IPaymentConfig } from 'models/config.model'
import { IAction, IAppState, TAppDispatchThunk } from 'models/store.model'
import { ISubscription } from 'models/subscriptions.model'
import { IVariant } from 'models/variant.model'

import { ScreenName } from 'services/eventLogger.service'

import { SubscriptionListType } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

const MODULE_NAME = 'COMMON'

// actions types
export const START_FETCHING = `${MODULE_NAME}/START_FETCHING`
export const STOP_FETCHING = `${MODULE_NAME}/STOP_FETCHING`
export const SET_ERROR = `${MODULE_NAME}/SET_ERROR`
export const RESET_ERROR = `${MODULE_NAME}/RESET_ERROR`
export const GET_VARIANT = `${MODULE_NAME}/GET_VARIANT`
export const SET_VARIANT = `${MODULE_NAME}/SET_VARIANT`
export const GET_SUBSCRIPTION_LIST = `${MODULE_NAME}/GET_SUBSCRIPTION_LIST`
export const SET_SUBSCRIPTION_LIST = `${MODULE_NAME}/SET_SUBSCRIPTION_LIST`
export const GET_APP_CONFIG = `${MODULE_NAME}/GET_APP_CONFIG`
export const SET_APP_CONFIG = `${MODULE_NAME}/SET_APP_CONFIG`
export const GET_PAYMENT_CONFIG = `${MODULE_NAME}/GET_PAYMENT_CONFIG`
export const SET_PAYMENT_CONFIG = `${MODULE_NAME}/SET_PAYMENT_CONFIG`
export const SET_ANSWERS = `${MODULE_NAME}/SET_ANSWERS`
export const SET_ANSWERS_FROM_BACKEND = `${MODULE_NAME}/SET_ANSWERS_FROM_BACKEND`
export const SET_SCREEN_NAME = `${MODULE_NAME}/SET_SCREEN_NAME`
export const SET_UTM_TAGS = `${MODULE_NAME}/SET_UTM_TAGS`
export const SET_OPTIMIZE_EXPERIMENT_ID_BY_VARIANT_ID_MAP = `${MODULE_NAME}/SET_OPTIMIZE_EXPERIMENT_ID_BY_VARIANT_ID_MAP`
export const SET_SUBSCRIPTION_TIMER_VALUE = `${MODULE_NAME}/SET_SUBSCRIPTION_TIMER_VALUE`
export const SET_CANCEL_OFFER_TIMER_VALUE = `${MODULE_NAME}/SET_CANCEL_OFFER_TIMER_VALUE`
export const SET_HIGHEST_DISCOUNT = `${MODULE_NAME}/SET_HIGHEST_DISCOUNT`

// actions handlers
export function startFetching(action: string): IAction<string> {
  return {
    type: START_FETCHING,
    payload: action,
  }
}

export function stopFetching(actionToStop: string): any {
  return (dispatch: TAppDispatchThunk<string[]>, getState: () => IAppState) => {
    const runningActions = getState().common.actionList
    const fetchList = runningActions.filter(
      (action: string) => action && action !== actionToStop,
    )

    dispatch({
      type: STOP_FETCHING,
      payload: fetchList,
    })
  }
}

export function setErrorAction<T>(error: T): IAction<T> {
  return {
    type: SET_ERROR,
    payload: error,
  }
}

export function resetErrorAction(): IAction<never> {
  return {
    type: RESET_ERROR,
  }
}

export function setVariantAction(payload: IVariant): IAction<IVariant> {
  return {
    type: SET_VARIANT,
    payload,
  }
}

export function setScreenNameAction(
  screenName: ScreenName,
): IAction<ScreenName> {
  return {
    type: SET_SCREEN_NAME,
    payload: screenName,
  }
}

export function setUtmTags(
  payload: Record<string, string>,
): IAction<Record<string, string>> {
  return {
    type: SET_UTM_TAGS,
    payload,
  }
}

export function getVariantAction({ cohort }: { cohort: string }): any {
  return async (dispatch) => {
    dispatch(startFetching(GET_VARIANT))

    const response = await variantsApi.getVariant({ cohort })

    if (response.success && response.data) {
      const variant = getVariantFromRawVariant(response.data.variant)

      dispatch(setVariantAction(variant))
    }

    dispatch(stopFetching(GET_VARIANT))
  }
}

export function setSubscriptionListAction(payload: ISubscription[]) {
  return {
    type: SET_SUBSCRIPTION_LIST,
    payload,
  } as const
}

export const setHighestDiscountAction = (payload: number | null) => ({
  type: SET_HIGHEST_DISCOUNT,
  payload,
})

export function getSubscriptionListAction({
  tags,
  subscriptionType = SubscriptionListType.PURCHASE,
}: {
  tags: string
  subscriptionType?: SubscriptionListType
}): any {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)
    const cohort = selectCurrentVariantCohort(state)

    dispatch(startFetching(GET_SUBSCRIPTION_LIST))

    const response = await subscriptionsApi.getSubscriptionList({
      uuid,
      cohort,
      type: subscriptionType,
      tags,
    })

    if (response.success && response.data) {
      const subscriptionList = getSubscriptionListFromRawSubscriptionList(
        response.data.plans,
      )

      dispatch(setSelectedSubscriptionAction(null))
      dispatch(setSubscriptionListAction(subscriptionList))

      dispatch(setHighestDiscountAction(null))

      const highestDiscount = response.data.plans.highest_intro_diff
      dispatch(setHighestDiscountAction(highestDiscount))
    }

    dispatch(stopFetching(GET_SUBSCRIPTION_LIST))
  }
}

export function setAppConfigAction(payload: IAppConfig): IAction<IAppConfig> {
  return {
    type: SET_APP_CONFIG,
    payload,
  }
}

export function getAppConfigAction(): any {
  return async (dispatch) => {
    dispatch(startFetching(GET_APP_CONFIG))

    const response = await configApi.getAppConfig()

    if (response.success && response.data) {
      const config = getAppConfigFromConfigRaw(response.data.config)
      dispatch(setAppConfigAction(config))
    }

    dispatch(stopFetching(GET_APP_CONFIG))
  }
}

export function setPaymentConfigAction(
  payload: IPaymentConfig,
): IAction<IPaymentConfig> {
  return {
    type: SET_PAYMENT_CONFIG,
    payload,
  }
}

export function getPaymentConfigAction(): any {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)
    const cohort = selectCurrentVariantCohort(state)

    dispatch(startFetching(GET_PAYMENT_CONFIG))

    const response = await configApi.getPaymentConfig({
      uuid,
      cohort,
    })

    if (response.success && response.data) {
      const config = getPaymentConfigFromConfigRaw(response.data)
      dispatch(setPaymentConfigAction(config))
    }

    dispatch(stopFetching(GET_PAYMENT_CONFIG))
  }
}

export function setAnswersAction({
  answers,
  pageId,
}: {
  answers: TAnswer
  pageId: PageId
}): AnyAction {
  return {
    type: SET_ANSWERS,
    payload: { [pageId]: answers },
  }
}

export function setAnswersFromBackendAction(
  answers: Record<PageId, TAnswer>,
): AnyAction {
  return {
    type: SET_ANSWERS_FROM_BACKEND,
    payload: answers,
  }
}

export function setOptimizeExperimentIdByVariantIdMap({
  experimentId,
  variantId,
}: {
  experimentId: string
  variantId: string
}): IAction<Record<string, string>> {
  return {
    type: SET_OPTIMIZE_EXPERIMENT_ID_BY_VARIANT_ID_MAP,
    payload: { [experimentId]: variantId },
  }
}

export const setSubscriptionTimerValueAction = (payload: number) =>
  ({
    type: SET_SUBSCRIPTION_TIMER_VALUE,
    payload,
  } as const)

export const setCancelOfferTimerValueAction = (payload: number) =>
  ({
    type: SET_CANCEL_OFFER_TIMER_VALUE,
    payload,
  } as const)
