import styled from 'styled-components'

import { BASE_COLUMN_WIDTH } from 'root-constants/common'

import { SvgImage } from '../SvgImage'

type THeaderProps = {
  hasBorder: boolean
}

export const StyledHeader = {
  HeaderWrapper: styled.div<THeaderProps>`
    ${({ hasBorder }) => `
      ${
        hasBorder &&
        `
          border-bottom: 1px solid var(--header-border-color);
        `
      };
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    `}
  `,
  Header: styled.header`
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 11px 0 6px;
    width: ${BASE_COLUMN_WIDTH}px;
  `,
  Logo: styled(SvgImage)`
    fill: var(--header-logo-color);
    height: 39px;
  `,
}
