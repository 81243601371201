import React from 'react'

import { Button } from 'components/Button'
import { PageContainer } from 'components/PageContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import spiritualityDescription from 'assets/images/spirituality-description.webp'

import { StyledSpiritualityDescription as S } from './SpiritualityDescription.styles'

export const SpiritualityDescriptionVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const handleContinue = useNextStep({
    pageId,
    question: 'Spirituality can help couples to cope with stress',
    nextPagePath,
  })

  return (
    <PageContainer>
      <S.Column>
        <S.Title>Spirituality can help couples to cope with stress</S.Title>

        <S.ImageContainer>
          <img src={spiritualityDescription} alt="spirituality-description" />
        </S.ImageContainer>

        <S.Description>
          Couples who engage in regular spiritual practices are{' '}
          <strong>29% more</strong> likely to report{' '}
          <strong>effective stress management.</strong>
        </S.Description>
        <Button onClick={() => handleContinue('')}>Continue</Button>
      </S.Column>
    </PageContainer>
  )
}
