import styled from 'styled-components'

import { SvgImage } from 'components/SvgImage'

export const StyledTimer = {
  Logo: styled(SvgImage)`
    fill: var(--header-logo-color);
  `,
  Timer: styled.div`
    display: flex;
    align-items: center;
  `,
  TimerSign: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  TimerLabel: styled.span`
    font-size: 11px;
    font-weight: 700;
    line-height: 15px;
  `,
  Separator: styled.strong`
    align-self: flex-start;
  `,
}
