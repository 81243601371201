import React, { useMemo, useState } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { StickyContainer } from 'components/StickyContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledStepsToSpiritualConnections as S } from './StepsToSpiritualConnections.styles'

const QUESTION =
  'What steps have you taken to strengthen your spiritual connection with your partner?'
const OPTION_VALUES = {
  spiritualRetreats: 'Attending spiritual retreats or events together',
  seekingGuidance: 'Seeking guidance from a spiritual leader or counselor',
  spiritualPractices: 'Incorporating spiritual practices into daily life',
  other: 'Other',
  doNothing: 'We do nothing',
}

export const StepsToSpiritualConnectionVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          value === OPTION_VALUES.doNothing
            ? setAnswers([value])
            : setAnswers([...answers, value])
        } else {
          const newValues = answers.filter((item) => item !== value)
          setAnswers(newValues)
        }
      },
    }),
    [pageId, answers],
  )

  const isButtonsDisabled = useMemo(
    () => answers.includes(OPTION_VALUES.doNothing),
    [answers],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.Title>{QUESTION}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.spiritualRetreats}
            disabled={isButtonsDisabled}
            checked={answers.includes(OPTION_VALUES.spiritualRetreats)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.spiritualRetreats}
            </S.QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.seekingGuidance}
            disabled={isButtonsDisabled}
            checked={answers.includes(OPTION_VALUES.seekingGuidance)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.seekingGuidance}
            </S.QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.spiritualPractices}
            disabled={isButtonsDisabled}
            checked={answers.includes(OPTION_VALUES.spiritualPractices)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.spiritualPractices}
            </S.QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.other}
            disabled={isButtonsDisabled}
            checked={answers.includes(OPTION_VALUES.other)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.other}
            </S.QuestionButton>
          </Option>

          <Option {...optionProps} value={OPTION_VALUES.doNothing}>
            <S.QuestionButton>{OPTION_VALUES.doNothing}</S.QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
      <StickyContainer>
        <S.Button
          onClick={() => handleContinue(answers)}
          disabled={!answers.length}
        >
          Continue
        </S.Button>
      </StickyContainer>
    </PageContainer>
  )
}
