import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton2 } from 'components/QuestionButton2'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'

import { useDelayedContinue } from 'hooks/useDelayedContinue'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'

import { StyledSpiritualBeliefs as S } from './SpiritualBeliefs.styles'

const QUESTION = 'Do you share similar spiritual beliefs with your partner?'
const OPTION_VALUES = {
  yes: 'Yes, we have similar beliefs',
  no: 'No, we have different beliefs',
  haveNotDiscussed: `We haven't discussed our beliefs yet`,
}

export const SpiritualBeliefsVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
  alternativePagePath,
}) => {
  const dispatch = useDispatch()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useCallback(
    (answer): void => {
      dispatch(
        setAnswersAction({
          pageId,
          answers: answer,
        }),
      )
      dispatch(sendUserAnswersAction())

      eventLogger.logQuestion({
        question: QUESTION,
        answers: answer,
        pageName: pageId,
      })

      goTo(answer !== OPTION_VALUES.no ? nextPagePath : alternativePagePath)
    },
    [alternativePagePath, dispatch, nextPagePath, pageId],
  )

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.Title>{QUESTION}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.yes}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.yes}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.no}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.no}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.haveNotDiscussed}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.haveNotDiscussed}</QuestionButton2>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
