import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'

import { useUserData } from 'hooks/useUserData'

import advantagesBg1 from 'assets/images/advantage-bg-1.png'
import advantagesBg2 from 'assets/images/advantage-bg-2.png'
import advantagesBg3 from 'assets/images/advantage-bg-3.png'
import advantagesBg4 from 'assets/images/advantage-bg-4.png'

import { StyledAdvantagesSlider as S } from './AdvantagesSlider.styles'

type TProps = React.HTMLAttributes<HTMLDivElement>

export const AdvantagesSlider: React.FC<TProps> = (props) => {
  const { isSexUsersPriority } = useUserData()

  return (
    <S.Wrapper {...props}>
      <Swiper slidesPerView="auto" spaceBetween={16}>
        <SwiperSlide>
          <S.Card style={{ backgroundImage: `url(${advantagesBg1})` }}>
            <S.CardText>Unlimited access to UpLuv app</S.CardText>
          </S.Card>
        </SwiperSlide>
        <SwiperSlide>
          <S.Card style={{ backgroundImage: `url(${advantagesBg2})` }}>
            <S.CardText>
              {isSexUsersPriority
                ? '100+ questions to get to know each other better'
                : '100+ questions to create a deeper connection'}
            </S.CardText>
          </S.Card>
        </SwiperSlide>
        <SwiperSlide>
          <S.Card style={{ backgroundImage: `url(${advantagesBg3})` }}>
            <S.CardText>
              {isSexUsersPriority
                ? '50+ quizzes to discover your partner’s desires'
                : '50+ quizzes to discover your partner’s needs'}
            </S.CardText>
          </S.Card>
        </SwiperSlide>
        <SwiperSlide>
          <S.Card style={{ backgroundImage: `url(${advantagesBg4})` }}>
            <S.CardText>
              {isSexUsersPriority
                ? 'Spend only up to 10 minutes a day to have a better sex'
                : 'Spend only up to 10 minutes a day for your couple’s intimacy'}
            </S.CardText>
          </S.Card>
        </SwiperSlide>
      </Swiper>
    </S.Wrapper>
  )
}
