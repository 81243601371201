import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Spinner } from 'components/Spinner'

import {
  getSubscriptionListAction,
  setCancelOfferTimerValueAction,
  setScreenNameAction,
} from 'root-redux/actions/common'
import {
  selectCancelOfferTimerValue,
  selectSubscriptionList,
} from 'root-redux/selects/common'

import {
  ViewportScrollPercentage,
  useViewportValue,
} from 'hooks/useViewportValue'

import { compareSubscriptionList } from 'helpers/compareSubscriptionList'
import { createProductId } from 'helpers/createProductId'

import { MoneyBackGuarantee } from 'modules/payment/components/MoneyBackGuarantee'
import { Timer } from 'modules/payment/components/Timer'
import { BENEFITS_DATA } from 'modules/payment/pages/constants'
import { selectSubscriptionIntroDifPercents } from 'modules/payment/redux/selects'
import { TrialPlanItemVariant1 } from 'modules/subscriptions/components/trial/TrialPlanItemVariant1'
import { usePaywall } from 'modules/subscriptions/hooks/usePaywall'
import {
  CANCEL_OFFER_V1_HEADER,
  SubscriptionButtonText,
} from 'modules/subscriptions/pages/constants'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { SubscriptionTag, TEN_MINUTES_IN_SECONDS } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import { StyledCancelOfferVariant1 as S } from './CancelOfferVariant1.styles'

export const CancelOfferVariant1: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { search } = useLocation()

  const pricingPlansBlockFirst = useRef<HTMLDivElement>(null)
  const pricingPlansBlockSecond = useRef<HTMLDivElement>(null)

  const { hasPrices, handleSelectSubscription, handleShowPayment } = usePaywall(
    `${PageId.PAYMENT_5}${search}`,
  )
  const viewportValue = useViewportValue()

  const subscriptionsList = useSelector(
    selectSubscriptionList,
    compareSubscriptionList,
  )
  const timerValueFromStore = useSelector(selectCancelOfferTimerValue)
  const discount = useSelector(selectSubscriptionIntroDifPercents)

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.CANCEL_OFFER))
    dispatch(
      getSubscriptionListAction({
        tags: `${SubscriptionTag.CANCEL_OFFER},${SubscriptionTag.NO_TAX}`,
      }),
    )
  }, [dispatch])

  useEffect(() => {
    if (viewportValue > ViewportScrollPercentage.V_0) {
      eventLogger.logPlanPageScrolled(viewportValue, ScreenName.CANCEL_OFFER)
    }
  }, [viewportValue])

  useEffect(() => {
    if (timerValueFromStore === null) {
      dispatch(setCancelOfferTimerValueAction(TEN_MINUTES_IN_SECONDS))
    }
  }, [dispatch, timerValueFromStore])

  useEffect(() => {
    if (subscriptionsList.length) {
      eventLogger.logSalePageShown({
        productIds: subscriptionsList.map(({ mainPrices }) =>
          createProductId({
            periodName: mainPrices.periodName,
            periodQuantity: mainPrices.periodQuantity,
            price: mainPrices.fullPrice,
          }),
        ),
        screenName: ScreenName.CANCEL_OFFER,
      })
    }
  }, [subscriptionsList])

  const handleHeaderButtonClick = useCallback(() => {
    const top = pricingPlansBlockFirst.current?.offsetTop
    if (top) {
      document.body.scrollTo(0, top - CANCEL_OFFER_V1_HEADER)
    }
  }, [pricingPlansBlockFirst])

  const handlePaywallButtonClick = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault()

      const top = pricingPlansBlockSecond.current?.offsetTop

      if (top) {
        document.body.scrollTo(0, top - CANCEL_OFFER_V1_HEADER)
      }

      eventLogger.logPlansPageButtonTapped({
        screenName: ScreenName.CANCEL_OFFER,
        buttonNumber: 2,
        buttonText: SubscriptionButtonText.GET_PLAN_MIDDLE,
      })
    },
    [pricingPlansBlockSecond],
  )

  const handleSetTimerValue = useCallback(
    (timerValue: number) =>
      dispatch(setCancelOfferTimerValueAction(timerValue)),
    [dispatch],
  )

  return !hasPrices ? (
    <Spinner />
  ) : (
    <S.Wrapper>
      <S.Header
        discount={discount}
        onBtnClick={handleHeaderButtonClick}
        timer={
          <Timer
            value={Number(timerValueFromStore)}
            setTimerValue={handleSetTimerValue}
            isHeaderTimer
          />
        }
        hasDiscount
      />
      <S.Column>
        <S.DiscountBanner>
          <S.Discount>
            <Trans
              i18nKey="cancelOfferV1.discountBanner"
              values={{ discount }}
              components={{ span: <span /> }}
            />
          </S.Discount>
        </S.DiscountBanner>
      </S.Column>
      <S.Column>
        <S.Title>{t('cancelOfferV1.chooseYourPlan')}</S.Title>
      </S.Column>
      <S.Column ref={pricingPlansBlockFirst}>
        <S.TrialSelectPlanBlockVariant1
          onSelect={handleSelectSubscription}
          SelectPlanItem={TrialPlanItemVariant1}
        />
        <S.Button
          data-button-number="1"
          data-button-text={SubscriptionButtonText.GET_PLAN_UPPER}
          onClick={handleShowPayment}
        >
          {t('actions.getMyPlan')}
        </S.Button>
        <S.Disclaimer />
      </S.Column>
      <S.Divider />
      <S.Column>
        <S.AppBlock />
        <S.Award />
        <S.NavigationButton onClick={(e) => handlePaywallButtonClick(e)}>
          {t('actions.getMyPlan')}
        </S.NavigationButton>
        <S.Benefits benefits={BENEFITS_DATA} />
        <S.PlanBlock />
      </S.Column>
      <S.Divider />
      <S.Column>
        <S.SuccessStoryBlock />
      </S.Column>
      <S.Divider />
      <S.Column>
        <S.GuaranteeContainer>
          <MoneyBackGuarantee text="commonComponents.guarantee.textV2" />
        </S.GuaranteeContainer>
      </S.Column>
      <S.Divider />
      <S.Column ref={pricingPlansBlockSecond}>
        <S.Title>{t('cancelOfferV1.chooseYourPlan')}</S.Title>
        <S.TrialSelectPlanBlockVariant1
          onSelect={handleSelectSubscription}
          SelectPlanItem={TrialPlanItemVariant1}
        />
        <S.Button
          data-button-number="3"
          data-button-text={SubscriptionButtonText.GET_PLAN_BOTTOM}
          onClick={handleShowPayment}
        >
          {t('actions.getMyPlan')}
        </S.Button>
        <S.Disclaimer />
      </S.Column>
      <S.Divider />
      <S.Column>
        <S.Security />
      </S.Column>
    </S.Wrapper>
  )
}
