import styled, { css } from 'styled-components'

import { Button } from 'components/Button'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton } from 'components/QuestionButton'

import {
  BottomStickyBase,
  ModalContainerBase,
  ModalWrapperBase,
  baseColumnStyles,
} from 'common-styles'
import { BASE_COLUMN_WIDTH, Color } from 'root-constants/common'

const progressValueStyles = css`
  border-radius: 3px;
  transition: all 0.2s ease-out;
`

const progressValueStyles2 = css`
  background-color: ${Color.LILAC_130};
  border-radius: 2px;
  transition: all 0.2s ease-out;
`

type TProgressTitleColor = {
  color: string
}

export const StyledPlanProcessing = {
  Wrapper2: styled(PageContainer)`
    padding: 96px 0 24px;
    height: 100%;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 40px 0;
  `,
  Column2: styled.div`
    ${baseColumnStyles};
  `,
  ProgressBarsList: styled.ul`
    width: 100%;
  `,
  ProgressContainer: styled.li`
    width: 100%;
    margin-bottom: 32px;
  `,
  ProgressHeader: styled.header`
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  ProgressTitle: styled.span`
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
  `,
  ProgressTitle2: styled.h2`
    margin: 0 -10px 35px;
    padding-top: 14px;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: var(--base-text-color);
  `,
  ProgressTitle3: styled.h4<TProgressTitleColor>`
    max-width: 327px;
    width: 100%;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: ${Color.LILAC_120};
    text-align: center;
    margin: 0 auto;
    & span {
      color: ${({ color }) => color};
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
    }
  `,
  Image: styled.div`
    aspect-ratio: 5 / 4;
    height: 100%;
    margin-top: 94px;
    width: 100%;
  `,
  CircleWrapper: styled.div`
    width: 132px;
    height: 132px;
    margin-bottom: 40px;
  `,
  Percents: styled.p`
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: var(--base-text-color);
    padding-bottom: 21px;
  `,
  CarouselContainer: styled.div`
    max-width: 375px;
    padding-left: 54px;
    margin-bottom: 32px;
  `,
  CarouselContainer2: styled.div`
    max-width: 375px;
    margin-bottom: 32px;
  `,
  ReviewContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 24px 8px 6px;
    background-color: ${Color.WHITE_100};
    border: 4px solid ${Color.LILAC_110};
    border-radius: 25px;
  `,
  ReviewAuthor: styled.p`
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  `,
  ReviewRating: styled.img`
    margin-bottom: 8px;
  `,
  ReviewText: styled.p`
    min-height: 78px;
    font-style: italic;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
  `,
  Button: styled(Button)`
    height: 62px;
  `,
  ProgressPercent: styled.span`
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #fff;

    ::after {
      content: '%';
    }
  `,
  ProgressContent: styled.div`
    margin-top: 60px;
    width: 100%;
    margin-bottom: 20px;
  `,
  ProgressInfo: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  `,
  ProgressPlan: styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${Color.LILAC_120};
  `,
  ProgressBar: styled.progress<{ color: string }>`
    width: 100%;
    height: 6px;
    appearance: none;
    border: none;
    display: block;
    border-radius: 3px;
    overflow: hidden;
    background-color: ${Color.WHITE_110};
    transition: all 0.2s ease-out;

    &::-webkit-progress-bar {
      background-color: ${Color.WHITE_110};
    }
    &::-webkit-progress-value {
      ${progressValueStyles};
      background-color: ${({ color }) => color};
    }
    &::-moz-progress-bar {
      ${progressValueStyles};
      background-color: ${({ color }) => color};
    }
    &::-ms-fill {
      ${progressValueStyles};
      background-color: ${({ color }) => color};
    }
  `,
  Progress: styled.progress`
    width: 100%;
    height: 6px;
    appearance: none;
    border: none;
    display: block;
    border-radius: 2px;
    overflow: hidden;
    background-color: #bcc7dd;
    margin-top: 10px;

    &::-webkit-progress-bar {
      background-color: #bcc7dd;
    }
    &::-webkit-progress-value {
      ${progressValueStyles2}
    }
    &::-moz-progress-bar {
      ${progressValueStyles2}
    }
    &::-ms-fill {
      ${progressValueStyles2}
    }
  `,
  Footer: styled.footer`
    ${BottomStickyBase};
    width: ${BASE_COLUMN_WIDTH}px;

    @media (max-height: 640px) {
      position: relative;
      bottom: 0;
    }
  `,
  Description: styled.div`
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: ${Color.LIGHT_TEXT};
  `,
  Modal: styled.div`
    ${ModalWrapperBase};
    background-color: rgba(19, 15, 78, 0.57);
  `,
  ModalContainer: styled.div`
    ${ModalContainerBase};
    padding: 32px 11px 20px;
  `,
  ModalTitle: styled.h2`
    margin-bottom: 28px;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
  `,
  OptionsContainer: styled(StyledOption.OptionsContainer)`
    padding: 0 29px;
  `,
  QuestionButton: styled(QuestionButton)`
    height: 60px;
    justify-content: center;
  `,
}
