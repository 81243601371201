import React, { useMemo } from 'react'

import { Option, OptionType } from 'components/Option'
import { PoliciesGroup } from 'components/PoliciesGroup'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import logo from 'assets/images/sprite/logo.svg'

import { IntroVariant7GlobalStyle, StyledIntro as S } from './Intro.styles'
import { OPTIONS_VALUES } from './constants'

const QUESTION = 'What do you think is more important in a relationship?'

export const IntroVariant7: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <>
      <IntroVariant7GlobalStyle />
      <S.Wrapper>
        <S.Column>
          <S.Logo svg={logo} height={39} />
          <S.Title>
            Let’s start your path <br />
            to a more intimate relationship
          </S.Title>

          <S.FixedContainer>
            <S.Question>{QUESTION}</S.Question>
            <S.OptionsContainer>
              <Option
                {...optionProps}
                value={OPTIONS_VALUES.intimacy}
                disabled={isAnswersDisabled}
              >
                <S.QuestionButton>{OPTIONS_VALUES.intimacy}</S.QuestionButton>
              </Option>
              <Option
                {...optionProps}
                value={OPTIONS_VALUES.sex}
                disabled={isAnswersDisabled}
              >
                <S.QuestionButton>{OPTIONS_VALUES.sex}</S.QuestionButton>
              </Option>
            </S.OptionsContainer>
            <Option
              {...optionProps}
              value={OPTIONS_VALUES.both}
              disabled={isAnswersDisabled}
            >
              <S.OtherButton>{OPTIONS_VALUES.both}</S.OtherButton>
            </Option>
            <PoliciesGroup style={{ opacity: '0.55' }} />
          </S.FixedContainer>
        </S.Column>
      </S.Wrapper>
    </>
  )
}
