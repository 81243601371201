import couple1 from 'assets/images/couple-1.png'
import couple2 from 'assets/images/couple-2.png'
import couple3 from 'assets/images/couple-3.png'
import couple4 from 'assets/images/couple-4.png'

import {
  DifficultyAreas,
  RateRelationshipValue,
  StartAgeValue,
  TagsValue,
} from 'root-constants/common'

export const DIFFICULTY_AREAS_MAP = {
  [DifficultyAreas.INTIMACY]: [
    TagsValue.SEX,
    TagsValue.KINKS,
    TagsValue.FETISHES,
  ],
  [DifficultyAreas.LACK_OF_TRUST]: [
    TagsValue.PAST_RELATIONSHIPS,
    TagsValue.JEALOUSY,
  ],
  [DifficultyAreas.SELF_DEVELOPMENT]: [TagsValue.CAREER, TagsValue.FINANCES],
  [DifficultyAreas.FUTURE_AND_FAMILY]: [
    TagsValue.FAMILY_AND_INLAWS,
    TagsValue.CHILDREN,
    TagsValue.FUTURE_PLANS,
  ],
  [DifficultyAreas.TOXIC_BEHAVIOR]: [
    TagsValue.PERSONAL_BOUNDARIES,
    TagsValue.TOXIC_BEHAVIOR,
  ],
}

export const RELATIONSHIP_ASSESSMENT_MAP = {
  [RateRelationshipValue.FINE]: 'onboarding.rateRelationshipV1.options.fine',
  [RateRelationshipValue.IMPROVEMENT]:
    'onboarding.rateRelationshipV1.options.improvement',
  [RateRelationshipValue.ISSUES]:
    'onboarding.rateRelationshipV1.options.issues',
  [RateRelationshipValue.PERFECT]:
    'onboarding.rateRelationshipV1.options.perfect',
  [RateRelationshipValue.ROOM]: 'onboarding.rateRelationshipV1.options.room',
}

export const START_AGE_TO_IMAGE_MAP = {
  [StartAgeValue.FIRST_AGE_RANGE]: couple1,
  [StartAgeValue.SECOND_AGE_RANGE]: couple2,
  [StartAgeValue.THIRD_AGE_RANGE]: couple3,
  [StartAgeValue.FOURTH_AGE_RANGE]: couple4,
}

export const SATISFACTION_ANIMATION_PATH =
  '/animations/graph_relationship_satisfaction.json'
export const STRUGGLES_ANIMATION_PATH =
  '/animations/graph_relationship_struggles.json'
