import React, { useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Spinner } from 'components/Spinner'
import { SupportAction } from 'components/SupportAction'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { getSubscriptionListAction } from 'root-redux/actions/common'
import {
  selectCurrentVariantCohort,
  selectSubscriptionList,
} from 'root-redux/selects/common'

import { useUserData } from 'hooks/useUserData'

import { compareSubscriptionList } from 'helpers/compareSubscriptionList'
import { createProductId } from 'helpers/createProductId'

import {
  selectSubscriptionMainPrice,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPrice,
} from 'modules/payment/redux/selects'
import { Slider } from 'modules/subscriptions/components/Slider'
import { SubheaderWithTimer } from 'modules/subscriptions/components/SubheaderWithTimer'
import { MixedPlanItemVariant1 } from 'modules/subscriptions/components/mixed/MixedPlanItemVariant1'
import { Security } from 'modules/subscriptions/components/security'
import { TrialPlanItemVariant1 } from 'modules/subscriptions/components/trial/TrialPlanItemVariant1'
import { TrialSelectPlanBlockVariant1 } from 'modules/subscriptions/components/trial/TrialSelectPlanBlockVariant1'
import { usePaywall } from 'modules/subscriptions/hooks/usePaywall'
import { benefits } from 'modules/subscriptions/pages/constants'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import dart from 'assets/images/dart.webp'
import coupleImg from 'assets/images/subscriptions-couple.webp'

import {
  AUTO_RENEWED_BASIS,
  Cohort,
  Color,
  SELECTED_SUBSCRIPTION_PERIOD_NAME,
  SubscriptionTag,
} from 'root-constants/common'

import { StyledTrialSubscriptionsVariant1 as S } from './TrialSubscriptionsVariant1.styles'

const { list, subtitle } = benefits
export const TrialSubscriptionsVariant1: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const dispatch = useDispatch()
  const selectedSubscriptionPeriodQuantity = useSelector(
    selectSubscriptionPeriodQuantity,
  )
  const subscriptionsList = useSelector(
    selectSubscriptionList,
    compareSubscriptionList,
  )
  const selectedTrialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)
  const mainPrice = useSelector(selectSubscriptionMainPrice)
  const subscriptionTrialPeriodPrice = useSelector(selectSubscriptionTrialPrice)
  const cohort = useSelector(selectCurrentVariantCohort)
  const descriptionRef = useRef<HTMLDivElement>(null)
  const selectPlanBlockRef = useRef<HTMLDivElement>(null)
  const { userGoal } = useUserData()
  const { hasPrices, handleSelectSubscription, handleShowPayment } =
    usePaywall(nextPagePath)

  const isMixedSubscriptionPlanType = useMemo(
    () => cohort === Cohort.UPLUV_INTIMACY_1_INTRO_1,
    [cohort],
  )

  useLayoutEffect(() => {
    dispatch(
      getSubscriptionListAction({
        tags: SubscriptionTag.NO_TAX,
      }),
    )
  }, [dispatch])

  useEffect(() => {
    if (!subscriptionsList.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptionsList.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.ONBOARDING,
    })
  }, [subscriptionsList])

  const handleTimerButtonClick = () => {
    selectPlanBlockRef.current?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }
  return !hasPrices ? (
    <Spinner />
  ) : (
    <S.Wrapper>
      <SubheaderWithTimer
        onClick={handleTimerButtonClick}
        elemForComparisonRef={descriptionRef as React.RefObject<HTMLDivElement>}
      />
      <S.Column>
        <S.Title>
          <strong>Connect</strong> With Your Partner on
          <br />a <strong>Deeper</strong> Level
        </S.Title>

        <S.Text ref={descriptionRef}>
          <strong>UpLuv</strong> is designed to help you achieve your
          relationship goals, both <strong>emotionally</strong> and{' '}
          <strong>physically.</strong>
        </S.Text>
      </S.Column>
      <S.CoupleImg>
        <img src={coupleImg} alt="couple" />
      </S.CoupleImg>
      <S.Column>
        <S.GoalWrapper>
          <S.Dart>
            <img src={dart} alt="dart" />
          </S.Dart>
          <S.GoalTitle>Your goal: </S.GoalTitle>
          <S.Goal>{userGoal}</S.Goal>
        </S.GoalWrapper>
        <S.AdvantagesListWrapper>
          <S.Subtitle margin="0 0 13px">What you get</S.Subtitle>
          <ul>
            <S.Item>A deeper emotional connection</S.Item>
            <S.Item>More satisfying sex life</S.Item>
            <S.Item>Improved physical compatibility</S.Item>
            <S.Item>Exciting new intimate experiences</S.Item>
            <S.Item>Greater intimacy and closeness</S.Item>
          </ul>
        </S.AdvantagesListWrapper>
      </S.Column>

      <S.Divider />
      <S.Column>
        <S.Subtitle margin="34px 0 25px">Choose Your Plan</S.Subtitle>
        <TrialSelectPlanBlockVariant1
          ref={selectPlanBlockRef}
          onSelect={handleSelectSubscription}
          SelectPlanItem={
            isMixedSubscriptionPlanType
              ? MixedPlanItemVariant1
              : TrialPlanItemVariant1
          }
        />
        <S.Button onClick={handleShowPayment}>Get My Plan</S.Button>
      </S.Column>
      <S.Divider marginBottom={40} />
      <S.Column>
        <S.Guarantee text="commonComponents.guarantee.textV1" />
        <S.Subtitle margin="0 0 25px" color="#fefad4">
          <span>200K+ </span>couples strengthened their bond with UpLuv
        </S.Subtitle>
        <Slider style={{ marginBottom: 18 }} />
      </S.Column>
      <S.Divider marginBottom={40} />
      <S.Column>
        <S.Subtitle color={Color.LILAC_130}>{subtitle}</S.Subtitle>
        <S.BenefitsWrapper>
          {list.map(({ id, text }) => (
            <S.BenefitsList key={id}>{text}</S.BenefitsList>
          ))}
        </S.BenefitsWrapper>
      </S.Column>
      <S.Divider marginBottom={40} />
      <S.Column>
        <S.Subtitle margin="40px 0 25px" color={Color.LILAC_130}>
          Invest In Your Relationship <span>TODAY</span>:
        </S.Subtitle>
        <TrialSelectPlanBlockVariant1
          ref={selectPlanBlockRef}
          onSelect={handleSelectSubscription}
          SelectPlanItem={
            isMixedSubscriptionPlanType
              ? MixedPlanItemVariant1
              : TrialPlanItemVariant1
          }
        />
        <S.Button onClick={handleShowPayment}>Start now</S.Button>
        {!subscriptionTrialPeriodPrice ? (
          <S.MoneyBackText>
            To avoid any disruption of the service, the subscription you
            selected will automatically renew on a weekly basis at the price of
            ${mainPrice}. To learn more, read our <TermsOfUseLink /> or contact
            us at <SupportAction />
          </S.MoneyBackText>
        ) : (
          <S.MoneyBackText>
            Extended{' '}
            {AUTO_RENEWED_BASIS[selectedSubscriptionPeriodQuantity || 1]} after{' '}
            {selectedSubscriptionPeriodQuantity}-
            {SELECTED_SUBSCRIPTION_PERIOD_NAME[selectedTrialPeriodDays]} intro
            offer at the full price of ${mainPrice}. To learn more, visit our{' '}
            <TermsOfUseLink /> or contact us at <SupportAction />
          </S.MoneyBackText>
        )}
      </S.Column>
      <S.Column>
        <Security />
      </S.Column>
    </S.Wrapper>
  )
}
