import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { OptionType } from 'components/Option'
import { PageContainer } from 'components/PageContainer'
import { StickyContainer } from 'components/StickyContainer'
import { TitleContainer } from 'components/TitleContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledOptionsPage as S } from './OptionsPage.styles'

type TOption = {
  key: string
  value: string
}

type TProps = {
  options: TOption[]
  translationFamily: string
  hasTags?: boolean
  customOptions?: TOption[]
} & TPageProps

export const CheckboxOptionsPage: React.FC<TProps> = ({
  pageId,
  nextPagePath,
  options,
  translationFamily,
  hasTags = false,
  customOptions = [],
}) => {
  const { t, i18n } = useTranslation()

  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId,
    question: t(`${translationFamily}.question`, { lng: 'en' }),
    nextPagePath,
  })

  const customOptionsValues = useMemo(
    () => customOptions.map(({ value }) => value),
    [customOptions],
  )

  const hasDescriptionKey = useMemo(
    () => i18n.exists(`${translationFamily}.description`),
    [translationFamily],
  )

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          customOptionsValues.includes(value)
            ? setAnswers([value])
            : setAnswers([...answers, value])
        } else {
          setAnswers(answers.filter((answer) => answer !== value))
        }
      },
    }),
    [pageId, answers, customOptionsValues],
  )

  const checkIsOptionDisabled = useCallback(
    (value: string) => {
      const isCustomOption = customOptionsValues.includes(value)

      if (isCustomOption) {
        return false
      }

      return answers.some((answer) => customOptionsValues.includes(answer))
    },
    [customOptionsValues, answers],
  )

  return (
    <PageContainer>
      <S.Column>
        <TitleContainer
          title={
            <S.QuestionTitle>
              <Trans i18nKey={`${translationFamily}.question`} />
            </S.QuestionTitle>
          }
          subtitle={
            hasDescriptionKey && (
              <S.QuestionDescription>
                <Trans i18nKey={`${translationFamily}.description`} />
              </S.QuestionDescription>
            )
          }
        />
        <S.CheckboxOptionsContainer hasTags={hasTags}>
          {options.map(({ key, value }) => {
            const isDisabled = checkIsOptionDisabled(value)

            return hasTags ? (
              <S.Option
                {...optionProps}
                hasTags
                key={key}
                value={value}
                disabled={isDisabled}
                checked={answers.includes(value)}
              >
                <S.TagButton>
                  {t(`${translationFamily}.options.${key}`)}
                </S.TagButton>
              </S.Option>
            ) : (
              <S.Option
                {...optionProps}
                key={key}
                value={value}
                disabled={isDisabled}
                checked={answers.includes(value)}
              >
                <S.QuestionButton
                  hasCheckboxIcon={!customOptionsValues.includes(value)}
                >
                  {t(`${translationFamily}.options.${key}`)}
                </S.QuestionButton>
              </S.Option>
            )
          })}
        </S.CheckboxOptionsContainer>
        <StickyContainer>
          <S.Button
            disabled={!answers.length}
            onClick={() => handleContinue(answers)}
          >
            {t('actions.continue')}
          </S.Button>
        </StickyContainer>
      </S.Column>
    </PageContainer>
  )
}
