import styled from 'styled-components'

import { basePageStyles } from 'common-styles'
import {
  HEADER_HEIGHT,
  HEADER_WITH_PROGRESS_BAR_HEIGHT,
} from 'root-constants/common'

type TProps = {
  paddingTop: typeof HEADER_HEIGHT | typeof HEADER_WITH_PROGRESS_BAR_HEIGHT | 0
}

export const StyledPageContainer = {
  Container: styled.main<TProps>`
    ${({ paddingTop }) => `
      ${basePageStyles};
      padding-top: ${paddingTop}px;
    `}
  `,
}
