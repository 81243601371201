import styled from 'styled-components'

import { Color } from 'root-constants/common'

export const StyledBenefitsV1 = {
  Title: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    color: ${Color.LILAC_120};
    margin: 0 0 8px 0;
  `,
  ListItem: styled.li`
    display: flex;
    align-items: center;
    aspect-ratio: 280/40;
    height: 40px;
    width: 100%;

    &:not(:last-of-type) {
      margin: 0 0 6px 0;
    }
  `,
  ListPoint: styled.img`
    margin: 0 12px 0 0;
  `,
  ListText: styled.span`
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: ${Color.WHITE_100};
  `,
}
