import styled, { css } from 'styled-components'

import { Button } from 'components/Button'
import { SvgImage } from 'components/SvgImage'

import { BottomStickyBase, baseColumnStyles } from 'common-styles'
import {
  BASE_COLUMN_WIDTH,
  BIG_COLUMN_WIDTH,
  Color,
  MAX_PHONE_WIDTH,
} from 'root-constants/common'

const commonAutoComplereWrapperStyles = css`
  width: 100%;
  max-height: 160px;
  padding: 0 15px;
  position: absolute;
  top: 56px;
  left: 0;
  border-radius: 12px;
  overflow-y: auto;
  background-color: ${Color.WHITE_100};
`

export const StyledEmail = {
  Content: styled.div`
    ${baseColumnStyles};
    padding: 16px 20px;
    width: 100%;

    @media (min-width: ${MAX_PHONE_WIDTH}px) {
      width: ${BIG_COLUMN_WIDTH}px;
    }
  `,
  TitleImg: styled.img`
    padding-bottom: 6px;
    max-width: none;
  `,
  Subtitle: styled.h6`
    margin: 24px 0 12px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--base-text-color);

    strong {
      font-weight: 700;
    }
  `,
  Subtitle2: styled.h6`
    margin: 24px 0 12px;
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    line-height: normal;
    color: var(--base-text-color);

    strong {
      font-weight: 700;
    }

    span {
      color: ${Color.LILAC_130};
      font-weight: 700;
    }
  `,
  SubtitleV4: styled.h6`
    color: ${Color.LILAC_120};
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
    margin: 24px 0;
    text-align: center;

    strong {
      color: ${Color.LILAC_130};
    }
  `,
  Info: styled.div`
    background-color: ${Color.WHITE_100};
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    padding: 12px 16px;
    row-gap: 2px;
  `,
  InfoTitle: styled.p`
    color: ${Color.DEFAULT_TEXT};
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
  `,
  InfoContent: styled.p`
    color: ${Color.LIGHT_TEXT};
    font-size: 10px;
    line-height: 14px;
  `,
  InputWrapper: styled.div`
    width: 100%;
    position: relative;
  `,
  AutoCompleteWrapper: styled.div`
    ${commonAutoComplereWrapperStyles};
    filter: drop-shadow(0px 7px 20px #c8d4d8);
  `,
  AutoCompleteWrapperVariant2: styled.div`
    ${commonAutoComplereWrapperStyles};
  `,
  AutoCompleteButton: styled.button`
    height: 50px;
    width: 100%;
    padding: 0;
    display: block;
    cursor: pointer;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 50px;
    text-decoration: none;
    text-align: left;
    color: ${Color.DEFAULT_TEXT};
    background-color: transparent;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    direction: rtl;

    :not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  `,
  AutoCompleteButtonTextWrapper: styled.span`
    unicode-bidi: plaintext;
  `,
  Note: styled.div`
    display: flex;
    flex: 0 0 auto;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
    margin-bottom: 20px;
  `,
  LockImgVariant: styled(SvgImage)`
    width: 22px;
    margin-left: 3px;
    fill: ${Color.LILAC_130};
  `,
  Description: styled.p`
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: ${Color.LIGHT_TEXT};
  `,
  Button: styled(Button)`
    margin-bottom: 22px;
  `,
  ButtonVariant2: styled(Button)`
    height: 62px;
    margin-bottom: 16px;
  `,
  Footer: styled.footer`
    ${BottomStickyBase};
    width: ${BASE_COLUMN_WIDTH}px;
    bottom: 16px;

    @media (max-height: 640px) {
      position: relative;
      bottom: 0;
    }
  `,
}
