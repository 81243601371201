import styled from 'styled-components'

export const StyledAdvantagesSlider = {
  Wrapper: styled.div`
    width: 100%;

    .swiper {
      width: 100%;
      overflow: visible;
    }

    .swiper-wrapper {
      display: flex;
    }

    .swiper-slide {
      position: relative;
      width: 185px;
      flex-shrink: 0;
    }
  `,
  Card: styled.div`
    position: relative;
    height: 215px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: contain;
  `,
  CardText: styled.p`
    position: absolute;
    width: 100%;
    padding: 0 10px;
    bottom: 20px;
    left: 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    color: #fff;
  `,
}
