import React, { ReactNode, SyntheticEvent, useMemo } from 'react'

import { t } from 'i18next'

import { OuterLink } from 'components/OuterLink'

import { getCurrentEnvironment } from 'helpers/getCurrentEnvironment'

import { CONTACT_FORM_LINKS, CurrentEnvironment } from 'root-constants/common'

type TSupportActionProps = {
  children?: ReactNode
  className?: string
  onClick?: (event: SyntheticEvent<HTMLAnchorElement>) => void
}
export const SupportAction: React.FC<TSupportActionProps> = ({
  children = t('actions.contactSupport'),
  className,
  onClick,
}: TSupportActionProps) => {
  const { isDevEnvironment, isProdEnvironment, isStageEnvironment } =
    getCurrentEnvironment()

  const link = useMemo(() => {
    switch (true) {
      case isDevEnvironment:
        return CONTACT_FORM_LINKS[CurrentEnvironment.DEV]

      case isStageEnvironment:
        return CONTACT_FORM_LINKS[CurrentEnvironment.STAGE]

      case isProdEnvironment:
        return CONTACT_FORM_LINKS[CurrentEnvironment.PROD]

      default:
        return CONTACT_FORM_LINKS[CurrentEnvironment.DEV]
    }
  }, [isDevEnvironment, isProdEnvironment, isStageEnvironment])

  return (
    <OuterLink className={className} href={link} onClick={(e) => onClick?.(e)}>
      {children}
    </OuterLink>
  )
}
