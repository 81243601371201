import React, { useMemo } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton } from 'components/QuestionButton'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledHatedThings as S } from './HatedThings.styles'

const QUESTION = `What are your least favorite things to do sexually?`
const OPTION_VALUES = {
  oralSex: 'Oral sex',
  withLights: 'Sex with the lights on',
  newPositions: 'Sex in new positions',
  withPartner: 'Sex with my partner',
  other: 'Other',
}

export const HatedThingsVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, handleContinue, pageId, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.QuestionTitle>{QUESTION}</S.QuestionTitle>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.oralSex}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.oralSex}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.withLights}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.withLights}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.newPositions}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.newPositions}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.withPartner}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.withPartner}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.other}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.other}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
