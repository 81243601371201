import slide1 from 'assets/images/slide-1.jpg'
import slide2 from 'assets/images/slide-2.jpg'
import slide3 from 'assets/images/slide-3.jpg'
import slide4 from 'assets/images/slide-4.jpg'
import slide5 from 'assets/images/slide-5.jpg'

import {
  CommonValue,
  FrequencyOfAffectionValue,
  IntimacyProblemsValue,
  SexQuantityValue,
  StatusValue,
} from 'root-constants/common'

export const PERIOD_QUANTITY_TO_MARKUP_MAP = {
  1: 'monthly',
  3: 'quarterly',
  6: 'semi-annually',
}

export const STATUS_TO_MARKUP_MAP = {
  [StatusValue.DATING]: 'Dating',
  [StatusValue.SERIOUS]: 'Serious relationship',
  [StatusValue.MARRIED]: 'Married',
  [StatusValue.COMPLICATED]: 'Complicated',
}

export const SATISFACTION_TO_MARKUP_MAP = {
  [CommonValue.YES]: 'Your sex life is mostly satisfying',
  [CommonValue.NO]: 'Your sex life is not satisfying',
}

export const DRIVE_TO_MARKUP_MAP = {
  [CommonValue.YES]: 'Same',
  [CommonValue.NO]: 'Different',
}

export const SEX_QUANTITY_TO_MARKUP_MAP = {
  [SexQuantityValue.ONCE_IN_A_WHILE]: 'Once in a while',
  [SexQuantityValue.ONE_TWO]: '1-2 times a week',
  [SexQuantityValue.THREE_FIVE]: '3-5 times a week',
  [SexQuantityValue.SIX]: 'More than 6 times a week',
}

export const FREQUENCY_OF_AFFECTION_TO_MARKUP_MAP = {
  [FrequencyOfAffectionValue.ALWAYS]: 'Often',
  [FrequencyOfAffectionValue.SOMETIMES]: 'Seldom',
  [FrequencyOfAffectionValue.RARELY]: 'Seldom',
  [FrequencyOfAffectionValue.NEVER]: 'Never',
}

export const INTIMACY_PROBLEMS_TO_MARKUP_MAP = {
  [IntimacyProblemsValue.STOPPED_HAVING_SEX]: 'Sexual activity is low',
  [IntimacyProblemsValue.LESS_INTIMACY]:
    'There is much less intimacy compared to before',
  [IntimacyProblemsValue.BORING]:
    'Physical intimacy has become routine and boring',
  [IntimacyProblemsValue.DIFFERENCES]:
    'Different love languages and difficulties in connection',
  [IntimacyProblemsValue.STRESS]:
    'The level of intimacy has decreased due to stress and external pressures',
  [IntimacyProblemsValue.NOT_NOTICE]:
    'No noticeable changes have been observed',
  [IntimacyProblemsValue.NOT_SAY]: 'Not disclosed',
}

export const SLIDER_DATA = [
  {
    image: slide1,
    userName: 'James, 40',
    text: `"I was skeptical at first, but this program really exceeded my expectations. It's given me and my partner a roadmap to deeper intimacy and a more fulfilling relationship."`,
  },
  {
    image: slide2,
    userName: 'Olivia, 32',
    text: `"I can't believe how much closer my partner and I feel after going through this program. We're communicating better, feeling more connected, and our physical intimacy has improved too."`,
  },
  {
    image: slide3,
    userName: 'Emily, 28',
    text: `"Thank you for creating this program. It's helped me and my partner work through some tough issues and build a stronger, more loving relationship." `,
  },

  {
    image: slide4,
    userName: 'Sarah, 35 and Tom, 39',
    text: `"We were struggling to connect on a deeper level, but this plan really helped us."`,
  },
  {
    image: slide5,
    userName: 'Anna, 28',
    text: `"This plan gave us the tools we needed to communicate better and work through our issues. We're so grateful for this program!" `,
  },
]

export const DAYS_IN_A_MONTH = 30
export const DAYS_IN_A_WEEK = 7
