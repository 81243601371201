import styled, { css } from 'styled-components'

import priceImage from 'assets/images/price-bg-grey.svg'
import activePriceImage from 'assets/images/price-bg.svg'
import checkIcon from 'assets/images/success.svg'

import { Color } from 'root-constants/common'

const activePlanItemStyles = css`
  border-color: ${Color.GREEN};

  ::before {
    background: center url(${checkIcon}) no-repeat;
    background-size: 41px;
  }
`

const defaultPlanItemStyles = css`
  position: relative;

  &::after {
    content: 'most popular';
    display: block;
    padding: 0 16px;
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    height: 26px;
    font-size: 10px;
    line-height: 26px;
    font-weight: 800;
    text-transform: uppercase;
    color: #ffffff;
    background-color: ${Color.GREEN};
    border-radius: 100px;
  }
`
const baseOldPriceStyles = css`
  text-decoration: line-through;
  text-decoration-color: ${Color.LIGHT_TEXT};
`

const PlanItem = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px 11px 21px 51px;
  border-radius: 16px;
  border: 3px solid transparent;
  color: ${Color.LIGHT_TEXT};
  background-color: ${Color.WHITE_120};
  transition: all 0.2s ease-out;

  &[data-is-default='true'] {
    ${defaultPlanItemStyles};
  }

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 16px;
    width: 24px;
    height: 24px;
    margin: auto;
    border: 2px solid rgba(10, 50, 77, 0.45);
    border-radius: 50%;
  }

  &[data-is-selected='true']::before {
    border-color: ${Color.GREEN};
  }
`

export const StyledTrialPlanItemVariant1 = {
  PlanItem: styled(PlanItem)`
    &[data-is-selected='true'] {
      ${activePlanItemStyles};
    }
  `,
  CurrentPriceContainer: styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
  `,
  PlanTitle: styled.strong`
    font-weight: 900;
    color: ${Color.DEFAULT_TEXT};
    font-size: 15px;
    line-height: 22px;
    text-transform: uppercase;
    margin-bottom: 3px;
  `,
  OldPrice: styled.span`
    ${baseOldPriceStyles};
    margin-right: 13px;

    &[data-is-selected='true'] {
      text-decoration-color: ${Color.ERROR};
    }
  `,
  NewPrice: styled.span``,
  OldDayPrice: styled.div`
    ${baseOldPriceStyles};
    align-self: flex-end;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;

    &[data-is-selected='true'] {
      text-decoration-color: ${Color.ERROR};
    }
  `,
  PlanPrice: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80px;
    height: 40px;
    padding-left: 25px;
    background-image: url(${priceImage});
    background-position: left center;
    background-size: contain;
    background-repeat: no-repeat;
    color: ${Color.LIGHT_TEXT};

    &[data-is-selected='true'] {
      background-image: url(${activePriceImage});
      color: #ffffff;
    }

    strong {
      font-weight: 900;
      font-size: 16px;
      line-height: 16px;
    }
  `,
  Period: styled.span`
    font-weight: 500;
    font-size: 8px;
    line-height: 11px;
  `,
}
