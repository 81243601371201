import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  selectSubscriptionIntroDifAmount,
  selectSubscriptionIntroDifPercents,
  selectSubscriptionMainPrice,
  selectSubscriptionTrialDailyPrice,
  selectSubscriptionTrialLookupKey,
  selectSubscriptionTrialPrice,
} from 'modules/payment/redux/selects'

import { TimeInterval } from 'root-constants/common'

import { StyledMixedPaymentSummaryV2 as V2 } from './MixedPaymentSummary.styles'

export const MixedPaymentSummary2 = () => {
  const { t } = useTranslation()

  const mainPrice = useSelector(selectSubscriptionMainPrice)
  const trialPriceId = useSelector(selectSubscriptionTrialLookupKey)
  const trialPrice = useSelector(selectSubscriptionTrialPrice)
  const discountAmount = useSelector(selectSubscriptionIntroDifAmount)
  const discountPercent = useSelector(selectSubscriptionIntroDifPercents)
  const trialDailyPrice = useSelector(selectSubscriptionTrialDailyPrice)

  const oldPrice = useMemo(
    () => (trialPrice + discountAmount).toFixed(2),
    [trialPrice, discountAmount],
  )

  return trialPriceId ? (
    <V2.Wrapper>
      <V2.SelectedPrice>
        <Trans
          i18nKey="paymentV4.personalizedIntimacyPlan"
          values={{ price: oldPrice }}
          components={{ span: <span /> }}
        />
      </V2.SelectedPrice>
      <V2.TrialPrice>
        <Trans
          i18nKey="paymentV4.introductoryOfferDiscount"
          values={{ price: discountAmount }}
          components={{ span: <span /> }}
        />
      </V2.TrialPrice>
      <V2.Divider />
      <V2.TotalPerDay>
        <Trans
          i18nKey="paymentV4.totalPerDay"
          values={{
            priceInterval: TimeInterval.DAY,
            price: trialDailyPrice,
          }}
          components={{ span: <span /> }}
        />
      </V2.TotalPerDay>
      <V2.Total>
        <Trans
          i18nKey="paymentV4.total"
          values={{ price: trialPrice }}
          components={{ span: <span /> }}
        />
      </V2.Total>
      <V2.YouSave>
        {t('paymentV4.youSave', {
          price: discountAmount,
          discountPercent,
        })}
      </V2.YouSave>
    </V2.Wrapper>
  ) : (
    <V2.SelectedPrice>
      <Trans
        i18nKey="paymentV4.personalizedIntimacyPlan"
        values={{ price: mainPrice }}
        components={{ span: <span /> }}
      />
    </V2.SelectedPrice>
  )
}
