import styled, { createGlobalStyle } from 'styled-components'

import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton } from 'components/QuestionButton'
import { SvgImage } from 'components/SvgImage'

import bgImg5 from 'assets/images/intro-7.jpg'

import { baseColumnStyles } from 'common-styles'
import { Color } from 'root-constants/common'

export const IntroVariant7GlobalStyle = createGlobalStyle`
body {
      background-image: url(${bgImg5});
      background-size: 100% auto;
      background-position: center top;
      background-color: #070525;
   }
`

export const StyledIntro = {
  Wrapper: styled(PageContainer)`
    background-position: top center;
    background-repeat: no-repeat;
    min-height: 100%;
    padding: 0;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    padding: 10px 0;
  `,
  FixedContainer: styled.div`
    position: fixed;
    width: 311px;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  `,
  Logo: styled(SvgImage)`
    fill: var(--header-logo-color);
    margin-bottom: 16px;
  `,
  Title: styled.h1`
    font-weight: 700;
    font-size: 26px;
    line-height: 35px;
    color: ${Color.LILAC_120};
    text-align: center;
  `,
  Question: styled.h2`
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 23px;
    color: ${Color.LILAC_120};
  `,
  OptionsContainer: styled(StyledOption.OptionsContainer)`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-bottom: 9px;

    > * {
      width: calc(50% - 6px);
      flex-shrink: 0;
    }
  `,
  QuestionButton: styled(QuestionButton)`
    justify-content: center;
    font-size: 18px;
    color: ${Color.DEFAULT_TEXT};
  `,
  OtherButton: styled.div`
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: #fff;
    text-decoration: underline;
    text-align: center;
    padding-bottom: 10px;
  `,
}
