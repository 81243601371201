import styled from 'styled-components'

import { Button } from 'components/Button'
import { PageContainer } from 'components/PageContainer'

import { Guarantee } from 'modules/subscriptions/components/Guarantee'
import { SubscriptionDescription } from 'modules/subscriptions/components/SubscriptionDescription'

import bgImage from 'assets/images/subscriptions-bg.svg'

import { baseColumnStyles } from 'common-styles'
import { Color } from 'root-constants/common'

export const StyledSubscriptionsVariant2 = {
  Wrapper: styled(PageContainer)`
    min-height: 100%;
    background-image: url(${bgImage});
    background-position: center 0;
    background-size: 550px auto;
    background-repeat: no-repeat;
  `,
  Column: styled.div`
    ${baseColumnStyles};
  `,
  Guarantee: styled(Guarantee)`
    margin: 0 0 18px 0;
  `,
  Title: styled.h1`
    color: var(--base-text-color);
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    text-align: center;
    margin: 16px 0 24px;
  `,
  TitleDescription: styled.span`
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: ${Color.LILAC_120};
    margin: 8px 0 35px;
  `,
  Text: styled.span`
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: ${Color.LILAC_120};
  `,
  PlansTitle: styled.h2`
    color: var(--base-text-color);
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    text-align: center;
    margin: 16px 0 40px 0;
  `,
  Subtitle: styled.h2`
    color: var(--base-text-color);
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    text-align: center;
    margin: 10px 0 0 0;
  `,
  Divider: styled.div`
    height: 1px;
    width: 100%;
    background-color: #362f99;
    margin: 0 0 22px 0;
  `,
  Timer: styled.div`
    width: 100%;
    height: 70px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${Color.LILAC_130};
    border-radius: 16px;
    font-size: 22px;
    font-weight: 700;
    line-height: 40px;
    color: #0a082f;

    strong {
      font-size: 38px;
    }
  `,
  SubscriptionDescription: styled(SubscriptionDescription)`
    margin: 0 0 24px 0;
  `,
  Button: styled(Button)`
    margin: 0 0 26px 0;
  `,
}
