import React from 'react'
import { useTranslation } from 'react-i18next'

import { Navigation, Pagination } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { SCREENS } from 'modules/payment/components/AppBlock/constants'

import { StyledAppBlock as S } from './AppBlock.styles'

type TProps = {
  className?: string
}
export const AppBlock: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  return (
    <div className={className}>
      <S.Title>{t('paymentTrialV2.appBlock.title')}</S.Title>
      <S.CarouselContainer>
        <Carousel
          pagination={{ dynamicBullets: true, clickable: true }}
          navigation
          modules={[Pagination, Navigation]}
          slidesPerView={2}
          centeredSlides
          loop
        >
          {SCREENS.map((screen) => (
            <SwiperSlide key={screen}>
              <S.ScreenContainer>
                <img src={screen} alt="screen" />
              </S.ScreenContainer>
            </SwiperSlide>
          ))}
        </Carousel>
      </S.CarouselContainer>
    </div>
  )
}
