import React, { useMemo } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton2 } from 'components/QuestionButton2'
import { TitleContainer } from 'components/TitleContainer'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledConversationsFrequency as S } from './ConversationsFrequency.styles'

const QUESTION = 'How often do you and your partner have deep conversations?'
const OPTION_VALUES = {
  daily: `We have deep conversations daily, we're very close`,
  almostAlways: `Almost always, it's a strong connection`,
  often: `Often, we enjoy discussing deeper topics`,
  sometimes: `Sometimes, but it's a struggle`,
  rarely: `Rarely, it's hard to open up emotionally`,
}

export const ConversationsFrequencyVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <TitleContainer title={<S.QuestionTitle>{QUESTION}</S.QuestionTitle>} />
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.daily}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.daily}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.almostAlways}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.almostAlways}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.often}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.often}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.sometimes}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.sometimes}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.rarely}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.rarely}</QuestionButton2>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
