import React from 'react'

import { Button } from 'components/Button'
import { PageContainer } from 'components/PageContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import couple from 'assets/images/couple.png'

import { StyledAdmitCheating as S } from './AdmitCheating.styles'

export const AdmitCheatingVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const handleContinue = useNextStep({
    pageId,
    question: 'Did you know that',
    nextPagePath,
  })

  return (
    <PageContainer>
      <S.Column>
        <S.Title>Did you know that</S.Title>
        <S.Subtitle>
          <span>20%</span> of <span>people in committed relationships</span>{' '}
          admit to cheating at least once?
        </S.Subtitle>

        <S.ImageContainer>
          <img src={couple} alt="couple" />
        </S.ImageContainer>

        <S.Description>
          In their relationship, individuals may be <span>unsatisfied</span>{' '}
          with the frequency of sex, style of sex, or specific sexual behaviors
          they want. This can contribute to their <span>reasons to cheat.</span>
        </S.Description>
        <Button onClick={() => handleContinue('')}>Continue</Button>
      </S.Column>
    </PageContainer>
  )
}
