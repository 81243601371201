import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ISelectPlanItemProps } from 'modules/subscriptions/types'

import {
  CURRENCY_SYMBOLS,
  TRIAL_DESCRIPTION_CONTEXT,
} from 'root-constants/common'

import { StyledTrialPlanItemVariant1 as S } from '../trial/TrialPlanItemVariant1.styles'

export const MixedPlanItemVariant1: React.FC<ISelectPlanItemProps> = ({
  subscription: {
    isDefault,
    currency,
    introDiff,

    mainPrices,
    trialPrices: { fullPrice, durationDays, daily, oldPrices },
  },
  isSelected,
}) => {
  const { t } = useTranslation()

  const oldPrice = useMemo(
    () => (fullPrice + introDiff.discountAmount).toFixed(2),
    [fullPrice, introDiff],
  )

  const oldDailyPrice = useMemo(
    () => oldPrices.beforeCustomDiscount.daily,
    [oldPrices],
  )

  return (
    <S.PlanItem data-is-selected={isSelected} data-is-default={isDefault}>
      {fullPrice ? (
        <>
          <S.CurrentPriceContainer>
            <S.PlanTitle>
              {t('subscriptions.trialPlanPeriod', {
                periodName: mainPrices.periodName,
                periodQuantity: mainPrices.periodQuantity,
                trialDays: durationDays,
                context: TRIAL_DESCRIPTION_CONTEXT[durationDays],
              })}
            </S.PlanTitle>
            <div>
              {oldPrice && (
                <S.OldPrice>
                  {CURRENCY_SYMBOLS[currency]}
                  {oldPrice}
                </S.OldPrice>
              )}
              <S.NewPrice>
                {CURRENCY_SYMBOLS[currency]}
                {fullPrice}
              </S.NewPrice>
            </div>
          </S.CurrentPriceContainer>

          {oldDailyPrice && (
            <S.OldDayPrice>
              {CURRENCY_SYMBOLS[currency]}
              {oldDailyPrice}
            </S.OldDayPrice>
          )}

          {daily && (
            <S.PlanPrice data-is-selected={isSelected}>
              <strong>
                {CURRENCY_SYMBOLS[currency]}
                {daily}
              </strong>
              <S.Period>{t('subscriptions.perDay')}</S.Period>
            </S.PlanPrice>
          )}
        </>
      ) : (
        <>
          <S.CurrentPriceContainer>
            <S.PlanTitle>
              {t('subscriptions.noTrialPlanPeriodWeek', {
                periodQuantity: mainPrices.periodQuantity,
              })}
            </S.PlanTitle>
            <div>
              <span>
                {CURRENCY_SYMBOLS[currency]}
                {mainPrices.fullPrice}
              </span>
            </div>
          </S.CurrentPriceContainer>

          {mainPrices.daily && (
            <S.PlanPrice data-is-selected={isSelected}>
              <strong>
                {CURRENCY_SYMBOLS[currency]} {mainPrices.daily}
              </strong>
              <S.Period>{t('subscriptions.perDay')}</S.Period>
            </S.PlanPrice>
          )}
        </>
      )}
    </S.PlanItem>
  )
}
