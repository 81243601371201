import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledBenefitsV3 as S } from './BenefitsV3.styles'

type TProps = {
  className?: string
  benefits: string[]
}

export const BenefitsV3: React.FC<TProps> = ({ className, benefits }) => {
  const { t } = useTranslation()

  return (
    <ul className={className}>
      {benefits.map((benefit) => (
        <S.ListItem key={benefit}>{t(benefit)}</S.ListItem>
      ))}
    </ul>
  )
}
