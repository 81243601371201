import React, { forwardRef, useCallback } from 'react'
import { useSelector } from 'react-redux'

import { OptionType } from 'components/Option'

import { selectSubscriptionList } from 'root-redux/selects/common'

import { selectSubscriptionId } from 'modules/payment/redux/selects'
import { ISelectPlanItemProps } from 'modules/subscriptions/types'

import { ISubscription } from 'models/subscriptions.model'

import { StyledSelectPlanVariant1 as S } from './SelectPlanBlockVariant2.styles'

type TProps = {
  onSelect: (subscription?: ISubscription) => void
  SelectPlanItem: React.FC<ISelectPlanItemProps>
}

export const SelectPlanBlockVariant2 = forwardRef<HTMLDivElement, TProps>(
  ({ onSelect, SelectPlanItem }, ref) => {
    const subscriptions = useSelector(selectSubscriptionList)
    const selectedSubscriptionId = useSelector(selectSubscriptionId)

    const handleChange = useCallback(
      (value) => {
        const checkedSubscription = subscriptions.find(({ id }) => id === value)
        onSelect(checkedSubscription)
      },
      [onSelect, subscriptions],
    )

    return (
      <S.Wrapper ref={ref}>
        {subscriptions.map((subscription) => (
          <S.Option
            type={OptionType.RADIO}
            name="subscription"
            value={subscription.id}
            key={subscription.id}
            onChange={handleChange}
          >
            <SelectPlanItem
              subscription={subscription}
              isSelected={subscription.id === selectedSubscriptionId}
            />
          </S.Option>
        ))}
      </S.Wrapper>
    )
  },
)
