import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  selectCurrency,
  selectSubscriptionIntroDifAmount,
  selectSubscriptionIntroDifPercents,
  selectSubscriptionMainPrice,
  selectSubscriptionTrialLookupKey,
  selectSubscriptionTrialPrice,
} from 'modules/payment/redux/selects'

import { CURRENCY_SYMBOLS } from 'root-constants/common'

import { StyledMixedPaymentSummary as S } from './MixedPaymentSummary.styles'

export const MixedPaymentSummary1 = () => {
  const { t } = useTranslation()

  const mainPrice = useSelector(selectSubscriptionMainPrice)
  const currency = useSelector(selectCurrency)
  const trialPriceId = useSelector(selectSubscriptionTrialLookupKey)
  const trialPrice = useSelector(selectSubscriptionTrialPrice)
  const discountAmount = useSelector(selectSubscriptionIntroDifAmount)
  const discountPercent = useSelector(selectSubscriptionIntroDifPercents)

  const oldPrice = useMemo(
    () => (trialPrice + discountAmount).toFixed(2),
    [trialPrice, discountAmount],
  )

  return trialPriceId ? (
    <S.Wrapper>
      <S.TrialPriceContainer>
        <span>{t('payments.mixedPaymentSummaryV1.title')}</span>
        <strong>
          {CURRENCY_SYMBOLS[currency]}
          {oldPrice}
        </strong>
      </S.TrialPriceContainer>
      <S.TrialPriceContainer>
        <span>{t('payments.mixedPaymentSummaryV1.introOfferDiscount')}</span>
        <S.Discount>
          -{CURRENCY_SYMBOLS[currency]}
          {discountAmount}
        </S.Discount>
      </S.TrialPriceContainer>
      <S.TrialPriceContainer>
        <strong>{t('payments.mixedPaymentSummaryV1.total')}</strong>
        <strong>
          {CURRENCY_SYMBOLS[currency]}
          {trialPrice}
        </strong>
      </S.TrialPriceContainer>
      <S.DiscountDescription>
        <Trans
          i18nKey="payments.mixedPaymentSummaryV1.youSave"
          values={{
            currency: CURRENCY_SYMBOLS[currency],
            amount: discountAmount,
            percent: discountPercent,
          }}
        />
      </S.DiscountDescription>
    </S.Wrapper>
  ) : (
    <S.Container>
      <S.TitleContainer>
        {t('payments.mixedPaymentSummaryV1.title')}
      </S.TitleContainer>
      <div>
        <S.PriceContainer>
          {CURRENCY_SYMBOLS[currency]}
          {mainPrice}
        </S.PriceContainer>
      </div>
    </S.Container>
  )
}
