import { ConflictManagementValue } from 'root-constants/common'

export const TRANSLATION_FAMILY = 'onboarding.conflictManagementV1'

export const OPTIONS = [
  {
    key: 'communicate',
    value: ConflictManagementValue.COMMUNICATE,
  },
  {
    key: 'conflict',
    value: ConflictManagementValue.CONFLICT,
  },
  {
    key: 'defensive',
    value: ConflictManagementValue.DEFENSIVE,
  },
  {
    key: 'treatment',
    value: ConflictManagementValue.TREATMENT,
  },
  {
    key: 'other',
    value: ConflictManagementValue.OTHER,
  },
]
