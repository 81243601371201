import {
  selectScreenName,
  selectStripeAccountId,
  selectStripeAccountName,
} from 'root-redux/selects/common'
import { selectEmail, selectUUID } from 'root-redux/selects/user'

import { createIntroOfferProductId } from 'helpers/createIntroOfferProductId'
import { createProductId } from 'helpers/createProductId'

import { PaymentSystem } from 'modules/payment/constants'
import {
  selectCurrency,
  selectSubscriptionLookupKey,
  selectSubscriptionMainPrice,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialLookupKey,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPrice,
} from 'modules/payment/redux/selects'

import { IAppState } from 'models/store.model'

export const getPurchaseCommonEventParams = (state: IAppState) => {
  const uuid = selectUUID(state)
  const email = selectEmail(state)
  const stripePriceId = selectSubscriptionLookupKey(state)
  const mainPrice = selectSubscriptionMainPrice(state)
  const trialPrice = selectSubscriptionTrialPrice(state)
  const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
  const trialPriceId = selectSubscriptionTrialLookupKey(state)
  const trialPeriodQuantity = selectSubscriptionTrialPeriodDays(state)
  const currency = selectCurrency(state)
  const screenName = selectScreenName(state)
  const stripeAccountName = selectStripeAccountName(state)
  const stripeAccountId = selectStripeAccountId(state)
  const periodName = selectSubscriptionPeriodName(state)
  const periodQuantity = selectSubscriptionPeriodQuantity(state)
  const paymentSystem = PaymentSystem.STRIPE

  const productId = trialPriceId
    ? createIntroOfferProductId({
        priceId: stripePriceId,
        trialPriceId,
        trialPeriodQuantity,
      })
    : createProductId({ periodName, periodQuantity, price: mainPrice })

  return {
    uuid,
    email,
    mainPrice,
    trialPrice,
    trialPriceId,
    trialPeriodDays,
    currency,
    screenName,
    productId,
    stripeAccountName,
    stripeAccountId,
    stripePriceId,
    paymentSystem,
  }
}
