import styled from 'styled-components'

import { QuestionTitle, baseColumnStyles } from 'common-styles'

export const StyledHatedThings = {
  Column: styled.div`
    ${baseColumnStyles};
    padding: 54px 0;
  `,
  QuestionTitle: styled(QuestionTitle)`
    margin-bottom: 53px;
    line-height: 28px;

    @media (min-width: 350px) {
      width: 340px;
    }
  `,
}
