import styled from 'styled-components'

export const StyledSubscriptionDescription = styled.p`
  font-size: 11px;
  line-height: 18px;
  text-align: center;
  color: var(--subscription-description-color);

  & > a {
    color: inherit;

    &[href^='mailto:'] {
      font-weight: 700;
    }

    &:not([href^='mailto:']) {
      font-weight: inherit;
      text-decoration: underline;
    }
  }
`
