import React from 'react'
import { useTranslation } from 'react-i18next'

import stars from 'assets/images/rating-stars.png'

import { StyledReviewCard as S } from './ReviewCard.styles'

type TProps = {
  userAvatar: string
  userName: string
  review: string
}

export const ReviewCard: React.FC<TProps> = ({
  userAvatar,
  userName,
  review,
}) => {
  const { t } = useTranslation()

  return (
    <S.Container>
      <S.UserBlock>
        <S.UserAvatar>
          <img src={userAvatar} alt="avatar" />
        </S.UserAvatar>
        <S.UserName>{userName}</S.UserName>
      </S.UserBlock>
      <S.Rating>
        <img src={stars} alt="stars" />
      </S.Rating>
      <S.ReviewText>{t(review)}</S.ReviewText>
    </S.Container>
  )
}
