import ava from 'assets/images/ava.png'
import charlotte from 'assets/images/charlotte.png'
import ethan from 'assets/images/ethan.png'
import lucas from 'assets/images/lucas.png'
import sophia from 'assets/images/sophia.png'

export const REVIEWS_MAP = [
  {
    userAvatar: lucas,
    userName: 'Lukas',
    review: 'commonComponents.reviews.lucas',
  },
  {
    userAvatar: sophia,
    userName: 'Sophia',
    review: 'commonComponents.reviews.sophia',
  },
  {
    userAvatar: charlotte,
    userName: 'Charlotte',
    review: 'commonComponents.reviews.charlotte',
  },
  {
    userAvatar: ava,
    userName: 'Ava',
    review: 'commonComponents.reviews.ava',
  },
  {
    userAvatar: ethan,
    userName: 'Ethan',
    review: 'commonComponents.reviews.ethan',
  },
]
