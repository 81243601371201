import styled from 'styled-components'

import { Button } from 'components/Button'

import heart from 'assets/images/icon-heart-2.svg'
import time from 'assets/images/icon-time.svg'
import warning from 'assets/images/icon-warning.svg'

import { baseColumnStyles } from 'common-styles'
import { BIG_COLUMN_WIDTH, Color, StartAgeValue } from 'root-constants/common'

export const StyledPlan = {
  Column: styled.div`
    ${baseColumnStyles};
    padding: 40px 20px 20px;
    width: ${BIG_COLUMN_WIDTH}px;
  `,
  Title: styled.h1`
    color: ${Color.LILAC_120};
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
    margin-bottom: 56px;
    text-align: center;

    strong {
      color: ${Color.LILAC_130};
    }
  `,
  TitleV2: styled.h1`
    color: ${Color.LILAC_120};
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
    margin-bottom: 16px;
    text-align: center;
  `,
  PlanContainer: styled.div`
    background-color: ${Color.WHITE_100};
    border-radius: 16px;
    margin-bottom: 88px;
    padding: 16px;
    position: relative;
    width: 100%;
  `,
  PlanContainerV2: styled.div`
    background-color: ${Color.WHITE_100};
    border-radius: 16px;
    margin-bottom: 16px;
    padding: 16px;
    position: relative;
    width: 100%;
  `,
  PlanList: styled.ul`
    display: flex;
    flex-direction: column;
    row-gap: 28px;
    width: calc(100% - 140px + 12px + 16px);
  `,
  Plan: styled.li`
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    position: relative;
    row-gap: 4px;

    :before {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      left: 0;
      top: 23px;
    }

    &[data-icon='heart']:before {
      background-image: url(${heart});
    }

    &[data-icon='time']:before {
      background-image: url(${time});
    }

    &[data-icon='warning']:before {
      background-image: url(${warning});
    }
  `,
  PlanName: styled.p`
    color: ${Color.LIGHT_TEXT};
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
  `,
  PlanValue: styled.p`
    color: ${Color.DEFAULT_TEXT};
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  `,
  ImageContainer: styled.div`
    bottom: 0;
    position: absolute;
    right: -12px;
    width: 140px;

    &[data-start-age='${StartAgeValue.FIRST_AGE_RANGE}'] {
      aspect-ratio: 48 / 85;
      right: -4px;
      width: 130px;
    }

    &[data-start-age='${StartAgeValue.SECOND_AGE_RANGE}'] {
      aspect-ratio: 31 / 52;
      right: -8px;
      width: 140px;
    }

    &[data-start-age='${StartAgeValue.THIRD_AGE_RANGE}'] {
      aspect-ratio: 149 / 260;
      right: -3px;
      width: 130px;
    }

    &[data-start-age='${StartAgeValue.FOURTH_AGE_RANGE}'] {
      aspect-ratio: 3 / 5;
      right: -10px;
      width: 140px;
    }
  `,
  Graph: styled.div`
    background-color: ${Color.WHITE_100};
    border-radius: 16px;
    display: flex;
    margin-bottom: 88px;
    min-height: 200px;
    overflow: hidden;
    width: 100%;
  `,
  Button: styled(Button)`
    --next-page-button-border-radius: 100px;
    --next-page-button-height: 62px;
  `,
}
