import React from 'react'

import { PageContainer } from 'components/PageContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import img from 'assets/images/intellectual-intimacy.webp'

import { StyledIntellectualIntimacy as S } from './IntellectualIntimacy.styles'

export const IntellectualIntimacyVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const handleContinue = useNextStep({
    pageId,
    question: 'Intellectual Intimacy leads to better communication',
    nextPagePath,
  })

  return (
    <PageContainer>
      <S.Column>
        <S.Title>Intellectual Intimacy leads to better communication</S.Title>
        <S.Image src={img} alt="couple" />
        <S.Text>
          Couples who engage in regular intellectual conversations are more
          likely to report <strong>feeling comfortable</strong> expressing their
          thoughts and feelings to their partner.
        </S.Text>
        <S.Button onClick={() => handleContinue('')}>Continue</S.Button>
      </S.Column>
    </PageContainer>
  )
}
