import styled from 'styled-components'

export const StyledSpinner = {
  Wrapper: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100vw;
    height: var(--full-height, 100vh);
    background-image: var(--base-background-image);
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
}
