import React from 'react'

import { eventLogger } from 'services/eventLogger.service'

import { AppLinks } from 'root-constants/common'

import { OuterLink } from '../OuterLink'

type TProps = {
  title?: string
}

export const TermsOfUseLink: React.FC<TProps> = ({ title }) => (
  <OuterLink
    href={AppLinks.TOU_LINK}
    onClick={() => eventLogger.logTermsOfUseClicked()}
  >
    {title || 'Terms of Use'}
  </OuterLink>
)
