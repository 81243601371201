import React from 'react'
import { useTranslation } from 'react-i18next'

import { useUserData } from 'hooks/useUserData'

import { SexQuantityValue } from 'root-constants/common'

import {
  DRIVE_TO_MARKUP_MAP,
  FREQUENCY_OF_AFFECTION_TO_MARKUP_MAP,
  INTIMACY_PROBLEMS_TO_MARKUP_MAP,
  SATISFACTION_TO_MARKUP_MAP,
  SEX_QUANTITY_TO_MARKUP_MAP,
  STATUS_TO_MARKUP_MAP,
} from '../../constants'
import { StyledSummaryBlock as S } from './SummaryBlock.styles'

export const SummaryBlock: React.FC = () => {
  const { t } = useTranslation()

  const {
    isSexUsersPriority,
    relationshipStatus,
    userGoal,
    satisfaction,
    drive,
    sexQuantity,
    affectionFrequency,
    intimacyProblems,
  } = useUserData()

  return (
    <S.Wrapper>
      <S.Title>{t('subscriptionsV2.summaryBlock.title')}</S.Title>
      <S.Block data-is-sex-priority={isSexUsersPriority}>
        {isSexUsersPriority ? (
          <S.List>
            <li>
              <strong>{t('subscriptionsV2.summaryBlock.goal')}</strong>
              <span>{userGoal}</span>
            </li>
            <li>
              <strong>
                {t('subscriptionsV2.summaryBlock.relationshipStatus')}
              </strong>
              <span>{STATUS_TO_MARKUP_MAP[relationshipStatus]}</span>
            </li>
            <li>
              <strong>
                {t('subscriptionsV2.summaryBlock.satisfactionSexLife')}
              </strong>
              <span>{SATISFACTION_TO_MARKUP_MAP[satisfaction]} </span>
            </li>
            <li>
              <strong>{t('subscriptionsV2.summaryBlock.sexDrive')}</strong>
              <span>{DRIVE_TO_MARKUP_MAP[drive]}</span>
            </li>
            {sexQuantity === SexQuantityValue.NOT_SAY ? null : (
              <li>
                <strong>
                  {t('subscriptionsV2.summaryBlock.sexualFrequency')}
                </strong>
                <span>{SEX_QUANTITY_TO_MARKUP_MAP[sexQuantity]}</span>
              </li>
            )}
          </S.List>
        ) : (
          <S.List>
            <li>
              <strong>{t('subscriptionsV2.summaryBlock.goal')}</strong>
              <span>{userGoal}</span>
            </li>
            <li>
              <strong>
                {t('subscriptionsV2.summaryBlock.relationshipStatus')}
              </strong>
              <span>{STATUS_TO_MARKUP_MAP[relationshipStatus]}</span>
            </li>
            <li>
              <strong>
                {t('subscriptionsV2.summaryBlock.frequencyOfAffection')}
              </strong>
              <span>
                {FREQUENCY_OF_AFFECTION_TO_MARKUP_MAP[affectionFrequency]}
              </span>
            </li>
            <li>
              <strong>
                {t('subscriptionsV2.summaryBlock.intimacyProblem')}
              </strong>
              <span>{INTIMACY_PROBLEMS_TO_MARKUP_MAP[intimacyProblems]}</span>
            </li>
            <li>
              <strong>
                {t('subscriptionsV2.summaryBlock.communicationProblems')}
              </strong>
              <span>{t('subscriptionsV2.summaryBlock.present')}</span>
            </li>
          </S.List>
        )}
      </S.Block>
    </S.Wrapper>
  )
}
