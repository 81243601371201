import { AnyAction } from 'redux'

import { SET_HIGHEST_DISCOUNT } from 'root-redux/actions/common'

import { ISubscription } from 'models/subscriptions.model'

import {
  RESET_3D_SECURE_IFRAME_URL,
  SET_3D_SECURE_IFRAME_URL,
  SET_IS_PAYMENT_FLOWS_SHOWN,
  SET_PAYMENT_CLIENT_SECRET,
  SET_SUBSCRIPTION,
  SET_SUBSCRIPTION_ID,
  SET_TRIAL_PERIOD_DAYS,
} from './actions'

export interface IPaymentState {
  selectedSubscription: ISubscription | null
  threeDSecureIframeURL: string
  paymentClientSecret: string
  trialPeriodDays: number
  subscriptionId: string
  isPaymentFlowsShown: boolean
  highestDiscountPercentage: number
}

const initState: IPaymentState = {
  selectedSubscription: null,
  threeDSecureIframeURL: '',
  paymentClientSecret: '',
  trialPeriodDays: 0,
  subscriptionId: '',
  isPaymentFlowsShown: false,
  highestDiscountPercentage: 0,
}

export function paymentReducer(
  state: IPaymentState = initState,
  { type, payload = null }: AnyAction,
): IPaymentState {
  switch (type) {
    case SET_SUBSCRIPTION: {
      return {
        ...state,
        selectedSubscription: payload,
      }
    }
    case SET_3D_SECURE_IFRAME_URL: {
      return {
        ...state,
        threeDSecureIframeURL: payload,
      }
    }
    case RESET_3D_SECURE_IFRAME_URL: {
      return {
        ...state,
        threeDSecureIframeURL: '',
      }
    }
    case SET_PAYMENT_CLIENT_SECRET: {
      return {
        ...state,
        paymentClientSecret: payload,
      }
    }
    case SET_TRIAL_PERIOD_DAYS: {
      return {
        ...state,
        trialPeriodDays: payload,
      }
    }
    case SET_SUBSCRIPTION_ID: {
      return {
        ...state,
        subscriptionId: payload,
      }
    }
    case SET_IS_PAYMENT_FLOWS_SHOWN: {
      return {
        ...state,
        isPaymentFlowsShown: payload,
      }
    }
    case SET_HIGHEST_DISCOUNT: {
      return {
        ...state,
        highestDiscountPercentage: payload,
      }
    }
    default:
      return state
  }
}
