import React, { useMemo } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton2 } from 'components/QuestionButton2'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledFeelHeard as S } from './FeelHeard.styles'

const QUESTION = 'How often do you feel heard and understood by your partner?'
const OPTION_VALUES = {
  always: 'Always, we understand each other well',
  almostAlways: 'Almost always, we have good communication',
  often: `Often, but there's room for improvement`,
  sometimes: 'Sometimes, but it could be better',
  rarely: 'Rarely, I wish it were more',
}

export const FeelHeardVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })
  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.QuestionTitle>{QUESTION}</S.QuestionTitle>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.always}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.always}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.almostAlways}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.almostAlways}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.often}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.often}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.sometimes}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.sometimes}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.rarely}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.rarely}</QuestionButton2>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
