import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js'
import { paymentApi } from 'api'

import { Modal } from 'components/Modal'

import { sendUserConfigAction } from 'root-redux/actions/user'
import {
  selectPayPalClientId,
  selectScreenName,
  selectStripeAccountId,
  selectStripeAccountName,
} from 'root-redux/selects/common'
import { selectEmail, selectUUID } from 'root-redux/selects/user'

import { useUserData } from 'hooks/useUserData'

import { PaymentMethod, PaymentSystem } from 'modules/payment/constants'
import { logFailedPayment } from 'modules/payment/helpers/logFailedPayment'
import { logSuccessfulPayment } from 'modules/payment/helpers/logSuccessfulPayment'
import {
  selectCurrency,
  selectPayPalPlanId,
  selectSubscriptionMainPrice,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPrice,
} from 'modules/payment/redux/selects'

import { eventLogger } from 'services/eventLogger.service'

import { PaymentSuccess } from '../PaymentSuccess'
import { StyledPayPalContainer as S } from './PayPalContainer.styles'

type TProps = {
  onClick: () => void
}

export const PayPalContainer: React.FC<TProps> = ({ onClick }) => {
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)
  const paypalPlanId = useSelector(selectPayPalPlanId)
  const paypalClientId = useSelector(selectPayPalClientId)
  const mainPrice = useSelector(selectSubscriptionMainPrice)
  const trialPeriodPrice = useSelector(selectSubscriptionTrialPrice)
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)
  const periodName = useSelector(selectSubscriptionPeriodName)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const currency = useSelector(selectCurrency)
  const email = useSelector(selectEmail)
  const screenName = useSelector(selectScreenName)
  const stripeAccountId = useSelector(selectStripeAccountId)
  const stripeAccountName = useSelector(selectStripeAccountName)

  const [isPaymentStatusShown, setIsPaymentStatusShown] = useState(false)
  const [isErrorModalShown, setIsErrorModalShown] = useState(false)

  const { usersPriority } = useUserData()

  const handleResetError = useCallback(() => {
    setIsPaymentStatusShown(false)
    setIsErrorModalShown(false)
  }, [])

  const handlePaymentApprove = useCallback(
    async (data) => {
      logSuccessfulPayment({
        email,
        screenName,
        subscriptionId: data.subscriptionID,
        productId: paypalPlanId,
        uuid,
        price: mainPrice,
        trialPrice: trialPeriodPrice,
        trialPeriodDays,
        currency,
        paymentMethod: PaymentMethod.PAYPAL,
        paymentSystem: PaymentSystem.PAYPAL,
        goal: usersPriority,
        stripeAccountId,
        stripeAccountName,
      })

      const response = await paymentApi.createPaypalSubscription({
        uuid,
        paypalPlanId,
      })

      if (response.status) {
        dispatch(
          sendUserConfigAction({
            payment_currency: currency,
            payment_method: PaymentMethod.PAYPAL,
            is_download_visited: false,
            subscription_price: mainPrice,
            subscription_duration: `${periodQuantity}${periodName}`,
            price_id: paypalPlanId,
            trial_price: trialPeriodPrice,
            trial_period: trialPeriodDays,
          }),
        )
        setIsPaymentStatusShown(true)
        setIsErrorModalShown(false)
      }
    },
    [
      email,
      screenName,
      paypalPlanId,
      mainPrice,
      trialPeriodPrice,
      trialPeriodDays,
      currency,
      usersPriority,
      uuid,
      dispatch,
      periodQuantity,
      periodName,
      stripeAccountId,
      stripeAccountName,
    ],
  )

  const handlePaymentError = useCallback(
    (error) => {
      setIsErrorModalShown(true)
      logFailedPayment({
        email,
        screenName,
        productId: paypalPlanId,
        price: mainPrice,
        currency,
        paymentResponse: {
          type: error?.name || '',
          code: error?.debug_id,
          message: error?.message,
        },
        paymentMethod: PaymentMethod.PAYPAL,
        paymentSystem: PaymentSystem.PAYPAL,
        isTrialActive: !!trialPeriodPrice,
        goal: usersPriority,
        stripeAccountId,
        stripeAccountName,
      })
    },
    [
      currency,
      mainPrice,
      email,
      paypalPlanId,
      usersPriority,
      screenName,
      trialPeriodPrice,
      stripeAccountId,
      stripeAccountName,
    ],
  )

  const handleButtonClick = useCallback(() => {
    onClick()
    eventLogger.logPurchaseStarted({
      email,
      screenName,
      productId: paypalPlanId,
      priceDetails: {
        price: mainPrice,
        trial: !!trialPeriodPrice,
        currency,
      },
      paymentMethod: PaymentMethod.PAYPAL,
      paymentSystem: PaymentSystem.PAYPAL,
      goal: usersPriority,
      stripeAccountId,
      stripeAccountName,
    })
  }, [
    currency,
    mainPrice,
    email,
    onClick,
    paypalPlanId,
    usersPriority,
    screenName,
    trialPeriodPrice,
    stripeAccountId,
    stripeAccountName,
  ])

  return (
    <>
      <S.ButtonsContainer>
        {paypalPlanId && paypalClientId && (
          <PayPalScriptProvider
            options={{
              'client-id': paypalClientId,
              vault: true,
              'disable-funding': 'credit,card',
            }}
          >
            <PayPalButtons
              style={{
                label: 'paypal',
                tagline: false,
                layout: 'horizontal',
                height: 55,
              }}
              forceReRender={[paypalPlanId]}
              createSubscription={
                (data, actions) =>
                  actions.subscription.create({
                    plan_id: paypalPlanId, // Creates the subscription
                    custom_id: uuid,
                  })
                // eslint-disable-next-line react/jsx-curly-newline
              }
              onApprove={(data) => handlePaymentApprove(data)}
              onError={(error) => handlePaymentError(error)}
              onClick={handleButtonClick}
            />
          </PayPalScriptProvider>
        )}
      </S.ButtonsContainer>
      {isPaymentStatusShown && <PaymentSuccess />}
      <Modal onClose={handleResetError} isShown={isErrorModalShown}>
        There was an error processing your payment information
      </Modal>
    </>
  )
}
