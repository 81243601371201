import React, { useMemo, useState } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton } from 'components/QuestionButton'
import { StickyButton } from 'components/StickyButton'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledThingsToBeFun as S } from './ThingsToBeFun.styles'

const QUESTION =
  'What are some things you do to make sure your sex life stays fun and exciting?'
const OPTION_VALUES = {
  positions: 'Try new positions',
  toys: 'Use sex toys',
  porn: 'Watch porn',
  fantasies: 'Talk about our fantasies',
  other: 'Other',
}

export const ThingsToBeFunVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value, isChecked) => {
        if (isChecked) {
          setAnswers([...answers, value])
        } else {
          const newValues = answers.filter((item) => item !== value)
          setAnswers(newValues)
        }
      },
    }),
    [answers, pageId],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.QuestionTitle>{QUESTION}</S.QuestionTitle>
        <StyledOption.OptionsContainer>
          <Option {...optionProps} value={OPTION_VALUES.positions}>
            <QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.positions}
            </QuestionButton>
          </Option>
          <Option {...optionProps} value={OPTION_VALUES.toys}>
            <QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.toys}
            </QuestionButton>
          </Option>
          <Option {...optionProps} value={OPTION_VALUES.porn}>
            <QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.porn}
            </QuestionButton>
          </Option>
          <Option {...optionProps} value={OPTION_VALUES.fantasies}>
            <QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.fantasies}
            </QuestionButton>
          </Option>
          <Option {...optionProps} value={OPTION_VALUES.other}>
            <QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.other}
            </QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
        <StickyButton
          disabled={!answers.length}
          onClick={() => handleContinue(answers)}
        >
          Next
        </StickyButton>
      </S.Column>
    </PageContainer>
  )
}
