import React, { useMemo } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton } from 'components/QuestionButton'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledIntimacyThoughts as S } from './IntimacyThoughts.styles'

const QUESTION = 'What are your thoughts on sexual intimacy?'
const OPTION_VALUES = {
  loveIt: 'I love it and want it all the time',
  notATopPriority: `I like it, but it's not a top priority`,
  notInterested: `I'm not really interested`,
  notSure: `I'm not sure`,
}

export const IntimacyThoughtsVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, handleContinue, pageId, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.QuestionTitle>{QUESTION}</S.QuestionTitle>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.loveIt}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.loveIt}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.notATopPriority}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.notATopPriority}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.notInterested}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.notInterested}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.notSure}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.notSure}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
