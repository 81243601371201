import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledBenefitsV2 as S } from './BenefitsV2.styles'

type TBenefit = {
  icon: string
  title: string
  description: string
  color: string
}

type TProps = {
  benefits: TBenefit[]
  className?: string
}

export const BenefitsV2: React.FC<TProps> = ({ benefits, className }) => {
  const { t } = useTranslation()

  return (
    <div className={className}>
      <S.Title>{t('paymentTrialV1.benefits.title')}</S.Title>
      <S.List>
        {benefits.map((item) => (
          <S.ListItem color={item.color} key={item.title}>
            <S.ItemContent>
              <S.IconContainer>
                <img src={item.icon} alt="icon" />
              </S.IconContainer>
              <S.TextContainer>
                <S.BenefitTitle>{t(item.title)}</S.BenefitTitle>
                <S.BenefitDescription>
                  {t(item.description)}
                </S.BenefitDescription>
              </S.TextContainer>
            </S.ItemContent>
          </S.ListItem>
        ))}
      </S.List>
    </div>
  )
}
