import React, { useMemo, useState } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { StickyContainer } from 'components/StickyContainer'

import { useGetFlowGroupInfo } from 'hooks/useGetFlowGroupInfo'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledGratitude as S } from './Gratitude.styles'

const QUESTION =
  'How do you express appreciation and gratitude towards your partner? '
const OPTION_VALUES = {
  sayingThanks: 'Saying "thank you"',
  gifts: 'Small gifts or gestures',
  service: 'Acts of service',
  time: 'Quality time',
  touch: 'Physical touch',
  other: 'Other',
  dontShow: `We don't show appreciation for each other`,
}

export const GratitudeVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
  alternativePagePath,
}) => {
  const { isMultipleFlow } = useGetFlowGroupInfo()

  const [answers, setAnswers] = useState<string[]>([])

  const isButtonsDisabled = useMemo(
    () => answers.includes(OPTION_VALUES.dontShow),
    [answers],
  )
  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath: isMultipleFlow ? alternativePagePath : nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          value === OPTION_VALUES.dontShow
            ? setAnswers([value])
            : setAnswers([...answers, value])
        } else {
          setAnswers(answers.filter((item) => item !== value))
        }
      },
    }),
    [pageId, answers],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.QuestionTitle>{QUESTION}</S.QuestionTitle>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.sayingThanks}
            disabled={isButtonsDisabled}
            checked={answers.includes(OPTION_VALUES.sayingThanks)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.sayingThanks}
            </S.QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.gifts}
            disabled={isButtonsDisabled}
            checked={answers.includes(OPTION_VALUES.gifts)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.gifts}
            </S.QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.service}
            disabled={isButtonsDisabled}
            checked={answers.includes(OPTION_VALUES.service)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.service}
            </S.QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.time}
            disabled={isButtonsDisabled}
            checked={answers.includes(OPTION_VALUES.time)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.time}
            </S.QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.touch}
            disabled={isButtonsDisabled}
            checked={answers.includes(OPTION_VALUES.touch)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.touch}
            </S.QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.other}
            disabled={isButtonsDisabled}
            checked={answers.includes(OPTION_VALUES.other)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.other}
            </S.QuestionButton>
          </Option>
          <Option {...optionProps} value={OPTION_VALUES.dontShow}>
            <S.QuestionButton>{OPTION_VALUES.dontShow}</S.QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
      <StickyContainer>
        <S.Button
          disabled={!answers.length}
          onClick={() => handleContinue(answers)}
        >
          Continue
        </S.Button>
      </StickyContainer>
    </PageContainer>
  )
}
