import { IUserStatus, IUserStatusRaw } from 'models/user.model'

import { LOGIN_METHOD_RAW_MAP } from 'root-constants/common'

export const getUserStatusFromRawUserStatus = (
  userStatusRaw: IUserStatusRaw,
): IUserStatus => ({
  countryCode: userStatusRaw.country_code,
  isOnboardingSkipped: userStatusRaw.onboarding.is_skipped,
  onboarding: userStatusRaw.onboarding.quiz,
  config: {
    paymentCurrency: userStatusRaw.config?.payment_currency || '',
    paymentMethod: userStatusRaw.config?.payment_method || '',
    isDownloadVisited: userStatusRaw.config?.is_download_visited || false,
    isDiscountApplied: userStatusRaw.config?.discount_applied || false,
    subscriptionPrice: userStatusRaw.config?.subscription_price || '',
    subscriptionDuration: userStatusRaw.config?.subscription_duration || '',
    priceId: userStatusRaw.config?.price_id || '',
    trialPeriod: userStatusRaw.config?.trial_period || '',
    trialPrice: userStatusRaw.config?.trial_price || '',
  },
  email: {
    hasEmail: userStatusRaw.email.has_email,
    onboardingEmail: userStatusRaw.email.onboarding_email,
  },
  hasSubscription: userStatusRaw.subscription.has_subscription,
  account: {
    hasAccount: userStatusRaw.account.has_account,
    loginMethod:
      LOGIN_METHOD_RAW_MAP[userStatusRaw.account?.login_method] || null,
    email: userStatusRaw.account.identifier,
  },
  upsell: {
    isUpsellPurchased: userStatusRaw.upsell.is_upsell_purchased,
    isUpsellAvailable: userStatusRaw.upsell.is_upsell_available,
    isUpsellTried: userStatusRaw.upsell.is_upsell_tried,
  },
  upgrade: {
    isUpgradePassed: userStatusRaw.upgrade.is_upgrade_passed,
    isUpgradeAvailable: userStatusRaw.upgrade.is_upgrade_available,
  },
})
