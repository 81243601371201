import React from 'react'

import { CheckboxOptionsPage } from 'pages/options-page'

import { TPageProps } from 'models/common.model'

import { OPTIONS_V1, TRANSLATION_FAMILY } from './constants'

export const MainGoalsVariant7: React.FC<TPageProps> = (props) => (
  <CheckboxOptionsPage
    {...props}
    options={OPTIONS_V1}
    translationFamily={TRANSLATION_FAMILY}
  />
)
