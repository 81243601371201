import styled from 'styled-components'

import { QuestionTitle, baseColumnStyles } from 'common-styles'

export const StyledSpiritualSupport = {
  Column: styled.div`
    ${baseColumnStyles};
    padding: 40px 0;
  `,
  Title: styled(QuestionTitle)`
    margin: 0 -10px 52px;
    line-height: 29px;
  `,
}
