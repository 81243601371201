import styled from 'styled-components'

import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton } from 'components/QuestionButton'

import { QuestionTitle, baseColumnStyles } from 'common-styles'
import { BIG_COLUMN_WIDTH, Color, MAX_PHONE_WIDTH } from 'root-constants/common'

export const StyledGender = {
  Column: styled.div`
    ${baseColumnStyles};
    padding: 20px;
    width: 100%;

    @media (min-width: ${MAX_PHONE_WIDTH}px) {
      width: ${BIG_COLUMN_WIDTH}px;
    }
  `,
  Title: styled(QuestionTitle)`
    line-height: 28px;
  `,
  TitleVariant2: styled(QuestionTitle)`
    line-height: 28px;
  `,
  Img: styled.img`
    margin-bottom: 24px;
  `,
  OptionsContainer: styled(StyledOption.OptionsContainer)`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    > * {
      width: calc(50% - 6px);
      flex-shrink: 0;
    }
  `,
  QuestionButton: styled(QuestionButton)`
    justify-content: center;
    font-size: 18px;
  `,
  OtherButton: styled.div`
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: ${Color.LILAC_110};
    text-align: center;
    text-decoration: underline;
    text-decoration-thickness: from-font;
  `,
}
