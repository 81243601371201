import React, { HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'

import { SupportAction } from 'components/SupportAction'

import secureImg from 'assets/images/secure.jpg'

import { StyledSecurity as S } from './Security.styles'

export const Security: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <S.SecureCard {...props}>
      <S.SecureImage>
        <img src={secureImg} alt="secure" />
      </S.SecureImage>
      <ul>
        <li>
          <S.SecureItemTitle>
            {t('commonComponents.security.saveTitle')}
          </S.SecureItemTitle>
          <S.SecureItemText>
            {t('commonComponents.security.saveText')}
          </S.SecureItemText>
        </li>
        <li>
          <S.SecureItemTitle>
            {t('commonComponents.security.checkoutTitle')}
          </S.SecureItemTitle>
          <S.SecureItemText>
            {t('commonComponents.security.checkoutText')}
          </S.SecureItemText>
        </li>
        <li>
          <S.SecureItemTitle>
            {t('commonComponents.security.needHelpTitle')}
          </S.SecureItemTitle>
          <S.SecureItemText>
            {t('commonComponents.security.contactUs')}
            <SupportAction />
          </S.SecureItemText>
        </li>
      </ul>
    </S.SecureCard>
  )
}
