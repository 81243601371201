import styled, { css } from 'styled-components'

import priceBgImage2 from 'assets/images/price-bg-2.svg'
import priceBgImage from 'assets/images/price-bg.svg'
import checkIcon from 'assets/images/success-2.svg'

import { Color } from 'root-constants/common'

const activePlanItemStyles = css`
  ::before {
    background: center url(${checkIcon}) no-repeat;
  }
`

const defaultPlanItemStyles = css`
  border-radius: 0 0 16px 16px;
  padding-top: 12px;

  &::after {
    content: 'BEST MATCH';
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    top: -17px;
    left: 0;
    font-size: 10px;
    line-height: 17px;
    font-weight: 800;
    color: #ffffff;
    background-color: #b07bc2;
    border-radius: 16px 16px 0 0;
  }
`

const PlanItem = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px 24px 56px;
  border-radius: 16px;
  background-color: ${Color.LILAC_110};
  transition: all 0.2s ease-out;

  &[data-is-default='true'] {
    ${defaultPlanItemStyles};
  }

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 16px;
    width: 24px;
    height: 24px;
    margin: auto;
    border: 2px solid rgba(10, 50, 77, 0.45);
    border-radius: 100%;
  }

  &[data-is-selected='true']::before {
    border: none;
  }
`

export const StyledPlanItemVariant3 = {
  PlanItem: styled(PlanItem)`
    &[data-is-selected='true'] {
      ${activePlanItemStyles};
    }
  `,
  PlanTitle: styled.div`
    flex-grow: 1;
    font-size: 16px;
    font-weight: 800;
    line-height: 22px;
    text-transform: capitalize;
  `,
  OldPlanPrice: styled.div`
    margin-right: 6px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    text-decoration: line-through;
    color: rgba(10, 50, 77, 0.45);

    [data-is-selected='true'] & {
      color: #f66e6e;
    }
  `,
  PlanPrice: styled.div`
    width: 90px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 800;
    font-size: 14px;
    line-height: 16px;
    padding-left: 34px;
    color: #ffffff;
    background: top right url(${priceBgImage2}) no-repeat;

    &[data-is-selected='true'] {
      background-image: url(${priceBgImage});
    }
  `,
  Period: styled.span`
    font-size: 9px;
    font-weight: 500;
    line-height: 11px;
    color: rgba(255, 255, 255, 0.7);
  `,
}
