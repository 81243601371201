/* eslint-disable max-lines */
import { GrowthbookTestPage } from 'pages/GrowthbookTestPage'
import { ActivitiesVariant1 } from 'pages/activities'
import { AdmitCheatingVariant1 } from 'pages/admit-cheating/AdmitCheatingVariant1'
import { AdventurousInSexVariant1 } from 'pages/adventurous-in-sex/AdventurousInSexVariant1'
import { AgeVariant1 } from 'pages/age/AgeVariant1'
import { AgeVariant2 } from 'pages/age/AgeVariant2'
import { BalanceIntellectualPhysicalsVariant1 } from 'pages/balance-intellectual-physical/BalanceIntellectualPhysicalVariant1'
import { BalanceVariant1 } from 'pages/balance/BalanceVariant1'
import { BasicVariant1 } from 'pages/basic/BasicVariant1'
import { BeUnfaithfulVariant1 } from 'pages/be-unfaithful/BeUnfaithfulVariant1'
import { BenefitsVariant1 } from 'pages/benefits/BenefitsVariant1'
import { BestFriendVariant1 } from 'pages/best-friend/BestFriendVariant1'
import { BiggestFearVariant1 } from 'pages/biggest-fear/BiggestFearVariant1'
import { BreakupVariant1 } from 'pages/breakup/BreakupVariant1'
import { ChallengeIntellectuallyVariant1 } from 'pages/challenge-intellectually/ChallengeIntellectuallyVariant1'
import { ChallengeVariant1 } from 'pages/challenge/ChallengeVariant1'
import { ChangeInSexVariant1 } from 'pages/change-in-sex/ChangeInSexVariant1'
import { ClosenessScaleVariant1 } from 'pages/closeness-scale/ClosenessScaleVariant1'
import { CommentsVariant1 } from 'pages/comments/CommentsVariant1'
import { CommonEmotionsVariant1 } from 'pages/common-emotions/CommonEmotionsVariant1'
import { CommonEmotionsVariant2 } from 'pages/common-emotions/CommonEmotionsVariant2'
import { CommunicationLevelVariant1 } from 'pages/communication-level'
import { ConflictFeelingsVariant1 } from 'pages/conflict-feelings'
import {
  ConflictManagementVariant1,
  ConflictManagementVariant2,
} from 'pages/conflict-management'
import { ConversationsFrequencyVariant1 } from 'pages/conversations-frequency/ConversationsFrequencyVariant1'
import { CreatingVariant1 } from 'pages/creating'
import { DatesVariant1 } from 'pages/dates/DatesVariant1'
import { DealWithDifferencesVariant1 } from 'pages/deal-with-differences/DealWithDifferencesVariant1'
import { DiscussFutureVariant1 } from 'pages/discuss-future/DiscussFutureVariant1'
import { DiscussVariant1 } from 'pages/discuss/DiscussVariant1'
import { DownloadApp } from 'pages/download-app/DownloadApp'
import { FactorsVariant1 } from 'pages/factors'
import { FavoriteThingsVariant1 } from 'pages/favorite-things/FavoriteThingsVariant1'
import { FeedbackVariant1 } from 'pages/feedback/FeedbackVariant1'
import { FeelHeardVariant1 } from 'pages/feel-heard/FeelHeardVariant1'
import { FeltLonelyVariant1 } from 'pages/felt-lonely/FeltLonelyVariant1'
import { FireVariant1 } from 'pages/fire/FireVariant1'
import { FortunatelyVariant1 } from 'pages/fortunately/FortunatelyVariant1'
import { GenderVariant2, GenderVariant3 } from 'pages/gender'
import { GratitudeVariant1 } from 'pages/gratitude/GratitudeVariant1'
import { HappinessVariant1 } from 'pages/happiness/Happiness'
import { HatedThingsVariant1 } from 'pages/hated-things/HatedThingsVariant1'
import { HugsAndKissesVariant1 } from 'pages/hugs-and-kisses/HugsAndKissesVariant1'
import { ImportantAspectVariant1 } from 'pages/important-aspect/ImportantAspectVariant1'
import { InfidelityAffectVariant1 } from 'pages/infidelity-affect/InfidelityAffectVariant1'
import { IntellectualDifferencesVariant1 } from 'pages/intellectual-differences/IntellectualDifferencesVariant1'
import { IntellectualIntimacyVariant1 } from 'pages/intellectual-intimacy/IntellectualIntimacyVariant1'
import { IntimacyAreaVariant1 } from 'pages/intimacy-area/IntimacyAreaVariant1'
import { IntimacyDefferenceVariant1 } from 'pages/intimacy-defference'
import { IntimacyProblemsVariant1 } from 'pages/intimacy-problems/IntimacyProblemsVariant1'
import { IntimacyProblemsVariant2 } from 'pages/intimacy-problems/IntimacyProblemsVariant2'
import { IntimacyThoughtsVariant1 } from 'pages/intimacy-thoughts/IntimacyThoughtsVariant1'
import { IntroVariant7 } from 'pages/intro/IntroVariant7'
import { JealousyVariant1, JealousyVariant2 } from 'pages/jealousy'
import { JealousyDescriptionVariant1 } from 'pages/jealousy-description/JealousyDescriptionVariant1'
import { KeyVariant1 } from 'pages/key/KeyVariant1'
import { KidsVariant1 } from 'pages/kids'
import { LedToInfidelityVariant1 } from 'pages/led-to-infidelity/LedToInfidelityVariant1'
import { LikelihoodBreakupsVariant1 } from 'pages/likelihood-breakups/LikelihoodBreakupsVariant1'
import { LocationVariant1 } from 'pages/location/LocationVariant1'
import { LocationVariant2 } from 'pages/location/LocationVariant2'
import {
  MainGoalsVariant1,
  MainGoalsVariant2,
  MainGoalsVariant3,
  MainGoalsVariant4,
  MainGoalsVariant5,
  MainGoalsVariant6,
  MainGoalsVariant7,
  MainGoalsVariant8,
} from 'pages/main-goals'
import { MaintainPhysicalIntimacyVariant1 } from 'pages/maintain-physical-intimacy/MaintainPhysicalIntimacyVariant1'
import { MakePriorityVariant1 } from 'pages/make-priority/MakePriorityVariant1'
import { MostlySatisfyingVariant1 } from 'pages/mostly-satisfying/MostlySatisfyingVariant1'
import { NewActivitiesVariant1 } from 'pages/new-activities/NewActivitiesVariant1'
import { NewInSexVariant1 } from 'pages/new-in-sex/NewInSexVariant1'
import { ObstacleToGoodSexVariant1 } from 'pages/obstacle-to-good-sex/ObstacleToGoodSexVariant1'
import { PartnerFriendshipsVariant1 } from 'pages/partner-friendships/PartnerFriendshipsVariant1'
import { PerfectDateVariant1 } from 'pages/perfect-date/PerfectDateVariant1'
import { PhysicalCloseVariant1 } from 'pages/physical-close/PhysicalCloseVariant1'
import { PhysicalCloseVariant2 } from 'pages/physical-close/PhysicalCloseVariant2'
import { PhysicalIntimacyDefinitionVariant1 } from 'pages/physical-intimacy-definition/PhysicalIntimacyDefinitionVariant1'
import { PhysicalTouchPriorityVariant1 } from 'pages/physical-touch-priority/PhysicalTouchPriorityVariant1'
import { PlanVariant1, PlanVariant2 } from 'pages/plan'
import { PlanProcessingVariant2 } from 'pages/plan-processing/PlanProcessingVariant2'
import { PlanProcessingVariant3 } from 'pages/plan-processing/PlanProcessingVariant3'
import { PlanProcessingVariant4 } from 'pages/plan-processing/PlanProcessingVariant4'
import { Presale1 } from 'pages/presale/Presale1'
import { QualityTimeVariant1 } from 'pages/quality-time/QualityTimeVariant1'
import { RateRelationshipVariant1 } from 'pages/rate-relationship'
import { RelationshipDurationVariant1 } from 'pages/relationship-duration/RelationshipDurationVariant1'
import { RelationshipDurationVariant2 } from 'pages/relationship-duration/RelationshipDurationVariant2'
import { RelationshipStatusVariant1 } from 'pages/relationship-status/RelationshipStatusVariant1'
import { RelationshipStatusVariant2 } from 'pages/relationship-status/RelationshipStatusVariant2'
import { RestrictiveVariant1, RestrictiveVariant2 } from 'pages/restrictive'
import { RomanceVariant1 } from 'pages/romance/RomanceVariant1'
import { TendernessVariant1 } from 'pages/satisfaction'
import { SatisfactionWithSexVariant1 } from 'pages/satisfaction-with-sex/SatisfactionWithSexVariant1'
import { SexDriveVariant2 } from 'pages/sex-drive/SexDriveVariant2'
import { SexDriveVariant3 } from 'pages/sex-drive/SexDriveVariant3'
import { SexImproveVariant1 } from 'pages/sex-improve/SexImproveVariant1'
import { SexQuantityVariant1 } from 'pages/sex-quantity/SexQuantityVariant1'
import { SexQuantityVariant2 } from 'pages/sex-quantity/SexQuantityVariant2'
import { SexStatisticVariant1 } from 'pages/sex-statistic/SexStatisticVariant1'
import { SexualExperiencesVariant1 } from 'pages/sexual-experiences/SexualExperiencesVariant1'
import { SexualNeedsVariant1 } from 'pages/sexual-needs/SexualNeedsVariant1'
import { SharedInterestsVariant1 } from 'pages/shared-interests/SharedInterestsVariant1'
import { SharingVariant1 } from 'pages/sharing/SharingVariant1'
import {
  SocialProofVariant1,
  SocialProofVariant2,
  SocialProofVariant3,
} from 'pages/social-proof'
import { SomeoneInterestVariant1 } from 'pages/someone-interest/SomeoneInterestVariant1'
import { SparkVariant1 } from 'pages/spark/SparkVariant1'
import { SpiritualBeliefsVariant1 } from 'pages/spiritual-beliefs/SpiritualBeliefsVariant1'
import { SpiritualChallengesVariant1 } from 'pages/spiritual-challenges/SpiritualChallengesVariant1'
import { SpiritualConnectionVariant1 } from 'pages/spiritual-connection/SpiritualConnectionVariant1'
import { SpiritualIntimacyDescriptionVariant1 } from 'pages/spiritual-intimacy-description/SpiritualIntimacyDescriptionVariant'
import { SpiritualPracticesVariant1 } from 'pages/spiritual-practices/SpiritualPracticesVariant1'
import { SpiritualSupportVariant1 } from 'pages/spiritual-support/SpiritualSupportVariant1'
import { SpiritualityConflictsVariant1 } from 'pages/spirituality-conflicts/SpiritualityConflictsVariant1'
import { SpiritualityDescriptionVariant1 } from 'pages/spirituality-description/SpiritualityDescriptionVariant1'
import { SpiritualityDisagreementsVariant1 } from 'pages/spirituality-disagreements/SpiritualityDisagreementsVariant1'
import { SpiritualityRoleVariant1 } from 'pages/spirituality-role/SpiritualityRoleVariant1'
import { StartAgeMixVariant1 } from 'pages/start-age-mix/StartAgeMixVariant1'
import { StepsToSpiritualConnectionVariant1 } from 'pages/steps-to-spiritual-connections/StepsToSpiritualConnectionsVariant1'
import { SupportInterestsVariant1 } from 'pages/support-interests/SupportInterestsVariant1'
import { TagsVariant1, TagsVariant2 } from 'pages/tags'
import { ThingsToBeFunVariant1 } from 'pages/things-to-be-fun/ThingsToBeFunVariant1'
import { ThingsToBeIntimateVariant1 } from 'pages/things-to-be-intimate/ThingsToBeIntimateVariant1'
import { ThoughtsAboutInfidelityVariant1 } from 'pages/thoughts-about-infidelity/ThoughtsAboutInfidelityVariant1'
import { TimeInAppVariant1 } from 'pages/time-in-app/TimeInAppVariant1'
import { TrustAfterInfidelityVariant1 } from 'pages/trust-after-infidelity/TrustAfterInfidelityVariant1'
import { TrustSecretsVariant1 } from 'pages/trust-secrets/TrustSecretsVariant1'
import { WhatYouSeeVariant1 } from 'pages/what-you-see'
import { WhatYouSeeIntroVariant1 } from 'pages/what-you-see-intro'

import { Account } from 'modules/account/Account'
import { EmailVariant2, EmailVariant3, EmailVariant4 } from 'modules/email'
import {
  PaymentTrialVariant1,
  PaymentTrialVariant2,
  PaymentVariant2,
  PaymentVariant3,
  PaymentVariant4,
} from 'modules/payment/pages'
import {
  CancelOfferVariant1,
  IntroDiscountVariant1,
  SubscriptionsVariant2,
  SubscriptionsVariant4,
  TrialSubscriptionsVariant1,
} from 'modules/subscriptions/pages'

import { TIdToPageMap } from 'models/common.model'

export const enum PageId {
  INTRO_7 = 'intro_7',
  INTIMACY_AREA_1 = 'intimacy_area_1',
  START_AGE_MIX_1 = 'start_age_mix',
  GENDER_2 = 'gender_2',
  GENDER_3 = 'gender_3',
  AGE_1 = 'age_1',
  AGE_2 = 'age_2',
  FELT_LONELY_1 = 'felt_lonely_1',
  COMMON_EMOTIONS_1 = 'common_emotions_1',
  COMMON_EMOTIONS_2 = 'common_emotions_2',
  BIGGEST_FEAR_1 = 'biggest_fear_1',
  RELATIONSHIP_STATUS_1 = 'relationship_status_1',
  RELATIONSHIP_STATUS_2 = 'relationship_status_2',
  RELATIONSHIP_DURATION_1 = 'relationship_duration_1',
  RELATIONSHIP_DURATION_2 = 'relationship_duration_2',
  LOCATION_1 = 'location_1',
  LOCATION_2 = 'location_2',
  CREATING = 'creating_1',
  SATISFACTION_WITH_SEX_1 = 'satisfaction_with_sex_1',
  SEX_DRIVE_2 = 'sex_drive_2',
  SEX_DRIVE_3 = 'sex_drive_3',
  MAIN_GOALS_1 = 'main_goals_1',
  MAIN_GOALS_2 = 'main_goals_2',
  MAIN_GOALS_3 = 'main_goals_3',
  MAIN_GOALS_4 = 'main_goals_4',
  MAIN_GOALS_5 = 'main_goals_5',
  MAIN_GOALS_6 = 'main_goals_6',
  MAIN_GOALS_7 = 'main_goals_7',
  MAIN_GOALS_8 = 'main_goals_8',
  COMMUNICATION_LEVEL_1 = 'communication_level_1',
  RATE_RELATIONSHIP_1 = 'rate_relationship_1',
  FACTORS_1 = 'factors_1',
  CONFLICT_FEELINGS_1 = 'conflict_feelings_1',
  INTIMACY_DEFFERENCE_1 = 'intimacy_defference_1',
  KIDS_1 = 'kids_1',
  TAGS_1 = 'tags_1',
  TAGS_2 = 'tags_2',
  SPIRITUALITY_ROLE_1 = 'spirituality_role_1',
  SPIRITUAL_BELIEFS_1 = 'spiritual_beliefs_1',
  SPIRITUAL_PRACTICES_1 = 'spiritual_practices_1',
  SPIRITUAL_CONNECTION_1 = 'spiritual_connection_1',
  SPIRITUALITY_CONFLICTS_1 = 'spirituality_conflicts_1',
  SPIRITUALITY_DISAGREEMENTS_1 = 'spirituality_disagreements_1',
  SPIRITUAL_SUPPORT_1 = 'spiritual_support_1',
  SPIRITUAL_CHALLENGES_1 = 'spiritual_challenges_1',
  SPIRITUALITY_DESCRIPTION_1 = 'spirituality_description_1',
  STEPS_TO_SPIRITUAL_CONNECTION_1 = 'steps_to_spiritual_connection_1',
  SPIRITUAL_INTIMACY_DESCRIPTION_1 = 'spiritual_intimacy_description_1',
  PHYSICAL_INTIMACY_DEFINITION_1 = 'physical_intimacy_definition_1',
  PHYSICAL_TOUCH_PRIORITY_1 = 'physical_touch_priority_1',
  SEXUAL_NEEDS_1 = 'sexual_needs_1',
  SEXUAL_EXPERIENCES_1 = 'sexual_experiences_1',
  MAINTAIN_PHYSICAL_INTIMACY_1 = 'maintain_physical_intimacy_1',
  DEAL_WITH_DIFFERENCES_1 = 'deal_with_differences_1',
  ADVENTUROUS_IN_SEX_1 = 'adventurous_in_sex_1',
  BE_UNFAITHFUL_1 = 'be_unfaithful_1',
  LED_TO_INFIDELITY_1 = 'led_to_infidelity_1',
  INFIDELITY_AFFECT_1 = 'infidelity_affect_1',
  TRUST_AFTER_INFIDELITY_1 = 'trust_after_infidelity_1',
  ADMIT_CHEATING_1 = 'admit_cheating_1',
  THOUGHTS_ABOUT_INFIDELITY_1 = 'thoughts_about_infidelity_1',
  SOMEONE_INTEREST_1 = 'someone_interest_1',
  PARTNER_FRIENDSHIPS_1 = 'partner_friendships_1',
  JEALOUSY_1 = 'jealousy_1',
  JEALOUSY_2 = 'jealousy_2',
  JEALOUSY_DESCRIPTION_1 = 'jealousy_description_1',
  TENDERNESS_1 = 'tenderness_1',
  BASIC_1 = 'basic_1',
  ROMANCE_1 = 'romance_1',
  INTIMACY_THOUGHTS_1 = 'intimacy_thoughts_1',
  INTIMACY_PROBLEMS_1 = 'intimacy_problems_1',
  INTIMACY_PROBLEMS_2 = 'intimacy_problems_2',
  DISCUSS_1 = 'discuss_1',
  PHYSICAL_CLOSE_1 = 'physical_close_1',
  PHYSICAL_CLOSE_2 = 'physical_close_2',
  HUGS_AND_KISSES_1 = 'hugs_and_kisses_1',
  MAKE_PRIORITY_1 = 'make_priority_1',
  FIRE_1 = 'fire_1',
  MOSTLY_SATISFYING_1 = 'mostly_satisfying_1',
  CHANGE_IN_SEX_1 = 'change_in_sex_1',
  OBSTACLE_TO_GOOD_SEX_1 = 'obstacle_to_good_sex_1',
  THINGS_TO_BE_FUN_1 = 'things_to_be_fun_1',
  THINGS_TO_BE_INTIMATE_1 = 'things_to_be_intimate_1',
  SEX_QUANTITY_1 = 'sex_quantity_1',
  SEX_QUANTITY_2 = 'sex_quantity_2',
  NEW_IN_SEX_1 = 'new_in_sex_1',
  FAVORITE_THINGS_1 = 'favorite_things_1',
  HATED_THINGS_1 = 'hated_things_1',
  BENEFITS_1 = 'benefits_1',
  KEY_1 = 'key_1',
  TIME_IN_APP_1 = 'time_in_app_1',
  PLAN_PROCESSING_2 = 'plan_processing_2',
  PLAN_PROCESSING_3 = 'plan_processing_3',
  PLAN_PROCESSING_4 = 'plan_processing_4',
  COMMENTS_1 = 'comments_1',
  SOCIAL_PROOF_1 = 'social_proof_1',
  SOCIAL_PROOF_2 = 'social_proof_2',
  SOCIAL_PROOF_3 = 'social_proof_3',
  HAPPINESS_1 = 'happiness_1',
  IMPORTANT_ASPECT_1 = 'important_aspect_1',
  BALANCE_1 = 'balance_1',
  CLOSENESS_SCALE_1 = 'closeness_scale_1',
  BREAKUP_1 = 'breakup_1',
  CHALLENGE_1 = 'challenge_1',
  QUALITY_TIME_1 = 'quality_time_1',
  DATES_1 = 'dates_1',
  ACTIVITIES_1 = 'activities_1',
  PERFECT_DATE_1 = 'perfect_date_1',
  GRATITUDE_1 = 'gratitude_1',
  SHARING_1 = 'sharing_1',
  FEEL_HEARD_1 = 'feel_heard_1',
  FEEDBACK_1 = 'feedback_1',
  CONFLICT_MANAGEMENT_1 = 'conflict_management_1',
  CONFLICT_MANAGEMENT_2 = 'conflict_management_2',
  SPARK_1 = 'spark_1',
  DISCUSS_FUTURE_1 = 'discuss_future_1',
  TRUST_SECRETS_1 = 'trust_secrets_1',
  CONVERSATIONS_FREQUENCY_1 = 'conversations_frequency_1',
  INTELLECTUAL_INTIMACY_1 = 'intellectual_intimacy_1',
  SHARED_INTERESTS_1 = 'shared_interests_1',
  CHALLENGE_INTELLECTUALLY_1 = 'challenge_intellectually_1',
  INTELLECTUAL_DIFFERENCES_1 = 'intellectual_differences_1',
  NEW_ACTIVITIES_1 = 'new_activities_1',
  SUPPORT_INTERESTS_1 = 'support_interests_1',
  BALANCE_INTELLECTUAL_PHYSICALS_1 = 'balance_intellectual_physicals_1',
  BEST_FRIEND_1 = 'best_friend_1',
  EMAIL_2 = 'email_2',
  EMAIL_3 = 'email_3',
  EMAIL_4 = 'email_4',
  PLAN_1 = 'plan_1',
  PLAN_2 = 'plan_2',
  PRESALE_1 = 'presale_1',
  SUBSCRIPTIONS_2 = 'subscriptions_2',
  SUBSCRIPTIONS_4 = 'subscriptions_4',
  TRIAL_SUBSCRIPTIONS_1 = 'trial_subscriptions_1',
  PAYMENT_TRIAL_1 = 'payment_trial_1',
  PAYMENT_TRIAL_2 = 'payment_trial_2',
  PAYMENT_2 = 'payment_2',
  PAYMENT_3 = 'payment_3',
  PAYMENT_4 = 'payment_4',
  PAYMENT_5 = 'payment_5',
  INTRO_DISCOUNT = 'intro_discount',
  CANCEL_OFFER = 'cancel_offer',
  ACCOUNT = 'account',
  DOWNLOAD = 'download',
  TEST = 'test',
  WHAT_YOU_SEE_INTRO = 'what_you_see_intro',
  WHAT_YOU_SEE_1 = 'what_you_see_1',
  WHAT_YOU_SEE_2 = 'what_you_see_2',
  WHAT_YOU_SEE_3 = 'what_you_see_3',
  WHAT_YOU_SEE_4 = 'what_you_see_4',
  RESTRICTIVE_1 = 'restrictive_1',
  RESTRICTIVE_2 = 'restrictive_2',
  PRIVACY_POLICY = 'privacy-policy',
  TERMS_OF_USE = 'terms-of-use',
}

export const ID_TO_PAGE_MAP: TIdToPageMap = {
  intro_7: IntroVariant7,
  start_age_mix: StartAgeMixVariant1,
  intimacy_area_1: IntimacyAreaVariant1,
  age_1: AgeVariant1,
  age_2: AgeVariant2,
  felt_lonely_1: FeltLonelyVariant1,
  common_emotions_1: CommonEmotionsVariant1,
  common_emotions_2: CommonEmotionsVariant2,
  biggest_fear_1: BiggestFearVariant1,
  gender_2: GenderVariant2,
  gender_3: GenderVariant3,
  relationship_status_1: RelationshipStatusVariant1,
  relationship_status_2: RelationshipStatusVariant2,
  relationship_duration_1: RelationshipDurationVariant1,
  relationship_duration_2: RelationshipDurationVariant2,
  restrictive_1: RestrictiveVariant1,
  restrictive_2: RestrictiveVariant2,
  what_you_see_intro: WhatYouSeeIntroVariant1,
  what_you_see_1: WhatYouSeeVariant1,
  what_you_see_2: WhatYouSeeVariant1,
  what_you_see_3: WhatYouSeeVariant1,
  what_you_see_4: WhatYouSeeVariant1,
  location_1: LocationVariant1,
  location_2: LocationVariant2,
  creating_1: CreatingVariant1,
  sex_stat_1: SexStatisticVariant1,
  sex_improve_1: SexImproveVariant1,
  satisfaction_with_sex_1: SatisfactionWithSexVariant1,
  sex_drive_2: SexDriveVariant2,
  sex_drive_3: SexDriveVariant3,
  main_goals_1: MainGoalsVariant1,
  main_goals_2: MainGoalsVariant2,
  main_goals_3: MainGoalsVariant3,
  main_goals_4: MainGoalsVariant4,
  main_goals_5: MainGoalsVariant5,
  main_goals_6: MainGoalsVariant6,
  main_goals_7: MainGoalsVariant7,
  main_goals_8: MainGoalsVariant8,
  rate_relationship_1: RateRelationshipVariant1,
  communication_level_1: CommunicationLevelVariant1,
  factors_1: FactorsVariant1,
  conflict_feelings_1: ConflictFeelingsVariant1,
  intimacy_defference_1: IntimacyDefferenceVariant1,
  kids_1: KidsVariant1,
  tags_1: TagsVariant1,
  tags_2: TagsVariant2,
  spirituality_role_1: SpiritualityRoleVariant1,
  spiritual_beliefs_1: SpiritualBeliefsVariant1,
  spiritual_practices_1: SpiritualPracticesVariant1,
  spiritual_connection_1: SpiritualConnectionVariant1,
  spirituality_conflicts_1: SpiritualityConflictsVariant1,
  spirituality_disagreements_1: SpiritualityDisagreementsVariant1,
  spiritual_support_1: SpiritualSupportVariant1,
  spiritual_challenges_1: SpiritualChallengesVariant1,
  spirituality_description_1: SpiritualityDescriptionVariant1,
  steps_to_spiritual_connection_1: StepsToSpiritualConnectionVariant1,
  spiritual_intimacy_description_1: SpiritualIntimacyDescriptionVariant1,
  physical_intimacy_definition_1: PhysicalIntimacyDefinitionVariant1,
  physical_touch_priority_1: PhysicalTouchPriorityVariant1,
  sexual_needs_1: SexualNeedsVariant1,
  sexual_experiences_1: SexualExperiencesVariant1,
  maintain_physical_intimacy_1: MaintainPhysicalIntimacyVariant1,
  deal_with_differences_1: DealWithDifferencesVariant1,
  adventurous_in_sex_1: AdventurousInSexVariant1,
  be_unfaithful_1: BeUnfaithfulVariant1,
  led_to_infidelity_1: LedToInfidelityVariant1,
  infidelity_affect_1: InfidelityAffectVariant1,
  trust_after_infidelity_1: TrustAfterInfidelityVariant1,
  admit_cheating_1: AdmitCheatingVariant1,
  thoughts_about_infidelity_1: ThoughtsAboutInfidelityVariant1,
  someone_interest_1: SomeoneInterestVariant1,
  partner_friendships_1: PartnerFriendshipsVariant1,
  jealousy_1: JealousyVariant1,
  jealousy_2: JealousyVariant2,
  jealousy_description_1: JealousyDescriptionVariant1,
  tenderness_1: TendernessVariant1,
  basic_1: BasicVariant1,
  romance_1: RomanceVariant1,
  intimacy_thoughts_1: IntimacyThoughtsVariant1,
  intimacy_problems_1: IntimacyProblemsVariant1,
  intimacy_problems_2: IntimacyProblemsVariant2,
  discuss_1: DiscussVariant1,
  physical_close_1: PhysicalCloseVariant1,
  physical_close_2: PhysicalCloseVariant2,
  hugs_and_kisses_1: HugsAndKissesVariant1,
  make_priority_1: MakePriorityVariant1,
  fire_1: FireVariant1,
  mostly_satisfying_1: MostlySatisfyingVariant1,
  change_in_sex_1: ChangeInSexVariant1,
  obstacle_to_good_sex_1: ObstacleToGoodSexVariant1,
  things_to_be_fun_1: ThingsToBeFunVariant1,
  things_to_be_intimate_1: ThingsToBeIntimateVariant1,
  sex_quantity_1: SexQuantityVariant1,
  sex_quantity_2: SexQuantityVariant2,
  new_in_sex_1: NewInSexVariant1,
  favorite_things_1: FavoriteThingsVariant1,
  hated_things_1: HatedThingsVariant1,
  benefits_1: BenefitsVariant1,
  key_1: KeyVariant1,
  time_in_app_1: TimeInAppVariant1,
  comments_1: CommentsVariant1,
  social_proof_1: SocialProofVariant1,
  social_proof_2: SocialProofVariant2,
  social_proof_3: SocialProofVariant3,
  happiness_1: HappinessVariant1,
  important_aspect_1: ImportantAspectVariant1,
  balance_1: BalanceVariant1,
  closeness_scale_1: ClosenessScaleVariant1,
  breakup_1: BreakupVariant1,
  challenge_1: ChallengeVariant1,
  quality_time_1: QualityTimeVariant1,
  dates_1: DatesVariant1,
  activities_1: ActivitiesVariant1,
  perfect_date_1: PerfectDateVariant1,
  gratitude_1: GratitudeVariant1,
  sharing_1: SharingVariant1,
  feel_heard_1: FeelHeardVariant1,
  feedback_1: FeedbackVariant1,
  conflict_management_1: ConflictManagementVariant1,
  conflict_management_2: ConflictManagementVariant2,
  likelihood_breakups_1: LikelihoodBreakupsVariant1,
  fortunately_1: FortunatelyVariant1,
  discuss_future_1: DiscussFutureVariant1,
  spark_1: SparkVariant1,
  trust_secrets_1: TrustSecretsVariant1,
  conversations_frequency_1: ConversationsFrequencyVariant1,
  intellectual_intimacy_1: IntellectualIntimacyVariant1,
  shared_interests_1: SharedInterestsVariant1,
  challenge_intellectually_1: ChallengeIntellectuallyVariant1,
  intellectual_differences_1: IntellectualDifferencesVariant1,
  new_activities_1: NewActivitiesVariant1,
  support_interests_1: SupportInterestsVariant1,
  balance_intellectual_physicals_1: BalanceIntellectualPhysicalsVariant1,
  best_friend_1: BestFriendVariant1,
  plan_processing_2: PlanProcessingVariant2,
  plan_processing_3: PlanProcessingVariant3,
  plan_processing_4: PlanProcessingVariant4,
  email_2: EmailVariant2,
  email_3: EmailVariant3,
  email_4: EmailVariant4,
  plan_1: PlanVariant1,
  plan_2: PlanVariant2,
  presale_1: Presale1,
  subscriptions_2: SubscriptionsVariant2,
  subscriptions_4: SubscriptionsVariant4,
  trial_subscriptions_1: TrialSubscriptionsVariant1,
  payment_trial_1: PaymentTrialVariant1,
  payment_trial_2: PaymentTrialVariant2,
  payment_2: PaymentVariant2,
  payment_3: PaymentVariant3,
  payment_4: PaymentVariant4,
  payment_5: PaymentVariant4,
  intro_discount: IntroDiscountVariant1,
  cancel_offer: CancelOfferVariant1,
  account: Account,
  download: DownloadApp,
  test: GrowthbookTestPage,
}
