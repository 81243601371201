import React, { useMemo } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton2 } from 'components/QuestionButton2'
import { TitleContainer } from 'components/TitleContainer'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledLocation as S } from './Location.styles'

const QUESTION = 'Do you and your partner live together?'
const OPTION_VALUES = {
  yes: 'Yes',
  no: 'No',
  soon: 'We plan to move in together soon',
  longDistance: 'Long-distance',
}

export const LocationVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <TitleContainer title={<S.Title>{QUESTION}</S.Title>} />
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.yes}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.yes}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.no}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.no}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.soon}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.soon}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.longDistance}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.longDistance}</QuestionButton2>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
