import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { PageContainer } from 'components/PageContainer'
import { StickyContainer } from 'components/StickyContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import banner from 'assets/images/sex-improve-banner.png'

import { StyledSexImproveVariant1 as S } from './SexImproveVariant1.styles'
import { BENEFITS, TRANSLATION_FAMILY } from './constants'

export const SexImproveVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: t(`${TRANSLATION_FAMILY}.title`, { lng: 'en' }),
    nextPagePath,
  })

  return (
    <PageContainer>
      <S.Column>
        <S.Title>
          <Trans i18nKey={`${TRANSLATION_FAMILY}.title`} />
        </S.Title>
        <S.BannerContainer>
          <img src={banner} alt="banner" />
        </S.BannerContainer>
        <S.BenefitsList benefits={BENEFITS} />
        <StickyContainer>
          <S.Button onClick={() => handleContinue('')}>
            {t('actions.continue')}
          </S.Button>
        </StickyContainer>
      </S.Column>
    </PageContainer>
  )
}
