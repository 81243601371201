import styled from 'styled-components'

import { Button } from 'components/Button'
import { PageContainer } from 'components/PageContainer'

import { Guarantee } from 'modules/subscriptions/components/Guarantee'

import bgImage from 'assets/images/ellipses.svg'
import checkIcon from 'assets/images/icon-heart.svg'
import star from 'assets/images/star.png'

import { baseColumnStyles } from 'common-styles'
import { Color } from 'root-constants/common'

type TProps = {
  margin?: string
  marginBottom?: number
  color?: string
}

export const StyledSubscriptionsVariant4 = {
  Wrapper: styled(PageContainer)`
    min-height: 100%;
    background-image: url(${bgImage});
    background-position: center 0;
    background-size: auto 1900px;
    background-repeat: no-repeat;
  `,
  Column: styled.div`
    ${baseColumnStyles};
  `,
  Title: styled.h1`
    color: var(--base-text-color);
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    text-align: center;
    margin: 16px 0 11px;

    strong {
      font-weight: inherit;
      color: ${Color.LILAC_130};
    }
  `,
  Text: styled.span`
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: var(--base-text-color);
    margin-bottom: 42px;

    strong {
      font-weight: 800;
      color: ${Color.LILAC_130};
    }
  `,
  CoupleImg: styled.div`
    aspect-ratio: 375 / 302;
    width: 100%;
  `,
  GoalWrapper: styled.div`
    width: 100%;
    position: relative;
    padding: 18px 32px 14px;
    background-image: linear-gradient(
      180deg,
      rgba(217, 216, 246, 0.9) 0%,
      rgba(217, 216, 246, 0.8) 100%
    );
    border-radius: 16px;
    text-align: center;
    margin: -96px 0 43px;
  `,
  Dart: styled.div`
    aspect-ratio: 26 / 27;
    left: 50%;
    position: absolute;
    top: -27px;
    transform: translate(-50%);
    width: 52px;
  `,
  GoalTitle: styled.h2`
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
  `,
  Goal: styled.span`
    font-size: 16px;
    line-height: 24px;
  `,
  AdvantagesListWrapper: styled.div`
    color: var(--base-text-color);
    padding: 16px 14px 3px;
    background-color: rgba(44, 40, 111, 0.85);
    border-radius: 25px;
    margin-bottom: 41px;
  `,
  Subtitle: styled.h2<TProps>`
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    text-align: center;
    color: var(--base-text-color);
    margin: ${({ margin }) => margin};

    & span {
      color: ${({ color }) => color};
    }
  `,
  BenefitsWrapper: styled.ul`
    margin-bottom: 40px;
  `,
  BenefitsList: styled.li`
    color: var(--base-text-color);
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    margin-top: 16px;
    list-style: none;
    display: flex;
    align-items: center;
    background-image: url(${star});
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 32px;
    padding-left: 40px;
    height: 32px;

    & span {
      color: #fefad4;
    }
  `,
  MoneyBackText: styled.p`
    margin: 0 0 40px;
    color: #9d95d1;
    text-align: center;
    font-size: 11px;
    font-weight: 400;
    line-height: 18px;

    & a {
      color: #9d95d1;
      text-decoration: underline;
    }
  `,
  Item: styled.li`
    padding-left: 38px;
    margin-bottom: 19px;
    display: flex;
    align-items: center;
    position: relative;
    font-size: 16px;
    line-height: 28px;
    color: #fff;

    ::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 28px;
      height: 28px;
      background-color: ${Color.LILAC_130};
      border-radius: 50%;
      background-image: url(${checkIcon});
      background-repeat: no-repeat;
      background-position: 5px center;
    }
  `,
  Divider: styled.div<TProps>`
    height: 1px;
    width: 100%;
    background-color: #362f99;
    margin-bottom: ${({ marginBottom }) => marginBottom}px;
  `,
  Button: styled(Button)`
    margin-bottom: 25px;
  `,
  StartButton: styled(Button)`
    margin-bottom: 27px;
  `,
  Guarantee: styled(Guarantee)`
    margin-bottom: 80px;
  `,
  FakeScrollTarget: styled.button`
    visibility: hidden;
  `,
}
