import styled from 'styled-components'

import { Color } from 'root-constants/common'

export const StyledMoneyBackGuarantee = {
  Wrapper: styled.div`
    position: relative;
    z-index: 2;
    background-color: ${Color.WHITE_120};
    border-radius: 16px;
  `,
  Content: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 24px 20px 24px;
    position: relative;
    z-index: 4;
  `,
  Title: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 22px;
    text-align: center;
    color: ${Color.DEFAULT_TEXT};
    margin: 0 0 10px 0;
  `,
  Text: styled.p`
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: ${Color.DEFAULT_TEXT};

    a {
      text-decoration: underline;
      color: ${Color.DEFAULT_TEXT};
    }
  `,
  Stamp: styled.img`
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 3;
    width: 75px;
    height: 75px;
  `,
}
