import React, { HTMLAttributes } from 'react'

import { StyledStickyContainer as S } from './StickyContainer.styles'

export const StickyContainer: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
}) => (
  <S.Wrapper className={className}>
    <S.Column>{children}</S.Column>
  </S.Wrapper>
)
