import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton2 } from 'components/QuestionButton2'
import { TitleContainer } from 'components/TitleContainer'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useGetFlowGroupInfo } from 'hooks/useGetFlowGroupInfo'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { IntimacyArea } from 'root-constants/common'

import { StyledClosenessScale as S } from './ClosenessScale.styles'

const QUESTION =
  'How would you rate the emotional closeness between you and your partner on a scale of 1-10?'
const OPTION_VALUES = {
  three: '1-3',
  six: '4-6',
  eight: '7-8',
  ten: '9-10',
}

export const ClosenessScaleVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
  alternativePagePath,
}) => {
  const { isMultipleFlow, nextPagePath: nextGroupPath } = useGetFlowGroupInfo(
    IntimacyArea.EMOTIONAL,
  )
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()
  const dispatch = useDispatch()

  const handleContinue = useCallback(
    (answer: string | string[]) => {
      dispatch(
        setAnswersAction({
          answers: answer,
          pageId,
        }),
      )
      dispatch(sendUserAnswersAction())

      eventLogger.logQuestion({
        question: QUESTION,
        answers: answer,
        pageName: pageId,
      })

      if (answer === OPTION_VALUES.ten && isMultipleFlow) {
        goTo(nextGroupPath)
        return
      }

      if (answer === OPTION_VALUES.ten && !isMultipleFlow) {
        goTo(alternativePagePath)
        return
      }

      goTo(nextPagePath)
    },
    [
      dispatch,
      isMultipleFlow,
      alternativePagePath,
      nextPagePath,
      pageId,
      nextGroupPath,
    ],
  )

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <TitleContainer title={<S.QuestionTitle>{QUESTION}</S.QuestionTitle>} />
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.three}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.three}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.six}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.six}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.eight}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.eight}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.ten}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.ten}</QuestionButton2>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
