import React from 'react'

import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { SLIDER_DATA } from '../constants'
import { StyledSlider as S } from './Slider.styles'

type TProps = React.HTMLAttributes<HTMLDivElement>

export const Slider: React.FC<TProps> = (props) => {
  return (
    <S.Wrapper {...props}>
      <Swiper
        slidesPerView="auto"
        spaceBetween={12}
        centeredSlides
        modules={[Pagination]}
        pagination={{ clickable: true }}
        loop
        autoplay={{ delay: 3000, disableOnInteraction: false }}
      >
        {SLIDER_DATA.map((data) => (
          <SwiperSlide key={data.userName}>
            <S.Card backgroundImage={data.image}>
              <S.CardText>{data.text}</S.CardText>
              <S.Name>{data.userName}</S.Name>
            </S.Card>
          </SwiperSlide>
        ))}
      </Swiper>
    </S.Wrapper>
  )
}
