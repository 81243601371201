import styled from 'styled-components'

import { StickyButton } from 'components/StickyButton'

import { QuestionTitle, baseColumnStyles } from 'common-styles'
import { Color } from 'root-constants/common'

export const StyledFeedback = {
  Column: styled.div`
    ${baseColumnStyles};
    padding: 40px 0 94px;
  `,
  QuestionTitle: styled(QuestionTitle)`
    line-height: 28px;
    margin-bottom: 76px;

    @media (min-width: 360px) {
      width: 340px;
    }
  `,
  FeedbackWrapper: styled.div`
    width: 270px;
    position: relative;
    text-align: center;
    background-color: ${Color.LILAC_110};
    border-radius: 25px;
    padding: 43px 10px 20px;
    margin-bottom: 47px;
  `,
  Photo: styled.div`
    aspect-ratio: 145 / 144;
    border: 4px solid ${Color.LILAC_110};
    border-radius: 50%;
    left: 50%;
    overflow: hidden;
    position: absolute;
    top: -37px;
    transform: translateX(-50%);
    width: 70px;
  `,
  UserName: styled.span`
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 3px;
  `,
  Stars: styled.div`
    aspect-ratio: 13 / 2;
    margin: 0 auto 7px;
    width: 91px;
  `,
  FeedbackText: styled.p`
    font-style: italic;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
  `,
  Text: styled.p`
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: var(--base-text-color);
    margin-bottom: 32px;

    strong {
      font-weight: 900;
    }
  `,
  Button: styled(StickyButton)`
    height: 62px;

    @media (max-height: 620px) {
      position: relative;
      bottom: 0;
    }
  `,
}
