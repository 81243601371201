import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectAnswers } from 'root-redux/selects/common'

import {
  GENDER_PAGES,
  GenderValue,
  MAIN_GOAL_PAGES,
  PriorityValue,
  RATE_RELATIONSHIP_PAGES,
  RELATIONSHIP_DURATION_PAGES,
  RELATIONSHIP_STATUS_PAGES,
  RateRelationshipValue,
  RelationshipDurationValue,
  START_AGE_MIX_PAGES,
  StartAgeValue,
  StatusValue,
  TAGS_PAGES,
  TagsValue,
} from 'root-constants/common'
import { PageId } from 'root-constants/pages'

export const useUserData = (): {
  startAgeMix: StartAgeValue
  isMale: boolean
  usersPriority: string
  isSexUsersPriority: boolean
  relationshipStatus: StatusValue
  relationshipDuration: RelationshipDurationValue
  tags: TagsValue[]
  userGoal: string
  satisfaction: string
  drive: string
  sexQuantity: string
  affectionFrequency: string
  intimacyProblems: string
  gender: string
  age: string
  rateRelationship: RateRelationshipValue
} => {
  const answers = useSelector(selectAnswers)

  const isMale = useMemo(
    () =>
      (answers?.[PageId.GENDER_2] as string)?.toLowerCase() ===
      GenderValue.MALE,
    [answers],
  )

  const usersPriority = useMemo(
    () => (answers?.[PageId.INTRO_7] as string)?.toLowerCase(),
    [answers],
  )

  const isSexUsersPriority = useMemo(
    () => usersPriority === PriorityValue.SEX,
    [usersPriority],
  )

  const relationshipStatus = useMemo(() => {
    const relationshipStatusPageId =
      RELATIONSHIP_STATUS_PAGES.find((page) => answers?.[page]) || ''
    return answers?.[relationshipStatusPageId]
  }, [answers])

  const relationshipDuration = useMemo(() => {
    const relationshipDurationPageId =
      RELATIONSHIP_DURATION_PAGES.find((page) => answers?.[page]) || ''
    return answers?.[relationshipDurationPageId]
  }, [answers])

  const startAgeMix = useMemo(() => {
    const startAgeMixPageId =
      START_AGE_MIX_PAGES.find((page) => answers?.[page]) || ''
    return answers?.[startAgeMixPageId]
  }, [answers])

  const tags = useMemo(() => {
    const tagsPageId = TAGS_PAGES.find((page) => answers?.[page]) || ''
    return answers?.[tagsPageId] || []
  }, [answers])

  const userGoal = useMemo(() => {
    const goalPageId = MAIN_GOAL_PAGES.find((page) => answers?.[page]) || ''
    return answers?.[goalPageId]
  }, [answers])

  const satisfaction = useMemo(
    () => (answers?.[PageId.MOSTLY_SATISFYING_1] as string)?.toLowerCase(),
    [answers],
  )

  const drive = useMemo(
    () => (answers?.[PageId.SEX_DRIVE_2] as string)?.toLowerCase(),
    [answers],
  )

  const sexQuantity = useMemo(
    () => answers?.[PageId.SEX_QUANTITY_1] as string,
    [answers],
  )

  const affectionFrequency = useMemo(
    () => (answers?.[PageId.HUGS_AND_KISSES_1] as string)?.toLowerCase(),
    [answers],
  )

  const intimacyProblems = useMemo(
    () => answers?.[PageId.INTIMACY_PROBLEMS_1] as string,
    [answers],
  )

  const gender = useMemo(() => {
    const genderPageId = GENDER_PAGES.find((page) => answers?.[page]) || ''
    return answers?.[genderPageId]
  }, [answers])

  const age = useMemo(
    () => (answers?.[PageId.AGE_2] as string)?.toLowerCase(),
    [answers],
  )

  const rateRelationship = useMemo(() => {
    const relationshipPageId =
      RATE_RELATIONSHIP_PAGES.find((page) => answers?.[page]) || ''
    return answers?.[relationshipPageId]
  }, [answers])

  return {
    startAgeMix,
    isMale,
    usersPriority,
    isSexUsersPriority,
    relationshipStatus,
    relationshipDuration,
    tags,
    userGoal,
    satisfaction,
    drive,
    sexQuantity,
    affectionFrequency,
    intimacyProblems,
    gender,
    age,
    rateRelationship,
  }
}
