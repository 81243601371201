import React, { useMemo } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton2 } from 'components/QuestionButton2'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledSpiritualityRole as S } from './SpiritualityRole.styles'

const QUESTION = 'What role does spirituality play in your relationship?'
const OPTION_VALUES = {
  itIsFoundation: `It's the foundation of our relationship`,
  importantAspect: `It's an important aspect, but not the only one`,
  notMajorFactor: `It's not a major factor in our relationship`,
  notImportant: `It's not important at all to us`,
}

export const SpiritualityRoleVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.Title>{QUESTION}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.itIsFoundation}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.itIsFoundation}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.importantAspect}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.importantAspect}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.notMajorFactor}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.notMajorFactor}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.notImportant}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.notImportant}</QuestionButton2>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
