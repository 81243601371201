import React, { useMemo } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton2 } from 'components/QuestionButton2'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledIntimacyProblems as S } from './IntimacyProblems.styles'

const QUESTION =
  'How do you and your partner feel about physical intimacy during times of stress or conflict?'
const OPTION_VALUES = {
  useIntimacy:
    'We use physical intimacy as a way to comfort and support each other',
  avoidIntimacy:
    'We avoid physical intimacy during times of stress or conflict',
  haveNotDiscussed: `We haven't discussed it`,
  other: 'Other',
}

export const IntimacyProblemsVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.ColumnVariant2>
        <S.QuestionTitleVariant2>{QUESTION}</S.QuestionTitleVariant2>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.useIntimacy}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.useIntimacy}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.avoidIntimacy}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.avoidIntimacy}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.haveNotDiscussed}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.haveNotDiscussed}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.other}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.other}</QuestionButton2>
          </Option>
        </StyledOption.OptionsContainer>
      </S.ColumnVariant2>
    </PageContainer>
  )
}
