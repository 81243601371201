import styled from 'styled-components'

import { baseColumnStyles } from 'common-styles'

export const StyledStickyContainer = {
  Wrapper: styled.div`
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    background-image: linear-gradient(
      180deg,
      rgba(23, 18, 91, 0) 0%,
      #0e0b3e 30%
    );
    padding: 19px 0;
  `,
  Column: styled.div`
    ${baseColumnStyles};
  `,
}
