import { ActivitiesValue } from 'root-constants/common'

export const TRANSLATION_FAMILY = 'onboarding.activitiesV1'

export const OPTIONS = [
  {
    key: 'cooking',
    value: ActivitiesValue.COOKING,
  },
  {
    key: 'watchingMovies',
    value: ActivitiesValue.WATCHING_MOVIES,
  },
  {
    key: 'walks',
    value: ActivitiesValue.WALKS,
  },
  {
    key: 'playing',
    value: ActivitiesValue.PLAYING,
  },
  {
    key: 'traveling',
    value: ActivitiesValue.TRAVELING,
  },
  {
    key: 'attendingConcerts',
    value: ActivitiesValue.ATTENDING_CONCERTS,
  },
  {
    key: 'relaxing',
    value: ActivitiesValue.RELAXING,
  },
  {
    key: 'other',
    value: ActivitiesValue.OTHER,
  },
]
