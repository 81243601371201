import audioCourses from 'assets/images/benefit-audio-courses.png'
import challenge from 'assets/images/benefit-challenge.png'
import exercises from 'assets/images/benefit-exercises.png'
import personalPlan from 'assets/images/benefit-personal-plan.png'
import premium from 'assets/images/benefit-premium.png'
import quizzes from 'assets/images/benefit-quizzes.png'

export const BENEFITS = [
  'paymentTrialV1.benefits.premium',
  'paymentTrialV1.benefits.personalizedPlan',
  'paymentTrialV1.benefits.challenge',
  'paymentTrialV1.benefits.dailyConversation',
  'paymentTrialV1.benefits.thematicQuizzes',
  'paymentTrialV1.benefits.audioCourses',
]

export const BENEFITS_DATA = [
  {
    icon: personalPlan,
    title: 'paymentTrialV2.benefits.personalizedPlan.title',
    description: 'paymentTrialV2.benefits.personalizedPlan.description',
    color: '#ffede8',
  },
  {
    icon: premium,
    title: 'paymentTrialV2.benefits.premium.title',
    description: 'paymentTrialV2.benefits.premium.description',
    color: '#f5d9ff',
  },
  {
    icon: challenge,
    title: 'paymentTrialV2.benefits.challenge.title',
    description: 'paymentTrialV2.benefits.challenge.description',
    color: '#daefff',
  },
  {
    icon: quizzes,
    title: 'paymentTrialV2.benefits.quizzes.title',
    description: 'paymentTrialV2.benefits.quizzes.description',
    color: '#ffe3b1',
  },
  {
    icon: exercises,
    title: 'paymentTrialV2.benefits.exercisesTests.title',
    description: 'paymentTrialV2.benefits.exercisesTests.description',
    color: '#dacbff',
  },
  {
    icon: audioCourses,
    title: 'paymentTrialV2.benefits.audioCourses.title',
    description: 'paymentTrialV2.benefits.audioCourses.description',
    color: '#ffc4ca',
  },
]

export const PAYMENT_TRIAL_V2_HEADER = 83
export const PAYMENT_TRIAL_V1_HEADER = 67
