import styled from 'styled-components'

import gradient from 'assets/images/gradient.svg'

import { Color } from 'root-constants/common'

type TProps = {
  backgroundImage: string
}

export const StyledSlider = {
  Wrapper: styled.div`
    .swiper-wrapper {
      display: flex;
    }

    .swiper-slide {
      position: relative;
      width: 328px;
      height: 494px;
    }

    .swiper-pagination {
      position: static;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 8px 0 16px;
    }

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 50%;
      margin: 0 3px !important;
    }

    .swiper-pagination-bullet-active {
      background-color: #fff;
    }
  `,
  Card: styled.div<TProps>`
    height: 494px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 0 37px 40px;
    border-radius: 25px;
    border: 4px solid ${Color.LILAC_110};
    color: var(--base-text-color);
    background-image: ${({ backgroundImage }) => `url(${gradient}),
    url(${backgroundImage})`};
    background-size: cover;
    background-repeat: no-repeat;
  `,
  Name: styled.h3`
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  `,
  CardText: styled.p`
    font-style: italic;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    text-align: center;
    margin-bottom: 8px;
  `,
}
