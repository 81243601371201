import React from 'react'
import { Trans } from 'react-i18next'

import plan from 'assets/images/plan.png'

import { StyledPlanBlock as S } from './PlanBlock.styles'

type TProps = {
  className?: string
}

export const PlanBlock: React.FC<TProps> = ({ className }) => (
  <S.Block className={className}>
    <S.Title>
      <Trans i18nKey="paymentTrialV2.planBlockTitle" />
    </S.Title>
    <S.PlanContainer>
      <img src={plan} alt="plan" />
    </S.PlanContainer>
  </S.Block>
)
