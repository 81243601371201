import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import lottie from 'lottie-web/build/player/lottie_light'

import { PageContainer } from 'components/PageContainer'
import { StickyContainer } from 'components/StickyContainer'

import { useUserData } from 'hooks/useUserData'

import { STATUS_TO_MARKUP_MAP } from 'modules/subscriptions/constants'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import {
  CDN_FOLDER_LINK,
  RateRelationshipValue,
  RelationshipDurationValue,
  StartAgeValue,
  StatusValue,
} from 'root-constants/common'

import { StyledPlan as S } from './Plan.styles'
import {
  RELATIONSHIP_ASSESSMENT_MAP,
  SATISFACTION_ANIMATION_PATH,
  START_AGE_TO_IMAGE_MAP,
  STRUGGLES_ANIMATION_PATH,
} from './constants'

export const PlanVariant2: React.FC<TPageProps> = ({
  nextPagePath,
  pageId,
}) => {
  const { t } = useTranslation()

  const animationRef = useRef<HTMLDivElement>(null)

  const {
    relationshipStatus,
    relationshipDuration,
    startAgeMix,
    rateRelationship,
  } = useUserData()

  const relationshipAssessmentValue = useMemo(
    () =>
      t(RELATIONSHIP_ASSESSMENT_MAP[rateRelationship]) ||
      t(RELATIONSHIP_ASSESSMENT_MAP[RateRelationshipValue.PERFECT]),
    [rateRelationship, t],
  )

  const plans = useMemo(
    () => [
      {
        id: 'status',
        name: t('planV1.status'),
        value:
          STATUS_TO_MARKUP_MAP[relationshipStatus] ||
          STATUS_TO_MARKUP_MAP[StatusValue.SERIOUS],
        iconName: 'heart',
      },
      {
        id: 'together',
        name: t('planV1.together'),
        value: relationshipDuration || RelationshipDurationValue.MORE_4_YEARS,
        iconName: 'time',
      },
      {
        id: 'relationshipAssessment',
        name: t('planV1.relationshipAssessment'),
        value: relationshipAssessmentValue,
        iconName: 'warning',
      },
    ],
    [relationshipStatus, relationshipDuration, relationshipAssessmentValue],
  )

  const animationPath = useMemo(() => {
    switch (rateRelationship) {
      case RateRelationshipValue.PERFECT:
      case RateRelationshipValue.FINE:
      case RateRelationshipValue.ROOM:
        return `${CDN_FOLDER_LINK}${SATISFACTION_ANIMATION_PATH}`

      case RateRelationshipValue.IMPROVEMENT:
      case RateRelationshipValue.ISSUES:
        return `${CDN_FOLDER_LINK}${STRUGGLES_ANIMATION_PATH}`

      default:
        return `${CDN_FOLDER_LINK}${SATISFACTION_ANIMATION_PATH}`
    }
  }, [rateRelationship])

  useEffect(() => {
    eventLogger.logPlan1PageShow({ pageName: pageId })
  }, [pageId])

  useEffect(() => {
    if (animationRef.current) {
      lottie.loadAnimation({
        container: animationRef.current,
        path: animationPath,
        loop: false,
      })
    }
  }, [animationPath])

  const handleContinue = useCallback(() => {
    eventLogger.logPlan1PageCompleted({ pageName: pageId })
    goTo(nextPagePath)
  }, [nextPagePath, pageId])

  return (
    <PageContainer>
      <S.Column>
        <S.TitleV2>
          <Trans i18nKey="planV1.titleV2" />
        </S.TitleV2>
        <S.PlanContainerV2>
          <S.PlanList>
            {plans.map(({ id, name, value, iconName }) => (
              <S.Plan key={id} data-icon={iconName}>
                <S.PlanName>{name}</S.PlanName>
                <S.PlanValue>{value}</S.PlanValue>
              </S.Plan>
            ))}
          </S.PlanList>
          <S.ImageContainer data-start-age={startAgeMix}>
            <img
              src={
                START_AGE_TO_IMAGE_MAP[startAgeMix] ||
                START_AGE_TO_IMAGE_MAP[StartAgeValue.FIRST_AGE_RANGE]
              }
              alt="couple"
            />
          </S.ImageContainer>
        </S.PlanContainerV2>
        <S.Graph ref={animationRef} />
        <StickyContainer>
          <S.Button onClick={handleContinue}>{t('actions.continue')}</S.Button>
        </StickyContainer>
      </S.Column>
    </PageContainer>
  )
}
