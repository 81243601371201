import styled from 'styled-components'

import leftArrow from 'assets/images/swiper-left-arrow.svg'
import rightArrow from 'assets/images/swiper-right-arrow.svg'

import { Color } from 'root-constants/common'

export const StyledAppBlock = {
  Title: styled.h2`
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    color: ${Color.LILAC_120};
    margin: 0 0 32px 0;
  `,
  CarouselContainer: styled.div`
    .swiper-wrapper {
      display: flex;
      align-items: center;
    }
    .swiper-pagination {
      bottom: 0;
    }

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      background: #c5aaff;
    }

    .swiper-slide {
      display: flex;
      justify-content: center;

      > div {
        width: 155px;
      }
    }

    .swiper-slide-active {
      > div {
        width: 182px;
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      height: 32px;
      width: 32px;
      border-radius: 22px;
      top: 92%;
      margin: 0;
      background-color: ${Color.VIOLET_130};
    }

    .swiper-button-prev {
      left: 16px;
      &:before {
        content: url(${leftArrow});
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        line-height: 0;
      }
    }
    .swiper-button-next {
      right: 16px;
      &:before {
        content: url(${rightArrow});
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        line-height: 0;
      }
    }
  `,
  ScreenContainer: styled.div`
    margin: 0 0 56px 0;
    aspect-ratio: 155/312;
  `,
}
