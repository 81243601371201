import styled from 'styled-components'

import { Color } from 'root-constants/common'

export const StyledTotalAmount = {
  Container: styled.div`
    display: flex;
    margin-bottom: 32px;
    font-size: 13px;
    line-height: 20px;
    color: var(--base-text-color);
  `,
  TitleContainer: styled.span`
    margin-right: 3px;
  `,
  SubscriptionDescription: styled.span``,
  PriceContainer: styled.span`
    font-weight: 800;
    color: ${Color.LILAC_130};
  `,
}
