import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'

import { getStringWithoutTags } from 'helpers/getStringWithoutTags'

import { TAnswer, TStepInfo } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'

export const useNextStep = ({ pageId, question, nextPagePath }: TStepInfo) => {
  const dispatch = useDispatch()

  return useCallback(
    (userAnswer: TAnswer) => {
      dispatch(
        setAnswersAction({
          pageId,
          answers: userAnswer,
        }),
      )
      dispatch(sendUserAnswersAction())

      eventLogger.logQuestion({
        question: getStringWithoutTags(question),
        answers: userAnswer,
        pageName: pageId,
      })

      goTo(nextPagePath)
    },
    [dispatch, nextPagePath, pageId, question],
  )
}
