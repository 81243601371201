import React, { FC, ReactNode } from 'react'

import { StyledTitleContainer as S } from './TitleContainer.styles'

type TProps = {
  title: ReactNode
  subtitle?: ReactNode
  info?: ReactNode
  className?: string
}

export const TitleContainer: FC<TProps> = ({
  title,
  subtitle = null,
  info = null,
  className,
}) => (
  <S.Container className={className}>
    {title}
    {subtitle}
    {info}
  </S.Container>
)
