import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import {
  selectGoogleAnalyticsId,
  selectGoogleOptimizeId,
} from 'root-redux/selects/common'

export const useGoogleAnalytics = (): void => {
  const googleOptimizeId = useSelector(selectGoogleOptimizeId)
  const googleAnalyticsId = useSelector(selectGoogleAnalyticsId)

  useEffect(() => {
    if (googleAnalyticsId && googleOptimizeId) {
      const setUpGTM = () => {
        const script = document.createElement('script')
        script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`
        script.async = true
        document.head.appendChild(script)
      }

      const setUpGO = () => {
        const script = document.createElement('script')
        script.src = `https://www.googleoptimize.com/optimize.js?id=${googleOptimizeId}`
        script.async = true
        document.head.appendChild(script)
      }

      setUpGTM()
      setUpGO()
      window.gtag('config', googleAnalyticsId, {
        optimize_id: googleOptimizeId,
        send_page_view: false,
      })
    }
  }, [googleAnalyticsId, googleOptimizeId])
}
