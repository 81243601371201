import { RateRelationshipValue } from 'root-constants/common'

export const TRANSLATION_FAMILY = 'onboarding.rateRelationshipV1'
export const OPTIONS = [
  {
    key: 'perfect',
    value: RateRelationshipValue.PERFECT,
  },
  {
    key: 'fine',
    value: RateRelationshipValue.FINE,
  },
  {
    key: 'room',
    value: RateRelationshipValue.ROOM,
  },
  {
    key: 'improvement',
    value: RateRelationshipValue.IMPROVEMENT,
  },
  {
    key: 'issues',
    value: RateRelationshipValue.ISSUES,
  },
]
