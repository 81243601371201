import { IAppState } from 'models/store.model'

export const selectIsFetching = (state: IAppState): boolean =>
  state.common.isFetching
export const selectActionList = (state: IAppState): string[] =>
  state.common.actionList
export const selectError = (state: IAppState): any => state.common.error
export const selectCurrentVariantCohort = (state: IAppState): string =>
  state.common.currentVariant?.cohort || ''
export const selectFacebookPixelId = (state: IAppState): string =>
  state.common.currentVariant?.facebookPixelId || ''
export const selectCurrentVariant = (state: IAppState) =>
  state.common.currentVariant
export const selectCurrentVariantSteps = (state: IAppState) =>
  state.common.currentVariant?.steps || []
export const selectSubscriptionList = (state: IAppState) =>
  state.common.subscriptions
export const selectConfig = (state: IAppState) => state.common.appConfig
export const selectAmplitudeApiKey = (state: IAppState): string | null =>
  state.common.appConfig?.amplitudeApiKey || null
export const selectGiaApiKeys = (
  state: IAppState,
): Record<string, string> | null => state.common.appConfig?.giaApiKeys || null
export const selectFirebaseConfig = (state: IAppState) =>
  state.common.appConfig?.firebaseConfig || null
export const selectGoogleOptimizeId = (state: IAppState): string | null =>
  state.common.appConfig?.googleOptimizeId || null
export const selectGoogleAnalyticsId = (state: IAppState): string | null =>
  state.common.appConfig?.googleAnalyticsId || null
export const selectPaymentConfig = (state: IAppState) =>
  state.common.paymentConfig
export const selectStripeAccountId = (state: IAppState): string =>
  state.common.paymentConfig?.stripe.accountId || ''
export const selectStripeAccountName = (state: IAppState): string =>
  state.common.paymentConfig?.stripe.accountName || ''
export const selectStripePublicKey = (state: IAppState): string =>
  state.common.paymentConfig?.stripe.publicKey || ''
export const selectPayPalClientId = (state: IAppState): string =>
  state.common.paymentConfig?.paypal.clientId || ''
export const selectAnswers = (state: IAppState) => state.common.answers
export const selectScreenName = (state: IAppState) => state.common.screenName
export const selectUtmTags = (state: IAppState): Record<string, string> =>
  state.common.utmTags || {}
export const selectAbSegmentName = (state: IAppState): string =>
  state.common.currentVariant?.abSegmentName || ''
export const selectOptimizeExperimentMap = (
  state: IAppState,
): Record<string, string> | null =>
  state.common.currentVariant?.optimizeExperimentMap || null
export const selectOptimizeMap = (
  state: IAppState,
): Record<string, Record<string, string>> | null =>
  state.common.currentVariant?.optimizeMap || null
export const selectOptimizeExperimentIdByVariantIdMap = (
  state: IAppState,
): Record<string, string> | null =>
  state.common.optimizeExperimentIdByVariantIdMap
export const selectSubscriptionTimerValue = (state: IAppState) =>
  state.common.subscriptionTimerValue
export const selectCancelOfferTimerValue = (state: IAppState) =>
  state.common.cancelOfferTimerValue
export const selectHasCancelOffer = (state: IAppState): boolean =>
  state.common.currentVariant?.hasCancelOffer || false
