import React, { useCallback, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { PageContainer } from 'components/PageContainer'
import { StickyContainer } from 'components/StickyContainer'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import graph from 'assets/images/intro-discount-v1-graph.png'

import { goTo } from 'browser-history'

import { StyledIntroDiscountVariant1 as S } from './IntroDiscountVariant1.styles'

export const IntroDiscountVariant1: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    eventLogger.logCancelOfferPageShown()
  }, [])

  const handleGotIt = useCallback(() => {
    eventLogger.logCancelOfferPageСompleted()
    goTo(nextPagePath)
  }, [nextPagePath])

  return (
    <PageContainer>
      <S.Column>
        <S.Title>
          <Trans
            i18nKey="introDiscountV1.title"
            components={{ span: <span /> }}
          />
        </S.Title>
        <S.GraphContainer>
          <img src={graph} alt="graph" />
        </S.GraphContainer>
        <S.PromotionContainer>
          <S.PromoText>
            <Trans
              i18nKey="introDiscountV1.promotion"
              components={{ span: <span /> }}
            />
          </S.PromoText>
        </S.PromotionContainer>
        <StickyContainer>
          <S.Button onClick={handleGotIt}>{t('actions.gotIt')}</S.Button>
        </StickyContainer>
      </S.Column>
    </PageContainer>
  )
}
