import styled from 'styled-components'

import { StyledButtonBase } from 'components/Button/Button.styles'
import { PageContainer } from 'components/PageContainer'

import { Color } from 'root-constants/common'

export const UpLovePresale = {
  Wrapper: styled(PageContainer)`
    background: linear-gradient(
      180deg,
      ${Color.VIOLET_140} 0%,
      #141052 22.66%,
      #311e57 47.22%,
      #110c42 56.35%,
      #392154 67.84%,
      #0c0934 76.51%,
      #070525 100%
    );
    padding-top: 72px;
    text-align: center;
  `,
  Title: styled.h1`
    color: ${Color.LILAC_120};
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    max-width: 342px;
    width: 100%;

    & span {
      color: ${Color.LILAC_130};
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
    }

    &:last-of-type {
      margin-top: 48px;
    }
  `,
  Subtitle: styled.h4`
    color: ${Color.LILAC_120};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 11px;
    max-width: 280px;
    width: 100%;

    & span {
      color: ${Color.LILAC_130};
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
    }
  `,
  SetupSummaryWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 16px 0;
    border-radius: 25px;
    background: ${Color.VIOLET_130};
    opacity: 0.8;
    margin-top: 32px;
    width: 312px;
    text-align: center;
  `,
  Context: styled.p`
    color: ${Color.LILAC_120};
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
  `,
  SetupSummaryContent: styled.div`
    padding: 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
  `,
  Icon: styled.div`
    aspect-ratio: 23 / 28;
    height: 28px;
  `,
  SetupSummaryText: styled.p`
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    max-width: 254px;
    width: 100%;
    text-align: start;
  `,
  GuaranteesWrapper: styled.div`
    max-width: 305px;
    width: 100%;
    margin-top: 64px;
  `,
  GuaranteesImage: styled.div`
    aspect-ratio: 86 / 55;
    height: 165px;
    margin: 0 auto;
  `,
  GuaranteesText: styled.p`
    text-align: center;
    color: ${Color.LILAC_120};
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin-top: 12px;

    & span {
      color: ${Color.LILAC_130};
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    }
  `,
  Subtitle2: styled.h4`
    color: ${Color.LILAC_120};
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    margin-top: 48px;
    max-width: 311px;
    width: 100%;
  `,
  ReasonsWrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    gap: 8px;
    max-width: 328px;
    width: 100%;
  `,
  ReasonsText: styled.p`
    color: ${Color.LILAC_120};
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    width: 100%;
    text-align: start;
  `,
  Button: styled.button`
    ${StyledButtonBase};
    margin: 24px 0 40px;
  `,
}
