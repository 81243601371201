import React, { useEffect, useLayoutEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { Spinner } from 'components/Spinner'

import {
  selectScreenName,
  selectStripeAccountId,
  selectStripeAccountName,
} from 'root-redux/selects/common'

import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useProductId } from 'hooks/useProductId'
import { useUserData } from 'hooks/useUserData'

import {
  select3DSecureIframeUrl,
  selectIsPaymentFlowsShown,
  selectSubscriptionId,
} from 'modules/payment/redux/selects'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { replaceHistory } from 'browser-history'
import { PageId } from 'root-constants/pages'

import { PaymentFlows4 } from '../components/PaymentFlows'
import { StripePaymentProcessing } from '../components/StripePaymentProcessing'
import { StyledPayment as S } from './Payment.styles'

export const PaymentVariant4: React.FC<TPageProps> = ({ pageId }) => {
  const threeDSecureIframeUrl = useSelector(select3DSecureIframeUrl)
  const isPaymentFlowsShown = useSelector(selectIsPaymentFlowsShown)
  const screenName = useSelector(selectScreenName)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)
  const stripeAccountId = useSelector(selectStripeAccountId)
  const stripeAccountName = useSelector(selectStripeAccountName)
  const { search } = useLocation()

  const productId = useProductId()
  const { usersPriority } = useUserData()

  const { currentSubscriptionPageId } = useGetPageInfo()

  const hasDescription = useMemo(() => pageId === PageId.PAYMENT_4, [pageId])

  useEffect(() => {
    window.scrollTo(0, 0)
    document.body.scrollTo(0, 0)
  }, [])

  useLayoutEffect(() => {
    if (selectedSubscriptionId) return

    replaceHistory({
      pathname: currentSubscriptionPageId as PageId,
      search,
    })
  }, [currentSubscriptionPageId, search, selectedSubscriptionId])

  useEffect(() => {
    if (productId) {
      eventLogger.logPurchaseShown({
        productId,
        screenName,
        goal: usersPriority,
        stripeAccountId,
        stripeAccountName,
      })
    }
  }, [usersPriority, productId, screenName, stripeAccountId, stripeAccountName])

  return (
    <S.Wrapper>
      {threeDSecureIframeUrl ? (
        <S.ThreeDSecureIframe
          title="3DSecure"
          src={threeDSecureIframeUrl}
          scrolling="yes"
        />
      ) : (
        <>
          <StripePaymentProcessing />
          <S.ContentV4>
            <PaymentFlows4 hasDescription={hasDescription} />
          </S.ContentV4>
        </>
      )}
      {!isPaymentFlowsShown && <Spinner />}
    </S.Wrapper>
  )
}
