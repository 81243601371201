import { RefObject, useEffect, useState } from 'react'

export const useIsInViewport = (ref: RefObject<HTMLButtonElement>): boolean => {
  const [isInViewport, setIsInViewport] = useState(false)

  useEffect(() => {
    const checkIfInViewport = () => {
      const { current: elem } = ref

      if (!elem) return

      const elemScrollValue = Math.floor(
        elem.getBoundingClientRect().top + window.scrollY - window.innerHeight,
      )
      const windowScrollValue = Math.floor(window.scrollY)

      setIsInViewport(elemScrollValue <= windowScrollValue)
    }

    document.body.addEventListener('scroll', checkIfInViewport)

    return () => {
      document.body.removeEventListener('scroll', checkIfInViewport)
    }
  }, [ref])

  return isInViewport
}
