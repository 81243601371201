import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { getDecoratedFractionPricesPart } from 'modules/subscriptions/helpers'
import { ISelectPlanItemProps } from 'modules/subscriptions/types'

import { CURRENCY_SYMBOLS } from 'root-constants/common'

import { StyledPlanItemVariant2 as S } from './PlanItemVariant2.styles'

export const PlanItemVariant2: React.FC<ISelectPlanItemProps> = ({
  subscription: {
    currency,
    mainPrices: { periodQuantity, periodName, weekly, oldPrices },
  },
  isSelected,
}) => {
  const { t } = useTranslation()

  const oldPrice = useMemo(
    () => oldPrices.beforeCustomDiscount?.weekly.toFixed(2),
    [oldPrices],
  )

  const integerPartPrice = useMemo(
    () => (weekly ? Math.trunc(weekly) : 0),
    [weekly],
  )

  const fractionalPartPrice = useMemo(() => {
    if (!weekly) return '00'

    return getDecoratedFractionPricesPart(weekly)
  }, [weekly])

  return (
    <S.PlanItem data-is-selected={isSelected}>
      <S.PlanTitle>
        <Trans
          i18nKey="subscriptions.planPeriod"
          values={{ periodName, periodQuantity }}
        />
      </S.PlanTitle>
      {oldPrice && (
        <S.OldPlanPrice>
          {CURRENCY_SYMBOLS[currency]}
          {oldPrice}
        </S.OldPlanPrice>
      )}
      {weekly && (
        <S.PlanPrice>
          <strong data-fractional={fractionalPartPrice}>
            {integerPartPrice}
          </strong>
          <S.Period>{t('subscriptions.perWeek')}</S.Period>
        </S.PlanPrice>
      )}
    </S.PlanItem>
  )
}
