import React from 'react'

import { PageContainer } from 'components/PageContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import img from 'assets/images/breakup.webp'

import { StyledBreakup as S } from './Breakup.styles'

export const BreakupVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const handleContinue = useNextStep({
    pageId,
    question: 'Lack of emotional connection is a common reason for breakups',
    nextPagePath,
  })

  return (
    <PageContainer>
      <S.Column>
        <S.Title>
          <strong>Lack of emotional connection</strong> is a common reason for
          <br />
          <strong>breakups</strong>
        </S.Title>
        <S.Image>
          <img src={img} alt="couple" />
        </S.Image>
        <S.Text>
          Emotional intimacy is a bond built over time through shared
          experiences and getting to know one another. When it is missing, a
          relationship can feel <strong>shallow</strong> and{' '}
          <strong>boring.</strong>
        </S.Text>
        <S.Button onClick={() => handleContinue('')}>Continue</S.Button>
      </S.Column>
    </PageContainer>
  )
}
