import { AnyAction } from 'redux'

import {
  RESET_ERROR,
  SET_ANSWERS,
  SET_ANSWERS_FROM_BACKEND,
  SET_APP_CONFIG,
  SET_CANCEL_OFFER_TIMER_VALUE,
  SET_ERROR,
  SET_OPTIMIZE_EXPERIMENT_ID_BY_VARIANT_ID_MAP,
  SET_PAYMENT_CONFIG,
  SET_SCREEN_NAME,
  SET_SUBSCRIPTION_LIST,
  SET_SUBSCRIPTION_TIMER_VALUE,
  SET_UTM_TAGS,
  SET_VARIANT,
  START_FETCHING,
  STOP_FETCHING,
} from 'root-redux/actions/common'

import { TAnswers } from 'models/common.model'
import { IAppConfig, IPaymentConfig } from 'models/config.model'
import { ISubscription } from 'models/subscriptions.model'
import { IVariant } from 'models/variant.model'

import { ScreenName } from 'services/eventLogger.service'

const initState: ICommonState = {
  isFetching: false,
  appConfig: null,
  paymentConfig: null,
  actionList: [],
  error: null,
  currentVariant: null,
  subscriptions: [],
  answers: null,
  screenName: ScreenName.ONBOARDING,
  utmTags: {},
  optimizeExperimentIdByVariantIdMap: null,
  subscriptionTimerValue: null,
  cancelOfferTimerValue: null,
}

export interface ICommonState {
  isFetching: boolean
  appConfig: IAppConfig | null
  paymentConfig: IPaymentConfig | null
  actionList: string[]
  error: any
  currentVariant: IVariant | null
  subscriptions: ISubscription[]
  answers: TAnswers | null
  screenName: ScreenName
  utmTags: Record<string, string>
  optimizeExperimentIdByVariantIdMap: Record<string, string> | null
  subscriptionTimerValue: number | null
  cancelOfferTimerValue: number | null
}

export function commonReducer(
  state: ICommonState = initState,
  { type, payload = null }: AnyAction,
): ICommonState {
  switch (type) {
    case START_FETCHING: {
      return {
        ...state,
        isFetching: true,
        actionList: state.actionList.concat(payload),
      }
    }
    case STOP_FETCHING: {
      return {
        ...state,
        isFetching: !!payload.length,
        actionList: payload,
      }
    }
    case SET_ERROR: {
      return {
        ...state,
        error: payload,
      }
    }
    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      }
    }
    case SET_VARIANT: {
      return {
        ...state,
        currentVariant: payload,
      }
    }
    case SET_SUBSCRIPTION_LIST: {
      return {
        ...state,
        subscriptions: payload,
      }
    }
    case SET_APP_CONFIG: {
      return {
        ...state,
        appConfig: payload,
      }
    }
    case SET_PAYMENT_CONFIG: {
      return {
        ...state,
        paymentConfig: payload,
      }
    }
    case SET_ANSWERS: {
      return {
        ...state,
        answers: { ...state.answers, ...payload },
      }
    }
    case SET_ANSWERS_FROM_BACKEND: {
      return {
        ...state,
        answers: payload,
      }
    }
    case SET_SCREEN_NAME: {
      return {
        ...state,
        screenName: payload,
      }
    }
    case SET_UTM_TAGS: {
      return {
        ...state,
        utmTags: payload,
      }
    }
    case SET_OPTIMIZE_EXPERIMENT_ID_BY_VARIANT_ID_MAP: {
      return {
        ...state,
        optimizeExperimentIdByVariantIdMap: {
          ...state.optimizeExperimentIdByVariantIdMap,
          ...payload,
        },
      }
    }
    case SET_SUBSCRIPTION_TIMER_VALUE: {
      return {
        ...state,
        subscriptionTimerValue: payload,
      }
    }
    case SET_CANCEL_OFFER_TIMER_VALUE: {
      return {
        ...state,
        cancelOfferTimerValue: payload,
      }
    }
    default:
      return state
  }
}
