import styled, { css } from 'styled-components'

import icon from 'assets/images/check-icon-violet.svg'

import { Color } from 'root-constants/common'

export const StyledQuestionButtonBase = css`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 24px;
  height: 72px;
  border-radius: 16px;
  cursor: pointer;
  background-color: ${Color.LILAC_110};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  transition: all 0.2s ease-out;

  &[data-has-check-icon='true'] {
    padding-left: 58px;
  }

  &[data-has-check-icon='true']::before {
    content: '';
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    border: 2px solid #0a324c;
    border-radius: 4px;
  }

  input:checked + & {
    background-color: var(--checked-question-button-background-color);

    &[data-has-check-icon='true'] {
      background-color: ${Color.LILAC_110};
    }

    &[data-has-check-icon='true']::before {
      content: url(${icon});
      border: none;
    }
  }
`

export const StyledQuestionButton = styled.div`
  ${StyledQuestionButtonBase};
`
