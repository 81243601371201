import styled from 'styled-components'

export const StyledPayPalContainer = {
  ButtonsContainer: styled.div`
    width: 100%;
    height: 55px;
    border-radius: 28px;
    background-color: rgb(255, 196, 57);

    & iframe {
      border-radius: 28px;
      z-index: 1 !important;
    }
  `,
}
