import React, { HTMLAttributes, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { SupportAction } from 'components/SupportAction'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import {
  selectSubscriptionMainPrice,
  selectSubscriptionPeriodQuantity,
} from 'modules/payment/redux/selects'

import { PERIOD_QUANTITY_TO_MARKUP_MAP } from '../constants'
import { StyledSubscriptionDescription } from './SubscriptionDescription.styles'

type TSubscriptionDescriptionProps = HTMLAttributes<HTMLParagraphElement> & {
  className?: string
}

export const SubscriptionDescription: React.FC<
  TSubscriptionDescriptionProps
> = ({ className, ...props }) => {
  const mainPrice = useSelector(selectSubscriptionMainPrice)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)

  const currentPriceFormatted = useMemo(
    () => +mainPrice.toFixed(2),
    [mainPrice],
  )

  return (
    <StyledSubscriptionDescription className={className} {...props}>
      To avoid any disruption of the service, you will be automatically charged
      ${currentPriceFormatted} after the payment confirmation, and the
      subscription will then be auto-renewed on a{' '}
      {PERIOD_QUANTITY_TO_MARKUP_MAP[periodQuantity]} basis at the price of $
      {currentPriceFormatted}. To learn more, visit our <TermsOfUseLink /> or
      contact us at <SupportAction />
    </StyledSubscriptionDescription>
  )
}
