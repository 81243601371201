import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import {
  selectAnswers,
  selectCurrentVariantSteps,
} from 'root-redux/selects/common'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { IntimacyArea } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

const SINGLE_INTIMACY_ANSWER = 1
const AREAS_PRIORITY_ORDER = [
  IntimacyArea.MULTIPLE,
  IntimacyArea.EMOTIONAL,
  IntimacyArea.SPIRITUAL,
  IntimacyArea.INTELLECTUAL,
  IntimacyArea.PHYSICAL,
]

export const useGetFlowGroupInfo = (
  currentFlow = '',
): {
  nextPagePath: string
  isMultipleFlow: boolean
  intimacyAreas: IntimacyArea[]
} => {
  const { search } = useLocation()
  const answers = useSelector(selectAnswers)
  const steps = useSelector(selectCurrentVariantSteps)

  const { currentPageIndex } = useGetPageInfo()

  const getNextFlowGroup = useCallback(
    (intimacyArea: IntimacyArea) => {
      switch (intimacyArea) {
        case IntimacyArea.EMOTIONAL:
          return `${PageId.IMPORTANT_ASPECT_1}${search}`

        case IntimacyArea.INTELLECTUAL:
          return `${PageId.MAIN_GOALS_6}${search}`

        case IntimacyArea.PHYSICAL:
          return `${PageId.MAIN_GOALS_2}${search}`

        case IntimacyArea.SPIRITUAL:
          return `${PageId.MAIN_GOALS_4}${search}`

        default:
          return `${steps[currentPageIndex + 1].id}${search}`
      }
    },
    [currentPageIndex, search, steps],
  )

  const selectedAreas = useMemo(
    () => (answers?.[PageId.INTIMACY_AREA_1] as IntimacyArea[]) || [],
    [answers],
  )

  const getNextFlowSingleChoice = useMemo(
    () => getNextFlowGroup(selectedAreas[0]),
    [getNextFlowGroup, selectedAreas],
  )

  const getNextFlowMultipleChoice = useMemo(() => {
    const currentAreaIndex = AREAS_PRIORITY_ORDER.findIndex(
      (flow) => flow === currentFlow,
    )

    if (!currentFlow) {
      return `${steps[currentPageIndex + 1].id}${search}`
    }

    const remainingAreas = AREAS_PRIORITY_ORDER.slice(currentAreaIndex + 1)
    const nextAreaIndex = remainingAreas.findIndex((area) =>
      selectedAreas.includes(area),
    )

    if (nextAreaIndex === -1) {
      return `${PageId.FELT_LONELY_1}${search}`
    }

    return getNextFlowGroup(remainingAreas[nextAreaIndex])
  }, [
    currentFlow,
    currentPageIndex,
    getNextFlowGroup,
    search,
    selectedAreas,
    steps,
  ])

  return {
    nextPagePath:
      selectedAreas.length > SINGLE_INTIMACY_ANSWER
        ? getNextFlowMultipleChoice
        : getNextFlowSingleChoice,
    isMultipleFlow: selectedAreas.length > SINGLE_INTIMACY_ANSWER,
    intimacyAreas: selectedAreas,
  }
}
