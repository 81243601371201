import styled from 'styled-components'
import { SwiperSlide } from 'swiper/react'

import { Award } from 'components/Award'
import { ProgressBarRounded } from 'components/ProgressBarRounded'

import { baseColumnStyles } from 'common-styles'
import { BIG_COLUMN_WIDTH } from 'root-constants/common'

export const StyledCreatingVariant1 = {
  Column: styled.div`
    ${baseColumnStyles};
    width: ${BIG_COLUMN_WIDTH}px;
    padding: 24px 20px 36px 20px;
  `,
  ProgressBarRounded: styled(ProgressBarRounded)`
    margin: 0 0 34px 0;
  `,
  Award: styled(Award)`
    margin: 0 0 24px 0;
  `,
  CarouselContainer: styled.div`
    max-width: 315px;
    margin: 0 auto;

    & .swiper-pagination {
      bottom: 0;
    }

    & .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      background: #c5aaff;
    }
  `,
  SwiperSlide: styled(SwiperSlide)`
    margin: 0 0 32px 0;
  `,
}
