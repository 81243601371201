import styled from 'styled-components'

import {
  BIG_COLUMN_WIDTH,
  Color,
  HEADER_HEIGHT,
  MAX_PHONE_WIDTH,
} from 'root-constants/common'

type TProgressProps = {
  progressValue: number
}

export const StyledProgressBar = {
  Wrapper: styled.div`
    align-items: center;
    column-gap: 12px;
    display: flex;
    margin: 0 auto;
    padding: 0 20px;
    top: ${HEADER_HEIGHT}px;
    width: 100%;

    @media (min-width: ${MAX_PHONE_WIDTH}px) {
      width: ${BIG_COLUMN_WIDTH}px;
    }
  `,
  Back: styled.div`
    cursor: pointer;
    display: flex;
    fill: ${Color.LILAC_130};
    height: 24px;
    width: 24px;
  `,
  Progress: styled.div<TProgressProps>`
    ${({ progressValue }) => `
      background-color: ${Color.LILAC_110};
      border-radius: 8px;
      height: 1px;
      position: relative;
      width: 100%;

      :after {
        background: ${Color.LILAC_130};
        border-radius: 8px;
        content: '';
        height: 5px;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.2s ease-out;
        width: ${progressValue}%;
      }
    `}
  `,
  PageProgress: styled.div`
    color: ${Color.WHITE_100};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;

    span {
      color: ${Color.LILAC_130};
    }
  `,
}
