import styled from 'styled-components'

import { QuestionTitle, baseColumnStyles } from 'common-styles'

export const StyledSpiritualityDescription = {
  Column: styled.div`
    ${baseColumnStyles};
    padding: 40px 0;
  `,
  Title: styled(QuestionTitle)`
    margin: 0 -5px 54px;
    line-height: 28px;
  `,
  ImageContainer: styled.div`
    aspect-ratio: 321/228;
    margin: 0 -6px 40px;
  `,
  Description: styled.p`
    margin: 0 -10px 32px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: var(--base-text-color);

    strong {
      font-weight: 800;
    }
  `,
}
