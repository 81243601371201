import React from 'react'

import { StyledBlinkingDots as S } from './BlinkingDots.styles'

type TProps = {
  className?: string
}
export const BlinkingDots: React.FC<TProps> = ({ className }) => (
  <>
    <S.Dot className={className}>.</S.Dot>
    <S.Dot className={className}>.</S.Dot>
    <S.Dot className={className}>.</S.Dot>
  </>
)
