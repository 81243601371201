import styled from 'styled-components'

import { BackButton, BottomStickyBase, baseColumnStyles } from 'common-styles'

export const StyledEmailAccount = {
  Wrapper: styled.div`
    ${baseColumnStyles};
    padding: 40px 0;
    position: relative;
    color: var(--base-text-color);
  `,
  Title: styled.h1`
    margin-bottom: 42px;
    font-size: 26px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
  `,
  BackButton: styled(BackButton)`
    position: absolute;
    left: -16px;
    top: 20px;
  `,
  Description: styled.span`
    position: relative;
    display: block;
    padding-top: 50px;
    margin-bottom: 12px;
    font-size: 11px;
    line-height: 20px;
    text-align: center;
    opacity: 0.6;

    a {
      color: inherit;
      text-decoration: underline;
    }
  `,
  Footer: styled.footer`
    ${BottomStickyBase};

    @media (max-height: 640px) {
      position: relative;
      bottom: 0;
    }
  `,
}
