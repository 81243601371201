import styled from 'styled-components'

import { PageContainer } from 'components/PageContainer'

import { baseColumnStyles } from 'common-styles'
import { Color } from 'root-constants/common'

export const StyledDownloadAppPage = {
  Wrapper: styled(PageContainer)`
    color: var(--base-text-color);
  `,
  Column: styled.main`
    ${baseColumnStyles};
    padding: 40px 0;
  `,
  Title: styled.h1`
    margin-bottom: 32px;
    font-size: 25px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
  `,
  List: styled.ul`
    margin-bottom: 32px;

    li {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 20px;
    }
  `,
  ListLabel: styled.div`
    flex-shrink: 0;
    width: 28px;
    height: 28px;
    margin-right: 14px;
    font-size: 16px;
    font-weight: 800;
    line-height: 28px;
    text-align: center;
    border-radius: 8px;
    color: #bcc9d0;
    background-color: ${Color.WHITE_110};
  `,
  ListText: styled.p`
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    opacity: 0.8;
  `,
  Image: styled.img`
    margin-bottom: 20px;
  `,
}
