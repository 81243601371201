import { PageId } from 'root-constants/pages'

export const enum CurrentEnvironment {
  DEV = 'dev',
  STAGE = 'stage',
  PROD = 'prod',
}

export const enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
}

export const enum StripeFieldName {
  NAME = 'name',
  NUMBER = 'cardNumber',
  EXPIRY = 'cardExpiry',
  CVC = 'cardCvc',
}

export const BASE_COLUMN_WIDTH = 310
export const BIG_COLUMN_WIDTH = 375

export const MAX_PHONE_WIDTH = 440
export const HEADER_HEIGHT = 56
export const HEADER_WITH_PROGRESS_BAR_HEIGHT = 80

export const enum AppLinks {
  TOU_LINK = '/terms-of-use',
  PP_LINK = '/privacy-policy',
}

export const TOU_AND_PP_LINKS: string[] = [AppLinks.TOU_LINK, AppLinks.PP_LINK]

export const CDN_FOLDER_LINK =
  'https://cdn-prod-unified-content.gismart.xyz/96dc6bf088e19cafbfae0098798ad3e5659f33446bb432cd1e232db0d49e05b7'

export const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const enum LoginMethod {
  EMAIL = 'email',
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
}

export const enum LoginMethodRaw {
  EMAIL = 'password',
  GOOGLE = 'google.com',
  FACEBOOK = 'facebook.com',
  APPLE = 'apple.com',
}

export const LOGIN_METHOD_RAW_MAP = {
  [LoginMethodRaw.EMAIL]: LoginMethod.EMAIL,
  [LoginMethodRaw.GOOGLE]: LoginMethod.GOOGLE,
  [LoginMethodRaw.FACEBOOK]: LoginMethod.FACEBOOK,
  [LoginMethodRaw.APPLE]: LoginMethod.APPLE,
}

export const enum BillingCycle {
  ONE_MONTH = 1,
  THREE_MONTHS = 3,
  SIX_MONTHS = 6,
  NINE_MONTHS = 9,
  YEAR = 12,
}

export const enum TrialPeriod {
  ONE_WEEK = 7,
  ONE_MONTH = 30,
  THREE_MONTH = 90,
}

export const AUTO_RENEWED_BASIS = {
  [BillingCycle.ONE_MONTH]: 'monthly',
  [BillingCycle.THREE_MONTHS]: 'quarterly',
  [BillingCycle.SIX_MONTHS]: 'semi-annually',
}

export const SELECTED_SUBSCRIPTION_PERIOD_NAME = {
  [TrialPeriod.ONE_WEEK]: 'week',
  [TrialPeriod.ONE_MONTH]: 'month',
  [TrialPeriod.THREE_MONTH]: 'month',
}

export const enum TrialPeriods {
  SEVEN_DAY = 7,
  ONE_MONTH = 30,
  THREE_MONTHS = 90,
}

export const enum FirebaseSignUpErrorCode {
  EMAIL_EXISTS = 'auth/email-already-in-use',
}

export const enum ValidationText {
  EMAIL_EXISTS = `Account with this email already exists`,
  INVALID_EMAIL = `Your email doesn't look valid. Please, check`,
  SHORT_PASSWORD = 'At least 8 characters',
  WRONG_CONFIRMATION_PASSWORD = `Confirmation password doesn't equal password`,
  WRONG_PASSWORD = 'The password you entered is incorrect',
  WRONG_EMAIL = 'The email you entered is incorrect',
  NOT_AGREE_WITH_POLITICS = 'Please check that you agree with Terms of Use and Privacy Policy.',
}

export const enum Color {
  WHITE_100 = '#fff',
  WHITE_110 = '#eff5f7',
  WHITE_120 = '#f0efff',

  LILAC_100 = '#b5b3d1',
  LILAC_110 = '#d9d8f6',
  LILAC_120 = '#ededff',
  LILAC_130 = '#e8bfe5',

  VIOLET_100 = '#403C7D',
  VIOLET_110 = '#7879F1',
  VIOLET_120 = '#9b9ce5',
  VIOLET_121 = '#9d95d1',
  VIOLET_130 = '#2c286f',
  VIOLET_140 = '#191465',

  GREY_100 = '#595b71',

  BLUE_100 = '#0a082f',
  BLUE_110 = '#10224f',
  BLACK_100 = '#000',
  DEFAULT_TEXT = '#0a324d',
  LIGHT_TEXT = '#7b8898',
  ERROR = '#fe5959',
  PATTENS_BLUE = '#E0EAF0',
  GREEN = '#7bc27e',
}

export const enum TimeInterval {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export const enum EventLoggerInstanceName {
  GIA = 'gia-amplitude',
  AMPLITUDE = 'amplitude',
  USER_FLOW_TRACKER = 'user-flow-tracker',
}

export const enum PlatformOS {
  ANDROID = 'Android',
  IOS = 'iOS',
  UNKNOWN = 'unknown',
}

export const enum AppWithInAppBrowser {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
}

export const enum UserAgent {
  INSTAGRAM = 'instagram',
  FACEBOOK = 'facebook',
  OTHER = 'other',
}

export const enum PagePath {
  LOCALHOST = 'localhost',
  SUBSCRIPTIONS = 'subscriptions',
}

export const enum IntimacyArea {
  PHYSICAL = 'Physical',
  EMOTIONAL = 'Emotional',
  SPIRITUAL = 'Spiritual',
  INTELLECTUAL = 'Intellectual',
  MULTIPLE = 'Multiple',
}

export const TEST_ENV_QUERY_PARAM = 'is_test_environment'
export const APP_NAME = 'upluv'
export const CENTS_IN_DOLLAR = 100
export const PAGE_TRANSITION_DELAY = 500
export const INITIAL_PRICE = 0
export const CUSTOM_TOKEN_LOCAL_STORAGE_KEY = 'CUSTOM_TOKEN'
export const DEFAULT_CURRENCY = 'usd'
export const NO_NAME_AB_SEGMENT = 'no_name'
export const NO_TEST_OPTIMIZE_VARIANT = 'no_test'
export const EXPERIMENT_ID_NOT_SET = 'experiment_id_not_set'

export const COMMON_ERROR = 'Something went wrong'
export const HAVE_ACCOUNT_ERROR = 'You already have an account'

export const enum CommonValue {
  YES = 'yes',
  NO = 'no',
}

export const enum PriorityValue {
  INTIMACY = 'intimacy',
  SEX = 'sex',
}

export const enum GenderValue {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other',
}

export const enum StatusValue {
  DATING = 'dating',
  SERIOUS = 'serious_relationship',
  MARRIED = 'married',
  COMPLICATED = 'complicated',
}

export const enum StartAgeValue {
  FIRST_AGE_RANGE = '18_25',
  SECOND_AGE_RANGE = '26_35',
  THIRD_AGE_RANGE = '36_45',
  FOURTH_AGE_RANGE = '45+',
}

export const enum RelationshipDurationValue {
  LESS_6_MONTHS = 'Less than 6 months',
  MORE_6_MONTHS = '6 months - 2 years',
  MORE_2_YEARS = '2 - 4 years',
  MORE_4_YEARS = 'More than 4 years',
}

export const enum TagsValue {
  SEX = 'Sex',
  PAST_RELATIONSHIPS = 'Past relationships',
  CAREER = 'Career',
  FUTURE_PLANS = 'Future plans',
  CHILDREN = 'Children',
  PERSONAL_BOUNDARIES = 'Personal boundaries',
  TOXIC_BEHAVIOR = 'Toxic behavior',
  FETISHES = 'Fetishes',
  JEALOUSY = 'Jealousy',
  FINANCES = 'Finances',
  KINKS = 'Kinks',
  FAMILY_AND_INLAWS = 'Family and in-laws',
  NONE_OF_THE_ABOVE = 'None of the above',
}

export const enum ConflictFeelingsValue {
  THREATENED = 'I often feel threatened',
  GUILT = `I always feel guilt, even when I'm not at fault`,
  POWERLESS = 'I feel powerless and it challenging to come out on top',
  UNCOMFORTABLE = 'I feel uncomfortable and want the argument to end quickly',
  NONE = 'None of the above',
}

export const enum FactorsValue {
  DISTANCE = 'Long distance',
  DESIRE = 'Low sexual desire',
  INFIDELITY = 'Infidelity',
  DEPRESSION = 'Depression',
  POSTPARTUM = 'Postpartum depression',
  ANXIETY = 'Anxiety',
  ISSUES = 'Parenting issues',
  BODY = 'Poor body image',
  NONE = 'None of the above',
}

export const enum ActivitiesValue {
  COOKING = 'Cooking and trying new recipes together',
  WATCHING_MOVIES = 'Watching movies or shows',
  WALKS = 'Going for walks or hikes in nature',
  PLAYING = 'Playing board or video games',
  TRAVELING = 'Traveling and exploring new places',
  ATTENDING_CONCERTS = 'Attending concerts or live events',
  RELAXING = `Just relaxing and enjoying each other's company at home`,
  OTHER = 'Other',
}

export const enum MainGoalsV7Value {
  COMMUNICATION = 'Improve communication',
  REDUCE = 'Reduce conflict',
  IMPROVE = 'Improve sex and intimacy',
  OVERCOME = 'Overcome jealousy',
  FEEL = 'Feel more connected',
  UNDERSTAND = 'Better understand each other',
  BUILD = 'Build meaning and growth',
  EVALUATE = 'Evaluate relationship health',
  MORE = 'More fun and excitement',
  SPICE = 'Spice up my sex life',
  CHECK = 'Check compatibility',
  MYSELF = 'Understand myself better',
}

export const enum CommunicationLevelValue {
  SIGNIFICANT = 'Significant communication issues',
  ISSUE = 'Some issue that need addressed',
  IMPROVEMENT = 'Positive with room for improvement',
  COMMUNICATION = 'Open and effective communication',
}

export const enum IntimacyDefferenceValue {
  COMMUNICATION = 'Open communication and compromise',
  EXPLORATION = 'Exploration and trying new things',
  CONSULTATION = 'Consultation with a therapist',
  DIFFERENCES = 'Avoid discussing differences',
}

export const enum JealousyV2Value {
  RELATIONSHIP = `I'm often more jealous in our relationship`,
  PRONE = 'My partner is more prone to jealousy',
  EXPERIENCE = 'We both experience jealousy occasionally',
  ISSUE = `Jealousy isn't a significant issue for us`,
}

export const enum KidsValue {
  YES = 'Yes',
  NO = 'No',
}

export const enum RateRelationshipValue {
  PERFECT = 'Almost perfect',
  FINE = `Generally, it's fine`,
  ROOM = 'Room for improvement',
  IMPROVEMENT = 'Needs significant improvement',
  ISSUES = 'Causing serious issues',
}

export const enum TendernessValue {
  ALWAYS = 'Always',
  SOMETIMES = 'Sometimes',
  RARELY = 'Rarely',
  NEVER = 'Never',
}

export const enum ConflictManagementValue {
  COMMUNICATE = 'Communicate openly and calmly',
  CONFLICT = 'Avoid conflict',
  DEFENSIVE = 'Get defensive and angry',
  TREATMENT = 'Give each other the silent treatment',
  OTHER = 'Other',
}

export const enum SexQuantityValue {
  ONCE_IN_A_WHILE = 'once_in_a_while',
  ONE_TWO = 'one_two',
  THREE_FIVE = 'three_five',
  SIX = 'six',
  NOT_SAY = 'not_say',
}

export const enum FrequencyOfAffectionValue {
  ALWAYS = 'always',
  SOMETIMES = 'sometimes',
  RARELY = 'rarely',
  NEVER = 'never',
}

export const enum IntimacyProblemsValue {
  STOPPED_HAVING_SEX = 'stopped_having_sex',
  LESS_INTIMACY = 'less_intimacy',
  BORING = 'boring',
  DIFFERENCES = 'differences',
  STRESS = 'stress',
  NOT_NOTICE = 'not_notice',
  NOT_SAY = 'not_say',
}

export const enum SubscriptionListType {
  PURCHASE = 'purchase',
  CANCEL_OFFER = 'cancel',
}

export const enum DifficultyAreas {
  INTIMACY = 'Intimacy',
  LACK_OF_TRUST = 'Lack of trust',
  SELF_DEVELOPMENT = 'Self-development',
  FUTURE_AND_FAMILY = 'Future and Family',
  TOXIC_BEHAVIOR = 'Toxic behavior',
}

export const MAIN_GOAL_PAGES = [
  PageId.MAIN_GOALS_1,
  PageId.MAIN_GOALS_2,
  PageId.MAIN_GOALS_3,
  PageId.MAIN_GOALS_4,
  PageId.MAIN_GOALS_5,
  PageId.MAIN_GOALS_6,
  PageId.MAIN_GOALS_7,
  PageId.MAIN_GOALS_8,
]

export const RELATIONSHIP_STATUS_PAGES = [
  PageId.RELATIONSHIP_STATUS_1,
  PageId.RELATIONSHIP_STATUS_2,
]

export const RELATIONSHIP_DURATION_PAGES = [
  PageId.RELATIONSHIP_DURATION_1,
  PageId.RELATIONSHIP_DURATION_2,
]

export const START_AGE_MIX_PAGES = [PageId.START_AGE_MIX_1]

export const TAGS_PAGES = [PageId.TAGS_1]

export const GENDER_PAGES = [PageId.GENDER_2, PageId.GENDER_3]

export const RATE_RELATIONSHIP_PAGES = [PageId.RATE_RELATIONSHIP_1]

export const PUBLIC_PAGES: PageId[] = [
  PageId.EMAIL_2,
  PageId.EMAIL_3,
  PageId.EMAIL_4,
  PageId.PRESALE_1,
  PageId.PLAN_1,
  PageId.PLAN_2,
]

export const enum Cohort {
  UPLUV_7 = 'upluv7',
  UPLUV_INTIMACY_1_INTRO_1 = 'upluv_intimacy1_intro1',
  UPLUV_GENERAL_INTRO = 'upluv_general_intro',
}

export const TEN_MINUTES_IN_SECONDS = 600

export const CONTACT_FORM_LINKS = {
  [CurrentEnvironment.DEV]: 'https://account-upluv.gidev.xyz/contact-form',
  [CurrentEnvironment.STAGE]: 'https://account-upluv.gistage.com/contact-form',
  [CurrentEnvironment.PROD]: 'https://account.upluv.one/contact-form',
}

export const enum SubscriptionTag {
  TAX = 'tax',
  NO_TAX = 'no_tax',
  CANCEL_OFFER = 'cancel',
}

export const enum Currency {
  USD = 'usd',
}
export const CURRENCY_SYMBOLS = {
  [Currency.USD]: '$',
}

export const TRIAL_DESCRIPTION_CONTEXT = {
  [TrialPeriod.ONE_WEEK]: 'week',
  [TrialPeriod.ONE_MONTH]: 'month',
  [TrialPeriod.THREE_MONTH]: 'quarter',
}
