import styled from 'styled-components'

import { Award } from 'components/Award'
import { BenefitsV1 } from 'components/Benefits'
import { Button } from 'components/Button'

import { DisclaimerV1 } from 'modules/payment/components/Disclaimer'
import { HeaderWithTimer } from 'modules/payment/components/HeaderWithTimer'
import { PaywallTimerBlock } from 'modules/payment/components/PaywallTimerBlock'
import { PersonalInfoBlock } from 'modules/payment/components/PersonalInfoBlock'
import { SatisfactionBlockV1 } from 'modules/payment/components/SatisfactionBlock'
import { SuccessStoryBlock } from 'modules/payment/components/SuccessStoryBlock'
import { PAYMENT_TRIAL_V1_HEADER } from 'modules/payment/pages/constants'
import { Security } from 'modules/subscriptions/components/security'
import { TrialSelectPlanBlockVariant1 } from 'modules/subscriptions/components/trial/TrialSelectPlanBlockVariant1'

import bannerEllipse from 'assets/images/payment-trial-v1-banner-ellipse.svg'
import bg1 from 'assets/images/payment-trial-v1-bg-1.svg'
import bg2 from 'assets/images/payment-trial-v1-bg-2.svg'
import bg3 from 'assets/images/payment-trial-v1-bg-3.svg'
import bg4 from 'assets/images/payment-trial-v1-bg-4.svg'

import { baseColumnStyles } from 'common-styles'
import { BIG_COLUMN_WIDTH, Color, MAX_PHONE_WIDTH } from 'root-constants/common'

export const StyledPaymentTrialVariant1 = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: linear-gradient(
      188.98deg,
      ${Color.VIOLET_140} 0%,
      #070525 93.18%
    );
    padding: ${PAYMENT_TRIAL_V1_HEADER}px 0 30px 0;
  `,
  Banner: styled.div`
    width: 100%;
    height: auto;
    aspect-ratio: 350/230;
    padding: 0 20px;
    margin: 0 0 16px 0;
    background: linear-gradient(360deg, #17125c 8%, #7671bf 100%);
    position: relative;

    img {
      position: relative;
      z-index: 2;
      margin: 10px 0 0 0;
    }

    &:before {
      content: url(${bannerEllipse});
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
    }

    @media (min-width: ${MAX_PHONE_WIDTH}px) {
      width: ${BIG_COLUMN_WIDTH}px;
    }
  `,
  Column: styled.div`
    ${baseColumnStyles};
    width: 100%;

    @media (min-width: ${MAX_PHONE_WIDTH}px) {
      width: ${BIG_COLUMN_WIDTH}px;
    }
  `,
  Header: styled(HeaderWithTimer)`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    width: 100%;
    margin: 0 auto;
    background-color: ${Color.VIOLET_140};

    strong {
      color: ${Color.WHITE_100};
    }
    span {
      color: ${Color.LILAC_120};
    }
    @media (min-width: ${MAX_PHONE_WIDTH}px) {
      width: ${BIG_COLUMN_WIDTH}px;
    }
  `,
  SatisfactionContainer: styled(SatisfactionBlockV1)`
    margin: 0 0 40px 0;
    padding: 0 20px;
  `,
  Title: styled.h1`
    width: 100%;
    padding: 0 20px;
    margin: 0 0 32px 0;
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    color: ${Color.LILAC_120};

    span {
      color: ${Color.LILAC_130};
    }
  `,
  PersonalInfoBlock: styled(PersonalInfoBlock)`
    padding: 0 20px;
    margin: 0 0 32px 0;
  `,
  Timer: styled(PaywallTimerBlock)`
    width: 100%;
    margin: 0 0 32px 0;
    color: ${Color.WHITE_100};
    background: linear-gradient(180deg, #e8636b 0%, #db3540 100%);
    position: relative;
    z-index: 2;

    &:after {
      position: absolute;
      content: url(${bg1});
      right: 0;
      z-index: 1;
    }

    @media (min-width: ${MAX_PHONE_WIDTH}px) {
      width: ${BIG_COLUMN_WIDTH}px;
    }
  `,
  TrialSelectPlanBlockVariant1: styled(TrialSelectPlanBlockVariant1)`
    padding: 0 24px;
    margin: 0 0 25px 0;
    position: relative;
    z-index: 2;
  `,
  Button: styled(Button)`
    margin: 0 0 32px 0;
    position: relative;
    z-index: 2;
  `,
  Divider: styled.div`
    height: 1px;
    background-color: #362f99;
    margin: 0 0 32px 0;
    position: relative;
    width: 100%;

    @media (min-width: ${MAX_PHONE_WIDTH}px) {
      width: ${BIG_COLUMN_WIDTH}px;
    }
  `,
  Benefits: styled.div`
    width: 100%;
    padding: 0 24px 0 24px;
    margin: 0 0 46px 0;
    position: relative;
    z-index: 1;

    &:after {
      position: absolute;
      z-index: -1;
      content: url(${bg2});
      left: 0;
      top: 0;
    }
  `,
  BenefitsList: styled(BenefitsV1)`
    padding: 16px 10px 16px 10px;
    background-color: ${Color.VIOLET_130};
    border-radius: 16px;
  `,
  Award: styled(Award)`
    width: 100%;
    margin: 0 0 32px 0;
  `,
  SuccessStoryBlock: styled(SuccessStoryBlock)`
    width: 100%;
    padding: 0 24px;
    margin: 0 0 32px 0;
    position: relative;
    z-index: 2;

    &:before {
      position: absolute;
      z-index: 1;
      content: url(${bg3});
      right: 0;
      top: 0;
    }
  `,
  GuaranteeContainer: styled.div`
    width: 100%;
    margin: 0 0 32px 0;
    padding: 0 32px 0 32px;
    position: relative;

    &:before {
      position: absolute;
      z-index: 1;
      content: url(${bg4});
      left: 0;
    }
  `,
  Security: styled(Security)`
    margin: 0 0 24px 0;
  `,
  Disclaimer: styled(DisclaimerV1)`
    padding: 0 32px;
    color: #9d95d1;

    a {
      color: #9d95d1;
    }
  `,
}
