import styled from 'styled-components'

import { BIG_COLUMN_WIDTH, Color } from 'root-constants/common'

type TProps = {
  color?: string
}

export const StyledBenefitsV2 = {
  Title: styled.h2`
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    color: ${Color.LILAC_120};
    margin: 0 0 24px 0;
  `,
  List: styled.ul`
    > :not(:last-of-type) {
      margin: 0 0 12px 0;
    }
  `,
  ListItem: styled.li<TProps>`
    display: flex;
    align-items: center;
    height: 116px;
    padding: 16px 10px;
    border-radius: 16px;
    ${({ color }) => color && `background-color: ${color};`};

    @media (max-width: ${BIG_COLUMN_WIDTH}px) {
      height: 132px;
    }
  `,
  ItemContent: styled.div`
    display: flex;
    align-items: center;
  `,
  IconContainer: styled.div`
    align-self: center;
    width: 78px;
    height: 78px;
    aspect-ratio: 78/78;
    margin: 0 12px 0 0;
  `,
  TextContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  BenefitTitle: styled.p`
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: ${Color.BLUE_110};
    margin: 0 0 8px 0;
  `,
  BenefitDescription: styled.p`
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: ${Color.GREY_100};
  `,
}
