import { useEffect, useLayoutEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import {
  selectAmplitudeApiKey,
  selectCurrentVariantCohort,
  selectStripeAccountId,
  selectStripeAccountName,
  selectUtmTags,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { getCurrentEnvironment } from 'helpers/getCurrentEnvironment'
import { getQueryParamsFromSearchString } from 'helpers/getQueryParamsFromSearchString'

import { Amplitude } from 'services/amplitude.service'
import { eventLogger } from 'services/eventLogger.service'

import {
  EventLoggerInstanceName,
  TEST_ENV_QUERY_PARAM,
} from 'root-constants/common'

import { useGetPageInfo } from './useGetPageInfo'

export const useAmplitudeInitialization = () => {
  const { search } = useLocation()
  const cohort = useSelector(selectCurrentVariantCohort)
  const uuid = useSelector(selectUUID)
  const amplitudeApiKey = useSelector(selectAmplitudeApiKey)
  const stripeAccountId = useSelector(selectStripeAccountId)
  const stripeAccountName = useSelector(selectStripeAccountName)
  const utmTags = useSelector(selectUtmTags)

  const { isPaymentPage } = useGetPageInfo()

  const [amplitudeInstance, setAmplitudeInstance] = useState<Amplitude | null>(
    null,
  )

  const URLParams = new URLSearchParams(search)
  const isTestEnvironment = URLParams.has(TEST_ENV_QUERY_PARAM)
  const { isProdEnvironment } = getCurrentEnvironment()

  useLayoutEffect(() => {
    if (eventLogger.isAmplitudeActive) return

    if (uuid && amplitudeApiKey && isPaymentPage) {
      const amplitudeService = new Amplitude({
        apiKey: amplitudeApiKey,
        instanceName: EventLoggerInstanceName.AMPLITUDE,
        userId: uuid,
        utmTags: Object.entries(utmTags).length
          ? utmTags
          : getQueryParamsFromSearchString(search),
        isTestEnvironment,
        isDevEnvironment: !isProdEnvironment,
      })

      amplitudeService.configure({ cohort })
      eventLogger.init(amplitudeService)
      setAmplitudeInstance(amplitudeService)
    }
  }, [
    amplitudeApiKey,
    cohort,
    isProdEnvironment,
    isTestEnvironment,
    search,
    utmTags,
    uuid,
    isPaymentPage,
  ])

  useEffect(() => {
    if (!stripeAccountId || !stripeAccountName) return

    amplitudeInstance?.updateUserProperties(stripeAccountId, stripeAccountName)
  }, [amplitudeInstance, stripeAccountId, stripeAccountName])
}
