import React, { useMemo } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton2 } from 'components/QuestionButton2'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledSharing as S } from './Sharing.styles'

const QUESTION =
  'How frequently do you share your thoughts and feelings with your partner?'
const OPTION_VALUES = {
  allTime: `All the time, we're always chatting`,
  regularly: 'We communicate regularly but not obsessively',
  normal: `I wish we talked more, but it's okay`,
  struggleToShare: 'I struggle to open up and share',
  feelIgnored: 'I feel ignored and unheard by my partner',
}

export const SharingVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )
  return (
    <PageContainer>
      <S.Column>
        {' '}
        <S.QuestionTitle>{QUESTION}</S.QuestionTitle>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.allTime}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.allTime}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.regularly}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.regularly}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.normal}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.normal}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.struggleToShare}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.struggleToShare}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.feelIgnored}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.feelIgnored}</QuestionButton2>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
