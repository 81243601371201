import styled from 'styled-components'

import checkIcon from 'assets/images/success-violet.svg'

export const StyledBenefitsV3 = {
  ListItem: styled.li`
    height: 48px;
    padding-left: 42px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    color: #fff;

    ::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 34px;
      height: 34px;
      background: center url(${checkIcon}) no-repeat;
      background-size: 34px;
    }
  `,
}
