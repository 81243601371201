import React, { useMemo } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton2 } from 'components/QuestionButton2'
import { TitleContainer } from 'components/TitleContainer'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useGetFlowGroupInfo } from 'hooks/useGetFlowGroupInfo'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { IntimacyArea } from 'root-constants/common'

import { StyledFeltLonely as S } from './FeltLonely.styles'

const QUESTION = 'Have you ever felt lonely in your relationship?'
const OPTION_VALUES = {
  yes: 'Yes',
  no: 'No',
}

export const FeltLonelyVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
  alternativePagePath,
}) => {
  const { isMultipleFlow, intimacyAreas } = useGetFlowGroupInfo()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath:
      !isMultipleFlow && intimacyAreas.includes(IntimacyArea.EMOTIONAL)
        ? alternativePagePath
        : nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <TitleContainer title={<S.Title>{QUESTION}</S.Title>} />
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.yes}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.yes}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.no}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.no}</QuestionButton2>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
