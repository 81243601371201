import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Spinner } from 'components/Spinner'

import {
  getSubscriptionListAction,
  setSubscriptionTimerValueAction,
} from 'root-redux/actions/common'
import {
  selectSubscriptionList,
  selectSubscriptionTimerValue,
} from 'root-redux/selects/common'

import {
  ViewportScrollPercentage,
  useViewportValue,
} from 'hooks/useViewportValue'

import { compareSubscriptionList } from 'helpers/compareSubscriptionList'
import { createProductId } from 'helpers/createProductId'

import { MoneyBackGuarantee } from 'modules/payment/components/MoneyBackGuarantee'
import { Timer } from 'modules/payment/components/Timer'
import { TrialPlanItemVariant1 } from 'modules/subscriptions/components/trial/TrialPlanItemVariant1'
import { usePaywall } from 'modules/subscriptions/hooks/usePaywall'
import { SubscriptionButtonText } from 'modules/subscriptions/pages/constants'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { SubscriptionTag, TEN_MINUTES_IN_SECONDS } from 'root-constants/common'

import { selectHighestIntroDiff } from '../redux/selects'
import { StyledPaymentTrialV2 as S } from './PaymentTrialVariant2.styles'
import { BENEFITS_DATA, PAYMENT_TRIAL_V2_HEADER } from './constants'

export const PaymentTrialVariant2: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const pricingPlansBlockFirst = useRef<HTMLDivElement>(null)
  const pricingPlansBlockSecond = useRef<HTMLDivElement>(null)

  const { hasPrices, handleSelectSubscription, handleShowPayment } =
    usePaywall(nextPagePath)
  const viewportValue = useViewportValue()

  const subscriptionsList = useSelector(
    selectSubscriptionList,
    compareSubscriptionList,
  )
  const timerValueFromStore = useSelector(selectSubscriptionTimerValue)
  const discount = useSelector(selectHighestIntroDiff)

  useLayoutEffect(() => {
    dispatch(getSubscriptionListAction({ tags: SubscriptionTag.NO_TAX }))
  }, [dispatch])

  useEffect(() => {
    if (viewportValue > ViewportScrollPercentage.V_0) {
      eventLogger.logPlanPageScrolled(viewportValue, ScreenName.ONBOARDING)
    }
  }, [viewportValue])

  useEffect(() => {
    if (timerValueFromStore === null) {
      dispatch(setSubscriptionTimerValueAction(TEN_MINUTES_IN_SECONDS))
    }
  }, [dispatch, timerValueFromStore])

  useEffect(() => {
    if (subscriptionsList.length) {
      eventLogger.logSalePageShown({
        productIds: subscriptionsList.map(
          ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
            createProductId({
              periodName,
              periodQuantity,
              price: fullPrice,
            }),
        ),
        screenName: ScreenName.ONBOARDING,
      })
    }
  }, [subscriptionsList])

  const handleHeaderButtonClick = useCallback(() => {
    const top = pricingPlansBlockFirst.current?.offsetTop
    if (top) {
      document.body.scrollTo(0, top - PAYMENT_TRIAL_V2_HEADER)
    }
  }, [pricingPlansBlockFirst])

  const handlePaywallButtonClick = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault()

      const top = pricingPlansBlockSecond.current?.offsetTop

      if (top) {
        document.body.scrollTo(0, top - PAYMENT_TRIAL_V2_HEADER)
      }

      eventLogger.logPlansPageButtonTapped({
        screenName: ScreenName.ONBOARDING,
        buttonNumber: 2,
        buttonText: SubscriptionButtonText.GET_PLAN_MIDDLE,
      })
    },
    [pricingPlansBlockSecond],
  )

  const handleSetTimerValue = useCallback(
    (timerValue: number) =>
      dispatch(setSubscriptionTimerValueAction(timerValue)),
    [dispatch],
  )

  return !hasPrices ? (
    <Spinner />
  ) : (
    <S.Wrapper>
      <S.Header
        discount={discount}
        onBtnClick={handleHeaderButtonClick}
        timer={
          <Timer
            value={Number(timerValueFromStore)}
            setTimerValue={handleSetTimerValue}
            isHeaderTimer
          />
        }
        hasDiscount
      />
      <S.Column>
        <S.SatisfactionBlock />
        <S.Title>
          <Trans
            i18nKey="paymentTrialV1.title"
            components={{ span: <span /> }}
          />
        </S.Title>
        <S.PersonalInfoBlock />
      </S.Column>
      <S.Timer
        timer={
          <Timer
            value={Number(timerValueFromStore)}
            setTimerValue={handleSetTimerValue}
          />
        }
      />
      <S.Column ref={pricingPlansBlockFirst}>
        <S.TrialSelectPlanBlockVariant1
          onSelect={handleSelectSubscription}
          SelectPlanItem={TrialPlanItemVariant1}
        />
        <S.Button
          data-button-number="1"
          data-button-text={SubscriptionButtonText.GET_PLAN_UPPER}
          onClick={handleShowPayment}
        >
          {t('actions.getMyPlan')}
        </S.Button>
        <S.Disclaimer />
      </S.Column>
      <S.Divider />
      <S.Column>
        <S.AppBlock />
        <S.Award />
        <S.NavigationButton onClick={(e) => handlePaywallButtonClick(e)}>
          {t('actions.getMyPlan')}
        </S.NavigationButton>
        <S.Benefits benefits={BENEFITS_DATA} />
        <S.PlanBlock />
      </S.Column>
      <S.Divider />
      <S.Column>
        <S.SuccessStoryBlock />
      </S.Column>
      <S.Divider />
      <S.Column>
        <S.GuaranteeContainer>
          <MoneyBackGuarantee text="commonComponents.guarantee.textV2" />
        </S.GuaranteeContainer>
      </S.Column>
      <S.Divider />
      <S.Column ref={pricingPlansBlockSecond}>
        <S.Title>
          <Trans
            i18nKey="paymentTrialV1.title"
            components={{ span: <span /> }}
          />
        </S.Title>
        <S.TrialSelectPlanBlockVariant1
          onSelect={handleSelectSubscription}
          SelectPlanItem={TrialPlanItemVariant1}
        />
        <S.Button
          data-button-number="3"
          data-button-text={SubscriptionButtonText.GET_PLAN_BOTTOM}
          onClick={handleShowPayment}
        >
          {t('actions.getMyPlan')}
        </S.Button>
        <S.Disclaimer />
      </S.Column>
      <S.Divider />
      <S.Column>
        <S.Security />
      </S.Column>
    </S.Wrapper>
  )
}
