import React, { lazy, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation, useRoutes } from 'react-router-dom'

import { DownloadApp } from 'pages/download-app/DownloadApp'

import { ProtectedRoute } from 'components/ProtectedRoute'
import { SubscribeFlow } from 'components/SubscribeFlow'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantSteps,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useUserStatus } from 'hooks/useUserStatus'

import { getPathFromPageId } from 'helpers/getPathFromPageId'

import { Account } from 'modules/account/Account'

import { IStep } from 'models/variant.model'

import { PUBLIC_PAGES } from 'root-constants/common'
import { ID_TO_PAGE_MAP, PageId } from 'root-constants/pages'

const PaymentProvider = lazy(() => import('components/PaymentProvider'))

export const RouteList: React.FC = () => {
  const { search } = useLocation()
  const steps = useSelector(selectCurrentVariantSteps)
  const cohort = useSelector(selectCurrentVariantCohort)
  const uuid = useSelector(selectUUID)

  const availableRoute = useUserStatus()
  const { currentSubscriptionPageId } = useGetPageInfo()

  const firstPagePath = useMemo(
    () =>
      getPathFromPageId({
        pageId: steps[0].id,
        cohort,
        uuid,
        currentSearch: search,
      }),
    [cohort, search, steps, uuid],
  )

  const { onboardingPages, subscriptionPages } = useMemo(() => {
    return steps.reduce<{
      onboardingPages: IStep[]
      subscriptionPages: IStep[]
    }>(
      (accum, { isSubscriptions, isPayment }, ind, arr) => {
        if (isSubscriptions || isPayment) {
          accum.subscriptionPages.push(arr[ind])
          return accum
        }

        accum.onboardingPages.push(arr[ind])
        return accum
      },
      {
        onboardingPages: [],
        subscriptionPages: [],
      },
    )
  }, [steps])

  return useRoutes([
    { index: true, element: <Navigate to={firstPagePath} /> },
    {
      element: <SubscribeFlow />,
      children: onboardingPages.map(({ id: pageId }, index, arr) => {
        const CurrentPage = ID_TO_PAGE_MAP[pageId]

        const nextPagePath = getPathFromPageId({
          pageId: arr[index + 1]?.id,
          cohort,
          uuid,
          currentSearch: search,
        })

        const alternativePageId = arr.find(
          (dynamicPage, dynamicPageIndex) =>
            !dynamicPage.isSkippable && dynamicPageIndex > index,
        )?.id

        const alternativePagePath = getPathFromPageId({
          pageId: alternativePageId || arr[index + 1]?.id,
          cohort,
          uuid,
          currentSearch: search,
        })

        if (PUBLIC_PAGES.includes(pageId)) {
          return {
            path: pageId,
            element: (
              <CurrentPage
                pageId={pageId}
                nextPagePath={nextPagePath}
                alternativePagePath="/"
              />
            ),
          }
        }

        return {
          path: pageId,
          element: (
            <ProtectedRoute isAvailable={!availableRoute}>
              <CurrentPage
                pageId={pageId}
                nextPagePath={nextPagePath}
                alternativePagePath={alternativePagePath}
              />
            </ProtectedRoute>
          ),
        }
      }),
    },
    ...subscriptionPages.map(({ id: pageId }, index, arr) => {
      const PurchasePage = ID_TO_PAGE_MAP[pageId]
      const nextPagePath = getPathFromPageId({
        pageId: arr[index + 1]?.id,
        cohort,
        uuid,
        currentSearch: search,
      })

      return {
        path: pageId,
        element: (
          <ProtectedRoute
            isAvailable={availableRoute === currentSubscriptionPageId}
          >
            <PaymentProvider>
              <PurchasePage
                pageId={pageId}
                nextPagePath={nextPagePath}
                alternativePagePath="/"
              />
            </PaymentProvider>
          </ProtectedRoute>
        ),
      }
    }),
    {
      path: PageId.ACCOUNT,
      element: (
        <ProtectedRoute isAvailable={availableRoute === PageId.ACCOUNT}>
          <Account />
        </ProtectedRoute>
      ),
    },
    {
      path: PageId.DOWNLOAD,
      element: (
        <ProtectedRoute isAvailable={availableRoute === PageId.DOWNLOAD}>
          <DownloadApp />
        </ProtectedRoute>
      ),
    },
    { path: '*', element: <Navigate to={firstPagePath} /> },
  ])
}
