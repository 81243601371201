import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Input } from 'components/Input'
import { Modal } from 'components/Modal'
import { PageContainer } from 'components/PageContainer'
import { PoliciesGroup } from 'components/PoliciesGroup'
import { Spinner } from 'components/Spinner'

import { resetErrorAction } from 'root-redux/actions/common'
import {
  GET_STATUS,
  SEND_USER_EMAIL,
  sendUserEmailAction,
} from 'root-redux/actions/user'
import { selectActionList, selectError } from 'root-redux/selects/common'
import { selectUserStatus } from 'root-redux/selects/user'

import { useEmailInputField } from 'hooks/useEmailInputField'
import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import lockImg from 'assets/images/sprite/lock.svg'

import { goTo } from 'browser-history'

import { getFilteredEmailDomains } from '../helpers'
import { StyledEmail as S } from './Email.styles'

export const EmailVariant4: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch = useDispatch()
  const userStatus = useSelector(selectUserStatus)
  const error = useSelector(selectError)
  const fetchingActionsList = useSelector(selectActionList)
  const [emailHasFocus, setEmailHasFocus] = useState(false)
  const [isAutoCompleteShown, setIsAutoCompleteShown] = useState(false)
  const [isErrorModalShown, setIsErrorModalShown] = useState(false)

  const [email, , validateEmail] = useEmailInputField()
  const { currentSubscriptionPageId } = useGetPageInfo()

  const isStatusFetching = useMemo(
    () =>
      fetchingActionsList?.includes(SEND_USER_EMAIL) ||
      fetchingActionsList?.includes(GET_STATUS),
    [fetchingActionsList],
  )

  const isButtonDisabled = useMemo(
    () => !email.value || !email.isValid || isStatusFetching,
    [email.isValid, email.value, isStatusFetching],
  )

  const filteredEmailDomains = useMemo(
    () => getFilteredEmailDomains(email.value),
    [email.value],
  )

  useLayoutEffect(() => {
    if (userStatus?.email.hasEmail) {
      goTo(nextPagePath || `${currentSubscriptionPageId}${search}`)
    }
  }, [
    currentSubscriptionPageId,
    nextPagePath,
    search,
    userStatus?.email.hasEmail,
  ])

  useEffect(() => {
    if (userStatus?.email.hasEmail) return
    eventLogger.logEmailPageShown()
  }, [userStatus?.email.hasEmail])

  useEffect(() => {
    filteredEmailDomains.length && emailHasFocus
      ? setIsAutoCompleteShown(true)
      : setIsAutoCompleteShown(false)
  }, [email.isValid, email.value, emailHasFocus, filteredEmailDomains.length])

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()

      if (!email.isValid || isStatusFetching) return

      dispatch(
        sendUserEmailAction({
          email: email.value,
          unsuccessCallback: () => setIsErrorModalShown(true),
          nextPagePath,
        }),
      )
    },
    [dispatch, email.isValid, email.value, isStatusFetching, nextPagePath],
  )

  const handleCloseModal = useCallback(() => {
    dispatch(resetErrorAction())
    setIsErrorModalShown(false)
  }, [])

  return isStatusFetching ? (
    <Spinner />
  ) : (
    <>
      <PageContainer>
        <form onSubmit={handleSubmit}>
          <S.Content>
            <S.SubtitleV4>
              <Trans i18nKey="onboarding.email.titleV4" />
            </S.SubtitleV4>

            <S.InputWrapper>
              <Input
                value={email.value}
                isValid={email.isValid}
                validationText={email.validationText}
                placeholder={t('onboarding.email.placeholder')}
                name="email"
                type="email"
                autoComplete="off"
                onChange={(e) => {
                  validateEmail(e.target.value)
                }}
                onFocus={() => {
                  setEmailHasFocus(true)
                  setTimeout(() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                  }, 100)
                }}
              />
              {isAutoCompleteShown && (
                <S.AutoCompleteWrapperVariant2>
                  {filteredEmailDomains.map((value) => (
                    <S.AutoCompleteButton
                      type="button"
                      key={value}
                      value={value}
                      onClick={(e) => {
                        validateEmail(
                          (e.currentTarget as HTMLButtonElement).value,
                        )
                        setEmailHasFocus(false)
                      }}
                    >
                      <S.AutoCompleteButtonTextWrapper>
                        {value}
                      </S.AutoCompleteButtonTextWrapper>
                    </S.AutoCompleteButton>
                  ))}
                </S.AutoCompleteWrapperVariant2>
              )}
            </S.InputWrapper>

            <S.Info>
              <S.InfoTitle>{t('onboarding.email.infoTitle')}</S.InfoTitle>
              <S.InfoContent>{t('onboarding.email.infoContent')}</S.InfoContent>
            </S.Info>

            <S.Note>
              <S.LockImgVariant svg={lockImg} />
              <S.Description>{t('onboarding.email.disclaimer')}</S.Description>
            </S.Note>

            <S.Footer data-is-auto-complete-shown={isAutoCompleteShown}>
              <S.ButtonVariant2 type="submit" disabled={isButtonDisabled}>
                {t('actions.continue')}
              </S.ButtonVariant2>
              <PoliciesGroup />
            </S.Footer>
          </S.Content>
        </form>
      </PageContainer>
      <Modal onClose={handleCloseModal} isShown={isErrorModalShown}>
        {error}
      </Modal>
    </>
  )
}
