/* eslint-disable max-lines */
import { getCurrentExperimentId } from 'helpers/getCurrentExperimentId'
import { getCurrentOnboardingVariant } from 'helpers/getCurrentOnboardingVariant'
import { getCurrentOptimizeExperimentId } from 'helpers/getCurrentOptimizeExperimentId'
import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'

import { PaymentMethod, PaymentSystem } from 'modules/payment/constants'
import { SubscriptionButtonText } from 'modules/subscriptions/pages/constants'

import { IEvent, IEventLogger, TQuestionPageParams } from 'models/events.model'
import { TProductId } from 'models/subscriptions.model'

import {
  EventLoggerInstanceName,
  LoginMethod,
  NO_NAME_AB_SEGMENT,
} from 'root-constants/common'

export const enum ScreenName {
  ONBOARDING = 'onboarding',
  CANCEL_OFFER = 'cancel_offer',
}

export const enum Source {
  INITIAL = 'initial',
  UPSALE_OFFER = 'upsale_offer',
  CANCEL_OFFER = 'cancel_offer',
  EMAIL = 'email',
}

export const enum Events {
  SESSION_STARTED = 'session_start',
  AB_SEGMENT = 'ab_segment',
  QUESTION_COMPLETED = 'question_page_completed',
  PRESALE_PAGE_COMPLETED = 'presale_page_completed',
  PRESALE_PAGE_SHOW = 'presale_page_show',
  EMAIL_PAGE_SHOW = 'email_page_show',
  EMAIL_PAGE_COMPLETED = 'email_page_completed',
  EMAIL_PAGE_ERROR = 'email_page_error',
  SCROLL_COMPLETED = 'scroll_completed',
  PAYMENT_METHOD_SELECTED = 'payment_method_selected',
  SALE_SCREEN_SHOW = 'plans_page_show',
  TERMS_OF_USE = 'terms_of_use_tap',
  PRIVACY_POLICY = 'privacy_policy_tap',
  CONTACT_US = 'contact_us_tap',
  NEED_HELP = 'need_help_tap',
  PURCHASE_SHOW = 'subs_purchase_show',
  PURCHASE_STARTED = 'subs_purchase_started',
  PURCHASE_COMPLETED = 'subs_purchase_completed',
  PURCHASE_FAILED = 'subs_purchase_failed',
  PURCHASE_SCREEN_CLOSE = 'subs_purchase_screen_close',
  CREATE_ACCOUNT_SHOW = 'create_account_page',
  LOGIN_METHOD_SELECTED = 'login_method_selected',
  ACCOUNT_CREATED = 'account_created',
  ACCOUNT_CREATION_FAILED = 'account_creation_failed',
  FINISH_ACCOUNT_SCREEN_VIEW = 'finish_account_screen_view',
  DOWNLOAD_BTN_PRESSED = 'download_button_press',
  PLAN_1_PAGE_SHOW = 'plan_1_page_show',
  PLAN_1_PAGE_COMPLETED = 'plan_1_page_completed',
  CANCEL_OFFER_PAGE_SHOW = 'cancel_offer_page_show',
  CANCEL_OFFER_PAGE_COMPLETED = 'cancel_offer_page_completed',
  PLANS_PAGE_BUTTON_TAP = 'plans_page_button_tap',
  PLANS_PAGE_SCROLLED = 'plans_page_scrolled',
}

type TPageName = {
  pageName: string
}

class EventLoggerService {
  private loggers?: Map<EventLoggerInstanceName, IEventLogger>
  private eventsQueue: IEvent[] = []

  get isAmplitudeActive() {
    return this.loggers?.has(EventLoggerInstanceName.AMPLITUDE)
  }

  get getIsGiaActive() {
    return this.loggers?.has(EventLoggerInstanceName.GIA)
  }

  init(...loggers: IEventLogger[]) {
    const entriesArr = loggers.map(
      (logger) =>
        [logger.name, logger] as [EventLoggerInstanceName, IEventLogger],
    )

    if (!this.loggers) {
      this.loggers = new Map(entriesArr)
      this.notifyInitFinished()
      return
    }

    if (this.loggers) {
      loggers.map((logger) => this.loggers?.set(logger.name, logger))
    }

    this.notifyInitFinished()
  }

  logSessionStarted = () => {
    this.logEventOrPushToQueue({
      event: Events.SESSION_STARTED,
      eventProperty: {
        device_type: getMobileOperatingSystem(),
      },
      isAmplitudeEvent: false,
    })
  }

  logGrowthbookAbSegmentName({
    variantId,
    experimentKey,
    variantName,
  }: {
    experimentKey: string
    variantId: string
    variantName: string
  }) {
    this.logEventOrPushToQueue({
      event: Events.AB_SEGMENT,
      eventProperty: {
        growthbook_feature_key: getCurrentExperimentId(experimentKey),
        ab_variant: getCurrentOnboardingVariant(variantId),
        ab_segment_name: variantName || NO_NAME_AB_SEGMENT,
      },
      isAmplitudeEvent: false,
    })
  }

  logAbSegmentName({
    optimizeVariantId,
    optimizeExperimentId,
    optimizeSegmentName,
  }: {
    optimizeExperimentId: string
    optimizeVariantId: string
    optimizeSegmentName: string
  }) {
    this.logEventOrPushToQueue({
      event: Events.AB_SEGMENT,
      eventProperty: {
        optimize_experiment_id:
          getCurrentOptimizeExperimentId(optimizeExperimentId),
        ab_variant: getCurrentOnboardingVariant(optimizeVariantId),
        ab_segment_name: optimizeSegmentName || NO_NAME_AB_SEGMENT,
      },
      isAmplitudeEvent: false,
    })
  }

  logQuestion({ question, answers, pageName }: TQuestionPageParams) {
    this.logEventOrPushToQueue({
      event: Events.QUESTION_COMPLETED,
      eventProperty: {
        question,
        answer: Array.isArray(answers) ? answers.join(';') : answers,
        screen_name: pageName,
      },
      isAmplitudeEvent: false,
    })
  }

  // EmailWrapper Page Events
  logEmailPageShown(goal?: string) {
    this.logEventOrPushToQueue({
      event: Events.EMAIL_PAGE_SHOW,
      eventProperty: { ...(goal && { goal }) },
      isAmplitudeEvent: false,
    })
  }

  logEmailPageCompleted({ email, goal }: { email: string; goal?: string }) {
    this.logEventOrPushToQueue({
      event: Events.EMAIL_PAGE_COMPLETED,
      eventProperty: { email, ...(goal && { goal }) },
      isAmplitudeEvent: false,
    })
  }

  logEmailPageError(eventProperty: { error: string }) {
    this.logEventOrPushToQueue({
      event: Events.EMAIL_PAGE_ERROR,
      eventProperty,
      isAmplitudeEvent: false,
    })
  }

  logScrollToBottom() {
    this.logEventOrPushToQueue({
      event: Events.SCROLL_COMPLETED,
      eventProperty: { scrolled: true },
      isAmplitudeEvent: false,
    })
  }

  // Presale Page Events
  logPresalePageShown({ pageName }: TPageName) {
    this.logEventOrPushToQueue({
      event: Events.PRESALE_PAGE_SHOW,
      eventProperty: { screen_name: pageName },
      isAmplitudeEvent: false,
    })
  }

  logPresalePageCompleted({ pageName }: TPageName) {
    this.logEventOrPushToQueue({
      event: Events.PRESALE_PAGE_COMPLETED,
      eventProperty: { screen_name: pageName },
      isAmplitudeEvent: false,
    })
  }

  // Sale Page Events
  logSalePageShown({
    productIds,
    screenName,
    onboardingId,
    goal,
  }: {
    productIds: string[]
    screenName: ScreenName
    onboardingId?: string
    goal?: string
  }) {
    this.logEventOrPushToQueue({
      event: Events.SALE_SCREEN_SHOW,
      eventProperty: {
        product_id: productIds.join(','),
        screen_name: screenName,
        onboarding_id: onboardingId || '',
        ...(goal && { goal }),
      },
      isAmplitudeEvent: false,
    })
  }

  logCancelOfferPageShown() {
    this.logEventOrPushToQueue({
      event: Events.CANCEL_OFFER_PAGE_SHOW,
      eventProperty: {
        screen_name: ScreenName.CANCEL_OFFER,
      },
    })
  }

  logCancelOfferPageСompleted() {
    this.logEventOrPushToQueue({
      event: Events.CANCEL_OFFER_PAGE_COMPLETED,
      eventProperty: {
        screen_name: ScreenName.CANCEL_OFFER,
      },
    })
  }

  logPlansPageButtonTapped({
    screenName,
    buttonNumber,
    buttonText,
  }: {
    screenName: ScreenName
    buttonNumber: number | string
    buttonText: SubscriptionButtonText
  }) {
    this.logEventOrPushToQueue({
      event: Events.PLANS_PAGE_BUTTON_TAP,
      eventProperty: {
        screen_name: screenName,
        button_number: buttonNumber,
        button_text: buttonText,
      },
    })
  }

  logPlanPageScrolled(value: number, screenName: ScreenName) {
    this.logEventOrPushToQueue({
      event: Events.PLANS_PAGE_SCROLLED,
      eventProperty: { screen_name: screenName, scrolled_part: `${value}%` },
    })
  }

  logTermsOfUseClicked() {
    this.logEventOrPushToQueue({ event: Events.TERMS_OF_USE })
  }

  logPrivacyPolicyClicked() {
    this.logEventOrPushToQueue({ event: Events.PRIVACY_POLICY })
  }

  logContactUsClicked() {
    this.logEventOrPushToQueue({ event: Events.CONTACT_US })
  }

  logNeedHelpClicked() {
    this.logEventOrPushToQueue({ event: Events.NEED_HELP })
  }

  // Account Page events
  logCreateAccountShown() {
    this.logEventOrPushToQueue({ event: Events.CREATE_ACCOUNT_SHOW })
  }

  logLoginMethodSelected(eventProperty: { method: LoginMethod }) {
    this.logEventOrPushToQueue({
      event: Events.LOGIN_METHOD_SELECTED,
      eventProperty,
    })
  }

  logAccountCreated(eventProperty: { method: LoginMethod | null }) {
    this.logEventOrPushToQueue({ event: Events.ACCOUNT_CREATED, eventProperty })
  }

  logAccountCreationFailed({ error }: { error: string }) {
    this.logEventOrPushToQueue({
      event: Events.ACCOUNT_CREATION_FAILED,
      eventProperty: { error_reason: error },
    })
  }

  // Getting App Page Events
  logGettingAppShown() {
    this.logEventOrPushToQueue({ event: Events.FINISH_ACCOUNT_SCREEN_VIEW })
  }

  logDownloadClicked(callback: () => void) {
    this.logEventOrPushToQueue({ event: Events.DOWNLOAD_BTN_PRESSED, callback })
  }

  // Payment
  logPaymentMethodSelected(paymentMethod: PaymentMethod) {
    this.logEventOrPushToQueue({
      event: Events.PAYMENT_METHOD_SELECTED,
      eventProperty: {
        payment_method: paymentMethod,
      },
    })
  }

  // Plan 1 Page events
  logPlan1PageShow({ pageName }: TPageName) {
    this.logEventOrPushToQueue({
      event: Events.PLAN_1_PAGE_SHOW,
      eventProperty: { screen_name: pageName },
      isAmplitudeEvent: false,
    })
  }

  logPlan1PageCompleted({ pageName }: TPageName) {
    this.logEventOrPushToQueue({
      event: Events.PLAN_1_PAGE_COMPLETED,
      eventProperty: { screen_name: pageName },
      isAmplitudeEvent: false,
    })
  }

  // SubscriptionsWrapper Page events
  logPurchaseShown({
    productId,
    screenName,
    source = Source.INITIAL,
    goal,
    stripeAccountId,
    stripeAccountName,
  }: {
    productId: TProductId
    screenName: ScreenName
    source?: Source
    goal?: string
    stripeAccountId: string
    stripeAccountName: string
  }) {
    this.logEventOrPushToQueue({
      event: Events.PURCHASE_SHOW,
      eventProperty: {
        source,
        onboarding_id: '',
        product_id: productId,
        screen_name: screenName,
        stripe_account_id: stripeAccountId,
        stripe_account_name: stripeAccountName,
        ...(goal && { goal }),
      },
    })
  }

  logPurchaseStarted({
    productId,
    screenName,
    priceDetails: { price, trial = false, currency = 'USD' },
    paymentMethod,
    paymentSystem,
    email,
    source = Source.INITIAL,
    goal,
    stripeAccountName,
    stripeAccountId,
  }: {
    productId: TProductId
    screenName: ScreenName
    priceDetails: { price: number; trial?: boolean; currency?: string }
    paymentMethod: PaymentMethod
    paymentSystem?: PaymentSystem
    email: string
    source?: Source
    goal: string
    stripeAccountName: string
    stripeAccountId: string
  }) {
    this.logEventOrPushToQueue({
      event: Events.PURCHASE_STARTED,
      eventProperty: {
        email,
        source,
        onboarding_id: '',
        trial,
        price,
        currency,
        product_id: productId,
        screen_name: screenName,
        payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
        stripe_account_name: stripeAccountName,
        stripe_account_id: stripeAccountId,
        ...(paymentSystem && {
          payment_system: paymentSystem,
        }),
        ...(goal && { goal }),
      },
    })
  }

  logPurchaseCompleted({
    productId,
    screenName,
    priceDetails: { price, trial = false, currency = 'USD' },
    paymentMethod,
    paymentSystem,
    discountApplied,
    transactionId,
    email,
    source = Source.INITIAL,
    goal,
    stripeAccountName,
    stripeAccountId,
  }: {
    productId: TProductId
    screenName: ScreenName
    priceDetails: { price: number; trial?: boolean; currency?: string }
    email: string
    paymentMethod?: PaymentMethod
    paymentSystem?: PaymentSystem
    discountApplied?: string
    transactionId?: string
    source?: Source
    goal: string
    stripeAccountName: string
    stripeAccountId: string
  }) {
    this.logEventOrPushToQueue({
      event: Events.PURCHASE_COMPLETED,
      eventProperty: {
        email,
        onboarding_id: '',
        source,
        trial,
        price,
        currency,
        product_id: productId,
        screen_name: screenName,
        payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
        stripe_account_name: stripeAccountName,
        stripe_account_id: stripeAccountId,
        ...(paymentSystem && {
          payment_system: paymentSystem,
        }),
        ...(goal && { goal }),
        ...(transactionId && { transaction_id: transactionId }),
        ...(discountApplied && { discount_applied: discountApplied }),
      },
    })
  }

  logPurchaseFailed({
    productId,
    screenName,
    priceDetails: { price, trial = false, currency = 'USD' },
    error: { description, type, code },
    paymentMethod,
    source = Source.INITIAL,
    email,
    goal,
    stripeAccountName,
    stripeAccountId,
    paymentSystem,
  }: {
    productId: TProductId
    screenName: ScreenName
    priceDetails: { price: number; trial?: boolean; currency?: string }
    error: { type: string; description?: string; code?: string }
    paymentMethod?: PaymentMethod
    source?: Source
    email: string
    goal: string
    stripeAccountName: string
    stripeAccountId: string
    paymentSystem?: PaymentSystem
  }) {
    this.logEventOrPushToQueue({
      event: Events.PURCHASE_FAILED,
      eventProperty: {
        email,
        onboarding_id: '',
        source,
        trial,
        price,
        currency,
        error_type: type,
        product_id: productId,
        screen_name: screenName,
        stripe_account_name: stripeAccountName,
        stripe_account_id: stripeAccountId,
        payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
        ...(paymentSystem && { payment_system: paymentSystem }),
        ...(description && { error_description: description }),
        ...(code && { error_code: code }),
        ...(goal && { goal }),
      },
    })
  }

  logPurchaseScreenClosed({
    productId,
    screenName,
    goal,
  }: {
    productId: TProductId
    screenName: ScreenName
    goal?: string
  }) {
    this.logEventOrPushToQueue({
      event: Events.PURCHASE_SCREEN_CLOSE,
      eventProperty: {
        product_id: productId,
        screen_name: screenName,
        ...(goal && { goal }),
      },
    })
  }

  private logEventOrPushToQueue({
    event,
    eventProperty,
    isAmplitudeEvent = true,
    callback,
  }: {
    event: Events
    eventProperty?: Record<string, any>
    isAmplitudeEvent?: boolean
    callback?: () => void
  }) {
    if (this.getIsGiaActive) {
      this.logEvent({
        event,
        eventProperty,
        isAmplitudeEvent,
        callback,
      })
    } else {
      this.eventsQueue.push({
        event,
        eventProperty,
        isAmplitudeEvent,
        callback,
      })
    }
  }

  private notifyInitFinished() {
    if (this.eventsQueue.length) {
      this.eventsQueue.forEach(
        ({ event, eventProperty, isAmplitudeEvent, callback }) =>
          this.logEvent({
            event,
            eventProperty,
            isAmplitudeEvent,
            callback,
          }),
      )

      this.eventsQueue = []
    }
  }

  private logEvent({
    event,
    eventProperty,
    isAmplitudeEvent = true,
    callback,
  }: {
    event: Events
    eventProperty?: Record<string, any>
    isAmplitudeEvent?: boolean
    callback?: () => void
  }) {
    if (!isAmplitudeEvent) {
      this.loggers
        ?.get(EventLoggerInstanceName.GIA)
        ?.log(event, eventProperty, callback)

      this.loggers
        ?.get(EventLoggerInstanceName.USER_FLOW_TRACKER)
        ?.log(event, eventProperty, callback)
      return
    }

    this.loggers?.forEach((logger) => {
      logger.log(event, eventProperty, callback)
    })
  }
}

export const eventLogger = new EventLoggerService()
