import { KidsValue } from 'root-constants/common'

export const TRANSLATION_FAMILY = 'onboarding.kidsV1'
export const OPTIONS = [
  {
    key: 'yes',
    value: KidsValue.YES,
  },
  {
    key: 'no',
    value: KidsValue.NO,
  },
]
