import React from 'react'

import { PageContainer } from 'components/PageContainer'

import { useGetFlowGroupInfo } from 'hooks/useGetFlowGroupInfo'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import img from 'assets/images/shared-interests.webp'

import { IntimacyArea } from 'root-constants/common'

import { StyledSharedInterests as S } from './SharedInterests.styles'

export const SharedInterestsVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { isMultipleFlow, nextPagePath: nextGroupPath } = useGetFlowGroupInfo(
    IntimacyArea.INTELLECTUAL,
  )

  const handleContinue = useNextStep({
    pageId,
    question: 'Shared interests lead to better relationships',
    nextPagePath: isMultipleFlow ? nextGroupPath : nextPagePath,
  })

  return (
    <PageContainer>
      <S.Column>
        <S.Title>Shared interests lead to better relationships</S.Title>
        <S.Image src={img} alt="couple" />
        <S.Text>
          The study showed that couples are happier when they engage in exciting
          shared interests.
        </S.Text>
        <S.Button onClick={() => handleContinue('')}>Continue</S.Button>
      </S.Column>
    </PageContainer>
  )
}
