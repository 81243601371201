import styled from 'styled-components'

import { QuestionButton } from 'components/QuestionButton'

import { QuestionTitle, baseColumnStyles } from 'common-styles'

export const StyledBenefits = {
  Column: styled.div`
    ${baseColumnStyles};
    padding: 15px 0;
  `,
  QuestionTitle: styled(QuestionTitle)`
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 12px;

    @media (min-width: 350px) {
      width: 340px;
    }
  `,
  QuestionButton: styled(QuestionButton)`
    input:disabled + & {
      background-color: rgba(217, 216, 246, 0.5);
    }
  `,
}
