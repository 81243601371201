import React from 'react'

import { RadioOptionsPage } from 'pages/options-page'

import { TPageProps } from 'models/common.model'

import { OPTIONS, TRANSLATION_FAMILY_V1 } from './constants'

export const RelationshipStatusVariant1: React.FC<TPageProps> = (props) => (
  <RadioOptionsPage
    {...props}
    options={OPTIONS}
    translationFamily={TRANSLATION_FAMILY_V1}
  />
)
