import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { selectCurrentVariantSteps } from 'root-redux/selects/common'

import { PageId } from 'root-constants/pages'

export const useGetPageInfo = () => {
  const { pathname } = useLocation()
  const steps = useSelector(selectCurrentVariantSteps)

  const currentPageId = useMemo(() => pathname.slice(1) as PageId, [pathname])

  const currentPageIndex = useMemo(
    () => steps.findIndex(({ id }) => id === currentPageId),
    [currentPageId, steps],
  )

  const isSecondPage = useMemo(
    () => steps.findIndex(({ id }) => id === currentPageId) === 1,
    [currentPageId, steps],
  )

  const currentSubscriptionPageId = useMemo(
    () => steps.find((page) => page.isSubscriptions)?.id,
    [steps],
  )

  const currentPaymentPageId = useMemo(
    () => steps.find((page) => page.isPayment)?.id,
    [steps],
  )

  const isPaymentPage = useMemo(
    () => currentPaymentPageId === currentPageId,
    [currentPaymentPageId, currentPageId],
  )

  const pagesWithProgressBar = useMemo(
    () => steps.filter(({ hasProgressBar }) => hasProgressBar),
    [steps],
  )

  const pagesWithHeader = useMemo(
    () => steps.filter(({ hasHeader }) => hasHeader),
    [steps],
  )

  const currentPageWithHeaderIndex = useMemo(
    () => pagesWithHeader.findIndex(({ id }) => id === currentPageId),
    [currentPageId, pagesWithHeader],
  )

  const isPostPaymentPage = useMemo(
    () => currentPageId === PageId.ACCOUNT || currentPageId === PageId.DOWNLOAD,
    [currentPageId],
  )

  const hasHeader = useMemo(
    () => currentPageWithHeaderIndex >= 0 || isPostPaymentPage,
    [currentPageWithHeaderIndex, isPostPaymentPage],
  )

  const currentPageWithProgressBarIndex = useMemo(
    () => pagesWithProgressBar.findIndex(({ id }) => id === currentPageId),
    [currentPageId, pagesWithProgressBar],
  )

  const hasProgressBar = useMemo(
    () => currentPageWithProgressBarIndex >= 0,
    [currentPageWithProgressBarIndex],
  )

  return {
    currentPageId,
    currentPageIndex,
    hasHeader,
    isSecondPage,
    currentSubscriptionPageId,
    currentPaymentPageId,
    pagesWithProgressBar,
    pagesWithHeader,
    currentPageWithHeaderIndex,
    isPostPaymentPage,
    hasProgressBar,
    currentPageWithProgressBarIndex,
    isPaymentPage,
  }
}
