import styled from 'styled-components'

import quotes from 'assets/images/quotes.svg'

import { Color } from 'root-constants/common'

export const StyledSuccessStoryBlock = {
  Block: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Title: styled.h2`
    text-align: center;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: ${Color.LILAC_120};
    padding: 0 12px 0 12px;
    margin: 0 0 24px 0;
  `,
  List: styled.ul`
    display: flex;
    flex-direction: column;
    > :not(:last-child) {
      margin: 0 0 24px 0;
    }
  `,
  ListItem: styled.li`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 0 0 28px;

    &:before {
      content: url(${quotes});
      position: absolute;
      left: 0;
      top: 0;
      width: 16px;
      height: 16px;
    }
  `,
  Story: styled.p`
    font-weight: 500;
    font-size: 15px;
    font-style: italic;
    line-height: 16px;
    color: ${Color.LILAC_120};
    margin: 0 0 12px 0;

    span {
      font-style: normal;
      font-weight: 700;
    }
  `,
  StoryAuthor: styled.div`
    display: flex;
    align-items: center;
    aspect-ratio: 150/20;
    height: 20px;

    > :not(:last-child) {
      margin: 0 12px 0 0;
    }
  `,
  Name: styled.span`
    font-weight: 700;
    font-size: 16px;
    color: ${Color.WHITE_100};
    line-height: 20px;
  `,
}
