import { TendernessValue } from 'root-constants/common'

export const TRANSLATION_FAMILY = 'onboarding.tendernessV1'
export const OPTIONS = [
  {
    key: 'always',
    value: TendernessValue.ALWAYS,
  },
  {
    key: 'sometimes',
    value: TendernessValue.SOMETIMES,
  },
  {
    key: 'rarely',
    value: TendernessValue.RARELY,
  },
  {
    key: 'never',
    value: TendernessValue.NEVER,
  },
]
