import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { Option, OptionType } from 'components/Option'
import { PageContainer } from 'components/PageContainer'

import { setAnswersAction } from 'root-redux/actions/common'
import { sendUserAnswersAction } from 'root-redux/actions/user'

import { useCounter } from 'hooks/useCounter'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { QuestionTitle } from 'common-styles'

import { StyledPlanProcessing as S } from './PlanProcessing.styles'
import { FIRST_QUESTION, OPTIONS_VALUES, SECOND_QUESTION } from './constants'

export const PlanProcessingVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const dispatch = useDispatch()

  const {
    counter: collectingCounterPercent,
    isFinished: isCollectingCounterFinished,
  } = useCounter(true, {
    duration: 6000,
  })

  const {
    counter: analyzingCounterPercent,
    isFinished: isAnalyzingCounterFinished,
    handlePause: handlePauseAnalyzingCounter,
    handleResume: handleResumeAnalyzingCounter,
  } = useCounter(isCollectingCounterFinished, {
    duration: 6000,
  })

  const {
    counter: customizingCounterPercent,
    isFinished: isCustomizingCounterFinished,
    handlePause: handlePauseCustomizingCounter,
    handleResume: handleResumeCustomizingCounter,
  } = useCounter(isAnalyzingCounterFinished, {
    duration: 6000,
  })

  const barsList = useMemo(
    () => [
      {
        title: 'Analyzing your relationship',
        color: '#83CBFB',
        counter: collectingCounterPercent,
      },
      {
        title: 'Personalized tips for improving intimacy',
        color: '#A9A7FA',
        counter: analyzingCounterPercent,
      },
      {
        title: 'Customizing content according to your relationship goal',
        color: '#F496B8',
        counter: customizingCounterPercent,
      },
    ],
    [
      analyzingCounterPercent,
      collectingCounterPercent,
      customizingCounterPercent,
    ],
  )

  const isFirstQuestionShown = useMemo(
    () => analyzingCounterPercent === 84,
    [analyzingCounterPercent],
  )

  const isSecondQuestionShown = useMemo(
    () => customizingCounterPercent === 84,
    [customizingCounterPercent],
  )

  useEffect(() => {
    isFirstQuestionShown && handlePauseAnalyzingCounter()
  }, [handlePauseAnalyzingCounter, isFirstQuestionShown])

  useEffect(() => {
    isSecondQuestionShown && handlePauseCustomizingCounter()
  }, [handlePauseCustomizingCounter, isSecondQuestionShown])

  useEffect(() => {
    if (!isCustomizingCounterFinished) return

    dispatch(
      setAnswersAction({
        answers: '',
        pageId,
      }),
    )
    dispatch(sendUserAnswersAction())

    eventLogger.logQuestion({
      question: 'Your Personal Relationship Plan is almost ready ...',
      answers: '',
      pageName: pageId,
    })

    goTo(nextPagePath)
  }, [dispatch, isCustomizingCounterFinished, nextPagePath, pageId])

  const firstQuestionOptionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        dispatch(
          setAnswersAction({
            answers: value,
            pageId,
          }),
        )

        eventLogger.logQuestion({
          question: FIRST_QUESTION,
          answers: value,
          pageName: pageId,
        })

        setTimeout(() => {
          handleResumeAnalyzingCounter()
        }, 500)
      },
    }),
    [pageId, dispatch, handleResumeAnalyzingCounter],
  )

  const secondQuestionOptionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        dispatch(
          setAnswersAction({
            answers: value,
            pageId,
          }),
        )

        eventLogger.logQuestion({
          question: SECOND_QUESTION,
          answers: value,
          pageName: pageId,
        })

        setTimeout(() => {
          handleResumeCustomizingCounter()
        }, 500)
      },
    }),
    [pageId, dispatch, handleResumeCustomizingCounter],
  )

  return (
    <>
      {isFirstQuestionShown && (
        <S.Modal>
          <S.ModalContainer>
            <S.ModalTitle>{FIRST_QUESTION}</S.ModalTitle>
            <S.OptionsContainer>
              <Option {...firstQuestionOptionProps} value={OPTIONS_VALUES.yes}>
                <S.QuestionButton>{OPTIONS_VALUES.yes}</S.QuestionButton>
              </Option>
              <Option {...firstQuestionOptionProps} value={OPTIONS_VALUES.no}>
                <S.QuestionButton>{OPTIONS_VALUES.no}</S.QuestionButton>
              </Option>
            </S.OptionsContainer>
          </S.ModalContainer>
        </S.Modal>
      )}
      {isSecondQuestionShown && (
        <S.Modal>
          <S.ModalContainer>
            <S.ModalTitle>{SECOND_QUESTION}</S.ModalTitle>
            <S.OptionsContainer data-is-one-row="true">
              <Option {...secondQuestionOptionProps} value={OPTIONS_VALUES.yes}>
                <S.QuestionButton>{OPTIONS_VALUES.yes}</S.QuestionButton>
              </Option>
              <Option {...secondQuestionOptionProps} value={OPTIONS_VALUES.no}>
                <S.QuestionButton>{OPTIONS_VALUES.no}</S.QuestionButton>
              </Option>
            </S.OptionsContainer>
          </S.ModalContainer>
        </S.Modal>
      )}
      <PageContainer>
        <S.Column>
          <QuestionTitle style={{ marginBottom: '100px' }}>
            Your Personal Relationship Plan is almost ready ...
          </QuestionTitle>
          <S.ProgressBarsList style={{ width: '100%' }}>
            {barsList.map((progressBar) => (
              <S.ProgressContainer key={progressBar.title}>
                <S.ProgressHeader>
                  <S.ProgressTitle>{progressBar.title}</S.ProgressTitle>
                  <S.ProgressPercent>{progressBar.counter}</S.ProgressPercent>
                </S.ProgressHeader>
                <S.ProgressBar
                  max={100}
                  value={progressBar.counter}
                  color={progressBar.color}
                />
              </S.ProgressContainer>
            ))}
          </S.ProgressBarsList>
          <S.Footer>
            <S.Description>
              With the help of your answers we will create a personalized plan
              to improve your relationship.
            </S.Description>
          </S.Footer>
        </S.Column>
      </PageContainer>
    </>
  )
}
