export const getPathFromPageId = ({
  pageId,
  cohort,
  uuid,
  currentSearch,
}: {
  pageId: string
  cohort: string
  uuid: string
  currentSearch: string
}): string => {
  const searchParams = new URLSearchParams(currentSearch)

  searchParams.set('cohort', cohort)
  searchParams.set('uuid', uuid)

  return pageId ? `${pageId}?${searchParams.toString()}` : ''
}
