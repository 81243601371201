import React, { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton2 } from 'components/QuestionButton2'
import { StickyContainer } from 'components/StickyContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { PageId } from 'root-constants/pages'

import { StyledSpiritualConnection as S } from './SpiritualConnection.styles'

const QUESTION = 'How do you and your partner connect on a spiritual level?'
const OPTION_VALUES = {
  religiousServices: 'We attend religious services together',
  meditation: 'We pray or meditate together',
  spiritualTexts: 'We read spiritual texts together',
  deepConversations: 'We have deep conversations about our beliefs',
  timeInNature: 'We spend time in nature',
  other: 'Other',
}

export const SpiritualConnectionVariant1: React.FC<TPageProps> = ({
  pageId,
}) => {
  const { search } = useLocation()
  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath: `${PageId.SPIRITUAL_SUPPORT_1}${search}`,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers([...answers, value])
        } else {
          setAnswers(answers.filter((item) => item !== value))
        }
      },
    }),
    [pageId, answers],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.Title>{QUESTION}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.religiousServices}
            checked={answers.includes(OPTION_VALUES.religiousServices)}
          >
            <QuestionButton2 hasCheckboxIcon>
              {OPTION_VALUES.religiousServices}
            </QuestionButton2>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.meditation}
            checked={answers.includes(OPTION_VALUES.meditation)}
          >
            <QuestionButton2 hasCheckboxIcon>
              {OPTION_VALUES.meditation}
            </QuestionButton2>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.spiritualTexts}
            checked={answers.includes(OPTION_VALUES.spiritualTexts)}
          >
            <QuestionButton2 hasCheckboxIcon>
              {OPTION_VALUES.spiritualTexts}
            </QuestionButton2>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.deepConversations}
            checked={answers.includes(OPTION_VALUES.deepConversations)}
          >
            <QuestionButton2 hasCheckboxIcon>
              {OPTION_VALUES.deepConversations}
            </QuestionButton2>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.timeInNature}
            checked={answers.includes(OPTION_VALUES.timeInNature)}
          >
            <QuestionButton2 hasCheckboxIcon>
              {OPTION_VALUES.timeInNature}
            </QuestionButton2>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.other}
            checked={answers.includes(OPTION_VALUES.other)}
          >
            <QuestionButton2 hasCheckboxIcon>
              {OPTION_VALUES.other}
            </QuestionButton2>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
      <StickyContainer>
        <S.Button
          onClick={() => handleContinue(answers)}
          disabled={!answers.length}
        >
          Continue
        </S.Button>
      </StickyContainer>
    </PageContainer>
  )
}
