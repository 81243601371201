import React, { useMemo } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton2 } from 'components/QuestionButton2'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledQualityTime as S } from './QualityTime.styles'

const QUESTION =
  'How often do you and your partner spend quality time together without any distractions?'
const OPTION_VALUES = {
  daily: 'Daily',
  fewTimesAWeek: 'A few times a week',
  monthly: 'Monthly',
  fewTimesAYear: 'A few times a year',
}

export const QualityTimeVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        {' '}
        <S.QuestionTitle>{QUESTION}</S.QuestionTitle>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.daily}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.daily}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.fewTimesAWeek}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.fewTimesAWeek}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.monthly}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.monthly}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.fewTimesAYear}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.fewTimesAYear}</QuestionButton2>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
