import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ISelectPlanItemProps } from 'modules/subscriptions/types'

import {
  CURRENCY_SYMBOLS,
  TRIAL_DESCRIPTION_CONTEXT,
} from 'root-constants/common'

import { StyledTrialPlanItemVariant1 as S } from './TrialPlanItemVariant1.styles'

export const TrialPlanItemVariant1: React.FC<ISelectPlanItemProps> = ({
  subscription: {
    isDefault,
    currency,
    introDiff,
    mainPrices: { periodName, periodQuantity },
    trialPrices: { fullPrice, durationDays, daily, oldPrices },
  },
  isSelected,
}) => {
  const { t } = useTranslation()

  const oldPrice = useMemo(
    () => (fullPrice + introDiff.discountAmount).toFixed(2),
    [fullPrice, introDiff],
  )

  const oldDailyPrice = useMemo(
    () => oldPrices.beforeCustomDiscount.daily.toFixed(2),
    [oldPrices],
  )

  return (
    <S.PlanItem data-is-selected={isSelected} data-is-default={isDefault}>
      <S.CurrentPriceContainer>
        <S.PlanTitle>
          {t('subscriptions.trialPlanPeriod', {
            periodName,
            periodQuantity,
            trialDays: durationDays,
            context: TRIAL_DESCRIPTION_CONTEXT[durationDays],
          })}
        </S.PlanTitle>
        <div>
          {oldPrice && (
            <S.OldPrice data-is-selected={isSelected}>
              {CURRENCY_SYMBOLS[currency]}
              {oldPrice}
            </S.OldPrice>
          )}

          <S.NewPrice>
            {CURRENCY_SYMBOLS[currency]}
            {fullPrice}
          </S.NewPrice>
        </div>
      </S.CurrentPriceContainer>
      {oldDailyPrice && (
        <S.OldDayPrice data-is-selected={isSelected}>
          {CURRENCY_SYMBOLS[currency]}
          {oldDailyPrice}
        </S.OldDayPrice>
      )}
      {daily && (
        <S.PlanPrice data-is-selected={isSelected}>
          <strong>
            {CURRENCY_SYMBOLS[currency]}
            {daily}
          </strong>
          <S.Period>{t('subscriptions.perDay')}</S.Period>
        </S.PlanPrice>
      )}
    </S.PlanItem>
  )
}
