import styled from 'styled-components'

export const StyledTitleContainer = {
  Container: styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    min-height: 144px;
    margin-bottom: 20px;
    row-gap: 12px;
    width: 100%;
  `,
}
