import { getPurchaseCommonEventParams } from 'modules/payment/helpers/getPurchaseCommonEventParams'

import { IAppState } from 'models/store.model'

export const getPurchaseSuccessEventParams = (state: IAppState) => {
  const {
    email,
    productId,
    mainPrice,
    trialPrice,
    uuid,
    currency,
    screenName,
    stripeAccountId,
    stripeAccountName,
    paymentSystem,
  } = getPurchaseCommonEventParams(state)

  return {
    email,
    productId,
    price: mainPrice,
    trialPrice,
    uuid,
    currency,
    screenName,
    stripeAccountId,
    stripeAccountName,
    paymentSystem,
  }
}
