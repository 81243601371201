import React, { useCallback, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { PageContainer } from 'components/PageContainer'
import { StickyContainer } from 'components/StickyContainer'

import { useUserData } from 'hooks/useUserData'

import { STATUS_TO_MARKUP_MAP } from 'modules/subscriptions/constants'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import {
  DifficultyAreas,
  RelationshipDurationValue,
  StartAgeValue,
  StatusValue,
} from 'root-constants/common'

import { StyledPlan as S } from './Plan.styles'
import { DIFFICULTY_AREAS_MAP, START_AGE_TO_IMAGE_MAP } from './constants'

export const PlanVariant1: React.FC<TPageProps> = ({
  nextPagePath,
  pageId,
}) => {
  const { t } = useTranslation()

  const { relationshipStatus, relationshipDuration, startAgeMix, tags } =
    useUserData()

  const difficultyAreasValue = useMemo(() => {
    const hasTags = (area: DifficultyAreas) =>
      tags.some((tag) => DIFFICULTY_AREAS_MAP[area].includes(tag))

    const values = []

    if (hasTags(DifficultyAreas.INTIMACY)) {
      values.push(t('planV1.difficultyAreas.intimacy'))
    }

    if (hasTags(DifficultyAreas.LACK_OF_TRUST)) {
      values.push(t('planV1.difficultyAreas.lackOfTrust'))
    }

    if (hasTags(DifficultyAreas.SELF_DEVELOPMENT)) {
      values.push(t('planV1.difficultyAreas.selfDevelopment'))
    }

    if (hasTags(DifficultyAreas.FUTURE_AND_FAMILY)) {
      values.push(t('planV1.difficultyAreas.futureAndFamily'))
    }

    if (hasTags(DifficultyAreas.TOXIC_BEHAVIOR)) {
      values.push(t('planV1.difficultyAreas.toxicBehavior'))
    }

    !values.length &&
      values.push(
        t('planV1.difficultyAreas.intimacy'),
        t('planV1.difficultyAreas.lackOfTrust'),
        t('planV1.difficultyAreas.toxicBehavior'),
      )

    return values.join(', ')
  }, [tags])

  const plans = useMemo(
    () => [
      {
        id: 'status',
        name: t('planV1.status'),
        value:
          STATUS_TO_MARKUP_MAP[relationshipStatus] ||
          STATUS_TO_MARKUP_MAP[StatusValue.SERIOUS],
        iconName: 'heart',
      },
      {
        id: 'together',
        name: t('planV1.together'),
        value: relationshipDuration || RelationshipDurationValue.MORE_4_YEARS,
        iconName: 'time',
      },
      {
        id: 'difficultyAreas',
        name: t('planV1.difficultyAreas.title'),
        value: difficultyAreasValue,
        iconName: 'warning',
      },
    ],
    [relationshipStatus, relationshipDuration, difficultyAreasValue],
  )

  useEffect(() => {
    eventLogger.logPlan1PageShow({ pageName: pageId })
  }, [pageId])

  const handleContinue = useCallback(() => {
    eventLogger.logPlan1PageCompleted({ pageName: pageId })
    goTo(nextPagePath)
  }, [nextPagePath, pageId])

  return (
    <PageContainer>
      <S.Column>
        <S.Title>
          <Trans i18nKey="planV1.title" />
        </S.Title>
        <S.PlanContainer>
          <S.PlanList>
            {plans.map(({ id, name, value, iconName }) => (
              <S.Plan key={id} data-icon={iconName}>
                <S.PlanName>{name}</S.PlanName>
                <S.PlanValue>{value}</S.PlanValue>
              </S.Plan>
            ))}
          </S.PlanList>
          <S.ImageContainer data-start-age={startAgeMix}>
            <img
              src={
                START_AGE_TO_IMAGE_MAP[startAgeMix] ||
                START_AGE_TO_IMAGE_MAP[StartAgeValue.FIRST_AGE_RANGE]
              }
              alt="couple"
            />
          </S.ImageContainer>
        </S.PlanContainer>
        <StickyContainer>
          <S.Button onClick={handleContinue}>{t('actions.continue')}</S.Button>
        </StickyContainer>
      </S.Column>
    </PageContainer>
  )
}
