import React, { FC, ReactNode, useMemo } from 'react'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import {
  HEADER_HEIGHT,
  HEADER_WITH_PROGRESS_BAR_HEIGHT,
} from 'root-constants/common'

import { StyledPageContainer as S } from './PageContainer.styles'

type TProps = {
  children: ReactNode
  className?: string
}

export const PageContainer: FC<TProps> = ({ children, className }) => {
  const { hasHeader, hasProgressBar } = useGetPageInfo()

  const paddingTop = useMemo(() => {
    if (hasHeader && hasProgressBar) {
      return HEADER_WITH_PROGRESS_BAR_HEIGHT
    }

    if (hasHeader) {
      return HEADER_HEIGHT
    }

    return 0
  }, [hasHeader, hasProgressBar])

  return (
    <S.Container className={className} paddingTop={paddingTop}>
      {children}
    </S.Container>
  )
}
