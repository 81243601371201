import React from 'react'

export const benefits = {
  subtitle: (
    <>
      What you get with <span>UpLuv</span>
    </>
  ),
  list: [
    {
      id: 1,
      text: (
        <>
          <span>500+&nbsp;</span>conversations starters
        </>
      ),
    },
    {
      id: 2,
      text: (
        <>
          <span>100+&nbsp;</span>couple quizzes
        </>
      ),
    },
    {
      id: 3,
      text: (
        <>
          <span>100+&nbsp;</span>couple games
        </>
      ),
    },
    {
      id: 4,
      text: 'Personalized tests to detect your relationship problems',
    },
    {
      id: 5,
      text: 'An expert guidance & relationship exercises to a healthier, happier love',
    },
  ],
}

export const CANCEL_OFFER_V1_HEADER = 83

export const enum SubscriptionButtonText {
  GET_PLAN_WITH_TIMER = 'get_plan_with_timer',
  GET_PLAN_UPPER = 'get_plan_upper',
  GET_PLAN_MIDDLE = 'get_plan_middle',
  GET_PLAN_BOTTOM = 'get_plan_bottom',
}
