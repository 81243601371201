import React, { useEffect } from 'react'

import { CONTENT } from 'pages/plan-processing/constants'
import Swiper, { Autoplay } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { useCounter } from 'hooks/useCounter'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'

import { StyledPlanProcessing as S } from './PlanProcessing.styles'

Swiper.use([Autoplay])

export const PlanProcessingVariant4: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { linearCounterValue, isLinearFinished } = useCounter(true, {
    duration: 16000,
  })

  useEffect(() => {
    if (!isLinearFinished) return

    eventLogger.logQuestion({
      question: 'Creating your plan',
      answers: '',
      pageName: pageId,
    })

    goTo(nextPagePath)
  }, [isLinearFinished, nextPagePath, pageId])

  return (
    <S.Wrapper2>
      <S.Column2>
        <S.CarouselContainer2>
          <Carousel
            spaceBetween={16}
            slidesPerView={1}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
          >
            {CONTENT.map(({ title, color, picture }) => (
              <SwiperSlide key={picture}>
                <S.ProgressTitle3 color={color}>{title}</S.ProgressTitle3>
                <S.Image>
                  <img src={picture} alt="plan-processing" />
                </S.Image>
              </SwiperSlide>
            ))}
          </Carousel>
        </S.CarouselContainer2>
        <S.ProgressContent>
          <S.ProgressInfo>
            <S.ProgressPlan>Creating your plan</S.ProgressPlan>
            <S.ProgressPercent>{linearCounterValue}</S.ProgressPercent>
          </S.ProgressInfo>
          <S.Progress max={100} value={linearCounterValue} />
        </S.ProgressContent>
      </S.Column2>
    </S.Wrapper2>
  )
}
