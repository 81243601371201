import styled from 'styled-components'

import { BenefitsV1 } from 'components/Benefits'
import { Button } from 'components/Button'

import { baseColumnStyles } from 'common-styles'
import { BIG_COLUMN_WIDTH, Color, MAX_PHONE_WIDTH } from 'root-constants/common'

export const StyledSexImproveVariant1 = {
  Column: styled.div`
    ${baseColumnStyles};
    padding: 20px 0;
    width: 100%;

    @media (min-width: ${MAX_PHONE_WIDTH}px) {
      width: ${BIG_COLUMN_WIDTH}px;
    }
  `,
  Title: styled.h1`
    color: ${Color.LILAC_120};
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    margin: 0 0 40px 0;
    padding: 0 20px;
    text-align: center;
  `,
  BannerContainer: styled.div`
    aspect-ratio: 375/146;
    margin: 0 0 32px 0;
    width: 100%;

    img {
      width: 100%;
    }
  `,
  BenefitsList: styled(BenefitsV1)`
    margin: 0 auto 88px;
    padding: 0 40px;
  `,
  Button: styled(Button)`
    --next-page-button-border-radius: 100px;
    --next-page-button-height: 62px;
  `,
}
