import styled, { css } from 'styled-components'

import { Button } from 'components/Button'

import { baseColumnStyles } from 'common-styles'
import { BIG_COLUMN_WIDTH, Color } from 'root-constants/common'

type TProps = {
  marginBottom?: number
}

const commonStrongStyles = css`
  color: ${Color.LILAC_130};
  font-weight: 800;
`

const commonTextStyles = css`
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: ${Color.LILAC_120};
`

export const StyledRestrictive = {
  Column: styled.div`
    ${baseColumnStyles};
    width: ${BIG_COLUMN_WIDTH}px;
    padding: 20px 20px 20px 20px;
  `,
  Title: styled.h1`
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: ${Color.LILAC_120};
    margin: 0 0 24px 0;

    strong {
      ${commonStrongStyles}
    }
  `,
  Subtitle: styled.p<TProps>`
    ${commonTextStyles};
    margin-bottom: ${({ marginBottom = 32 }) => marginBottom}px;

    strong {
      ${commonStrongStyles}
    }
  `,
  GraphContainer: styled.div`
    aspect-ratio: 311/220;
    margin: 0 0 32px 0;
    width: 100%;
  `,
  GraphContainerV2: styled.div`
    aspect-ratio: 327 / 220;
    margin: 0 0 32px 0;
    width: 100%;
  `,
  GraphDescription: styled.p`
    ${commonTextStyles};
    padding: 0 4px 0 4px;
    margin: 0 0 88px 0;

    strong {
      ${commonStrongStyles}
    }
  `,
  Button: styled(Button)`
    --next-page-button-border-radius: 100px;
    --next-page-button-height: 62px;
  `,
}
