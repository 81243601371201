import React from 'react'

import progressBarImage1 from 'assets/images/progressive-bar-1.png'
import progressBarImage2 from 'assets/images/progressive-bar-2.png'
import progressBarImage3 from 'assets/images/progressive-bar-3.png'
import progressBarImage4 from 'assets/images/progressive-bar-4.png'
import progressBarImage5 from 'assets/images/progressive-bar-5.png'

export const CONTENT = [
  {
    title: (
      <>
        Explore shared <span>dreams and conversations</span> that strengthen
        your love
      </>
    ),
    color: '#F1C1B6',
    picture: progressBarImage1,
  },
  {
    title: (
      <>
        Ignite the spark with <span>games</span> crafted for passion
      </>
    ),
    color: '#F1C1B6',
    picture: progressBarImage2,
  },
  {
    title: (
      <>
        Confidently navigate <span>sensitive topics</span>
      </>
    ),
    color: '#BCEAFF',
    picture: progressBarImage3,
  },
  {
    title: (
      <>
        <span>Personalized tests</span> illuminate your path to a deeper,
        lasting connection
      </>
    ),
    color: '#FFFDD9',
    picture: progressBarImage4,
  },
  {
    title: (
      <>
        Unlock an <span>expert guidance to</span> a healthier, happier love
      </>
    ),
    color: '#FFF2E7',
    picture: progressBarImage5,
  },
]

export const FIRST_QUESTION =
  'Do you feel comfortable discussing sexual topics with your partner?'
export const SECOND_QUESTION =
  'Are you willing to try new things sexually with your partner?'
export const OPTIONS_VALUES = {
  yes: 'Yes',
  no: 'No',
}

export const REVIEWS = [
  {
    author: 'Lisa S., 47',
    review: `"I never realized how much our lack of intimacy was affecting our relationship
             until we started using this plan. It's made all the difference."
            `,
  },
  {
    author: 'Alex T., 43',
    review: `"My partner and I were struggling to find a balance between physical and emotional intimacy,
             but this plan helped us achieve that. Our relationship is now more fulfilling than ever."
            `,
  },
  {
    author: 'Jenna R., 32',
    review: `"I was hesitant to invest in this plan, but it was
              worth every penny. It's transformed our 
              relationship and brought us closer than ever
              before."
            `,
  },
  {
    author: 'Sarah, 29',
    review: `"Our relationship was in a rut until we tried
            this plan. Now we're closer than ever!"
            `,
  },
  {
    author: 'Mark, 35',
    review: `"I'm so glad I stumbled upon this plan. It gave
            my girlfriend and me the tools we needed to 
            overcome our intimacy issues and strengthen
            our bond."
            `,
  },
]
