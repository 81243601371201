import styled, { keyframes } from 'styled-components'

const blink = keyframes`
  0% {
    opacity: 0.4;
    transform: scale(1, 1);
  }
  
  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }
  
  100% {
    opacity: 0.4;
    transform: scale(1, 1);
  }
`

export const StyledBlinkingDots = {
  Dot: styled.span`
    animation-name: ${blink};
    animation-duration: 1s;
    animation-iteration-count: infinite;
    &:nth-child(2) {
      animation-delay: 0.15s;
    }
    &:nth-child(3) {
      animation-delay: 0.3s;
    }
  `,
}
