import React, { useMemo, useState } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton2 } from 'components/QuestionButton2'
import { StickyContainer } from 'components/StickyContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledSpark as S } from './Spark.styles'

const QUESTION =
  'How do you and your partner keep the spark alive in your relationship?'
const OPTION_VALUES = {
  intimacy: 'Through intimacy and affection',
  dates: 'Regular date nights or outings',
  timeTogether: 'Through spending quality time together',
  expressingAppreciation: 'Through expressing appreciation and affection',
  other: 'Other',
}

export const SparkVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers([...answers, value])
        } else {
          setAnswers(answers.filter((item) => item !== value))
        }
      },
    }),
    [pageId, answers],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.QuestionTitle>{QUESTION}</S.QuestionTitle>
        <StyledOption.OptionsContainer>
          <Option {...optionProps} value={OPTION_VALUES.intimacy}>
            <QuestionButton2 hasCheckboxIcon>
              {OPTION_VALUES.intimacy}
            </QuestionButton2>
          </Option>
          <Option {...optionProps} value={OPTION_VALUES.dates}>
            <QuestionButton2 hasCheckboxIcon>
              {OPTION_VALUES.dates}
            </QuestionButton2>
          </Option>
          <Option {...optionProps} value={OPTION_VALUES.timeTogether}>
            <QuestionButton2 hasCheckboxIcon>
              {OPTION_VALUES.timeTogether}
            </QuestionButton2>
          </Option>
          <Option {...optionProps} value={OPTION_VALUES.expressingAppreciation}>
            <QuestionButton2 hasCheckboxIcon>
              {OPTION_VALUES.expressingAppreciation}
            </QuestionButton2>
          </Option>
          <Option {...optionProps} value={OPTION_VALUES.other}>
            <QuestionButton2 hasCheckboxIcon>
              {OPTION_VALUES.other}
            </QuestionButton2>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
      <StickyContainer>
        <S.Button
          onClick={() => handleContinue(answers)}
          disabled={!answers.length}
        >
          Continue
        </S.Button>
      </StickyContainer>
    </PageContainer>
  )
}
