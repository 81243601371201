export const STORIES_LIST = [
  {
    user: 'paymentTrialV1.successStories.story1.user',
    story: 'paymentTrialV1.successStories.story1.text',
  },
  {
    user: 'paymentTrialV1.successStories.story2.user',
    story: 'paymentTrialV1.successStories.story2.text',
  },
  {
    user: 'paymentTrialV1.successStories.story3.user',
    story: 'paymentTrialV1.successStories.story3.text',
  },
]
