import React, { useMemo } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton2 } from 'components/QuestionButton2'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useGetFlowGroupInfo } from 'hooks/useGetFlowGroupInfo'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledChallenge as S } from './Challenge.styles'

const QUESTION =
  'What do you feel is the biggest challenge in your emotional connection with your partner?'
const OPTION_VALUES = {
  expressing: 'Difficulty expressing emotions',
  struggleToConnect: 'Struggle to connect on a deeper level',
  empathy: 'Lack of understanding or empathy',
  barriers: 'Communication barriers or misunderstandings',
  hurts: 'Trust issues or past hurts',
  balancing: 'Balancing individual needs and priorities',
}

export const ChallengeVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
  alternativePagePath,
}) => {
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()
  const { isMultipleFlow } = useGetFlowGroupInfo()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath: isMultipleFlow ? alternativePagePath : nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.QuestionTitle>{QUESTION}</S.QuestionTitle>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.expressing}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.expressing}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.struggleToConnect}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.struggleToConnect}</QuestionButton2>{' '}
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.empathy}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.empathy}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.barriers}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.barriers}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.hurts}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.hurts}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.balancing}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.balancing}</QuestionButton2>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
