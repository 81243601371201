import styled from 'styled-components'

import { QuestionButton } from 'components/QuestionButton'

import { QuestionTitle, baseColumnStyles } from 'common-styles'

export const StyledAge = {
  Column: styled.div`
    ${baseColumnStyles};
    padding: 40px 0;
  `,
  Title: styled(QuestionTitle)`
    line-height: 28px;
    margin-bottom: 24px;
  `,
  TitleVariant2: styled(QuestionTitle)`
    line-height: 28px;
    margin-bottom: 108px;
  `,
  QuestionButton: styled(QuestionButton)`
    height: 56px;
    line-height: 56px;
    border-radius: 30px;
  `,
}
