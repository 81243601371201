import React from 'react'
import { Trans } from 'react-i18next'

import left from 'assets/images/laurel-left.svg'
import right from 'assets/images/laurel-right.svg'

import { StyledAward as S } from './Award.styles'

type TProps = {
  className?: string
}

export const Award: React.FC<TProps> = ({ className }) => (
  <S.Container className={className}>
    <S.Border>
      <img src={left} alt="left-laurel" />
    </S.Border>
    <S.Title>
      <Trans i18nKey="commonComponents.awardTitle" />
    </S.Title>
    <S.Border>
      <img src={right} alt="right-laurel" />
    </S.Border>
  </S.Container>
)
