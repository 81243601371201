import styled from 'styled-components'

import { QuestionTitle, baseColumnStyles } from 'common-styles'

export const StyledIntimacyProblems = {
  Column: styled.div`
    ${baseColumnStyles};
    padding: 54px 0;
  `,
  ColumnVariant2: styled.div`
    ${baseColumnStyles};
    padding: 40px 0;
  `,
  QuestionTitle: styled(QuestionTitle)`
    line-height: 28px;
    margin-bottom: 53px;

    @media (min-width: 350px) {
      width: 342px;
    }
  `,
  QuestionTitleVariant2: styled(QuestionTitle)`
    line-height: 28px;
    margin-bottom: 24px;
  `,
}
