import styled from 'styled-components'

import { Button } from 'components/Button'
import { QuestionButton } from 'components/QuestionButton'
import { SvgImage } from 'components/SvgImage'

import { baseColumnStyles } from 'common-styles'
import { Color } from 'root-constants/common'

export const StyledIntimacyArea = {
  Column: styled.div`
    ${baseColumnStyles};
    padding-bottom: 100px;
  `,
  HeaderContainer: styled.div`
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 11px 0 6px;
    z-index: 1;
  `,
  Logo: styled(SvgImage)`
    fill: var(--header-logo-color);
  `,
  ImageContainer: styled.div`
    display: flex;
    justify-content: center;
    margin: 34px 0 30px;
  `,
  Title: styled.h2`
    margin: 0 -10px 24px;
    font-weight: 700;
    font-size: 26px;
    line-height: 35px;
    color: ${Color.LILAC_120};
    text-align: center;
  `,
  OptionTitle: styled.div`
    margin-bottom: 17px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: rgba(237, 237, 255, 0.5);
  `,
  OptionContainer: styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 64px);
    grid-gap: 19px;
    margin-bottom: 10px;
  `,
  QuestionButton: styled(QuestionButton)`
    justify-content: center;
    height: 64px;
    background-color: rgba(237, 237, 255, 0.9);
    border: 4px solid transparent;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    input:checked + & {
      background-color: rgba(237, 237, 255, 0.9);
      border: 4px solid #9361ff;
    }
  `,
  StickyButtonContainer: styled.div`
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 20px 0;
    background: linear-gradient(180deg, rgba(2, 2, 11, 0) 0%, #070525 29.69%);
    z-index: 1;
  `,
  Button: styled(Button)`
    height: 62px;
    background-color: #c5aaff;

    &:disabled {
      background-color: #897dc1;
    }
  `,
}
