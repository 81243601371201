import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton2 } from 'components/QuestionButton2'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { PageId } from 'root-constants/pages'

import { StyledTrustAfterInfidelity as S } from './TrustAfterInfidelity.styles'

const QUESTION = 'How do you rebuild trust after infidelity occurs?'
const OPTION_VALUES = {
  stillWorking: `We're still working on it`,
  openCommunication: 'Open communication and transparency',
  therapy: 'Couples therapy and counseling',
  patience: 'Patience and forgiveness',
  rebuildingEmotional: 'Rebuilding emotional and physical intimacy',
}

export const TrustAfterInfidelityVariant1: React.FC<TPageProps> = ({
  pageId,
}) => {
  const { search } = useLocation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath: `${PageId.JEALOUSY_1}${search}`,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.Title>{QUESTION}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.stillWorking}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.stillWorking}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.openCommunication}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.openCommunication}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.therapy}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.therapy}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.patience}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.patience}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.rebuildingEmotional}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>
              {OPTION_VALUES.rebuildingEmotional}
            </QuestionButton2>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
