import React, { useMemo } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton2 } from 'components/QuestionButton2'
import { TitleContainer } from 'components/TitleContainer'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useGetFlowGroupInfo } from 'hooks/useGetFlowGroupInfo'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledCommonEmotions as S } from './CommonEmotions.styles'

const QUESTION =
  'What are the most common emotions you feel when interacting with your partner?'
const OPTION_VALUES = {
  loveAndAffection: 'Love and affection',
  frustrationAndAnnoyance: 'Frustration and annoyance',
  gratitudeAndAppreciation: 'Gratitude and appreciation',
  indifferenceAndBoredom: 'Indifference and boredom',
}

export const CommonEmotionsVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
  alternativePagePath,
}) => {
  const { isMultipleFlow } = useGetFlowGroupInfo()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath: isMultipleFlow ? alternativePagePath : nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <TitleContainer title={<S.Title>{QUESTION}</S.Title>} />
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.loveAndAffection}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.loveAndAffection}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.frustrationAndAnnoyance}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>
              {OPTION_VALUES.frustrationAndAnnoyance}
            </QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.gratitudeAndAppreciation}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>
              {OPTION_VALUES.gratitudeAndAppreciation}
            </QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.indifferenceAndBoredom}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>
              {OPTION_VALUES.indifferenceAndBoredom}
            </QuestionButton2>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
