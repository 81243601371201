import React, { useMemo } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton2 } from 'components/QuestionButton2'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useGetFlowGroupInfo } from 'hooks/useGetFlowGroupInfo'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledSpiritualChallenges as S } from './SpiritualChallenges.styles'

const QUESTION =
  'What challenges have you faced in your spiritual connection with your partner?'
const OPTION_VALUES = {
  differencesInBeliefs: 'Differences in beliefs or practices',
  lackOfTime: 'Lack of time to devote to spiritual practices',
  difficultyCommonGround: 'Difficulty finding common ground',
  externalFactors:
    'External factors (e.g. family pressure, societal expectations)',
  other: 'Other',
}

export const SpiritualChallengesVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
  alternativePagePath,
}) => {
  const { isMultipleFlow } = useGetFlowGroupInfo()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath: isMultipleFlow ? alternativePagePath : nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.Title>{QUESTION}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.differencesInBeliefs}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>
              {OPTION_VALUES.differencesInBeliefs}
            </QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.lackOfTime}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.lackOfTime}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.difficultyCommonGround}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>
              {OPTION_VALUES.difficultyCommonGround}
            </QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.externalFactors}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.externalFactors}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.other}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.other}</QuestionButton2>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
