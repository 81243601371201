import styled from 'styled-components'

import { Color } from 'root-constants/common'

export const StyledReviewCard = {
  Container: styled.div`
    width: 100%;
    height: 185px;
    max-width: 315px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px;
    border-radius: 20px;
    background-color: ${Color.WHITE_100};
  `,
  UserBlock: styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 0 4px 0;

    > :not(:last-child) {
      margin: 0 10px 0 0;
    }
  `,
  UserAvatar: styled.div`
    aspect-ratio: 280/80;
    width: 30px;
    height: auto;
  `,
  UserName: styled.span`
    color: ${Color.LIGHT_TEXT};
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
  `,
  Rating: styled.div`
    display: flex;
    margin: 0 0 8px 0;
    aspect-ratio: 280/16;
    height: 16px;
    width: 96px;
  `,
  ReviewText: styled.span`
    color: ${Color.DEFAULT_TEXT};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  `,
}
