import { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectFacebookPixelId } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

export const useFacebookPixelInitialization = (): void => {
  const uuid = useSelector(selectUUID)
  const facebookPixelId = useSelector(selectFacebookPixelId)

  useLayoutEffect(() => {
    if (!window.fbq || !facebookPixelId || !uuid) return
    window.fbq('init', facebookPixelId, { external_id: uuid })
    window.fbq('track', 'PageView')
  }, [facebookPixelId, uuid])
}
