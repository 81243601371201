import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectCurrentVariantSteps } from 'root-redux/selects/common'
import { selectUserStatus } from 'root-redux/selects/user'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { PageId } from 'root-constants/pages'

export const useUserStatus = (): string => {
  const steps = useSelector(selectCurrentVariantSteps)
  const userStatus = useSelector(selectUserStatus)

  const { currentSubscriptionPageId } = useGetPageInfo()

  return useMemo(() => {
    if (userStatus?.account.hasAccount) {
      return PageId.DOWNLOAD
    }

    if (userStatus?.hasSubscription) {
      return PageId.ACCOUNT
    }

    if (userStatus?.email.hasEmail) {
      return currentSubscriptionPageId || steps[0].id
    }

    return ''
  }, [
    currentSubscriptionPageId,
    steps,
    userStatus?.account.hasAccount,
    userStatus?.email.hasEmail,
    userStatus?.hasSubscription,
  ])
}
