import React from 'react'

import { CheckboxOptionsPage } from 'pages/options-page'

import { TPageProps } from 'models/common.model'

import { CUSTOM_OPTIONS, OPTIONS, TRANSLATION_FAMILY } from './constants'

export const TagsVariant2: React.FC<TPageProps> = (props) => (
  <CheckboxOptionsPage
    {...props}
    hasTags
    options={[...OPTIONS, ...CUSTOM_OPTIONS]}
    customOptions={CUSTOM_OPTIONS}
    translationFamily={TRANSLATION_FAMILY}
  />
)
