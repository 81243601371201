import styled from 'styled-components'

import { Button } from 'components/Button'
import { QuestionButton2 } from 'components/QuestionButton2'

import { QuestionTitle, baseColumnStyles } from 'common-styles'

export const StyledGratitude = {
  Column: styled.div`
    ${baseColumnStyles};
    padding: 40px 0 70px;
  `,
  QuestionTitle: styled(QuestionTitle)`
    line-height: 28px;
    margin-bottom: 52px;

    @media (min-width: 360px) {
      width: 340px;
    }
  `,
  Button: styled(Button)`
    height: 62px;
  `,
  QuestionButton: styled(QuestionButton2)`
    input:disabled + & {
      background-color: rgba(217, 216, 246, 0.5);
    }
  `,
}
