import styled from 'styled-components'

import { QuestionTitle, baseColumnStyles } from 'common-styles'

export const StyledMainGoals = {
  Column: styled.div`
    ${baseColumnStyles};
    padding: 40px 0;
  `,
  QuestionTitle: styled(QuestionTitle)`
    line-height: 29px;
    margin-bottom: 25px;

    @media (min-height: 640px) {
      margin-bottom: 99px;
    }
  `,
  QuestionTitleVariant2: styled(QuestionTitle)`
    line-height: 29px;
    margin-bottom: 52px;
  `,
}
