import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { OptionType } from 'components/Option'
import { PageContainer } from 'components/PageContainer'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledWhatYouSeeVariant1 as S } from './WhatYouSee.styles'
import { WHAT_YOU_SEE_PAGE_MAP } from './constants'

export const WhatYouSeeVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { handleChange } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.whatYouSeeV1.title', { lng: 'en' }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
      },
    }),
    [pageId, handleChange, handleContinue],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.Title>{t('onboarding.whatYouSeeV1.title')}</S.Title>
        <S.ImageContainer>
          <img src={WHAT_YOU_SEE_PAGE_MAP[pageId].imgSrc} alt="what you see" />
        </S.ImageContainer>
        <S.OptionsContainer>
          {WHAT_YOU_SEE_PAGE_MAP[pageId].options.map((item) => (
            <S.Option key={item.value} {...optionProps} value={item.value}>
              <S.QuestionButton>{t(item.title)}</S.QuestionButton>
            </S.Option>
          ))}
        </S.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
