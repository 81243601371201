import styled from 'styled-components'

import { QuestionTitle, baseColumnStyles } from 'common-styles'

export const StyledThingsToBeFun = {
  Column: styled.div`
    ${baseColumnStyles};
    padding: 15px 0 100px;
  `,
  QuestionTitle: styled(QuestionTitle)`
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 12px;

    @media (min-width: 350px) {
      width: 340px;
    }
  `,
}
