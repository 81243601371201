import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Option, OptionType } from 'components/Option'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton2 } from 'components/QuestionButton2'
import { TitleContainer } from 'components/TitleContainer'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledOptionsPage as S } from './OptionsPage.styles'

type TOption = {
  key: string
  value: string
}

type TProps = {
  options: TOption[]
  translationFamily: string
} & TPageProps

export const RadioOptionsPage: React.FC<TProps> = ({
  pageId,
  nextPagePath,
  options,
  translationFamily,
}) => {
  const { t, i18n } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: t(`${translationFamily}.question`, { lng: 'en' }),
    nextPagePath,
  })

  const hasDescriptionKey = useMemo(
    () => i18n.exists(`${translationFamily}.description`),
    [translationFamily],
  )

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <TitleContainer
          title={
            <S.QuestionTitle>
              <Trans i18nKey={`${translationFamily}.question`} />
            </S.QuestionTitle>
          }
          subtitle={
            hasDescriptionKey && (
              <S.QuestionDescription>
                <Trans i18nKey={`${translationFamily}.description`} />
              </S.QuestionDescription>
            )
          }
        />
        <S.RadioOptionsContainer>
          {options.map(({ key, value }) => (
            <Option
              {...optionProps}
              key={key}
              value={value}
              disabled={isAnswersDisabled}
            >
              <QuestionButton2>
                {t(`${translationFamily}.options.${key}`)}
              </QuestionButton2>
            </Option>
          ))}
        </S.RadioOptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
