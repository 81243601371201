import { userApi } from 'api'

import {
  setAnswersFromBackendAction,
  setErrorAction,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import { selectAnswers, selectUtmTags } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { getUserStatusFromRawUserStatus } from 'helpers/getUserStatusFromRawUserStatus'

import { TAnswers } from 'models/common.model'
import { IAction, IAppState, TAppDispatchThunk } from 'models/store.model'
import { IGetUUIDResponseRaw, IUserStatus } from 'models/user.model'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import {
  COMMON_ERROR,
  CUSTOM_TOKEN_LOCAL_STORAGE_KEY,
  HAVE_ACCOUNT_ERROR,
} from 'root-constants/common'

const MODULE_NAME = 'USER'

export const SET_UUID = `${MODULE_NAME}/SET_UUID`
export const GET_STATUS = `${MODULE_NAME}/GET_STATUS`
export const SET_STATUS = `${MODULE_NAME}/SET_STATUS`
export const SEND_USER_ANSWERS = `${MODULE_NAME}/SEND_USER_ANSWERS`
export const SEND_USER_EMAIL = `${MODULE_NAME}/SEND_USER_EMAIL`
export const SEND_USER_CONFIG = `${MODULE_NAME}/SEND_USER_CONFIG`
const BIND_USER = `${MODULE_NAME}/BIND_USER`

export function setUUIDAction(uuid: string): IAction<string> {
  return {
    type: SET_UUID,
    payload: uuid,
  }
}

export function setUserStatusAction(
  userStatus: IUserStatus,
): IAction<IUserStatus> {
  return {
    type: SET_STATUS,
    payload: userStatus,
  }
}

export const setUpUUIDAction =
  ({
    cohort,
    uuid: uuidFromUrl,
    utmTags: utmTagsFromUrl,
    giaApiKey,
  }: {
    cohort: string
    uuid: string | null
    utmTags: Record<string, string> | null
    giaApiKey: string
  }): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuidFromStore = selectUUID(state)
    const uuid = uuidFromUrl || uuidFromStore
    const utmTagsFromStore = selectUtmTags(state)
    const utmTags = utmTagsFromUrl || utmTagsFromStore

    if (uuid) {
      dispatch(setUUIDAction(uuid))
      return
    }

    const response = await userApi.getUUID({
      cohort,
      appId: giaApiKey,
      locale: 'en',
      utm: utmTags,
    })

    if (response.success) {
      const responseUUID = (response.data as IGetUUIDResponseRaw).value
      dispatch(setUUIDAction(responseUUID))
    } else {
      console.error('error')
    }
  }

export function bindUserAction(token: string): any {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)

    dispatch(startFetching(BIND_USER))

    const response = await userApi.bindUser({ token, uuid })

    if (response.success && response.data) {
      response.data.custom_token &&
        localStorage.setItem(
          CUSTOM_TOKEN_LOCAL_STORAGE_KEY,
          response.data.custom_token,
        )

      const userStatusResponse = await userApi.getUserStatus(uuid)
      const userStatus = getUserStatusFromRawUserStatus(
        userStatusResponse.data.state,
      )

      dispatch(setUserStatusAction(userStatus))
      eventLogger.logAccountCreated({
        method: userStatus.account.loginMethod,
      })
    } else {
      eventLogger.logAccountCreationFailed({ error: response?.data?.error })

      if (response.status === 409) {
        const errorMessage = response?.data?.error || ''
        const message = errorMessage.toLowerCase().includes('subscription')
          ? 'Error: You already have a email. Your subscriptions was refunded.'
          : 'Error: You already have an account.'

        dispatch(setErrorAction(message))
        dispatch(stopFetching(BIND_USER))
        return
      }

      dispatch(setErrorAction('Error: Something went wrong.'))
    }

    dispatch(stopFetching(BIND_USER))
  }
}

export function getUserStatusAction(uuid: string): any {
  return async (dispatch: TAppDispatchThunk<any>) => {
    dispatch(startFetching(GET_STATUS))

    const response = await userApi.getUserStatus(uuid)

    if (response.success && response.data) {
      const { state } = response.data

      const userStatus = getUserStatusFromRawUserStatus(state)
      dispatch(setUserStatusAction(userStatus))
      dispatch(setAnswersFromBackendAction(userStatus.onboarding))
    }

    if (!response.success && response.status === 404) {
      const { search } = window.location
      const urlParams = new URLSearchParams(search)
      urlParams.delete('uuid')

      // use window.location.search to reload the page with updated query params
      window.location.search = `?${urlParams}`
    }

    dispatch(stopFetching(GET_STATUS))
  }
}

export const sendUserAnswersAction =
  (isFinished = false): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const answers = selectAnswers(state) as TAnswers
    const uuid = selectUUID(state)

    dispatch(startFetching(SEND_USER_ANSWERS))

    const response = await userApi.saveUserAnswers({
      uuid,
      answers,
      isFinished,
    })

    if (!response.success) {
      dispatch(setErrorAction(COMMON_ERROR))
    }

    dispatch(stopFetching(SEND_USER_ANSWERS))
  }

export const sendUserConfigAction =
  (
    config: Record<string, string | number | boolean>,
    unsuccessCallback?: (() => void) | null,
  ): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)

    dispatch(startFetching(SEND_USER_CONFIG))

    const response = await userApi.saveUserConfig({
      uuid,
      config,
    })

    if (!response.success) {
      dispatch(setErrorAction(COMMON_ERROR))
      unsuccessCallback && unsuccessCallback()
    }

    dispatch(stopFetching(SEND_USER_CONFIG))
  }

export const sendUserEmailAction =
  ({
    email,
    unsuccessCallback,
    nextPagePath,
    goal,
  }: {
    email: string
    unsuccessCallback: () => void
    nextPagePath: string
    goal?: string
  }): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)

    dispatch(startFetching(SEND_USER_EMAIL))

    const response = await userApi.saveUserEmail({
      uuid,
      email,
    })

    if (!response.success) {
      if (response.status === 409) {
        dispatch(setErrorAction(HAVE_ACCOUNT_ERROR))
      } else {
        dispatch(setErrorAction(COMMON_ERROR))
      }

      eventLogger.logEmailPageError({ error: JSON.stringify(response.data) })
      unsuccessCallback()
      dispatch(stopFetching(SEND_USER_EMAIL))
      return
    }

    eventLogger.logEmailPageCompleted({ email, goal })
    window.fbq('track', 'Lead', {}, { eventID: uuid })

    !!nextPagePath && goTo(nextPagePath)
    dispatch(getUserStatusAction(uuid))
    dispatch(stopFetching(SEND_USER_EMAIL))
  }
