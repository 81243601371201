import styled from 'styled-components'

import { Color } from 'root-constants/common'

type TProps = {
  color?: string
}

export const StyledSatisfactionBlockV1 = {
  Block: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    column-gap: 12px;
  `,
  Column: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  Label: styled.p<TProps>`
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    text-align: center;
    color: ${({ color }) => color || Color.PATTENS_BLUE};
  `,
  Card: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${Color.LILAC_110};
    border-radius: 16px;
    padding: 16px;
  `,
  CardTitle: styled.p`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${Color.BLUE_100};
    margin: 0 0 12px 0;
  `,
  ScaleContainer: styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
  `,
  ScaleDivision: styled.div<TProps>`
    width: 22px;
    height: 6px;
    border-radius: 12px;
    background-color: ${({ color }) => color || Color.WHITE_110};
  `,
}
