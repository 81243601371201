import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { PaymentRequest } from '@stripe/stripe-js/types/stripe-js/payment-request'

import { resetErrorAction } from 'root-redux/actions/common'

import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useUserData } from 'hooks/useUserData'

import { PaymentMethod } from 'modules/payment/constants'
import {
  purchaseAction,
  setIsPaymentFlowsShownAction,
} from 'modules/payment/redux/actions'
import {
  selectCurrency,
  selectSubscriptionMainPrice,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialPrice,
} from 'modules/payment/redux/selects'
import { TPaymentRequestButton } from 'modules/payment/types'

import { eventLogger } from 'services/eventLogger.service'

import { CENTS_IN_DOLLAR } from 'root-constants/common'

import { StyledPaymentRequestButton as S } from './PaymentRequestButton.styles'

type TProps = {
  hasIndent?: boolean
  setPaymentRequestButtonType?: (
    buttonType: PaymentMethod.APPLE_PAY | PaymentMethod.GOOGLE_PAY,
  ) => void
}

export const PaymentRequestButton: React.FC<TProps> = ({
  hasIndent = true,
  setPaymentRequestButtonType,
}) => {
  const dispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()
  const trialCurrentPrice = useSelector(selectSubscriptionTrialPrice)
  const periodName = useSelector(selectSubscriptionPeriodName)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const selectedPrice = useSelector(selectSubscriptionMainPrice)
  const currency = useSelector(selectCurrency)
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
    null,
  )
  const [buttonTypes, setButtonTypes] = useState<TPaymentRequestButton | null>(
    null,
  )

  const { usersPriority } = useUserData()
  const { currentPageId } = useGetPageInfo()

  const calculatedPrice = useMemo(
    () => +((trialCurrentPrice || selectedPrice) * CENTS_IN_DOLLAR).toFixed(),
    [trialCurrentPrice, selectedPrice],
  )

  useEffect(() => {
    if (!stripe || !elements) {
      return
    }

    const pr = stripe.paymentRequest({
      currency,
      country: 'GB',
      requestPayerEmail: true,
      requestPayerName: true,
      total: {
        label: `${periodQuantity}-${periodName} plan`,
        amount: calculatedPrice,
      },
    })

    pr.canMakePayment().then((canMakePaymentResult) => {
      if (canMakePaymentResult) {
        const result = canMakePaymentResult as TPaymentRequestButton
        const paymentRequestButtonType = result?.applePay
          ? PaymentMethod.APPLE_PAY
          : PaymentMethod.GOOGLE_PAY

        setPaymentRequestButtonType?.(paymentRequestButtonType)
        setButtonTypes(result)
        setPaymentRequest(pr)
      }
      dispatch(setIsPaymentFlowsShownAction(true))
    })

    pr.on('paymentmethod', (event) => {
      dispatch(resetErrorAction())
      dispatch(
        purchaseAction({
          stripe,
          paymentPageId: currentPageId,
          createPaymentResFromDigitalWallet: event,
          goal: usersPriority,
        }),
      )
    })
  }, [
    calculatedPrice,
    currency,
    currentPageId,
    dispatch,
    elements,
    periodName,
    periodQuantity,
    usersPriority,
    stripe,
    setPaymentRequestButtonType,
  ])

  const handleButtonClick = useCallback(() => {
    const shownButtonType = buttonTypes?.applePay
      ? PaymentMethod.APPLE_PAY
      : PaymentMethod.GOOGLE_PAY

    eventLogger.logPaymentMethodSelected(shownButtonType)
  }, [buttonTypes])

  return paymentRequest ? (
    <S.Wrapper hasIndent={hasIndent}>
      <PaymentRequestButtonElement
        onClick={handleButtonClick}
        options={{
          paymentRequest,
          style: {
            paymentRequestButton: {
              height: '56px',
              theme: 'light',
            },
          },
        }}
      />
    </S.Wrapper>
  ) : null
}
