import React, { useMemo } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledAge as S } from './Age.styles'

const QUESTION = 'How old are you?'
const OPTION_VALUES = {
  under21: 'Under 21',
  after21: '21 - 30',
  after31: '31 - 40',
  after41: '41 - 50',
  after51: '51 - 60',
  after61: '61 - 70',
  after70: 'More than 70',
}

export const AgeVariant1: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, handleContinue, pageId, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.Title>{QUESTION}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.under21}
            disabled={isAnswersDisabled}
          >
            <S.QuestionButton>{OPTION_VALUES.under21}</S.QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.after21}
            disabled={isAnswersDisabled}
          >
            <S.QuestionButton>{OPTION_VALUES.after21}</S.QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.after31}
            disabled={isAnswersDisabled}
          >
            <S.QuestionButton>{OPTION_VALUES.after31}</S.QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.after41}
            disabled={isAnswersDisabled}
          >
            <S.QuestionButton>{OPTION_VALUES.after41}</S.QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.after51}
            disabled={isAnswersDisabled}
          >
            <S.QuestionButton>{OPTION_VALUES.after51}</S.QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.after61}
            disabled={isAnswersDisabled}
          >
            <S.QuestionButton>{OPTION_VALUES.after61}</S.QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.after70}
            disabled={isAnswersDisabled}
          >
            <S.QuestionButton>{OPTION_VALUES.after70}</S.QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
