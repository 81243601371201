import { useCallback, useEffect, useState } from 'react'

import { IInputFieldState, TInputFieldHook } from 'models/inputField.model'

import { ValidationText } from 'root-constants/common'

export const useConfirmationPasswordInputField = (
  password: IInputFieldState,
): TInputFieldHook => {
  const [confirmationPassword, setConfirmationPassword] =
    useState<IInputFieldState>({
      value: '',
      isValid: true,
      validationText: '',
    })

  useEffect(() => {
    if (
      password.value !== confirmationPassword.value &&
      password.value !== '' &&
      confirmationPassword.value !== ''
    ) {
      setConfirmationPassword((prevState) => ({
        ...prevState,
        isValid: false,
        validationText: ValidationText.WRONG_CONFIRMATION_PASSWORD,
      }))
      return
    }

    setConfirmationPassword((prevState) => ({
      ...prevState,
      isValid: true,
      validationText: '',
    }))
  }, [confirmationPassword.value, password.value])

  const validateConfirmationPassword = useCallback(
    (confirmationPasswordValue: string) => {
      if (
        confirmationPasswordValue.length < 8 &&
        confirmationPasswordValue !== ''
      ) {
        setConfirmationPassword((prevState) => ({
          ...prevState,
          value: confirmationPasswordValue,
          isValid: false,
          validationText: ValidationText.SHORT_PASSWORD,
        }))
        return
      }

      if (confirmationPasswordValue !== password.value) {
        setConfirmationPassword((prevState) => ({
          ...prevState,
          value: confirmationPasswordValue,
          isValid: false,
          validationText: ValidationText.WRONG_CONFIRMATION_PASSWORD,
        }))
        return
      }

      setConfirmationPassword((prevState) => ({
        ...prevState,
        value: confirmationPasswordValue,
        isValid: true,
        validationText: '',
      }))
    },
    [password.value],
  )

  return [
    confirmationPassword,
    setConfirmationPassword,
    validateConfirmationPassword,
  ]
}
