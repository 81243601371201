import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Header } from 'components/Header'

import { selectUUID } from 'root-redux/selects/user'

import { useAmplitudeInitialization } from 'hooks/useAmplitudeInitialization'
import { useGAPageTracker } from 'hooks/useGAPageTracker'
import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useGiaFlowTrackerInitialization } from 'hooks/useGiaFlowTrackerInitialization'
import { growthbook } from 'hooks/useGrowthBook'
import { useLogAbSegmentName } from 'hooks/useLogAbSegmentName'
import { useScrollToTop } from 'hooks/useScrollToTop'
import { useStartSession } from 'hooks/useStartSession'

import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { RouteList } from '../../RouteList'

export const LayoutProvider: React.FC = () => {
  const { isSecondPage } = useGetPageInfo()
  const uuid = useSelector(selectUUID)
  const { search, pathname } = useLocation()

  useGAPageTracker()
  useGiaFlowTrackerInitialization()
  useAmplitudeInitialization()
  useStartSession()
  useLogAbSegmentName()
  useScrollToTop()

  useEffect(() => {
    if (isSecondPage) {
      window.fbq('track', 'CustomizeProduct', {}, { eventID: uuid })
      googleAnalyticsLogger.logFirstPageCompleted()
    }
  }, [isSecondPage, uuid])

  useEffect(() => {
    growthbook.setAttributes({
      ...growthbook.getAttributes(),
      url: `${pathname}${search}`,
    })
  }, [pathname, search])

  return (
    <>
      <Header />
      <RouteList />
    </>
  )
}
