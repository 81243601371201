import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { RANGES } from 'pages/start-age-mix/constants'

import { OptionType } from 'components/Option'
import { PageContainer } from 'components/PageContainer'
import { TitleContainer } from 'components/TitleContainer'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledStartAgeMixVariant1 as S } from './StartAgeMixVariant1.styles'

export const StartAgeMixVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.startAgeMixV1.question', { lng: 'en' }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <TitleContainer
          title={<S.Title>{t('onboarding.startAgeMixV1.title')}</S.Title>}
          subtitle={
            <S.Subtitle>{t('onboarding.startAgeMixV1.subtitle')}</S.Subtitle>
          }
          info={
            <S.CallToAction>
              {t('onboarding.startAgeMixV1.callToAction')}
            </S.CallToAction>
          }
        />
        <S.OptionsContainer>
          {RANGES.map((range) => (
            <S.Option
              key={range.value}
              {...optionProps}
              value={range.value}
              disabled={isAnswersDisabled}
            >
              <S.Action>
                <S.ActionImage src={range.img} alt={range.title} />
                <S.Answer />
                <S.Range>{range.title}</S.Range>
              </S.Action>
            </S.Option>
          ))}
        </S.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
