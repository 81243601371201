import styled, { css } from 'styled-components'

import { BASE_COLUMN_WIDTH, Color } from 'root-constants/common'

import { IProps as IButtonProps } from './Button'

export const StyledButtonBase = css`
  --next-page-button-background-color: ${Color.LILAC_130};
  --next-page-button-text-color: ${Color.BLUE_100};
  --next-page-button-height: 56px;
  --next-page-button-border-radius: 30px;
  --disabled-next-page-button-background-color: #846c92;

  background-color: var(--next-page-button-background-color);
  border: none;
  border-radius: var(--next-page-button-border-radius);
  color: var(--next-page-button-text-color);
  cursor: pointer;
  display: block;
  font-size: 20px;
  font-weight: 700;
  line-height: var(--next-page-button-height);
  height: var(--next-page-button-height);
  margin: 0 auto;
  outline: none;
  padding: 0 20px;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: center;
  text-decoration: none;
  transition: background-color 0.2s ease-out;
  width: ${BASE_COLUMN_WIDTH}px;

  &:disabled {
    background-color: var(--disabled-next-page-button-background-color);
  }
`

export const StyledButton = styled.button<IButtonProps>`
  ${StyledButtonBase};
`
