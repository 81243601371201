import styled from 'styled-components'

import { Button } from 'components/Button'

import { baseColumnStyles } from 'common-styles'
import { BIG_COLUMN_WIDTH, Color } from 'root-constants/common'

export const StyledLikelihoodBreakupsVariant1 = {
  Column: styled.div`
    ${baseColumnStyles};
    width: ${BIG_COLUMN_WIDTH}px;
    padding: 20px;
  `,
  Title: styled.h1`
    color: ${Color.LILAC_120};
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    margin: 0 0 24px 0;
    text-align: center;
  `,
  GraphContainer: styled.div`
    aspect-ratio: 311/220;
    margin: 0 0 24px 0;
    width: 311px;
  `,
  GraphDescription: styled.p`
    color: ${Color.LILAC_120};
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 88px 0;
    text-align: center;
  `,
  Button: styled(Button)`
    --next-page-button-border-radius: 100px;
    --next-page-button-height: 62px;
  `,
}
