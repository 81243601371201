import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Swiper, { Autoplay, Pagination } from 'swiper'
import { Swiper as Carousel } from 'swiper/react'

import { PageContainer } from 'components/PageContainer'
import { ReviewCard } from 'components/ReviewCard'

import { useCounter } from 'hooks/useCounter'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledCreatingVariant1 as S } from './CreatingVariant1.styles'
import { REVIEWS_MAP } from './constants'

Swiper.use([Autoplay, Pagination])
export const CreatingVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.creatingV1.progressBarDescription', { lng: 'en' }),
    nextPagePath,
  })

  const handleNextClick = useCallback(
    () => handleContinue(''),
    [handleContinue],
  )

  const { counter } = useCounter(true, {
    duration: 8000,
    callback: handleNextClick,
  })

  return (
    <PageContainer>
      <S.Column>
        <S.ProgressBarRounded
          value={counter}
          description={t('onboarding.creatingV1.progressBarDescription')}
        />
        <S.Award />
        <S.CarouselContainer>
          <Carousel
            spaceBetween={16}
            slidesPerView={1}
            loop
            pagination={{ dynamicBullets: true, clickable: true }}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
          >
            {REVIEWS_MAP.map((review) => (
              <S.SwiperSlide key={review.review}>
                <ReviewCard
                  key={review.review}
                  userName={review.userName}
                  userAvatar={review.userAvatar}
                  review={review.review}
                />
              </S.SwiperSlide>
            ))}
          </Carousel>
        </S.CarouselContainer>
      </S.Column>
    </PageContainer>
  )
}
