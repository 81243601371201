import styled from 'styled-components'

import { QuestionTitle, baseColumnStyles } from 'common-styles'

export const StyledMostlySatisfying = {
  Column: styled.div`
    ${baseColumnStyles};
    padding: 25px 0;
  `,
  Title: styled(QuestionTitle)`
    line-height: 26px;
    margin-bottom: 100px;

    strong {
      display: block;
      font-weight: 700;
      margin-bottom: 16px;
    }
  `,
}
