import React from 'react'

import { useNextStep } from 'hooks/useNextStep'
import { useUserData } from 'hooks/useUserData'

import { TPageProps } from 'models/common.model'

import femaleImg from 'assets/images/social-proof-female.webp'
import maleImg from 'assets/images/social-proof-male.webp'

import { StyledSocialProof as S } from './SocialProof.styles'

export const SocialProofVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { isMale } = useUserData()

  const handleContinue = useNextStep({
    pageId,
    question:
      '83% of couples who use UpLuv report feeling more comfortable discussing their sexual desires with their partner',
    nextPagePath,
  })
  return (
    <S.Wrapper>
      <S.Column>
        <S.Percent>83%</S.Percent>
        <S.Text2 style={{ marginBottom: '53px' }}>
          <S.Bold>of couples who use UpLuv</S.Bold> report feeling more
          comfortable discussing their sexual desires with their partner
        </S.Text2>
        {isMale ? (
          <S.FeedbackWrapper>
            <S.Photo src={maleImg} alt="user" width={70} height={70} />
            <S.Name>Jack, 32</S.Name>
            <S.FeedbackText>
              &quot;We were in a bit of a rut in our relationship and
              didn&apos;t know how to spice things up. Using UpLuv gave us new
              ideas and helped us become more open about what we wanted in the
              bedroom.&quot;
            </S.FeedbackText>
          </S.FeedbackWrapper>
        ) : (
          <S.FeedbackWrapper>
            <S.Photo src={femaleImg} alt="user" width={70} height={70} />
            <S.Name>Emma, 35</S.Name>
            <S.FeedbackText>
              &quot;My partner and I have been using this app for a few months
              now, and it&apos;s been a great way for us to connect and
              communicate better. We feel closer than ever before.&quot;
            </S.FeedbackText>
          </S.FeedbackWrapper>
        )}
        <S.StickyButton onClick={() => handleContinue('')}>
          Continue
        </S.StickyButton>
      </S.Column>
    </S.Wrapper>
  )
}
