import styled from 'styled-components'

import { Button } from 'components/Button'
import { Option } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { QuestionButton2 } from 'components/QuestionButton2'
import { StyledQuestionButtonBase } from 'components/QuestionButton/QuestionButton.styles'

import { QuestionTitle, baseColumnStyles } from 'common-styles'
import { BIG_COLUMN_WIDTH, Color, MAX_PHONE_WIDTH } from 'root-constants/common'

type TTagsProps = {
  hasTags?: boolean
}

export const StyledOptionsPage = {
  Column: styled.div`
    ${baseColumnStyles};
    padding: 20px;
    width: 100%;

    @media (min-width: ${MAX_PHONE_WIDTH}px) {
      width: ${BIG_COLUMN_WIDTH}px;
    }
  `,
  QuestionTitle: styled(QuestionTitle)`
    line-height: 28px;
  `,
  QuestionDescription: styled.p`
    color: #ededff80;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  `,
  Option: styled(Option)`
    ${({ hasTags }: TTagsProps) => `
      ${
        hasTags &&
        `
          margin: 0;
        `
      };
    `}
  `,
  RadioOptionsContainer: styled(StyledOption.OptionsContainer)`
    padding: 0;
  `,
  CheckboxOptionsContainer: styled(StyledOption.OptionsContainer)`
    ${({ hasTags }: TTagsProps) => `
      padding-bottom: 88px;
      ${
        hasTags &&
        `
          flex-direction: row;
          flex-wrap: wrap;
          gap: 12px 8px;
          justify-content: center;
        `
      };
    `}
  `,
  QuestionButton: styled(QuestionButton2)`
    input:disabled + & {
      background-color: rgba(217, 216, 246, 0.5);
    }
  `,
  TagButton: styled.div`
    ${StyledQuestionButtonBase};
    --checked-question-button-background-color: ${Color.VIOLET_120};
    border-radius: 30px;
    font-size: 18px;
    height: 56px;
    justify-content: center;
    min-width: 100px;
    padding: 0 24px;
    text-align: center;

    input:disabled + & {
      background-color: rgba(217, 216, 246, 0.5);
    }
  `,
  Button: styled(Button)`
    --next-page-button-border-radius: 100px;
    --next-page-button-height: 62px;
  `,
}
