import styled from 'styled-components'

import { StickyButton } from 'components/StickyButton'

import { QuestionTitle, baseColumnStyles } from 'common-styles'

export const StyledIntellectualIntimacy = {
  Column: styled.div`
    ${baseColumnStyles};
    padding: 40px 0;
  `,
  Title: styled(QuestionTitle)`
    line-height: 28px;
    margin-bottom: 38px;

    @media (min-width: 360px) {
      width: 340px;
    }
  `,
  Image: styled.img`
    margin-bottom: 24px;
  `,
  Text: styled.p`
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: var(--base-text-color);
    margin-bottom: 33px;

    strong {
      font-weight: 800;
    }
  `,
  Button: styled(StickyButton)`
    height: 62px;

    @media (max-height: 620px) {
      position: relative;
      bottom: 0;
    }
  `,
}
