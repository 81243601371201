import { createGlobalStyle } from 'styled-components'

import { Color } from 'root-constants/common'

export const GlobalStyleForPrivacyPolicy = createGlobalStyle`
  * {
    border: 0;
    box-sizing: border-box;
    margin: 0;
    outline: 0;
    padding: 0;
  }

  body,
  html {
    height: 100%;
  }

  body {
    background: ${Color.WHITE_100};
    color: ${Color.BLACK_100};
    font-family: 'Montserrat', sans-serif;
    line-height: 1.5;
    min-width: 320px;
    overflow-x: hidden;
  }

  .container {
    margin: 0 auto;
  }

  .header {
    background-color: #f0f2fa;
    font-family: 'Raleway', sans-serif;
  }

  .header__container {
    align-items: center;
    column-gap: 20px;
    display: flex;
    max-width: 980px;
    padding: 10px 0;
    width: calc(100% - 10px - 10px);
  }

  .logo {
    display: flex;
    overflow: hidden;
    width: 48px;
  }

  .logo__img {
    max-width: 100%;
  }

  .header-title {
    color: #191919;
    font-size: 22px;
    font-weight: bold;
  }

  .article {
    width: calc(100% - 10px - 10px);
    max-width: 800px;
    padding: 35px 0 20px;
  }

  .article__title {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 30px;
  }

  .article__date {
    color: #808080;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 30px;
  }

  .article__title-v2,
  .article__date-v2 {
    font-size: 24px;
    font-weight: 700;
  }

  .article__date-v2 {
    margin-bottom: 30px;
  }

  .article section:not(:last-of-type) {
    margin-bottom: 30px;
  }

  .article section {
    font-weight: 300;
  }

  .article h2 {
    font-size: 24px;
    margin-bottom: 30px;
  }

  .article h3 {
    font-size: 18px;
    font-weight: 700;
  }

  .article p,
  .article li {
    font-size: 18px;
  }

  .article a {
    color: inherit;
    font: inherit;
    text-decoration: underline;
  }

  .article ol,
  .article ul {
    list-style: initial;
    padding-left: 32px;
  }

  .article table {
    text-align: left;
  }

  .article th,
  .article td {
    padding: 16px;
    vertical-align: top;
  }

  .article td:first-of-type,
  .article th:first-of-type {
    font-weight: bold;
    white-space: nowrap;
    padding-left: 0;
  }

  .article td:last-of-type,
  .article th:last-of-type {
    padding-right: 0;
  }

  .article .mb-30 {
    margin-bottom: 30px;
  }

  .footer {
    background-color: #f0f2fa;
    padding: 10px 0;
    text-align: center;
  }

  .footer__content {
    color: #191919;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-weight: 400;
  }

  @media only screen and (min-width: 1024px) {
    .header__container {
      padding: 25px 10px;
    }

    .logo {
      width: 64px;
    }

    .header-title {
      font-size: 22px;
    }

    .article {
      padding: 70px 0 40px;
    }

    .footer {
      padding: 25px 0;
    }
  }
`
