import React, { useMemo, useState } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { StickyContainer } from 'components/StickyContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledMaintainPhysicalIntimacy as S } from './MaintainPhysicalIntimacy.styles'

const QUESTION =
  'How do you and your partner maintain physical intimacy when you are apart from each other?'
const OPTION_VALUES = {
  phoneSex: 'Sexting and phone sex',
  sendPhotos: 'Sending sexy photos and videos',
  planningVisits: 'Planning visits to see each other',
  videoCalls: 'Having intimate video calls',
  sharingFantasies: 'Sharing intimate fantasies',
  doNothing: `We don't do anything`,
}

export const MaintainPhysicalIntimacyVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          value === OPTION_VALUES.doNothing
            ? setAnswers([value])
            : setAnswers([...answers, value])
        } else {
          const newValues = answers.filter((item) => item !== value)
          setAnswers(newValues)
        }
      },
    }),
    [pageId, answers],
  )

  const isButtonsDisabled = useMemo(
    () => answers.includes(OPTION_VALUES.doNothing),
    [answers],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.Title>{QUESTION}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.phoneSex}
            disabled={isButtonsDisabled}
            checked={answers.includes(OPTION_VALUES.phoneSex)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.phoneSex}
            </S.QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.sendPhotos}
            disabled={isButtonsDisabled}
            checked={answers.includes(OPTION_VALUES.sendPhotos)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.sendPhotos}
            </S.QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.planningVisits}
            disabled={isButtonsDisabled}
            checked={answers.includes(OPTION_VALUES.planningVisits)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.planningVisits}
            </S.QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.videoCalls}
            disabled={isButtonsDisabled}
            checked={answers.includes(OPTION_VALUES.videoCalls)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.videoCalls}
            </S.QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.sharingFantasies}
            disabled={isButtonsDisabled}
            checked={answers.includes(OPTION_VALUES.sharingFantasies)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.sharingFantasies}
            </S.QuestionButton>
          </Option>

          <Option {...optionProps} value={OPTION_VALUES.doNothing}>
            <S.QuestionButton>{OPTION_VALUES.doNothing}</S.QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
      <StickyContainer>
        <S.Button
          onClick={() => handleContinue(answers)}
          disabled={!answers.length}
        >
          Continue
        </S.Button>
      </StickyContainer>
    </PageContainer>
  )
}
