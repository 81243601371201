import styled from 'styled-components'

import { Option } from 'components/Option'

export const StyledTrialSelectPlanVariant1 = {
  Wrapper: styled.div`
    margin-bottom: 13px;
    width: 100%;
  `,
  Option: styled(Option)`
    width: 100%;

    &[data-is-default='true'] {
      margin-top: 25px;
    }
  `,
}
