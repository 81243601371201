import React, { HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'

import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { GuaranteeCard } from './Guarantee.styles'

type TProps = {
  text: string
  className?: string
  props?: HTMLAttributes<HTMLDivElement>
}
export const Guarantee: React.FC<TProps> = ({ text, className, ...props }) => {
  const { t } = useTranslation()

  return (
    <GuaranteeCard className={className} {...props}>
      <h4>{t('commonComponents.guarantee.title')}</h4>
      <span>
        {t(text)} <TermsOfUseLink />.
      </span>
    </GuaranteeCard>
  )
}
