import React from 'react'

import complete from 'assets//images/complete.png'
import upLovePresaleTrust from 'assets/images/uplove-presale-build-trust.png'
import upLovePresaleEmotionalConnection from 'assets/images/uplove-presale-emotional-connection.png'
import upLovePresaleConflicts from 'assets/images/uplove-presale-manage-conflicts.png'
import upLovePresaleActivities from 'assets/images/uplove-presale-new-activities.png'

export const config = {
  title: (
    <>
      Unlock Your <span>Happy Relationship</span>
    </>
  ),
  subtitle: (
    <>
      Build trust and overcome jealousy with your{' '}
      <span>personalized program</span>
    </>
  ),
  setup: 'Setup Summary',
  title2: (
    <>
      With <span>UpLuv</span> you will
    </>
  ),
  guarantees: [
    {
      icon: upLovePresaleEmotionalConnection,
      text: (
        <>
          Discover the art of <span>meaningful conversations</span> for a
          deeper, more connected bond.
        </>
      ),
      id: 1,
    },
    {
      icon: upLovePresaleTrust,
      text: (
        <>
          Heal past wounds and
          <span> strengthen trust</span> together.
        </>
      ),
      id: 2,
    },
    {
      icon: upLovePresaleConflicts,
      text: (
        <>
          Learn how to
          <span> navigate conflicts </span>
          with grace, transforming disagreements into opportunities for growth.
        </>
      ),
      id: 3,
    },
    {
      icon: upLovePresaleActivities,
      text: (
        <>
          <span>Rediscover the magic</span> that once bound you, reigniting
          closeness and embracing joyful moments.
        </>
      ),
      id: 4,
    },
  ],
  subtitle2: 'Why do people choose us?',
  reasons: [
    { icon: complete, text: 'Improved communication and intimacy', id: 1 },
    { icon: complete, text: 'Navigate difficult conversations', id: 2 },
    { icon: complete, text: 'Avoid boredom and have fun', id: 3 },
    {
      icon: complete,
      text: 'Stay connected in a long-distance relationship',
      id: 4,
    },
  ],
}
