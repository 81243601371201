import styled from 'styled-components'

import { Color } from 'root-constants/common'

export const StyledPersonalInfoBlock = {
  Block: styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
  `,
  Column: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    &:first-of-type {
      align-items: flex-end;
      text-align: right;
    }
    &:last-of-type {
      align-items: flex-start;
      text-align: left;
    }
  `,
  Separator: styled.div`
    width: 2px;
    height: 36px;
    background-color: #ccdce5;
    margin: 2px 32px;
  `,
  Label: styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #ccdce5;
    margin: 0 0 6px 0;
  `,
  Data: styled.p`
    font-weight: 800;
    font-size: 20px;
    line-height: 20px;
    color: ${Color.LILAC_120};
  `,
}
