import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import uniqueId from 'lodash/uniqueId'

import { useUserData } from 'hooks/useUserData'

import { Color } from 'root-constants/common'

import { StyledSatisfactionBlockV1 as S } from './SatisfactionBlockV1.styles'
import { RATE_RELATIONSHIP_VALUES } from './constants'

type TProps = {
  className?: string
}

export const SatisfactionBlockV1: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  const { rateRelationship } = useUserData()

  const currentRateValue = useMemo(
    () => RATE_RELATIONSHIP_VALUES[rateRelationship],
    [rateRelationship],
  )

  return (
    <S.Block className={className}>
      <S.Column>
        <S.Label>{t('paymentTrialV1.satisfactionLevel.labelNow')}</S.Label>
        <S.Card>
          <S.CardTitle>
            {t('paymentTrialV1.satisfactionLevel.title')}
          </S.CardTitle>
          <S.ScaleContainer>
            {[...Array(5)].map((el, index) => (
              <S.ScaleDivision
                key={uniqueId('current_scale_')}
                color={
                  index < currentRateValue ? Color.VIOLET_110 : Color.WHITE_110
                }
              />
            ))}
          </S.ScaleContainer>
        </S.Card>
      </S.Column>
      <S.Column>
        <S.Label color={Color.LILAC_130}>
          {t('paymentTrialV1.satisfactionLevel.labelGoal')}
        </S.Label>
        <S.Card>
          <S.CardTitle>
            {t('paymentTrialV1.satisfactionLevel.title')}
          </S.CardTitle>
          <S.ScaleContainer>
            {[...Array(5)].map(() => (
              <S.ScaleDivision
                key={uniqueId('goal_scale_')}
                color={Color.VIOLET_110}
              />
            ))}
          </S.ScaleContainer>
        </S.Card>
      </S.Column>
    </S.Block>
  )
}
