import styled from 'styled-components'

import { Color } from 'root-constants/common'

type TProps = {
  color?: string
}

export const StyledSatisfactionBlockV2 = {
  Block: styled.div`
    width: 100%;
  `,
  ContentContainer: styled.div`
    width: inherit;
    display: flex;
    flex-direction: column;
    background-color: ${Color.LILAC_110};
    border-radius: 18px;
  `,
  Column: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Label: styled.p`
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    color: ${Color.BLUE_100};
  `,
  LabelContainer: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 8px 0 12px;

    > div:first-of-type {
      border-right: 2px solid ${Color.WHITE_100};
    }
  `,
  BannerContainer: styled.div`
    width: 100%;
    aspect-ratio: 351/211;
    background: radial-gradient(
      56.34% 82.46% at 46.87% 49.78%,
      #e6e7ff 7.2%,
      #9595c5 90.42%
    ); ;
  `,
  RateContainer: styled.div`
    width: 100%;
    display: flex;

    > div {
      padding: 16px 0 20px 0;
    }
    > div:first-of-type {
      border-right: 2px solid ${Color.WHITE_100};
    }
  `,
  RateTitle: styled.p`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${Color.BLUE_100};
    margin: 0 0 12px 0;
  `,
  ScaleContainer: styled.div`
    display: flex;

    > div:not(:last-of-type) {
      margin: 0 5px 0 0;
    }
  `,
  ScaleDivision: styled.div<TProps>`
    width: 22px;
    height: 6px;
    border-radius: 12px;
    background-color: ${({ color }) => color || Color.WHITE_110};
  `,
}
