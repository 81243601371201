import imgSrc1 from 'assets/images/what-you-see-1.png'
import imgSrc2 from 'assets/images/what-you-see-2.png'
import imgSrc3 from 'assets/images/what-you-see-3.png'
import imgSrc4 from 'assets/images/what-you-see-4.png'

import { PageId } from 'root-constants/pages'

const enum OptionValue {
  AN_APPLE = 'an_apple',
  TWO_FACES = 'two_faces',
  AN_ANIMAL = 'an_animal',
  A_MANS_FACE = 'a_mans_face',
  A_WOMAN = 'a_woman',
  A_TREE = 'a_tree',
  AN_ELEPHANT = 'an_elephant',
}

export const WHAT_YOU_SEE_PAGE_MAP = {
  [PageId.WHAT_YOU_SEE_1]: {
    imgSrc: imgSrc1,
    options: [
      {
        title: 'onboarding.whatYouSeeV1.firstPage.option1',
        value: OptionValue.AN_APPLE,
      },
      {
        title: 'onboarding.whatYouSeeV1.firstPage.option2',
        value: OptionValue.TWO_FACES,
      },
    ],
  },
  [PageId.WHAT_YOU_SEE_2]: {
    imgSrc: imgSrc2,
    options: [
      {
        title: 'onboarding.whatYouSeeV1.secondPage.option1',
        value: OptionValue.AN_ANIMAL,
      },
      {
        title: 'onboarding.whatYouSeeV1.secondPage.option2',
        value: OptionValue.A_MANS_FACE,
      },
    ],
  },
  [PageId.WHAT_YOU_SEE_3]: {
    imgSrc: imgSrc3,
    options: [
      {
        title: 'onboarding.whatYouSeeV1.thirdPage.option1',
        value: OptionValue.A_WOMAN,
      },
      {
        title: 'onboarding.whatYouSeeV1.thirdPage.option2',
        value: OptionValue.A_TREE,
      },
    ],
  },
  [PageId.WHAT_YOU_SEE_4]: {
    imgSrc: imgSrc4,
    options: [
      {
        title: 'onboarding.whatYouSeeV1.fourthPage.option1',
        value: OptionValue.AN_ELEPHANT,
      },
      {
        title: 'onboarding.whatYouSeeV1.fourthPage.option2',
        value: OptionValue.TWO_FACES,
      },
    ],
  },
}
