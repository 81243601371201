import React, { useMemo } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton2 } from 'components/QuestionButton2'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledMainGoals as S } from './MainGoals.styles'

const QUESTION =
  'What is your main goal in improving your emotional intimacy with your partner?'
const OPTION_VALUES = {
  moreConnected: 'Feel more emotionally connected to my partner',
  moreMeaningful: 'Have more meaningful conversations',
  resolveConflicts: 'Resolve any emotional conflicts in our relationship',
  buildTrust: 'Build trust and intimacy with my partner',
}

export const MainGoalsVariant3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.QuestionTitle style={{ marginBottom: 52 }}>
          {QUESTION}
        </S.QuestionTitle>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.moreConnected}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.moreConnected}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.moreMeaningful}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.moreMeaningful}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.resolveConflicts}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.resolveConflicts}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.buildTrust}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.buildTrust}</QuestionButton2>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
