import styled from 'styled-components'

import { Option } from 'components/Option'

export const StyledSelectPlanVariant1 = {
  Wrapper: styled.div`
    margin-bottom: 20px;
    width: 100%;
    margin-top: 25px;
  `,
  Option: styled(Option)`
    width: 100%;
  `,
}
