/* eslint-disable max-lines */
import {
  PaymentMethodResult,
  PaymentRequestPaymentMethodEvent,
  Stripe,
  StripeCardNumberElement,
  StripeError,
} from '@stripe/stripe-js'
import { paymentApi } from 'api'

import {
  setErrorAction,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import { sendUserConfigAction } from 'root-redux/actions/user'
import { selectUUID } from 'root-redux/selects/user'

import {
  DEFAULT_CARDHOLDER_NAME,
  PaymentMethod,
} from 'modules/payment/constants'
import { getPurchaseFailedEventParams } from 'modules/payment/helpers/getPurchaseFailedEventParams'
import { getPurchaseStartedEventParams } from 'modules/payment/helpers/getPurchaseStartedEventParams'
import { getPurchaseSuccessEventParams } from 'modules/payment/helpers/getPurchaseSuccessEventParams'
import { getRedirectUrl } from 'modules/payment/helpers/getRedirectUrl'

import { IAction, IAppState, TAppDispatchThunk } from 'models/store.model'
import { ISubscription } from 'models/subscriptions.model'

import { eventLogger } from 'services/eventLogger.service'

import { CENTS_IN_DOLLAR } from 'root-constants/common'

import { checkIsRetryAllowed } from '../helpers/checkIsRetryAllowed'
import { logFailedPayment } from '../helpers/logFailedPayment'
import { logSuccessfulPayment } from '../helpers/logSuccessfulPayment'
import {
  selectCreatedSubscriptionId,
  selectCurrency,
  selectPaymentClientSecret,
  selectSubscriptionLookupKey,
  selectSubscriptionMainPrice,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialLookupKey,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPrice,
} from './selects'

const MODULE_NAME = 'PAYMENT'

export const SET_SUBSCRIPTION = `${MODULE_NAME}/SET_SUBSCRIPTION`
export const PURCHASE = `${MODULE_NAME}/PURCHASE`
export const CHECK_3D_SECURE = `${MODULE_NAME}/CHECK_3D_SECURE`
export const SET_3D_SECURE_IFRAME_URL = `${MODULE_NAME}/SET_3D_SECURE_IFRAME_URL`
export const RESET_3D_SECURE_IFRAME_URL = `${MODULE_NAME}/RESET_3D_SECURE_IFRAME_URL`
export const SET_PAYMENT_CLIENT_SECRET = `${MODULE_NAME}/SET_PAYMENT_CLIENT_SECRET`
export const SET_TRIAL_PERIOD_DAYS = `${MODULE_NAME}/SET_TRIAL_PERIOD_DAYS`
export const SET_SUBSCRIPTION_ID = `${MODULE_NAME}/SET_SUBSCRIPTION_ID`
export const SET_IS_PAYMENT_FLOWS_SHOWN = `${MODULE_NAME}/SET_IS_PAYMENT_FLOWS_SHOWN`

const getErrorActionPayload = ({ type, message }: StripeError): string =>
  message || type

const set3DSecureIframeUrlAction = (payload: string): IAction<string> => ({
  type: SET_3D_SECURE_IFRAME_URL,
  payload,
})

const reset3DSecureIframeUrlAction = (): IAction<never> => ({
  type: RESET_3D_SECURE_IFRAME_URL,
})

const setPaymentClientSecretAction = (payload: string): IAction<string> => ({
  type: SET_PAYMENT_CLIENT_SECRET,
  payload,
})

const setSubscriptionIdAction = (payload: string): IAction<string> => ({
  type: SET_SUBSCRIPTION_ID,
  payload,
})

export const setIsPaymentFlowsShownAction = (
  payload: boolean,
): IAction<boolean> => ({
  type: SET_IS_PAYMENT_FLOWS_SHOWN,
  payload,
})

export const setSelectedSubscriptionAction = (payload: ISubscription | null) =>
  ({
    type: SET_SUBSCRIPTION,
    payload,
  } as const)

export const purchaseAction =
  ({
    stripe,
    paymentPageId,
    card,
    name,
    createPaymentResFromDigitalWallet,
    goal,
  }: {
    stripe: Stripe
    paymentPageId: string
    card?: StripeCardNumberElement
    name?: string
    createPaymentResFromDigitalWallet?: PaymentRequestPaymentMethodEvent
    goal: string
  }): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const priceId = selectSubscriptionLookupKey(state)
    const trialPriceId = selectSubscriptionTrialLookupKey(state)
    const trialPrice = selectSubscriptionTrialPrice(state)
    const uuid = selectUUID(state)
    const mainPrice = selectSubscriptionMainPrice(state)
    const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
    const periodName = selectSubscriptionPeriodName(state)
    const periodQuantity = selectSubscriptionPeriodQuantity(state)
    const currency = selectCurrency(state)

    if (!priceId || !mainPrice) {
      console.error('Error: no subscriptions plan selected')
      return
    }

    const commonPurchaseStartedParams = getPurchaseStartedEventParams(state)
    const commonPurchaseSuccessParams = getPurchaseSuccessEventParams(state)
    const commonPurchaseFailedParams = getPurchaseFailedEventParams(state)

    dispatch(startFetching(PURCHASE))

    const paymentMethod =
      createPaymentResFromDigitalWallet?.paymentMethod?.card?.wallet?.type

    eventLogger.logPurchaseStarted({
      ...commonPurchaseStartedParams,
      paymentMethod,
      goal,
    })

    try {
      const createPaymentResponse =
        card && !createPaymentResFromDigitalWallet
          ? await stripe.createPaymentMethod({
              type: 'card',
              card,
              billing_details: { name: name || DEFAULT_CARDHOLDER_NAME },
            })
          : (createPaymentResFromDigitalWallet as PaymentMethodResult)

      if (
        !createPaymentResponse?.paymentMethod &&
        createPaymentResponse?.error
      ) {
        const {
          error: { type, message },
        } = createPaymentResponse

        eventLogger.logPurchaseFailed({
          ...commonPurchaseFailedParams,
          error: createPaymentResponse.error,
          paymentMethod,
          goal,
        })
        dispatch(setErrorAction(message || type))
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      const isFreeTrial = !!trialPeriodDays && !trialPriceId
      const createSubscriptionResponse = await paymentApi.createSubscription({
        uuid,
        priceId,
        trialPriceId,
        trialPeriodDays,
      })

      if (
        !createSubscriptionResponse.success ||
        !createSubscriptionResponse.data
      ) {
        // It's a hack for fixing behavior after changing response
        // from WS BE after getting payment_method
        if (
          createSubscriptionResponse.data?.error?.startsWith(
            'creating new customer',
          )
        ) {
          let error = { type: createSubscriptionResponse.data?.error }

          try {
            error = JSON.parse(
              createSubscriptionResponse.data.error.split(
                'creating new customer: ',
              )[1],
            )
          } finally {
            logFailedPayment({
              ...commonPurchaseFailedParams,
              price: mainPrice,
              paymentResponse: error,
              paymentMethod,
              goal,
            })
            dispatch(
              setErrorAction({ type: createSubscriptionResponse.data?.error }),
            )
            dispatch(stopFetching(PURCHASE))
            createPaymentResFromDigitalWallet?.complete('fail')
          }
          return
        }

        if (isFreeTrial) {
          logFailedPayment({
            ...commonPurchaseFailedParams,
            price: mainPrice,
            paymentResponse: { type: createSubscriptionResponse.data?.error },
            paymentMethod,
            goal,
          })
          dispatch(
            setErrorAction({ type: createSubscriptionResponse.data?.error }),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        dispatch(setErrorAction('Error: Something went wrong'))
        logFailedPayment({
          ...commonPurchaseFailedParams,
          price: mainPrice,
          paymentResponse: { type: createSubscriptionResponse.data?.error },
          paymentMethod,
          goal,
        })
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      if (isFreeTrial) {
        logSuccessfulPayment({
          ...commonPurchaseSuccessParams,
          trialPeriodDays:
            createSubscriptionResponse.data.purchase.trial_period_days,
          subscriptionId:
            createSubscriptionResponse.data.purchase.subscription_id,
          paymentMethod,
          goal,
        })

        dispatch(
          sendUserConfigAction({
            payment_currency: currency,
            payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
            is_download_visited: false,
            subscription_price: mainPrice,
            subscription_duration: `${periodQuantity}${periodName}`,
            price_id: priceId,
            trial_price: trialPrice,
            trial_period: trialPeriodDays,
          }),
        )
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('success')
        return
      }

      dispatch(
        setPaymentClientSecretAction(
          createSubscriptionResponse.data.purchase.client_secret,
        ),
      )
      dispatch(
        setSubscriptionIdAction(
          createSubscriptionResponse.data.purchase.subscription_id,
        ),
      )

      const cardPaymentResponseFirst = await stripe.confirmCardPayment(
        createSubscriptionResponse.data.purchase.client_secret,
        {
          payment_method: createPaymentResponse.paymentMethod.id,
          save_payment_method: true,
          return_url: getRedirectUrl(paymentPageId),
        },
      )

      if (
        !cardPaymentResponseFirst?.paymentIntent &&
        cardPaymentResponseFirst?.error
      ) {
        logFailedPayment({
          ...commonPurchaseFailedParams,
          price: mainPrice,
          paymentResponse: cardPaymentResponseFirst.error,
          paymentMethod,
          goal,
        })

        if (!checkIsRetryAllowed(cardPaymentResponseFirst)) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseFirst.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        const retryResponseFirst = await paymentApi.retryPayment({
          uuid,
          stripeError: cardPaymentResponseFirst.error as StripeError,
        })

        if (!retryResponseFirst.data.should_retry) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseFirst.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        dispatch(
          setPaymentClientSecretAction(
            createSubscriptionResponse.data.purchase.client_secret,
          ),
        )
        dispatch(
          setSubscriptionIdAction(
            createSubscriptionResponse.data.purchase.subscription_id,
          ),
        )

        const cardPaymentResponseSecond = await stripe.confirmCardPayment(
          retryResponseFirst.data.subscription.client_secret,
          {
            payment_method: createPaymentResponse.paymentMethod.id,
            save_payment_method: true,
            return_url: getRedirectUrl(paymentPageId),
          },
        )

        if (
          cardPaymentResponseSecond?.paymentIntent &&
          !cardPaymentResponseSecond?.error
        ) {
          logSuccessfulPayment({
            ...commonPurchaseSuccessParams,
            price:
              cardPaymentResponseSecond.paymentIntent.amount / CENTS_IN_DOLLAR,
            trialPrice:
              cardPaymentResponseSecond.paymentIntent.amount / CENTS_IN_DOLLAR,
            trialPeriodDays:
              retryResponseFirst.data.subscription.trial_period_days,
            subscriptionId:
              retryResponseFirst.data.subscription.subscription_id,
            discountApplied:
              retryResponseFirst.data.subscription.discount_applied,
            paymentMethod,
            goal,
          })

          dispatch(
            sendUserConfigAction({
              payment_currency: currency,
              payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
              is_download_visited: false,
              discount_applied:
                !!retryResponseFirst.data.subscription.discount_applied,
              subscription_price: mainPrice,
              subscription_duration: `${periodQuantity}${periodName}`,
              price_id: priceId,
              trial_price: trialPrice,
              trial_period: trialPeriodDays,
            }),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('success')
          return
        }

        logFailedPayment({
          ...commonPurchaseFailedParams,
          price: mainPrice,
          paymentResponse: cardPaymentResponseSecond.error,
          paymentMethod,
          goal,
        })

        if (!checkIsRetryAllowed(cardPaymentResponseSecond)) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseSecond.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        const retryResponseSecond = await paymentApi.retryPayment({
          uuid,
          stripeError: cardPaymentResponseSecond.error as StripeError,
        })

        if (!retryResponseSecond.data.should_retry) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseSecond.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        dispatch(
          setPaymentClientSecretAction(
            createSubscriptionResponse.data.purchase.client_secret,
          ),
        )
        dispatch(
          setSubscriptionIdAction(
            createSubscriptionResponse.data.purchase.subscription_id,
          ),
        )

        const cardPaymentResponseThird = await stripe.confirmCardPayment(
          retryResponseSecond.data.subscription.client_secret,
          {
            payment_method: createPaymentResponse.paymentMethod.id,
            save_payment_method: true,
            return_url: getRedirectUrl(paymentPageId),
          },
        )

        if (
          cardPaymentResponseThird?.paymentIntent &&
          !cardPaymentResponseThird?.error
        ) {
          logSuccessfulPayment({
            ...commonPurchaseSuccessParams,
            price:
              cardPaymentResponseThird.paymentIntent.amount / CENTS_IN_DOLLAR,
            trialPrice:
              cardPaymentResponseThird.paymentIntent.amount / CENTS_IN_DOLLAR,
            trialPeriodDays:
              retryResponseSecond.data.subscription.trial_period_days,
            subscriptionId:
              retryResponseSecond.data.subscription.subscription_id,
            discountApplied:
              retryResponseSecond.data.subscription.discount_applied,
            paymentMethod,
            goal,
          })

          dispatch(
            sendUserConfigAction({
              payment_currency: currency,
              payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
              is_download_visited: false,
              discount_applied:
                !!retryResponseSecond.data.subscription.discount_applied,
              subscription_price: mainPrice,
              subscription_duration: `${periodQuantity}${periodName}`,
              price_id: priceId,
              trial_price: trialPrice,
              trial_period: trialPeriodDays,
            }),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('success')
          return
        }

        logFailedPayment({
          ...commonPurchaseFailedParams,
          price: mainPrice,
          paymentResponse: cardPaymentResponseThird.error,
          paymentMethod,
          goal,
        })

        if (!checkIsRetryAllowed(cardPaymentResponseThird)) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseThird.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        const retryResponseThird = await paymentApi.retryPayment({
          uuid,
          stripeError: cardPaymentResponseThird.error as StripeError,
        })

        if (!retryResponseThird.data.should_retry) {
          dispatch(
            setErrorAction(
              getErrorActionPayload(cardPaymentResponseThird.error),
            ),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('fail')
          return
        }

        dispatch(
          setPaymentClientSecretAction(
            createSubscriptionResponse.data.purchase.client_secret,
          ),
        )
        dispatch(
          setSubscriptionIdAction(
            createSubscriptionResponse.data.purchase.subscription_id,
          ),
        )

        const cardPaymentResponseFourth = await stripe.confirmCardPayment(
          retryResponseThird.data.subscription.client_secret,
          {
            payment_method: createPaymentResponse.paymentMethod.id,
            save_payment_method: true,
            return_url: getRedirectUrl(paymentPageId),
          },
        )

        if (
          cardPaymentResponseFourth?.paymentIntent &&
          !cardPaymentResponseFourth?.error
        ) {
          logSuccessfulPayment({
            ...commonPurchaseSuccessParams,
            price:
              cardPaymentResponseFourth.paymentIntent.amount / CENTS_IN_DOLLAR,
            trialPrice:
              cardPaymentResponseFourth.paymentIntent.amount / CENTS_IN_DOLLAR,
            trialPeriodDays:
              retryResponseThird.data.subscription.trial_period_days,
            subscriptionId:
              retryResponseThird.data.subscription.subscription_id,
            discountApplied:
              retryResponseThird.data.subscription.discount_applied,
            paymentMethod,
            goal,
          })

          dispatch(
            sendUserConfigAction({
              payment_currency: currency,
              payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
              is_download_visited: false,
              discount_applied:
                !!retryResponseThird.data.subscription.discount_applied,
              subscription_price: mainPrice,
              subscription_duration: `${periodQuantity}${periodName}`,
              price_id: priceId,
              trial_price: trialPrice,
              trial_period: trialPeriodDays,
            }),
          )
          dispatch(stopFetching(PURCHASE))
          createPaymentResFromDigitalWallet?.complete('success')
          return
        }

        logFailedPayment({
          ...commonPurchaseFailedParams,
          price: mainPrice,
          paymentResponse: cardPaymentResponseFourth.error,
          paymentMethod,
          goal,
        })

        dispatch(
          setErrorAction(
            getErrorActionPayload(cardPaymentResponseFourth.error),
          ),
        )

        // Needed for reset invoice on BE
        await paymentApi.retryPayment({
          uuid,
          stripeError: cardPaymentResponseFourth.error as StripeError,
        })

        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      const { paymentIntent } = cardPaymentResponseFirst

      if (paymentIntent.status === 'requires_payment_method') {
        eventLogger.logPurchaseFailed({
          ...commonPurchaseFailedParams,
          error: {
            type: 'requires_payment_method',
          },
          paymentMethod,
          goal,
        })
        dispatch(setErrorAction('Payment failed'))
        dispatch(stopFetching(PURCHASE))
        createPaymentResFromDigitalWallet?.complete('fail')
        return
      }

      const threeDSecureURL = paymentIntent.next_action?.redirect_to_url?.url

      if (paymentIntent.status === 'requires_action' && threeDSecureURL) {
        dispatch(set3DSecureIframeUrlAction(threeDSecureURL))
        dispatch(stopFetching(PURCHASE))
        return
      }

      logSuccessfulPayment({
        ...commonPurchaseSuccessParams,
        trialPeriodDays:
          createSubscriptionResponse.data.purchase.trial_period_days,
        subscriptionId:
          createSubscriptionResponse.data.purchase.subscription_id,
        paymentMethod,
        goal,
      })

      dispatch(
        sendUserConfigAction({
          payment_currency: currency,
          payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
          is_download_visited: false,
          subscription_price: mainPrice,
          subscription_duration: `${periodQuantity}${periodName}`,
          price_id: priceId,
          trial_price: trialPrice,
          trial_period: trialPeriodDays,
        }),
      )
      dispatch(stopFetching(PURCHASE))
      createPaymentResFromDigitalWallet?.complete('success')
    } catch (error: any) {
      dispatch(setErrorAction(error.toString()))
      dispatch(stopFetching(PURCHASE))
      createPaymentResFromDigitalWallet?.complete('fail')
    }
  }

export const check3DSecure =
  (stripe: Stripe, goal: string): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const priceId = selectSubscriptionLookupKey(state)
    const currentPrice = selectSubscriptionMainPrice(state)
    const trialPeriodPrice = selectSubscriptionTrialPrice(state)
    const clientSecret = selectPaymentClientSecret(state)
    const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
    const subscriptionId = selectCreatedSubscriptionId(state)
    const periodName = selectSubscriptionPeriodName(state)
    const periodQuantity = selectSubscriptionPeriodQuantity(state)
    const currency = selectCurrency(state)

    if (!priceId || !currentPrice) {
      console.error('Error: no subscriptions plan selected')
      return
    }

    if (!clientSecret) {
      console.error('Error: client secret is needed')
      return
    }

    dispatch(startFetching(CHECK_3D_SECURE))

    const commonPurchaseSuccessParams = getPurchaseSuccessEventParams(state)
    const commonPurchaseFailedParams = getPurchaseFailedEventParams(state)

    const response = await stripe.retrievePaymentIntent(clientSecret)

    if (response.paymentIntent?.status === 'succeeded') {
      logSuccessfulPayment({
        ...commonPurchaseSuccessParams,
        trialPeriodDays,
        subscriptionId,
        goal,
      })

      dispatch(reset3DSecureIframeUrlAction())
      dispatch(
        sendUserConfigAction({
          payment_currency: currency,
          payment_method: PaymentMethod.CREDIT_CARD,
          is_download_visited: false,
          subscription_price: currentPrice,
          subscription_duration: `${periodQuantity}${periodName}`,
          price_id: priceId,
          trial_price: trialPeriodPrice,
          trial_period: trialPeriodDays,
        }),
      )
      return
    }

    if (response.paymentIntent?.status === 'requires_payment_method') {
      eventLogger.logPurchaseFailed({
        ...commonPurchaseFailedParams,
        error: {
          type: 'requires_payment_method',
        },
        goal,
      })
      dispatch(reset3DSecureIframeUrlAction())
      dispatch(setErrorAction('Payment failed'))
      dispatch(stopFetching(CHECK_3D_SECURE))
      return
    }

    if (response.error) {
      const {
        error: { type, code, message },
      } = response

      eventLogger.logPurchaseFailed({
        ...commonPurchaseFailedParams,
        error: {
          type,
          code,
          description: message,
        },
        goal,
      })
      dispatch(reset3DSecureIframeUrlAction())
      dispatch(setErrorAction(message || type))
      dispatch(stopFetching(CHECK_3D_SECURE))
      return
    }

    dispatch(reset3DSecureIframeUrlAction())
    dispatch(setErrorAction('Error: unhandled checking 3D Secure error'))
    dispatch(stopFetching(CHECK_3D_SECURE))
  }
