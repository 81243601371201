import React, { useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { BenefitsV3 } from 'components/Benefits'
import { ReviewsSlider } from 'components/ReviewSlider'
import { Spinner } from 'components/Spinner'

import { getSubscriptionListAction } from 'root-redux/actions/common'
import { selectSubscriptionList } from 'root-redux/selects/common'

import { useIsInViewport } from 'hooks/useIsInViewport'
import { useUserData } from 'hooks/useUserData'

import { compareSubscriptionList } from 'helpers/compareSubscriptionList'
import { createProductId } from 'helpers/createProductId'

import { AdvantagesSlider } from 'modules/subscriptions/components/AdvantagesSlider'
import { SubheaderWithTimer } from 'modules/subscriptions/components/SubheaderWithTimer'
import { SummaryBlock } from 'modules/subscriptions/components/SummaryBlock'
import { Timer } from 'modules/subscriptions/components/Timer'
import { Security } from 'modules/subscriptions/components/security'
import { PlanItemVariant2 } from 'modules/subscriptions/components/variant2/PlanItemVariant2'
import { SelectPlanBlockVariant2 } from 'modules/subscriptions/components/variant2/SelectPlanBlockVariant2'
import { usePaywall } from 'modules/subscriptions/hooks/usePaywall'
import {
  BENEFITS_V1,
  BENEFITS_V2,
} from 'modules/subscriptions/pages/SubscriptionsVariant2/constants'

import { TPageProps } from 'models/common.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { SubscriptionTag } from 'root-constants/common'

import { StyledSubscriptionsVariant2 as S } from './SubscriptionsVariant2.styles'

export const SubscriptionsVariant2: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const descriptionRef = useRef<HTMLDivElement>(null)
  const selectPlanBlockRef = useRef<HTMLDivElement>(null)
  const buttonElementRef = useRef<HTMLButtonElement>(null)
  const subscriptionsList = useSelector(
    selectSubscriptionList,
    compareSubscriptionList,
  )
  const isBottomButtonVisible = useIsInViewport(buttonElementRef)
  const { usersPriority, isSexUsersPriority } = useUserData()
  const { hasPrices, handleSelectSubscription, handleShowPayment } =
    usePaywall(nextPagePath)
  useLayoutEffect(() => {
    dispatch(
      getSubscriptionListAction({
        tags: SubscriptionTag.NO_TAX,
      }),
    )
  }, [dispatch])

  useEffect(() => {
    if (!subscriptionsList.length) return

    eventLogger.logSalePageShown({
      productIds: subscriptionsList.map(
        ({ mainPrices: { periodName, periodQuantity, fullPrice } }) =>
          createProductId({
            periodName,
            periodQuantity,
            price: fullPrice,
          }),
      ),
      screenName: ScreenName.ONBOARDING,
      goal: usersPriority,
    })
  }, [usersPriority, subscriptionsList])

  useEffect(() => {
    if (isBottomButtonVisible) {
      eventLogger.logScrollToBottom()
    }
  }, [isBottomButtonVisible])

  const handleTimerButtonClick = () => {
    selectPlanBlockRef.current?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }

  const title = useMemo(
    () =>
      isSexUsersPriority
        ? 'subscriptionsV2.titleV1'
        : 'subscriptionsV2.titleV2',
    [isSexUsersPriority],
  )

  return !hasPrices ? (
    <Spinner />
  ) : (
    <S.Wrapper>
      <SubheaderWithTimer
        onClick={handleTimerButtonClick}
        elemForComparisonRef={descriptionRef}
      />
      <S.Column>
        <S.Title>
          <Trans i18nKey={title} />
        </S.Title>
        <Timer />
        <S.TitleDescription ref={descriptionRef}>
          <Trans i18nKey="subscriptionsV2.titleDescription" />
        </S.TitleDescription>
      </S.Column>
      <SummaryBlock />
      <S.Divider />
      <S.Column>
        <S.Subtitle style={{ marginBottom: 16 }}>What you get</S.Subtitle>
        <AdvantagesSlider style={{ marginBottom: 48 }} />
        {isSexUsersPriority ? (
          <BenefitsV3 benefits={BENEFITS_V1} />
        ) : (
          <BenefitsV3 benefits={BENEFITS_V2} />
        )}
      </S.Column>
      <S.Divider />
      <S.Column>
        <S.PlansTitle>{t('subscriptionsV2.plansTitle')}</S.PlansTitle>
        <SelectPlanBlockVariant2
          ref={selectPlanBlockRef}
          onSelect={handleSelectSubscription}
          SelectPlanItem={PlanItemVariant2}
        />
        <S.Button onClick={handleShowPayment}>
          {t('actions.getMyPlan')}
        </S.Button>
        <S.SubscriptionDescription />
      </S.Column>
      <S.Divider />
      <ReviewsSlider />
      <S.Divider />
      <S.Column>
        <S.Guarantee text="commonComponents.guarantee.textV1" />
        <Security />
        <S.Button buttonRef={buttonElementRef} onClick={handleShowPayment}>
          {t('actions.startNow')}
        </S.Button>
      </S.Column>
    </S.Wrapper>
  )
}
