import { ConflictFeelingsValue } from 'root-constants/common'

export const TRANSLATION_FAMILY = 'onboarding.conflictFeelingsV1'
export const CUSTOM_OPTIONS = [
  { key: 'none', value: ConflictFeelingsValue.NONE },
]
export const OPTIONS = [
  {
    key: 'threatened',
    value: ConflictFeelingsValue.THREATENED,
  },
  {
    key: 'guilt',
    value: ConflictFeelingsValue.GUILT,
  },
  {
    key: 'powerless',
    value: ConflictFeelingsValue.POWERLESS,
  },
  {
    key: 'uncomfortable',
    value: ConflictFeelingsValue.UNCOMFORTABLE,
  },
  ...CUSTOM_OPTIONS,
]
