import styled from 'styled-components'

import { QuestionTitle, baseColumnStyles } from 'common-styles'
import { BIG_COLUMN_WIDTH, MAX_PHONE_WIDTH } from 'root-constants/common'

export const StyledRelationshipDuration = {
  Column: styled.div`
    ${baseColumnStyles};
    padding: 20px;
    width: 100%;

    @media (min-width: ${MAX_PHONE_WIDTH}px) {
      width: ${BIG_COLUMN_WIDTH}px;
    }
  `,
  Title: styled(QuestionTitle)`
    line-height: 28px;
  `,
}
