import styled from 'styled-components'

import globe from 'assets/images/globe.svg'

import { QuestionTitle, baseColumnStyles } from 'common-styles'
import { Color } from 'root-constants/common'

export const StyledComments = {
  Column: styled.div`
    ${baseColumnStyles};
    padding: 15px 0 32px;
  `,
  QuestionTitle: styled(QuestionTitle)`
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 6px;

    @media (min-width: 350px) {
      width: 340px;
    }
  `,
  ListItem: styled.li`
    background-color: #fff;
    border: 1px solid #423fba;
    box-shadow: 0 16px 23px #0b073e;
    border-radius: 12px;
    margin: 0 16px 5px;
    padding: 13px 20px 13px 14px;

    &:last-child {
      margin-bottom: 18px;
    }

    p {
      color: ${Color.BLACK_100};
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
    }
  `,
  UserInfo: styled.div`
    display: flex;
    gap: 12px;
    margin-bottom: 10px;
  `,
  UserName: styled.h2`
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #4946d2;
  `,
  Recommend: styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #7b7b7d;
    background-image: url(${globe});
    background-size: 13px;
    background-position: left center;
    background-repeat: no-repeat;
    padding-left: 16px;

    strong {
      font-weight: 500;
      color: #4946d2;
    }
  `,
}
