import React, { useMemo } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton2 } from 'components/QuestionButton2'
import { TitleContainer } from 'components/TitleContainer'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledIntellectualDifferences as S } from './IntellectualDifferences.styles'

const QUESTION =
  'How do you and your partner deal with intellectual differences or conflicts?'
const OPTION_VALUES = {
  discuss: `We openly discuss our differences and respect each other's opinions`,
  avoidDiscussing: 'We avoid discussing certain topics altogether',
  argue: 'We argue and try to prove our point',
  other: 'Other',
}

export const IntellectualDifferencesVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <TitleContainer
          title={<S.QuestionTitle>{QUESTION}</S.QuestionTitle>}
          subtitle={
            <S.Note>(e.g. political views, levels of education, etc.)</S.Note>
          }
        />
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.discuss}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.discuss}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.avoidDiscussing}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.avoidDiscussing}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.argue}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.argue}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.other}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.other}</QuestionButton2>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
