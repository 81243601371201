import styled from 'styled-components'

import { QuestionTitle, baseColumnStyles } from 'common-styles'
import { Color } from 'root-constants/common'

export const StyledJealousyDescription = {
  Column: styled.div`
    ${baseColumnStyles};
    padding: 40px 0;
  `,
  Title: styled(QuestionTitle)`
    margin: 0 -15px 14px;
    line-height: 28px;
  `,
  Subtitle: styled.h3`
    margin-bottom: 22px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: var(--base-text-color);

    span {
      font-weight: 800;
      color: ${Color.LILAC_130};
    }
  `,
  ImageContainer: styled.div`
    aspect-ratio: 321 / 173;
    margin: 0 -6px 20px;
    width: calc(100% + 12px);
  `,
  Percents: styled.p`
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 42px;
    line-height: 44px;
    color: var(--base-text-color);
  `,
  Description: styled.p`
    margin: 0 -15px 32px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: var(--base-text-color);

    span {
      font-weight: 800;
      color: ${Color.LILAC_130};
    }
  `,
}
