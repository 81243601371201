import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SupportAction } from 'components/SupportAction'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import {
  selectSubscriptionMainPrice,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPrice,
} from 'modules/payment/redux/selects'

import {
  AUTO_RENEWED_BASIS,
  SELECTED_SUBSCRIPTION_PERIOD_NAME,
} from 'root-constants/common'

import { StyledDisclaimerV1 as S } from './DisclaimerV1.styles'

type TProps = {
  className?: string
}
export const DisclaimerV1: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  const subscriptionTrialPeriodPrice = useSelector(selectSubscriptionTrialPrice)

  const mainPrice = useSelector(selectSubscriptionMainPrice)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)

  return (
    <S.DisclaimerText className={className}>
      {subscriptionTrialPeriodPrice ? (
        <>
          <Trans
            i18nKey="commonComponents.disclaimerV1.disclaimerTrial"
            values={{
              autoRenewBasis: AUTO_RENEWED_BASIS[periodQuantity || 1],
              periodQuantity,
              periodName: SELECTED_SUBSCRIPTION_PERIOD_NAME[trialPeriodDays],
              fullPrice: mainPrice,
            }}
          />
          <TermsOfUseLink />
          {t('commonComponents.disclaimerV1.contactUs')}
          <SupportAction />
        </>
      ) : (
        <>
          <Trans
            i18nKey="commonComponents.disclaimerV1.disclaimerNoTrial"
            values={{ mainPrice }}
          />
          <TermsOfUseLink /> {t('commonComponents.disclaimerV1.contactUs')}
          <SupportAction />
        </>
      )}
    </S.DisclaimerText>
  )
}
