import { CurrentEnvironment } from 'root-constants/common'

const CURRENT_META_ENV = 'current-env'

export const getCurrentEnvironment = () => {
  const currentEnv = (
    document.querySelector(`meta[name='${CURRENT_META_ENV}'`) as HTMLMetaElement
  ).content as CurrentEnvironment

  return {
    isProdEnvironment: currentEnv === CurrentEnvironment.PROD,
    isDevEnvironment: currentEnv === CurrentEnvironment.DEV,
    isStageEnvironment: currentEnv === CurrentEnvironment.STAGE,
  }
}
