import styled from 'styled-components'

import { Award } from 'components/Award'
import { BenefitsV2 } from 'components/Benefits'
import { Button } from 'components/Button'

import { AppBlock } from 'modules/payment/components/AppBlock'
import { DisclaimerV2 } from 'modules/payment/components/Disclaimer'
import { HeaderWithTimer } from 'modules/payment/components/HeaderWithTimer'
import { PaywallTimerBlock } from 'modules/payment/components/PaywallTimerBlock'
import { PersonalInfoBlock } from 'modules/payment/components/PersonalInfoBlock'
import { PlanBlock } from 'modules/payment/components/PlanBlock'
import { SatisfactionBlockV2 } from 'modules/payment/components/SatisfactionBlock'
import { SuccessStoryBlock } from 'modules/payment/components/SuccessStoryBlock'
import { PAYMENT_TRIAL_V2_HEADER } from 'modules/payment/pages/constants'
import { Security } from 'modules/subscriptions/components/security'
import { TrialSelectPlanBlockVariant1 } from 'modules/subscriptions/components/trial/TrialSelectPlanBlockVariant1'

import bg1 from 'assets/images/payment-trial-v1-bg-1.svg'
import bg3 from 'assets/images/payment-trial-v1-bg-3.svg'
import bg4 from 'assets/images/payment-trial-v1-bg-4.svg'

import { baseColumnStyles } from 'common-styles'
import { BIG_COLUMN_WIDTH, Color, MAX_PHONE_WIDTH } from 'root-constants/common'

export const StyledPaymentTrialV2 = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: linear-gradient(188.98deg, #191465 0%, #070525 93.18%);

    padding: ${PAYMENT_TRIAL_V2_HEADER}px 0 30px 0;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    width: 100%;

    @media (min-width: ${MAX_PHONE_WIDTH}px) {
      width: ${BIG_COLUMN_WIDTH}px;
    }
  `,
  Header: styled(HeaderWithTimer)`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    width: 100%;
    margin: 0 auto 16px auto;
    background-color: ${Color.VIOLET_140};

    strong {
      color: ${Color.WHITE_100};
    }
    span {
      color: ${Color.LILAC_120};
    }
    @media (min-width: ${MAX_PHONE_WIDTH}px) {
      width: ${BIG_COLUMN_WIDTH}px;
    }
  `,
  SatisfactionBlock: styled(SatisfactionBlockV2)`
    margin: 0 0 32px;
    padding: 0 12px;
  `,
  Title: styled.h1`
    width: 100%;
    padding: 0 20px;
    margin: 0 0 32px 0;
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    color: ${Color.LILAC_120};

    span {
      color: ${Color.LILAC_130};
    }
  `,
  PersonalInfoBlock: styled(PersonalInfoBlock)`
    padding: 0 20px;
    margin: 0 0 32px 0;
  `,
  Timer: styled(PaywallTimerBlock)`
    width: 100%;
    margin: 0 0 32px 0;
    color: ${Color.WHITE_100};
    background: linear-gradient(180deg, #e8636b 0%, #db3540 100%);
    position: relative;
    z-index: 2;

    &:after {
      position: absolute;
      content: url(${bg1});
      right: 0;
      z-index: 1;
    }

    @media (min-width: ${MAX_PHONE_WIDTH}px) {
      width: ${BIG_COLUMN_WIDTH}px;
    }
  `,
  TrialSelectPlanBlockVariant1: styled(TrialSelectPlanBlockVariant1)`
    padding: 0 24px;
    margin: 0 0 25px 0;
    position: relative;
    z-index: 2;
  `,
  Button: styled(Button)`
    margin: 0 0 25px 0;
    position: relative;
    z-index: 2;
  `,
  Divider: styled.div`
    height: 1px;
    background-color: #362f99;
    margin: 0 0 32px 0;
    position: relative;
    width: 100%;

    @media (min-width: ${MAX_PHONE_WIDTH}px) {
      width: ${BIG_COLUMN_WIDTH}px;
    }
  `,
  Benefits: styled(BenefitsV2)`
    width: 100%;
    padding: 0 24px 0 24px;
    margin: 0 0 40px 0;
    position: relative;
    z-index: 2;
  `,
  AppBlock: styled(AppBlock)`
    width: 100%;
    margin: 0 0 50px 0;
  `,
  Award: styled(Award)`
    width: 100%;
    margin: 0 0 22px 0;
    position: relative;
    z-index: 2;

    &:before {
      position: absolute;
      z-index: 1;
      content: url(${bg3});
      right: 0;
      top: 0;
    }
  `,
  NavigationButton: styled(Button)`
    margin: 0 0 48px 0;
    position: relative;
    z-index: 2;
  `,
  PlanBlock: styled(PlanBlock)`
    padding: 0 24px;
    margin: 0 0 32px 0;
  `,
  SuccessStoryBlock: styled(SuccessStoryBlock)`
    width: 100%;
    padding: 0 24px;
    margin: 0 0 32px 0;
    position: relative;
    z-index: 2;

    &:before {
      position: absolute;
      z-index: 1;
      content: url(${bg3});
      right: 0;
      top: 0;
    }
  `,
  GuaranteeContainer: styled.div`
    width: 100%;
    margin: 0 0 32px 0;
    padding: 0 32px 0 32px;
    position: relative;

    &:before {
      position: absolute;
      z-index: 1;
      content: url(${bg4});
      left: 0;
    }
  `,
  Security: styled(Security)`
    margin: 0 0 24px 0;
  `,
  Disclaimer: styled(DisclaimerV2)`
    margin: 0 0 32px 0;
    padding: 0 24px;
  `,
}
