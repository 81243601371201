import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledWhatYouSeeIntroVariant1 as S } from './WhatYouSeeIntroVariant1.styles'

export const WhatYouSeeIntroVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.whatYouSeeIntroV1.question', { lng: 'en' }),
    nextPagePath,
  })

  return (
    <S.PageContainer>
      <S.Column>
        <S.Title>
          <Trans i18nKey="onboarding.whatYouSeeIntroV1.title" />
        </S.Title>
        <S.Description>
          {t('onboarding.whatYouSeeIntroV1.description')}
        </S.Description>
        <S.StickyContainer>
          <S.Button onClick={() => handleContinue('')}>
            {t('actions.continue')}
          </S.Button>
        </S.StickyContainer>
      </S.Column>
    </S.PageContainer>
  )
}
