import styled from 'styled-components'

import { Button } from 'components/Button'

import hearts from 'assets/images/hearts.svg'

import { baseColumnStyles } from 'common-styles'
import { BIG_COLUMN_WIDTH, Color } from 'root-constants/common'

export const StyledFortunatelyVariant1 = {
  Column: styled.div`
    ${baseColumnStyles};
    width: ${BIG_COLUMN_WIDTH}px;
    padding: 20px 26px;
  `,
  Banner: styled.div`
    aspect-ratio: 286/259;
    margin: 0 0 16px 0;
    width: 286px;
  `,
  Title: styled.h1`
    color: ${Color.LILAC_120};
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    margin: 0 0 16px 0;
    text-align: center;
  `,
  Subtitle: styled.p`
    color: ${Color.LILAC_120};
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 88px 0;
    position: relative;
    text-align: center;

    strong {
      color: ${Color.LILAC_130};
      font-weight: 800;
    }

    &:after {
      content: url(${hearts});
      position: absolute;
      right: 20px;
    }
  `,
  Button: styled(Button)`
    --next-page-button-border-radius: 100px;
    --next-page-button-height: 62px;
  `,
}
