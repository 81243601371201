import { FactorsValue } from 'root-constants/common'

export const TRANSLATION_FAMILY = 'onboarding.factorsV1'
export const CUSTOM_OPTIONS = [{ key: 'none', value: FactorsValue.NONE }]
export const OPTIONS = [
  {
    key: 'distance',
    value: FactorsValue.DISTANCE,
  },
  {
    key: 'desire',
    value: FactorsValue.DESIRE,
  },
  {
    key: 'infidelity',
    value: FactorsValue.INFIDELITY,
  },
  {
    key: 'depression',
    value: FactorsValue.DEPRESSION,
  },
  {
    key: 'postpartum',
    value: FactorsValue.POSTPARTUM,
  },
  {
    key: 'anxiety',
    value: FactorsValue.ANXIETY,
  },
  {
    key: 'issues',
    value: FactorsValue.ISSUES,
  },
  {
    key: 'body',
    value: FactorsValue.BODY,
  },
  ...CUSTOM_OPTIONS,
]
