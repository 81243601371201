import { MainGoalsV7Value } from 'root-constants/common'

export const TRANSLATION_FAMILY = 'onboarding.mainGoalsV7'

export const OPTIONS_V1 = [
  {
    key: 'communication',
    value: MainGoalsV7Value.COMMUNICATION,
  },
  {
    key: 'reduce',
    value: MainGoalsV7Value.REDUCE,
  },
  {
    key: 'improve',
    value: MainGoalsV7Value.IMPROVE,
  },
  {
    key: 'overcome',
    value: MainGoalsV7Value.OVERCOME,
  },
  {
    key: 'feel',
    value: MainGoalsV7Value.FEEL,
  },
  {
    key: 'understand',
    value: MainGoalsV7Value.UNDERSTAND,
  },
  {
    key: 'build',
    value: MainGoalsV7Value.BUILD,
  },
  {
    key: 'evaluate',
    value: MainGoalsV7Value.EVALUATE,
  },
  {
    key: 'more',
    value: MainGoalsV7Value.MORE,
  },
]

export const OPTIONS_V2 = [
  {
    key: 'communication',
    value: MainGoalsV7Value.COMMUNICATION,
  },
  {
    key: 'reduce',
    value: MainGoalsV7Value.REDUCE,
  },
  {
    key: 'improve',
    value: MainGoalsV7Value.IMPROVE,
  },
  {
    key: 'spice',
    value: MainGoalsV7Value.SPICE,
  },
  {
    key: 'check',
    value: MainGoalsV7Value.CHECK,
  },
  {
    key: 'myself',
    value: MainGoalsV7Value.MYSELF,
  },
  {
    key: 'feel',
    value: MainGoalsV7Value.FEEL,
  },
]
