import styled from 'styled-components'

import { Button } from 'components/Button'

import { QuestionTitle, baseColumnStyles } from 'common-styles'

export const StyledSpiritualConnection = {
  Column: styled.div`
    ${baseColumnStyles};
    padding: 40px 0 70px;
  `,
  Title: styled(QuestionTitle)`
    margin: 0 -10px 80px;
    line-height: 28px;
  `,
  Button: styled(Button)`
    height: 62px;
  `,
}
