import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  selectSubscriptionMainPrice,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialLookupKey,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPrice,
} from 'modules/payment/redux/selects'

import { StyledTotalAmount as S } from './TotalAmount.styles'

export const TotalAmount: React.FC = () => {
  const trialPriceId = useSelector(selectSubscriptionTrialLookupKey)
  const trialCurrentPrice = useSelector(selectSubscriptionTrialPrice)
  const trialPeriodDays = useSelector(selectSubscriptionTrialPeriodDays)
  const mainPrice = useSelector(selectSubscriptionMainPrice)
  const periodName = useSelector(selectSubscriptionPeriodName)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)

  const subscriptionDescription = useMemo(
    () =>
      trialPriceId
        ? `per ${trialPeriodDays}-day trial`
        : `per ${periodQuantity}-${periodName} plan`,

    [trialPriceId, trialPeriodDays, periodQuantity, periodName],
  )

  return (
    <S.Container>
      <S.TitleContainer>Total:</S.TitleContainer>
      <div>
        <S.PriceContainer>${trialCurrentPrice || mainPrice} </S.PriceContainer>
        <S.SubscriptionDescription>
          {subscriptionDescription}
        </S.SubscriptionDescription>
      </div>
    </S.Container>
  )
}
