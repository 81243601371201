import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { selectSubscriptionList } from 'root-redux/selects/common'

import { setSelectedSubscriptionAction } from 'modules/payment/redux/actions'
import { selectSubscriptionId } from 'modules/payment/redux/selects'

export const useSetDefaultSubscription = (): void => {
  const dispatch = useDispatch()
  const subscriptions = useSelector(selectSubscriptionList, shallowEqual)
  const selectedSubscriptionId = useSelector(selectSubscriptionId)

  useEffect(() => {
    const defaultSubscription =
      subscriptions.find(({ isDefault }) => !!isDefault) || subscriptions[0]
    if (!selectedSubscriptionId) {
      dispatch(setSelectedSubscriptionAction(defaultSubscription))
    }
  }, [dispatch, subscriptions, selectedSubscriptionId])
}
