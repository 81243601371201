import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectScreenName } from 'root-redux/selects/common'

import { SubscriptionButtonText } from 'modules/subscriptions/pages/constants'

import { eventLogger } from 'services/eventLogger.service'

import { StyledHeaderWithTimer as S } from './HeaderWithTimer.styles'

type TProps = {
  className?: string
  hasDiscount?: boolean
  discount?: number
  timer: React.ReactElement
  onBtnClick: () => void
}
export const HeaderWithTimer: React.FC<TProps> = ({
  className,
  hasDiscount = false,
  discount,
  timer,
  onBtnClick,
}) => {
  const { t } = useTranslation()

  const screenName = useSelector(selectScreenName)

  const handleBtnClick = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault()

      eventLogger.logPlansPageButtonTapped({
        screenName,
        buttonNumber: 'timer',
        buttonText: SubscriptionButtonText.GET_PLAN_WITH_TIMER,
      })

      onBtnClick()
    },
    [onBtnClick, screenName],
  )

  return (
    <S.Container className={className}>
      <S.TimerBlock>
        {hasDiscount && (
          <S.TimerLabel>
            {t('commonComponents.timer.discountLabel', { discount })}
          </S.TimerLabel>
        )}
        {timer}
      </S.TimerBlock>
      <S.Button onClick={(e) => handleBtnClick(e)}>
        {t('actions.getMyPlan')}
      </S.Button>
    </S.Container>
  )
}
