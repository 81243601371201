import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useTimer } from 'hooks/useTimer'

import { getDecoratedTimerValue } from 'helpers/rootHelpers'

import logo from 'assets/images/sprite/logo.svg'

import { StyledTimer as S } from './Timer.styles'

type TProps = {
  value: number
  setTimerValue: (timerValue: number) => void
  isHeaderTimer?: boolean
  hasLabel?: boolean
}
export const Timer: React.FC<TProps> = ({
  value,
  setTimerValue,
  hasLabel,
  isHeaderTimer = false,
}) => {
  const [isTimerExpired, setIsTimerExpired] = useState(false)

  const { t } = useTranslation()

  useTimer(value, setTimerValue)

  const { minutes, seconds } = useMemo(
    () => getDecoratedTimerValue(value),
    [value],
  )

  useEffect(() => {
    if (!value && !Number.isNaN(value)) setIsTimerExpired(true)
  }, [value])

  return isHeaderTimer && isTimerExpired ? (
    <S.Logo height="39" svg={logo} />
  ) : (
    <S.Timer>
      <S.TimerSign>
        <strong>{minutes}</strong>
        {hasLabel && (
          <S.TimerLabel>{t('commonComponents.timer.minutes')}</S.TimerLabel>
        )}
      </S.TimerSign>
      <S.Separator>:</S.Separator>
      <S.TimerSign>
        <strong>{seconds}</strong>
        {hasLabel && (
          <S.TimerLabel>{t('commonComponents.timer.seconds')}</S.TimerLabel>
        )}
      </S.TimerSign>
    </S.Timer>
  )
}
