import { RelationshipDurationValue, StatusValue } from 'root-constants/common'

export const STATUS_RELATIONSHIP_VALUES = {
  [StatusValue.DATING]: 'paymentTrialV1.statusRelationship.dating',
  [StatusValue.SERIOUS]: 'paymentTrialV1.statusRelationship.serious',
  [StatusValue.MARRIED]: 'paymentTrialV1.statusRelationship.married',
  [StatusValue.COMPLICATED]: 'paymentTrialV1.statusRelationship.complicated',
}

export const RELATIONSHIP_DURATION_VALUES = {
  [RelationshipDurationValue.LESS_6_MONTHS]:
    'paymentTrialV1.durationRelationship.less6Months',
  [RelationshipDurationValue.MORE_6_MONTHS]:
    'paymentTrialV1.durationRelationship.more6Months',
  [RelationshipDurationValue.MORE_2_YEARS]:
    'paymentTrialV1.durationRelationship.more2Years',
  [RelationshipDurationValue.MORE_4_YEARS]:
    'paymentTrialV1.durationRelationship.more4Years',
}
