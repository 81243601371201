import React, { useMemo, useState } from 'react'

import { Option, OptionType } from 'components/Option'
import { PageContainer } from 'components/PageContainer'
import { SvgImage } from 'components/SvgImage'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import faces from 'assets/images/sprite/intimacy-area-faces.svg'
import logo from 'assets/images/sprite/logo.svg'

import { IntimacyArea } from 'root-constants/common'

import { StyledIntimacyArea as S } from './IntimacyArea.styles'

const QUESTION =
  'Which area of intimacy do you want to deepen with your partner?'

export const IntimacyAreaVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers([...answers, value])
        } else {
          setAnswers(answers.filter((item) => item !== value))
        }
      },
    }),
    [pageId, answers],
  )

  return (
    <PageContainer>
      <S.HeaderContainer>
        <S.Logo svg={logo} width={69} height={39} />
      </S.HeaderContainer>

      <S.Column>
        <S.ImageContainer>
          <SvgImage svg={faces} width={170} />
        </S.ImageContainer>
        <S.Title>{QUESTION}</S.Title>

        <S.OptionTitle>You can select more than one option</S.OptionTitle>
        <S.OptionContainer>
          <Option {...optionProps} value={IntimacyArea.PHYSICAL}>
            <S.QuestionButton>{IntimacyArea.PHYSICAL}</S.QuestionButton>
          </Option>
          <Option {...optionProps} value={IntimacyArea.EMOTIONAL}>
            <S.QuestionButton>{IntimacyArea.EMOTIONAL}</S.QuestionButton>
          </Option>
          <Option {...optionProps} value={IntimacyArea.SPIRITUAL}>
            <S.QuestionButton>{IntimacyArea.SPIRITUAL}</S.QuestionButton>
          </Option>
          <Option {...optionProps} value={IntimacyArea.INTELLECTUAL}>
            <S.QuestionButton>{IntimacyArea.INTELLECTUAL}</S.QuestionButton>
          </Option>
        </S.OptionContainer>
      </S.Column>
      <S.StickyButtonContainer>
        <S.Button
          onClick={() => handleContinue(answers)}
          disabled={!answers.length}
        >
          Continue
        </S.Button>
      </S.StickyButtonContainer>
    </PageContainer>
  )
}
