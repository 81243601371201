import styled, { css } from 'styled-components'

import { BlinkingDots } from 'components/BlinkingDots'

import { Color } from 'root-constants/common'

type TProps = {
  value: number
  radius?: number
}

const circleProgress = (isBackground = false) => css<TProps>`
  ${({ value, radius = 45 }) => {
    const length = radius * Math.PI * 2
    return css`
      stroke-width: ${radius / 5};
      stroke-dasharray: ${isBackground ? length : length * (value / 100)}
        ${length};
    `
  }};
  stroke-linecap: round;
  fill: transparent;
  transition: stroke-dasharray 0.2s;
`

export const StyledProgressBarRounded = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  `,
  ProgressBar: styled.div`
    position: relative;
    width: 107px;
    height: 107px;
    margin: 0 0 16px 0;
  `,
  Svg: styled.svg<{ value: number; radius?: number }>`
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);
    circle {
      ${circleProgress(true)};
      stroke: ${Color.LILAC_120};
      opacity: 0.3;
    }
    circle + circle {
      ${circleProgress()};
      stroke: ${Color.LILAC_130};
      opacity: 1;
    }
  `,
  Label: styled.div`
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    color: ${Color.LILAC_120};

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,
  TitleWrapper: styled.div`
    display: flex;
    align-items: center;
  `,
  ProgressTitle: styled.p`
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: #bcc9d0;
  `,
  BlinkingDots: styled(BlinkingDots)`
    font-size: 14px;
    font-weight: 700;
    color: #bcc9d0;
  `,
}
