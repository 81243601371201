import { StatusValue } from 'root-constants/common'

export const TRANSLATION_FAMILY_V1 = 'onboarding.relationshipStatusV1'
export const TRANSLATION_FAMILY_V2 = 'onboarding.relationshipStatusV2'

export const OPTIONS = [
  {
    key: 'dating',
    value: StatusValue.DATING,
  },
  {
    key: 'serious',
    value: StatusValue.SERIOUS,
  },
  {
    key: 'married',
    value: StatusValue.MARRIED,
  },
  {
    key: 'complicated',
    value: StatusValue.COMPLICATED,
  },
]
