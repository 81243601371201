import React from 'react'
import { useLocation } from 'react-router-dom'

import { Button } from 'components/Button'
import { PageContainer } from 'components/PageContainer'

import { useGetFlowGroupInfo } from 'hooks/useGetFlowGroupInfo'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import spiritualIntimacyDescription from 'assets/images/spiritual-intimacy-description.webp'

import { IntimacyArea } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import { StyledSpiritualIntimacyDescription as S } from './SpiritualIntimacyDescription.styles'

export const SpiritualIntimacyDescriptionVariant1: React.FC<TPageProps> = ({
  pageId,
}) => {
  const { search } = useLocation()
  const { isMultipleFlow, nextPagePath } = useGetFlowGroupInfo(
    IntimacyArea.SPIRITUAL,
  )

  const handleContinue = useNextStep({
    pageId,
    question: 'Spiritual Intimacy leads to greater emotional connection',
    nextPagePath: isMultipleFlow
      ? nextPagePath
      : `${PageId.FELT_LONELY_1}${search}`,
  })

  return (
    <PageContainer>
      <S.Column>
        <S.Title>
          Spiritual Intimacy leads to greater emotional connection
        </S.Title>

        <S.ImageContainer>
          <img
            src={spiritualIntimacyDescription}
            alt="spiritual-intimacy-description"
          />
        </S.ImageContainer>

        <S.Description>
          Couples who engage in regular spiritual practices are{' '}
          <strong>more</strong> likely to{' '}
          <strong>feel emotionally connected</strong> to their partner.
        </S.Description>
        <Button onClick={() => handleContinue('')}>Continue</Button>
      </S.Column>
    </PageContainer>
  )
}
