import React, { useMemo } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton2 } from 'components/QuestionButton2'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledPerfectDate as S } from './PerfectDate.styles'

const QUESTION = 'What is your idea of a perfect date with your partner?'
const OPTION_VALUES = {
  atHome: 'A quiet night at home',
  dinner: 'A romantic dinner out',
  activity: 'A fun activity or adventure',
  conversation: 'A meaningful conversation',
  other: 'Other',
}

export const PerfectDateVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.QuestionTitle>{QUESTION}</S.QuestionTitle>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.atHome}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.atHome}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.dinner}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.dinner}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.activity}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.activity}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.conversation}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.conversation}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.other}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.other}</QuestionButton2>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
