import React from 'react'

import { Button } from 'components/Button'
import { PageContainer } from 'components/PageContainer'

import { useNextStep } from 'hooks/useNextStep'
import { useUserData } from 'hooks/useUserData'

import { TPageProps } from 'models/common.model'

import user1 from 'assets/images/user-1.png'
import user2 from 'assets/images/user-2.png'
import user3 from 'assets/images/user-3.png'

import { StyledComments as S } from './Comments.styles'

export const CommentsVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { isSexUsersPriority } = useUserData()

  const handleContinue = useNextStep({
    pageId,
    question: 'Personalized plans help thousands of people',
    nextPagePath,
  })

  return (
    <PageContainer>
      <S.Column>
        {isSexUsersPriority ? (
          <S.QuestionTitle>
            Personalized plans help thousands of people to have better sex!
          </S.QuestionTitle>
        ) : (
          <S.QuestionTitle>
            Personalized plans help thousands of people to improve their
            intimacy!
          </S.QuestionTitle>
        )}
        <ul>
          <S.ListItem>
            <S.UserInfo>
              <img src={user1} alt="Marcus" height={36} />
              <div>
                <S.UserName>Marcus N.</S.UserName>
                <S.Recommend>
                  recommends <strong>UpLuv</strong>
                </S.Recommend>
              </div>
            </S.UserInfo>
            {isSexUsersPriority ? (
              <p>
                I highly recommend this app to anyone who wants to improve their
                sex life.
              </p>
            ) : (
              <p>
                The app is super helpful for couples who want to improve their
                intimacy and communication skills.
              </p>
            )}
          </S.ListItem>
          <S.ListItem>
            <S.UserInfo>
              <img src={user2} alt="Anna" height={36} />
              <div>
                <S.UserName>Anna S.</S.UserName>
                <S.Recommend>
                  recommends <strong>UpLuv</strong>
                </S.Recommend>
              </div>
            </S.UserInfo>
            {isSexUsersPriority ? (
              <p>
                If you&#39;re looking to improve your sex and intimacy life,
                this app is a must-have. There are tons of couple’s quizzes,
                games, and tips to get you started.
              </p>
            ) : (
              <p>
                The app has really helped us to have more deep conversations and
                connect on a deeper level.
              </p>
            )}
          </S.ListItem>
          <S.ListItem>
            <S.UserInfo>
              <img src={user3} alt="Maria" height={36} />
              <div>
                <S.UserName>Maria T.</S.UserName>
                <S.Recommend>
                  recommends <strong>UpLuv</strong>
                </S.Recommend>
              </div>
            </S.UserInfo>
            {isSexUsersPriority ? (
              <p>
                The tips and tasks in this app have been really helpful for
                reigniting the passion in my relationship.
              </p>
            ) : (
              <p>
                This app is a great way to help improve intimacy between
                partners. It provides exercises and tips on how to have more
                deep conversations, talk about everything openly, and improve
                communication in general.
              </p>
            )}
          </S.ListItem>
        </ul>
        <Button onClick={() => handleContinue('')}>Continue</Button>
      </S.Column>
    </PageContainer>
  )
}
