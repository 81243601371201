import styled from 'styled-components'

import { Button } from 'components/Button'

import { Color } from 'root-constants/common'

export const StyledHeaderWithTimer = {
  Container: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
  `,
  Button: styled(Button)`
    width: 168px;
    height: 48px;
    padding: 12px 18px;
    margin: 0;
    line-height: 22px;
  `,
  TimerBlock: styled.div`
    svg {
      display: block;
    }
    strong {
      font-weight: 800;
      font-size: 32px;
      line-height: 36px;
      color: ${Color.WHITE_100};
    }
  `,
  TimerLabel: styled.label`
    font-weight: 700;
    font-size: 11px;
    line-height: 15px;
    color: ${Color.LILAC_120};
  `,
}
