import React, { useMemo } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton } from 'components/QuestionButton'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledChangeInSex as S } from './ChangeInSex.styles'

const QUESTION = 'What would you like to change about your sex life?'
const OPTION_VALUES = {
  moreOften: `I'd like to have sex more often`,
  newThings: `I'd like to try new things`,
  orgasms: `I'd like to have more orgasms`,
  happy: `I'm happy with things the way they are`,
}

export const ChangeInSexVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, handleContinue, pageId, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.QuestionTitle>{QUESTION}</S.QuestionTitle>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.moreOften}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.moreOften}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.newThings}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.newThings}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.orgasms}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.orgasms}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.happy}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.happy}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
