import styled from 'styled-components'

import { Button } from 'components/Button'
import { PageContainer } from 'components/PageContainer'
import { StickyContainer } from 'components/StickyContainer'

import bgImg from 'assets/images/what-you-see-intro-bg.webp'

import { baseColumnStyles } from 'common-styles'
import { BIG_COLUMN_WIDTH, Color, MAX_PHONE_WIDTH } from 'root-constants/common'

export const StyledWhatYouSeeIntroVariant1 = {
  PageContainer: styled(PageContainer)`
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-image: url(${bgImg});
    background-size: cover;
    background-position: center center;

    @media (min-width: ${MAX_PHONE_WIDTH}px) {
      width: ${BIG_COLUMN_WIDTH}px;
    }

    @media (max-height: ${MAX_PHONE_WIDTH}px) {
      width: ${BIG_COLUMN_WIDTH}px;
      height: auto;
    }
  `,
  Column: styled.div`
    ${baseColumnStyles};
    width: 100%;
    padding: 0 20px;

    @media (min-width: ${MAX_PHONE_WIDTH}px) {
      width: ${BIG_COLUMN_WIDTH}px;
    }
  `,
  Title: styled.h1`
    font-weight: 800;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    text-transform: uppercase;
    text-wrap: balance;
    color: ${Color.WHITE_100};
    margin: 110px 0 16px 0;

    strong {
      color: ${Color.LILAC_130};
    }
  `,
  Description: styled.p`
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    text-wrap: balance;
    color: ${Color.WHITE_100};
    margin: 0 0 100px 0;
  `,
  StickyContainer: styled(StickyContainer)`
    background-image: none;
  `,
  Button: styled(Button)`
    --next-page-button-border-radius: 100px;
    --next-page-button-height: 62px;
  `,
}
