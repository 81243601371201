import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { PageContainer } from 'components/PageContainer'
import { StickyContainer } from 'components/StickyContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import graph from 'assets/images/restrictive-v1-graph.png'

import { StyledRestrictive as S } from './Restrictive.styles'

export const RestrictiveVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.restrictiveV1.title', { lng: 'en' }),
    nextPagePath,
  })

  return (
    <PageContainer>
      <S.Column>
        <S.Title>
          <Trans i18nKey="onboarding.restrictiveV1.title" />
        </S.Title>
        <S.Subtitle>
          <Trans i18nKey="onboarding.restrictiveV1.subtitle" />
        </S.Subtitle>
        <S.GraphContainer>
          <img src={graph} alt="graph" />
        </S.GraphContainer>
        <S.GraphDescription>
          <Trans i18nKey="onboarding.restrictiveV1.graphDescription" />
        </S.GraphDescription>
        <StickyContainer>
          <S.Button onClick={() => handleContinue('')}>
            {t('actions.continue')}
          </S.Button>
        </StickyContainer>
      </S.Column>
    </PageContainer>
  )
}
