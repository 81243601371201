export const enum Gender {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other',
  NON_BINARY = 'nonBinary',
}

export const GENDER_MAP = {
  [Gender.MALE]: 'onboarding.gender.male',
  [Gender.FEMALE]: 'onboarding.gender.female',
  [Gender.OTHER]: 'onboarding.gender.other',
  [Gender.NON_BINARY]: 'onboarding.gender.nonBinary',
}
