import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Spinner } from 'components/Spinner'
import { StickyButton } from 'components/StickyButton'

import {
  sendUserAnswersAction,
  sendUserConfigAction,
} from 'root-redux/actions/user'
import { selectUserAccountLoginMethod } from 'root-redux/selects/user'

import { eventLogger } from 'services/eventLogger.service'

import downloadAppImg from 'assets/images/download-app.png'

import { CUSTOM_TOKEN_LOCAL_STORAGE_KEY } from 'root-constants/common'

import { StyledDownloadAppPage as S } from './DownloadApp.styles'
import { ONE_LINK_URL, STEPS_MAP } from './constants'

export const DownloadApp: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const loginMethod = useSelector(selectUserAccountLoginMethod)

  const downloadAppLink = useMemo(() => {
    const customToken = localStorage.getItem(CUSTOM_TOKEN_LOCAL_STORAGE_KEY)

    return customToken
      ? `${ONE_LINK_URL}?deep_link_value=${customToken}`
      : ONE_LINK_URL
  }, [])

  useEffect(() => {
    eventLogger.logGettingAppShown()
    dispatch(sendUserAnswersAction(true))
    dispatch(
      sendUserConfigAction({
        is_download_visited: true,
      }),
    )
  }, [dispatch])

  const handleContinue = useCallback(() => {
    window.location.href = downloadAppLink
  }, [downloadAppLink])

  const handleClick = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault()
      eventLogger.logDownloadClicked(handleContinue)
    },
    [handleContinue],
  )

  return (
    <>
      {!loginMethod && <Spinner />}
      {loginMethod && (
        <S.Wrapper>
          <S.Column>
            <S.Title>{t('downloadApp.title')}</S.Title>
            <S.List>
              {STEPS_MAP.map(({ stepDescription, stepNumber }) => (
                <li key={stepNumber}>
                  <S.ListLabel>{stepNumber}</S.ListLabel>
                  <S.ListText>{t(stepDescription)}</S.ListText>
                </li>
              ))}
            </S.List>
            <S.Image src={downloadAppImg} width="250" alt="App screen" />
            <StickyButton onClick={handleClick}>
              {t('actions.downloadApp')}
            </StickyButton>
          </S.Column>
        </S.Wrapper>
      )}
    </>
  )
}
