import styled from 'styled-components'

import icon from 'assets/images/check-icon-violet-2.svg'

import { StyledQuestionButtonBase } from '../QuestionButton/QuestionButton.styles'

export const StyledQuestionButton2 = styled.div`
  ${StyledQuestionButtonBase};
  height: 80px;
  padding: 0 18px 0 24px;

  &[data-has-check-icon='true'] {
    padding-left: 46px;
  }

  &[data-has-check-icon='true']::before {
    left: 16px;
    border-color: #817fbe;
  }

  input:checked + & {
    &[data-has-check-icon='true']::before {
      content: url(${icon});
    }
  }
`
