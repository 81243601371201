import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledPaywallTimerBlock as S } from './PaywallTimerBlock.styles'

type TProps = {
  className?: string
  timer: React.ReactElement
}
export const PaywallTimerBlock: React.FC<TProps> = ({ className, timer }) => {
  const { t } = useTranslation()

  return (
    <S.Block className={className}>
      <S.Label>{t('paymentTrialV1.timer.label')}</S.Label>
      {timer}
      <S.Label>{t('commonComponents.timer.min')}</S.Label>
    </S.Block>
  )
}
