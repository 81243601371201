import React, { useRef } from 'react'

import { useTargetHiddenOnScroll } from 'hooks/useTargetHiddenOnScroll'

import { useTimerForTarget } from '../hooks/useTimerForTarget'
import { StyledSubheaderWithTimer as S } from './SubheaderWithTimer.styles'

const FIFTEEN_MINUTES_IN_SECONDS = 900

interface IProps {
  elemForComparisonRef: React.RefObject<HTMLDivElement>
  onClick: () => void
}

export const SubheaderWithTimer: React.FC<IProps> = ({
  elemForComparisonRef,
  onClick,
}) => {
  const timerElementRef = useRef<HTMLSpanElement>(null)
  const isTargetHidden = useTargetHiddenOnScroll(elemForComparisonRef)

  useTimerForTarget(timerElementRef, FIFTEEN_MINUTES_IN_SECONDS)

  return (
    <S.TimerContainer data-is-visible={isTargetHidden}>
      <S.TimerContent>
        <strong ref={timerElementRef} />
        <S.Button onClick={onClick}>Get my plan</S.Button>
      </S.TimerContent>
    </S.TimerContainer>
  )
}
