import React from 'react'
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar'

import Swiper, { Autoplay } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { PageContainer } from 'components/PageContainer'

import { useCounter } from 'hooks/useCounter'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import stars from 'assets/images/stars.svg'

import { Color } from 'root-constants/common'

import { StyledPlanProcessing as S } from './PlanProcessing.styles'
import { REVIEWS } from './constants'

Swiper.use([Autoplay])

export const PlanProcessingVariant3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { linearCounterValue, isFinished } = useCounter(true, {
    duration: 10000,
  })

  const handleContinue = useNextStep({
    pageId,
    question: 'Your Personal Intimacy Plan is almost ready ...',
    nextPagePath,
  })

  return (
    <PageContainer>
      <S.Column>
        <S.ProgressTitle2>
          Your Personal Intimacy Plan is almost ready ...
        </S.ProgressTitle2>

        <S.CircleWrapper>
          <CircularProgressbarWithChildren
            value={linearCounterValue}
            styles={buildStyles({
              pathColor: Color.LILAC_130,
              trailColor: 'rgba(237, 237, 255, 0.3)',
              strokeLinecap: 'round',
              pathTransition: 'all 0.5s ease-out',
            })}
          >
            <S.Percents>{linearCounterValue}%</S.Percents>
          </CircularProgressbarWithChildren>
        </S.CircleWrapper>

        <S.CarouselContainer>
          <Carousel
            spaceBetween={16}
            slidesPerView={1.2}
            loop
            autoplay={{ delay: 3000, disableOnInteraction: false }}
          >
            {REVIEWS.map(({ author, review }) => (
              <SwiperSlide key={author}>
                <S.ReviewContainer>
                  <S.ReviewAuthor>{author}</S.ReviewAuthor>
                  <S.ReviewRating src={stars} alt="stars" />
                  <S.ReviewText>{review}</S.ReviewText>
                </S.ReviewContainer>
              </SwiperSlide>
            ))}
          </Carousel>
        </S.CarouselContainer>

        <S.Button onClick={() => handleContinue('')} disabled={!isFinished}>
          Continue
        </S.Button>
      </S.Column>
    </PageContainer>
  )
}
