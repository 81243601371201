import firstRange from 'assets/images/first-range.png'
import fourthRange from 'assets/images/fourth-range.png'
import secondRange from 'assets/images/second-range.png'
import thirdRange from 'assets/images/third-range.png'

import { StartAgeValue } from 'root-constants/common'

export const RANGES = [
  {
    value: StartAgeValue.FIRST_AGE_RANGE,
    title: '18-25 >',
    img: firstRange,
  },
  {
    value: StartAgeValue.SECOND_AGE_RANGE,
    title: '26-35 >',
    img: secondRange,
  },
  {
    value: StartAgeValue.THIRD_AGE_RANGE,
    title: '36-45 >',
    img: thirdRange,
  },
  {
    value: StartAgeValue.FOURTH_AGE_RANGE,
    title: '45+ >',
    img: fourthRange,
  },
]
