import React, { useMemo, useState } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { StickyContainer } from 'components/StickyContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledSupportInterests as S } from './SupportInterests.styles'

const QUESTION = `How do you support your partner's passions and interests?`
const OPTION_VALUES = {
  eventsTogether: 'Attending events or activities together',
  askingQuestions: 'Asking questions and showing genuine interest',
  offeringHelp: 'Offering to help with projects or tasks',
  givingEncouragement: 'Giving encouragement and positive feedback',
  noSupport: `Not interested in supporting my partner's passions`,
}

export const SupportInterestsVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          value === OPTION_VALUES.noSupport
            ? setAnswers([value])
            : setAnswers([...answers, value])
        } else {
          const newValues = answers.filter((item) => item !== value)
          setAnswers(newValues)
        }
      },
    }),
    [pageId, answers],
  )

  const isButtonsDisabled = useMemo(
    () => answers.includes(OPTION_VALUES.noSupport),
    [answers],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.Title>{QUESTION}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.eventsTogether}
            disabled={isButtonsDisabled}
            checked={answers.includes(OPTION_VALUES.eventsTogether)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.eventsTogether}
            </S.QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.askingQuestions}
            disabled={isButtonsDisabled}
            checked={answers.includes(OPTION_VALUES.askingQuestions)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.askingQuestions}
            </S.QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.offeringHelp}
            disabled={isButtonsDisabled}
            checked={answers.includes(OPTION_VALUES.offeringHelp)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.offeringHelp}
            </S.QuestionButton>
          </Option>

          <Option
            {...optionProps}
            value={OPTION_VALUES.givingEncouragement}
            disabled={isButtonsDisabled}
            checked={answers.includes(OPTION_VALUES.givingEncouragement)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.givingEncouragement}
            </S.QuestionButton>
          </Option>

          <Option {...optionProps} value={OPTION_VALUES.noSupport}>
            <S.QuestionButton>{OPTION_VALUES.noSupport}</S.QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
      <StickyContainer>
        <S.Button
          onClick={() => handleContinue(answers)}
          disabled={!answers.length}
        >
          Continue
        </S.Button>
      </StickyContainer>
    </PageContainer>
  )
}
