import styled from 'styled-components'

import { QuestionTitle, baseColumnStyles } from 'common-styles'

export const StyledMakePriority = {
  Column: styled.div`
    ${baseColumnStyles};
    padding: 26px 0;
  `,
  Title: styled(QuestionTitle)`
    width: 321px;
    line-height: 26px;
    margin-bottom: 18px;

    strong {
      display: block;
      font-weight: 700;
      margin-bottom: 15px;
    }
  `,
}
