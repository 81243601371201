import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { createProductId } from 'helpers/createProductId'

import {
  selectSubscriptionMainPrice,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
} from 'modules/payment/redux/selects'

export const useProductId = () => {
  const periodName = useSelector(selectSubscriptionPeriodName)
  const periodQuantity = useSelector(selectSubscriptionPeriodQuantity)
  const price = useSelector(selectSubscriptionMainPrice)

  return useMemo(
    () => createProductId({ periodName, periodQuantity, price }),
    [periodName, periodQuantity, price],
  )
}
