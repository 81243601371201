import React, { useMemo } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton2 } from 'components/QuestionButton2'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledBiggestFear as S } from './BiggestFear.styles'

const QUESTION = 'What is your biggest fear in your relationship?'
const OPTION_VALUES = {
  losingConnection: 'Losing the connection with your partner',
  beingBetrayed: 'Being betrayed or cheated on',
  communicationBreakdown: 'Communication breakdown or conflict',
  fearAbandonment: 'Fear of abandonment',
  fearCommitment: 'Fear of commitment ',
}

export const BiggestFearVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.Title>{QUESTION}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.losingConnection}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.losingConnection}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.beingBetrayed}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.beingBetrayed}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.communicationBreakdown}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>
              {OPTION_VALUES.communicationBreakdown}
            </QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.fearAbandonment}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.fearAbandonment}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.fearCommitment}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.fearCommitment}</QuestionButton2>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
