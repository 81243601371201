import React from 'react'

import { StyledQuestionButton } from './QuestionButton.styles'

type TProps = {
  hasCheckboxIcon?: boolean
  children: React.ReactNode
}

export const QuestionButton: React.FC<TProps> = ({
  hasCheckboxIcon = false,
  children,
  ...props
}) => (
  <StyledQuestionButton data-has-check-icon={hasCheckboxIcon} {...props}>
    {children}
  </StyledQuestionButton>
)
