import React, { useMemo, useState } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { StickyButton } from 'components/StickyButton'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledKey as S } from './Key.styles'

const QUESTION = 'What do you think is the key to a good sexual relationship?'
const OPTION_VALUES = {
  communication: 'Open communication',
  respect: 'Mutual respect',
  variety: 'Variety',
  all: 'All of the above',
}

export const KeyVariant1: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const [answers, setAnswers] = useState<string[]>([])

  const isButtonsDisabled = useMemo(
    () => answers.includes(OPTION_VALUES.all),
    [answers],
  )
  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value, isChecked) => {
        if (isChecked) {
          value === OPTION_VALUES.all
            ? setAnswers([value])
            : setAnswers([...answers, value])
        } else {
          const newValues = answers.filter((item) => item !== value)
          setAnswers(newValues)
        }
      },
    }),
    [answers, pageId],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.QuestionTitle>{QUESTION}</S.QuestionTitle>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.communication}
            disabled={isButtonsDisabled}
            checked={answers.includes(OPTION_VALUES.communication)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.communication}
            </S.QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.respect}
            disabled={isButtonsDisabled}
            checked={answers.includes(OPTION_VALUES.respect)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.respect}
            </S.QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.variety}
            disabled={isButtonsDisabled}
            checked={answers.includes(OPTION_VALUES.variety)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.variety}
            </S.QuestionButton>
          </Option>
          <Option {...optionProps} value={OPTION_VALUES.all}>
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.all}
            </S.QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
        <StickyButton
          disabled={!answers.length}
          onClick={() => handleContinue(answers)}
        >
          Next
        </StickyButton>
      </S.Column>
    </PageContainer>
  )
}
