import styled from 'styled-components'

import { Option } from 'components/Option'

import { baseColumnStyles } from 'common-styles'
import { BIG_COLUMN_WIDTH, Color, MAX_PHONE_WIDTH } from 'root-constants/common'

export const StyledStartAgeMixVariant1 = {
  Column: styled.div`
    ${baseColumnStyles};
    padding: 20px;
    width: 100%;

    @media (min-width: ${MAX_PHONE_WIDTH}px) {
      width: ${BIG_COLUMN_WIDTH}px;
    }
  `,
  Title: styled.h1`
    color: ${Color.LILAC_120};
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    text-transform: uppercase;
  `,
  Subtitle: styled.p`
    color: ${Color.LILAC_100};
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
  `,
  CallToAction: styled.p`
    color: ${Color.LILAC_120};
    font-weight: 700;
    font-size: 18px;
    text-align: center;
  `,
  OptionsContainer: styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 180px);
    grid-column-gap: 16px;
    grid-row-gap: 37px;
    justify-content: center;
    padding: 20px;
    width: 100%;
  `,
  Option: styled(Option)`
    margin: 0;

    input:checked + div > div {
      border: 1px solid var(--checked-bg-color);
    }
    input:checked + div > span {
      background-color: var(--checked-bg-color);
    }
  `,
  Action: styled.div`
    aspect-ratio: 17/20;
    display: flex;
    height: 100%;
    min-height: 180px;
    position: relative;
  `,
  ActionImage: styled.img`
    height: auto;
    left: 50%;
    position: absolute;
    top: -18px;
    transform: translateX(-50%);
    width: 100%;
    z-index: 1;
  `,
  Answer: styled.div`
    --checked-bg-color: ${Color.VIOLET_120};
    background-color: ${Color.VIOLET_100};
    border-radius: 16px;
    position: relative;
    flex-basis: 100%;
    transition: background-color 0.1s ease-out;
  `,
  Range: styled.span`
    --checked-bg-color: ${Color.VIOLET_120};
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 2;
    padding: 17px 0;
    background-color: ${Color.LILAC_120};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 16px 16px;

    color: ${Color.BLUE_100};
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  `,
}
