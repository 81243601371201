import styled from 'styled-components'

import { Button } from 'components/Button'

import { Color } from 'root-constants/common'

export const StyledSubheaderWithTimer = {
  TimerContainer: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    height: 70px;
    display: none;
    align-items: center;
    justify-content: center;
    padding: 0 18px;
    background-color: ${Color.LILAC_130};
    border-radius: 0 0 16px 16px;

    &[data-is-visible='true'] {
      display: flex;
    }
  `,
  TimerContent: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-weight: 700;
    margin-left: 22px;

    strong {
      font-size: 38px;
      line-height: 40px;
    }
  `,
  Button: styled(Button)`
    width: 185px;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    margin: 0;
    color: #fff;
    background-color: #5344a9;
  `,
}
