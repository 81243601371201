import styled from 'styled-components'

export const StyledDisclaimerV1 = {
  DisclaimerText: styled.p`
    text-align: center;
    font-size: 11px;
    font-weight: 400;
    line-height: 18px;

    a {
      text-decoration: underline;
    }
  `,
}
