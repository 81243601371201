import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton2 } from 'components/QuestionButton2'
import { TitleContainer } from 'components/TitleContainer'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledGender as S } from './Gender.styles'
import { GENDER_MAP, Gender } from './constants'

export const GenderVariant3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.gender.question', { lng: 'en' }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <TitleContainer
          title={
            <S.TitleVariant2>{t('onboarding.gender.question')}</S.TitleVariant2>
          }
        />
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={Gender.MALE}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{t(GENDER_MAP[Gender.MALE])}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={Gender.FEMALE}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{t(GENDER_MAP[Gender.FEMALE])}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={Gender.NON_BINARY}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>
              {t(GENDER_MAP[Gender.NON_BINARY])}
            </QuestionButton2>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
