import { StripeError } from '@stripe/stripe-js'

import { TProductId } from 'models/subscriptions.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'

import { PaymentMethod, PaymentSystem, StripeErrorCode } from '../constants'

export const logFailedPayment = ({
  email,
  productId,
  screenName,
  price,
  isTrialActive,
  paymentResponse: { type, code, message, decline_code: declineCode },
  paymentMethod,
  paymentSystem,
  currency,
  goal,
  stripeAccountName,
  stripeAccountId,
}: {
  email: string
  productId: TProductId
  screenName: ScreenName
  price: number
  isTrialActive: boolean
  paymentResponse: StripeError
  paymentMethod: PaymentMethod
  paymentSystem: PaymentSystem
  currency?: string
  goal: string
  stripeAccountName: string
  stripeAccountId: string
}): void => {
  const errorCode =
    code === StripeErrorCode.CARD_DECLINED ? `${code}:${declineCode}` : code

  eventLogger.logPurchaseFailed({
    email,
    productId,
    screenName,
    priceDetails: { price, trial: isTrialActive, currency },
    error: { type, code: errorCode, description: message },
    paymentMethod,
    paymentSystem,
    goal,
    stripeAccountName,
    stripeAccountId,
  })
}
