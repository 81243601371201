import styled, { css } from 'styled-components'

import { PaymentMethod } from 'modules/payment/constants'

import crossIcon from 'assets/images/cross-2.svg'

import { CloseButton } from 'common-styles'
import { Color } from 'root-constants/common'

type TVisibleProps = {
  isVisible: boolean
}

type TPaymentMethodProps = {
  isSelected: boolean
}

const baseContainer = css`
  margin-bottom: 40px;
  width: 100%;

  :empty {
    display: none;
  }
`

const dividerTextStyles = css`
  position: relative;
  padding: 0 25px;
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;

  ::before,
  ::after {
    content: '';
    position: absolute;
    display: block;
    height: 1px;
    width: 500px;
    top: 10px;
  }

  ::before {
    right: 100%;
  }

  ::after {
    left: 100%;
  }
`

export const StyledPaymentFlows = {
  TitleWrapper: styled.div`
    width: 100%;
    margin: 48px 0 8px;
    position: relative;
  `,
  TrialTitleWrapper: styled.div`
    width: 100%;
    margin-top: 24px;
    position: relative;
  `,
  CloseButton: styled(CloseButton)`
    position: absolute;
    top: -32px;
    right: -16px;
  `,
  TrialCloseButton: styled(CloseButton)`
    position: absolute;
    top: -10px;
    right: -21px;
  `,
  Title: styled.h3`
    font-size: 24px;
    line-height: 28px;
    font-weight: 800;
    text-align: center;
    color: var(--base-text-color);
  `,
  Divider: styled.div`
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
  `,
  DividerText: styled.div`
    ${dividerTextStyles};
    color: ${Color.LIGHT_TEXT};

    ::before,
    ::after {
      background-color: #bcc9d0;
    }
  `,
  DividerText2: styled.div`
    ${dividerTextStyles};
    color: var(--base-text-color);

    ::before,
    ::after {
      background-color: #362f99;
    }
  `,
  Form: styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 25px;
  `,
  PaymentServiceImage: styled.img`
    margin: 32px 0 16px;
  `,
  Guarantee: styled.span`
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: var(--base-text-color);
    opacity: 0.7;
    margin-bottom: 48px;
  `,
  Description: styled.span`
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    color: ${Color.LIGHT_TEXT};
    margin-bottom: 44px;

    a {
      color: inherit;
    }
  `,
}

export const StyledPaymentFlowV4 = {
  ContentHeader: styled.div`
    margin-bottom: 24px;
    position: relative;
    width: 100%;
  `,
  Title: styled.h3`
    color: ${Color.LILAC_120};
    font-size: 16px;
    font-weight: 800;
    line-height: 20px;
    text-align: center;
  `,
  CloseButton: styled(CloseButton)`
    background-image: url(${crossIcon});
    right: -12px;
    position: absolute;
    top: -25px;
  `,
  PaymentMethods: styled.div`
    column-gap: 8px;
    display: flex;
    margin-bottom: 24px;
    width: calc(100% + 16px);
  `,
  PaymentMethod: styled.div<TPaymentMethodProps>`
    ${({ isSelected }) => `
      align-items: center;
      background-color: ${Color.WHITE_110};
      border: 2px solid ${isSelected ? Color.GREEN : 'transparent'};
      border-radius: 16px;
      cursor: pointer;
      display: flex;
      filter: ${isSelected ? 'initial' : 'grayscale(1)'};
      height: 72px;
      justify-content: center;
      width: 100%;
    `}
  `,
  PaymentMethodImage: styled.div`
    &[data-image=${PaymentMethod.PAYPAL}] {
      aspect-ratio: 147 / 40;
      width: 73px;
    }

    &[data-image=${PaymentMethod.GOOGLE_PAY}] {
      aspect-ratio: 5 / 2;
      width: 55px;
    }

    &[data-image=${PaymentMethod.CREDIT_CARD}] {
      aspect-ratio: 208 / 75;
      width: 103px;
    }

    &[data-image=${PaymentMethod.APPLE_PAY}] {
      aspect-ratio: 80 / 24;
      width: 80px;
    }
  `,
  PayPalContainer: styled.div`
    ${baseContainer};
  `,
  PaymentRequestButtonContainer: styled.div<TVisibleProps>`
    ${baseContainer};
    ${({ isVisible }) => `
      display: ${isVisible ? 'block' : 'none'};
    `}
  `,
  PaymentServiceImageContainer: styled.div`
    aspect-ratio: 64 / 7;
    margin: 32px 0 16px;
    width: 256px;
  `,
}
