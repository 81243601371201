import styled from 'styled-components'

type TProps = {
  hasIndent: boolean
}

export const StyledPaymentRequestButton = {
  Wrapper: styled.div<TProps>`
    ${({ hasIndent }) => `
      ${hasIndent && `margin-top: 24px;`};
      width: 100%;

      & * {
        border-radius: 28px;
      }
    `}
  `,
}
