import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'

import ratingStars from 'assets/images/rating-stars.svg'
import reviewAvatar1 from 'assets/images/review-avatar-1.jpg'
import reviewAvatar2 from 'assets/images/review-avatar-2.jpg'
import reviewAvatar3 from 'assets/images/review-avatar-3.jpg'

import { Color } from 'root-constants/common'

import { StyledReviewsSlider as S } from './ReviewSlider.styles'

type TProps = React.HTMLAttributes<HTMLDivElement>

export const ReviewsSlider: React.FC<TProps> = (props) => (
  <S.Wrapper {...props}>
    <Swiper slidesPerView="auto" spaceBetween={20} centeredSlides>
      <SwiperSlide>
        <S.Card style={{ borderColor: '#EED0E4' }}>
          <S.Avatar width="66" height="66" src={reviewAvatar1} loading="lazy" />
          <S.CardTitle>Helena Davis</S.CardTitle>
          <img
            height="14"
            src={ratingStars}
            alt="rating 5 stars"
            loading="lazy"
          />
          <S.CardText>
            “I LOVE this app. It helped improve my relationship and our
            communication. It has fun activities to work through together,
            quizzes where you challenge how well you know each other, and random
            questions you both answer that wouldn&#39;t normally come up in
            daily conversations.”
          </S.CardText>
        </S.Card>
      </SwiperSlide>
      <SwiperSlide>
        <S.Card style={{ borderColor: Color.LILAC_110 }}>
          <S.Avatar width="66" height="66" src={reviewAvatar2} loading="lazy" />
          <S.CardTitle>Anna Kobi</S.CardTitle>
          <img
            height="14"
            src={ratingStars}
            alt="rating 5 stars"
            loading="lazy"
          />
          <S.CardText>
            “I enjoy using this app with my husband. It has honestly helped us
            open up way more to each other, and be more understanding.”
          </S.CardText>
        </S.Card>
      </SwiperSlide>
      <SwiperSlide>
        <S.Card style={{ borderColor: '#F0DACE' }}>
          <S.Avatar width="66" height="66" src={reviewAvatar3} loading="lazy" />
          <S.CardTitle>Mike Harris</S.CardTitle>
          <img
            height="14"
            src={ratingStars}
            alt="rating 5 stars"
            loading="lazy"
          />
          <S.CardText>
            “I think this app is great for those who want to learn more about
            their partner and those who are dedicated to growing together. The
            daily conversations and quizzes help us come up with new ideas, and
            communicate our wants, needs, and concerns. It highlights things we
            do well, and things we can work on.”
          </S.CardText>
        </S.Card>
      </SwiperSlide>
    </Swiper>
  </S.Wrapper>
)
