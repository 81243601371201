import React, { useMemo } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton } from 'components/QuestionButton'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledHugsAndKisses as S } from './HugsAndKisses.styles'

const QUESTION =
  'How often do you show your partner how you feel through hugs and kisses?'
const OPTION_VALUES = {
  always: 'Always',
  sometimes: 'Sometimes',
  rarely: 'Rarely',
  never: 'Never',
}

export const HugsAndKissesVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, handleContinue, pageId, setIsAnswersDisabled],
  )
  return (
    <PageContainer>
      <S.Column>
        <S.QuestionTitle>{QUESTION}</S.QuestionTitle>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.always}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.always}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.sometimes}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.sometimes}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.rarely}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.rarely}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.never}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.never}</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
