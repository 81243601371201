import styled from 'styled-components'

import { Option } from 'components/Option'
import { QuestionButton2 } from 'components/QuestionButton2'

import { baseColumnStyles } from 'common-styles'
import { BIG_COLUMN_WIDTH, Color } from 'root-constants/common'

export const StyledWhatYouSeeVariant1 = {
  Column: styled.div`
    ${baseColumnStyles};
    padding: 20px 32px 52px 32px;
    width: ${BIG_COLUMN_WIDTH}px;
  `,
  Title: styled.h1`
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: ${Color.LILAC_120};
    margin: 0 0 40px 0;
  `,
  ImageContainer: styled.div`
    width: 100%;
    aspect-ratio: 311/222;
    margin: 0 0 48px 0;
  `,
  OptionsContainer: styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
  `,
  Option: styled(Option)`
    margin: 0;
  `,
  QuestionButton: styled(QuestionButton2)`
    height: 64px;
    width: 146px;
    justify-content: center;
  `,
}
