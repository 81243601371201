import React from 'react'

import { StyledQuestionButton2 } from './QuestionButton2.styles'

type TProps = {
  hasCheckboxIcon?: boolean
  children: React.ReactNode
}

export const QuestionButton2: React.FC<TProps> = ({
  hasCheckboxIcon = false,
  children,
  ...props
}) => (
  <StyledQuestionButton2 data-has-check-icon={hasCheckboxIcon} {...props}>
    {children}
  </StyledQuestionButton2>
)
