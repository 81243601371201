import styled from 'styled-components'

import { Award } from 'components/Award'
import { BenefitsV2 } from 'components/Benefits'
import { Button } from 'components/Button'

import { AppBlock } from 'modules/payment/components/AppBlock'
import { DisclaimerV2 } from 'modules/payment/components/Disclaimer'
import { HeaderWithTimer } from 'modules/payment/components/HeaderWithTimer'
import { PlanBlock } from 'modules/payment/components/PlanBlock'
import { SuccessStoryBlock } from 'modules/payment/components/SuccessStoryBlock'
import { Security } from 'modules/subscriptions/components/security'
import { TrialSelectPlanBlockVariant1 } from 'modules/subscriptions/components/trial/TrialSelectPlanBlockVariant1'
import { CANCEL_OFFER_V1_HEADER } from 'modules/subscriptions/pages/constants'

import discountBanner from 'assets/images/discount-banner.png'
import bg3 from 'assets/images/payment-trial-v1-bg-3.svg'
import bg4 from 'assets/images/payment-trial-v1-bg-4.svg'

import { baseColumnStyles } from 'common-styles'
import { BIG_COLUMN_WIDTH, Color, MAX_PHONE_WIDTH } from 'root-constants/common'

export const StyledCancelOfferVariant1 = {
  Wrapper: styled.div`
    align-items: center;
    background: linear-gradient(188.98deg, #191465 0%, #070525 93.18%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: ${CANCEL_OFFER_V1_HEADER}px 0 30px 0;
  `,
  Column: styled.div`
    ${baseColumnStyles};
    width: 100%;

    @media (min-width: ${MAX_PHONE_WIDTH}px) {
      width: ${BIG_COLUMN_WIDTH}px;
    }
  `,
  Header: styled(HeaderWithTimer)`
    background-color: ${Color.VIOLET_140};
    left: 0;
    margin: 0 auto 16px auto;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 3;

    strong {
      color: ${Color.WHITE_100};
    }
    span {
      color: ${Color.LILAC_120};
    }
    @media (min-width: ${MAX_PHONE_WIDTH}px) {
      width: ${BIG_COLUMN_WIDTH}px;
    }
  `,
  DiscountBanner: styled.div`
    margin-bottom: 44px;
    padding: 0 18px;
    width: 100%;
  `,
  Discount: styled.div`
    background-image: url(${discountBanner}),
      linear-gradient(258deg, #f6e4c8 -7.73%, #ffd8dd 52.92%, #e7d5fc 110.94%);
    background-position: center bottom;
    background-size: cover;
    border-radius: 20px;
    color: ${Color.BLUE_110};
    display: flex;
    flex-direction: column;
    font-size: 19px;
    font-weight: 800;
    line-height: 24px;
    padding: 19px 15px 17px;
    row-gap: 11px;
    width: 100%;

    span {
      background: linear-gradient(
        90deg,
        #3e1e43 0.29%,
        #84508c 49.3%,
        #3e1e43 98.46%
      );
      border-radius: 7px;
      box-shadow: 4px 4px 10px 0 rgba(107, 53, 59, 0.33);
      color: ${Color.WHITE_100};
      font-size: 20px;
      line-height: 22px;
      padding: 7px 9px;
      text-transform: uppercase;
      width: fit-content;
    }
  `,
  Title: styled.h1`
    color: ${Color.LILAC_120};
    font-size: 32px;
    font-weight: 800;
    line-height: 40px;
    margin: 0 0 32px 0;
    padding: 0 20px;
    text-align: center;
    width: 100%;

    span {
      color: ${Color.LILAC_130};
    }
  `,
  TrialSelectPlanBlockVariant1: styled(TrialSelectPlanBlockVariant1)`
    margin: 0 0 25px 0;
    padding: 0 24px;
    position: relative;
    z-index: 2;
  `,
  Button: styled(Button)`
    margin: 0 0 25px 0;
    position: relative;
    z-index: 2;
  `,
  Divider: styled.div`
    background-color: #362f99;
    height: 1px;
    margin: 0 0 32px 0;
    position: relative;
    width: 100%;

    @media (min-width: ${MAX_PHONE_WIDTH}px) {
      width: ${BIG_COLUMN_WIDTH}px;
    }
  `,
  Benefits: styled(BenefitsV2)`
    margin: 0 0 40px 0;
    padding: 0 24px 0 24px;
    position: relative;
    width: 100%;
    z-index: 2;
  `,
  AppBlock: styled(AppBlock)`
    margin: 0 0 50px 0;
    width: 100%;
  `,
  Award: styled(Award)`
    margin: 0 0 22px 0;
    position: relative;
    width: 100%;
    z-index: 2;

    &:before {
      content: url(${bg3});
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }
  `,
  NavigationButton: styled(Button)`
    margin: 0 0 48px 0;
    position: relative;
    z-index: 2;
  `,
  PlanBlock: styled(PlanBlock)`
    margin: 0 0 32px 0;
    padding: 0 24px;
  `,
  SuccessStoryBlock: styled(SuccessStoryBlock)`
    margin: 0 0 32px 0;
    padding: 0 24px;
    position: relative;
    width: 100%;
    z-index: 2;

    &:before {
      content: url(${bg3});
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }
  `,
  GuaranteeContainer: styled.div`
    margin: 0 0 32px 0;
    padding: 0 32px 0 32px;
    position: relative;
    width: 100%;

    &:before {
      content: url(${bg4});
      left: 0;
      position: absolute;
      z-index: 1;
    }
  `,
  Security: styled(Security)`
    margin: 0 0 24px 0;
  `,
  Disclaimer: styled(DisclaimerV2)`
    margin: 0 0 32px 0;
    padding: 0 24px;
  `,
}
