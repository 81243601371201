import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import {
  resetErrorAction,
  setSubscriptionListAction,
} from 'root-redux/actions/common'
import {
  selectCurrentVariantCohort,
  selectHasCancelOffer,
  selectScreenName,
} from 'root-redux/selects/common'

import { useProductId } from 'hooks/useProductId'
import { useUserData } from 'hooks/useUserData'

import { MixedPaymentSummary2 } from 'modules/payment/components/MixedPaymentSummary'
import { PaymentMethod } from 'modules/payment/constants'

import { ScreenName, eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import applyPayImage from 'assets/images/apply-pay.svg'
import creditCardImage from 'assets/images/credit-card.png'
import googlePayImage from 'assets/images/google-pay.png'
import paymentServicesImage from 'assets/images/payment-services-2.png'
import paypalImage from 'assets/images/paypal.png'

import { goBack, goTo } from 'browser-history'
import { PageId } from 'root-constants/pages'

import { PayPalContainer } from '../PayPalContainer'
import { PaymentRequestButton } from '../PaymentRequestButton'
import { StripePaymentForm } from '../StripePaymentForm'
import {
  StyledPaymentFlows as S,
  StyledPaymentFlowV4 as V4,
} from './PaymentFlows.styles'

type TProps = {
  hasDescription?: boolean
}

export const PaymentFlows4: React.FC<TProps> = ({ hasDescription = false }) => {
  const { t } = useTranslation()

  const { pathname, search } = useLocation()
  const dispatch = useDispatch()
  const cohort = useSelector(selectCurrentVariantCohort)
  const screenName = useSelector(selectScreenName)
  const hasCancelOffer = useSelector(selectHasCancelOffer)

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethod>(PaymentMethod.CREDIT_CARD)
  const [paymentRequestButtonType, setPaymentRequestButtonType] = useState<
    PaymentMethod.APPLE_PAY | PaymentMethod.GOOGLE_PAY | null
  >(null)

  const productId = useProductId()

  const { usersPriority } = useUserData()

  const paymentMethodOptions = useMemo(
    () => [
      {
        value: PaymentMethod.PAYPAL,
        image: paypalImage,
      },
      ...(paymentRequestButtonType === PaymentMethod.APPLE_PAY
        ? [
            {
              value: PaymentMethod.APPLE_PAY,
              image: applyPayImage,
            },
          ]
        : []),
      ...(paymentRequestButtonType === PaymentMethod.GOOGLE_PAY
        ? [
            {
              value: PaymentMethod.GOOGLE_PAY,
              image: googlePayImage,
            },
          ]
        : []),
      {
        value: PaymentMethod.CREDIT_CARD,
        image: creditCardImage,
      },
    ],
    [paymentRequestButtonType],
  )

  useEffect(() => {
    if (paymentRequestButtonType) {
      setSelectedPaymentMethod(paymentRequestButtonType)
      window.scrollTo(0, 0)
      document.body.scrollTo(0, 0)
    }
  }, [paymentRequestButtonType])

  const handleSelectPayPalPaymentMethod = useCallback(() => {
    eventLogger.logPaymentMethodSelected(PaymentMethod.PAYPAL)
  }, [])

  const handleBack = useCallback(() => {
    dispatch(resetErrorAction())
    googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)
    eventLogger.logPurchaseScreenClosed({
      productId,
      screenName,
      goal: usersPriority,
    })

    if (!hasCancelOffer) {
      goBack()
      return
    }

    dispatch(setSubscriptionListAction([]))
    screenName === ScreenName.CANCEL_OFFER
      ? goTo({ pathname: PageId.CANCEL_OFFER, search })
      : goTo({ pathname: PageId.INTRO_DISCOUNT, search })
  }, [
    cohort,
    dispatch,
    pathname,
    usersPriority,
    productId,
    screenName,
    search,
    hasCancelOffer,
  ])

  return (
    <>
      <V4.ContentHeader>
        <V4.CloseButton type="button" onClick={handleBack} />
        <V4.Title>{t('paymentV4.title')}</V4.Title>
      </V4.ContentHeader>

      <V4.PaymentMethods>
        {paymentMethodOptions.map(({ value, image }) => (
          <V4.PaymentMethod
            key={value}
            isSelected={value === selectedPaymentMethod}
            onClick={() => setSelectedPaymentMethod(value)}
          >
            <V4.PaymentMethodImage data-image={value}>
              <img src={image} alt={value} />
            </V4.PaymentMethodImage>
          </V4.PaymentMethod>
        ))}
      </V4.PaymentMethods>

      <MixedPaymentSummary2 />

      {selectedPaymentMethod === PaymentMethod.CREDIT_CARD && (
        <S.Form>
          <StripePaymentForm />
        </S.Form>
      )}

      {selectedPaymentMethod === PaymentMethod.PAYPAL && (
        <V4.PayPalContainer>
          <PayPalContainer onClick={() => handleSelectPayPalPaymentMethod()} />
        </V4.PayPalContainer>
      )}

      <V4.PaymentRequestButtonContainer
        isVisible={
          selectedPaymentMethod === PaymentMethod.APPLE_PAY ||
          selectedPaymentMethod === PaymentMethod.GOOGLE_PAY
        }
      >
        <PaymentRequestButton
          hasIndent={false}
          setPaymentRequestButtonType={setPaymentRequestButtonType}
        />
      </V4.PaymentRequestButtonContainer>

      {selectedPaymentMethod === PaymentMethod.CREDIT_CARD && (
        <>
          <V4.PaymentServiceImageContainer>
            <img
              src={paymentServicesImage}
              decoding="async"
              alt="payments services"
            />
          </V4.PaymentServiceImageContainer>
          <S.Guarantee>{t('paymentV4.guarantee')}</S.Guarantee>
        </>
      )}
      {hasDescription && (
        <S.Description>{t('paymentV4.description')}</S.Description>
      )}
    </>
  )
}
