import styled from 'styled-components'

import { QuestionTitle, baseColumnStyles } from 'common-styles'
import { Color } from 'root-constants/common'

export const StyledAdmitCheating = {
  Column: styled.div`
    ${baseColumnStyles};
    padding: 40px 0;
  `,
  Title: styled(QuestionTitle)`
    margin-bottom: 16px;
    line-height: 28px;
  `,
  Subtitle: styled.h3`
    margin: 0 -10px 4px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: var(--base-text-color);

    span {
      font-weight: 800;
      color: ${Color.LILAC_130};
    }
  `,
  ImageContainer: styled.div`
    aspect-ratio: 368 / 425;
    margin-bottom: 10px;
    max-width: 184px;
    width: 100%;
  `,
  Description: styled.p`
    margin: 0 -10px 30px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: var(--base-text-color);

    span {
      font-weight: 800;
      color: ${Color.LILAC_130};
    }
  `,
}
