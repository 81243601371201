import React from 'react'

import { FeaturesReady, useFeatureIsOn } from '@growthbook/growthbook-react'

import { Button } from 'components/Button'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

export const GrowthbookTestPage: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const isAlternativeVariant = useFeatureIsOn('upluvtest1')
  const isSecondaryAlternativeVariant = useFeatureIsOn('test2upluv')

  const handleContinue = useNextStep({
    pageId,
    question: 'test growthbook question',
    nextPagePath,
  })

  return (
    <FeaturesReady timeout={0} fallback={<div />}>
      {isAlternativeVariant ? (
        <Button
          onClick={() => handleContinue('')}
          style={{ background: 'red' }}
        >
          TEST1 Alternative
        </Button>
      ) : (
        <Button
          onClick={() => handleContinue('')}
          style={{ background: 'green' }}
        >
          TEST1 Default
        </Button>
      )}

      {isSecondaryAlternativeVariant ? (
        <Button
          onClick={() => handleContinue('')}
          style={{ background: 'pink' }}
        >
          test 2 alternative
        </Button>
      ) : (
        <Button
          onClick={() => handleContinue('')}
          style={{ background: 'antiquewhite' }}
        >
          test 2 default
        </Button>
      )}
    </FeaturesReady>
  )
}
