import { StripeError } from '@stripe/stripe-js'

import { IRequestOptions, IResponseResult } from 'models/api.model'
import {
  IPurchaseResponseRaw,
  IPurchaseRetrySubscriptionResponseRaw,
} from 'models/payment.model'
import { TPriceId, TTrialPriceId } from 'models/subscriptions.model'

import { ApiService } from 'services/api.service'

import { APP_NAME, RequestMethod } from 'root-constants/common'

export class PaymentApi {
  private readonly baseUrl = 'v2/purchases'
  private api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  createSubscription({
    uuid,
    priceId,
    trialPriceId,
    trialPeriodDays,
    promoCode,
  }: {
    uuid: string
    promoCode?: string
    priceId: TPriceId
    trialPriceId: TTrialPriceId
    trialPeriodDays: number
  }): Promise<IResponseResult<IPurchaseResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        intro_id: trialPriceId,
        promo_code: promoCode,
        recurring_id: priceId,
        trial_duration: trialPeriodDays,
      },
    }

    return this.api.makeRequest<IPurchaseResponseRaw>(
      `${this.baseUrl}/stripe/${uuid}/${APP_NAME}`,
      options,
    )
  }

  retryPayment({
    uuid,
    stripeError,
  }: {
    uuid: string
    stripeError: StripeError
  }): Promise<IResponseResult<IPurchaseRetrySubscriptionResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        payment_error: stripeError,
      },
    }
    return this.api.makeRequest<IPurchaseRetrySubscriptionResponseRaw>(
      `${this.baseUrl}/stripe/${uuid}/${APP_NAME}/retry`,
      options,
    )
  }

  createPaypalSubscription({
    uuid,
    paypalPlanId,
  }: {
    uuid: string
    paypalPlanId: string | null
  }): Promise<
    IResponseResult<{
      [key: string]: boolean
    }>
  > {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        paypal_plan_id: paypalPlanId,
      },
    }
    return this.api.makeRequest<{
      [key: string]: boolean
    }>(`${this.baseUrl}/paypal/${uuid}/${APP_NAME}`, options)
  }
}
