import React from 'react'

import { eventLogger } from 'services/eventLogger.service'

import { AppLinks } from 'root-constants/common'

import { OuterLink } from '../OuterLink'

export const PrivacyPolicyLink: React.FC = () => (
  <OuterLink
    href={AppLinks.PP_LINK}
    onClick={() => eventLogger.logPrivacyPolicyClicked()}
  >
    Privacy Policy
  </OuterLink>
)
