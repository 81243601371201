import styled from 'styled-components'

import { Button } from 'components/Button'

import { baseColumnStyles } from 'common-styles'
import { BIG_COLUMN_WIDTH, Color } from 'root-constants/common'

export const StyledIntroDiscountVariant1 = {
  Column: styled.div`
    ${baseColumnStyles};
    width: ${BIG_COLUMN_WIDTH}px;
    padding: 40px 32px 32px;
  `,
  Title: styled.h1`
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: ${Color.LILAC_120};
    margin: 0 0 40px 0;

    span {
      color: ${Color.LILAC_130};
    }
  `,
  GraphContainer: styled.div`
    width: 100%;
    aspect-ratio: 311/205;
    margin: 0 0 24px 0;
  `,
  PromotionContainer: styled.div`
    width: 100%;
    background-color: ${Color.VIOLET_130};
    padding: 16px;
    border-radius: 16px 16px 16px 16px;
    margin: 0 0 88px 0;
  `,
  PromoText: styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: ${Color.LILAC_120};

    span {
      font-weight: 800;
      color: ${Color.LILAC_130};
    }
  `,
  Button: styled(Button)`
    --next-page-button-border-radius: 100px;
    --next-page-button-height: 62px;
  `,
}
