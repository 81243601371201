import React, { useMemo } from 'react'

import { useGetPageInfo } from 'hooks/useGetPageInfo'

import logo from 'assets/images/sprite/logo.svg'

import { ProgressBar } from '../ProgressBar'
import { StyledHeader as S } from './Header.styles'
import { PAGES_WITHOUT_HEADER_BORDER } from './constants'

export const Header: React.FC = () => {
  const { currentPageId, pagesWithProgressBar, hasHeader } = useGetPageInfo()

  const hasBorder = useMemo(
    () =>
      !PAGES_WITHOUT_HEADER_BORDER.includes(currentPageId) &&
      !pagesWithProgressBar.map(({ id }) => id).includes(currentPageId),
    [currentPageId, pagesWithProgressBar],
  )

  if (!hasHeader) return null

  return (
    <S.HeaderWrapper hasBorder={hasBorder}>
      <S.Header>
        <S.Logo svg={logo} />
      </S.Header>
      <ProgressBar />
    </S.HeaderWrapper>
  )
}
