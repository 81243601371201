import React from 'react'

import { PageContainer } from 'components/PageContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import user from 'assets/images/feedback.jpg'
import stars from 'assets/images/stars.svg'

import { StyledFeedback as S } from './Feedback.styles'

export const FeedbackVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const handleContinue = useNextStep({
    pageId,
    question: 'Emotional Intimacy Leads to Stronger Bonds',
    nextPagePath,
  })

  return (
    <PageContainer>
      <S.Column>
        <S.QuestionTitle>
          Emotional Intimacy Leads to Stronger Bonds
        </S.QuestionTitle>
        <S.FeedbackWrapper>
          <S.Photo>
            <img src={user} alt="user" />
          </S.Photo>
          <S.UserName>Olivia, 41</S.UserName>
          <S.Stars>
            <img src={stars} alt="user" />
          </S.Stars>
          <S.FeedbackText>
            &quot;I was skeptical at first, but this program exceeded my
            expectations. It provided practical tools and insights to help my
            partner and I strengthen our emotional connection.&quot;
          </S.FeedbackText>
        </S.FeedbackWrapper>
        <S.Text>
          Couples who <strong>regularly</strong> engage in{' '}
          <strong>emotional conversations</strong> feel more connected and in
          love
        </S.Text>
        <S.Button onClick={() => handleContinue('')}>Continue</S.Button>
      </S.Column>
    </PageContainer>
  )
}
