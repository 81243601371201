import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { STORIES_LIST } from 'modules/payment/components/SuccessStoryBlock/constants'

import stars from 'assets/images/stars.svg'

import { StyledSuccessStoryBlock as S } from './SuccessStoryBlock.styles'

type TProps = {
  className?: string
}
export const SuccessStoryBlock: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  return (
    <S.Block className={className}>
      <S.Title>{t('paymentTrialV1.successStories.title')}</S.Title>
      <S.List>
        {STORIES_LIST.map(({ user, story }) => (
          <S.ListItem key={user}>
            <S.Story>
              <Trans i18nKey={story} components={{ span: <span /> }} />
            </S.Story>
            <S.StoryAuthor>
              <S.Name>{t(user)}</S.Name>
              <img src={stars} alt="points" />
            </S.StoryAuthor>
          </S.ListItem>
        ))}
      </S.List>
    </S.Block>
  )
}
