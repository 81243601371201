import React, { useMemo } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton2 } from 'components/QuestionButton2'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useGetFlowGroupInfo } from 'hooks/useGetFlowGroupInfo'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledAge as S } from './Age.styles'

const QUESTION = 'How old are you?'
const OPTION_VALUES = {
  under21: '20',
  after21: '20',
  after31: '30',
  after41: '40',
  after51: '50',
  after60: '60',
}

export const AgeVariant2: React.FC<TPageProps> = ({ pageId }) => {
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()
  const { nextPagePath } = useGetFlowGroupInfo()
  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.TitleVariant2>{QUESTION}</S.TitleVariant2>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.under21}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>Under 21</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.after21}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>21 - 30</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.after31}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>31 - 40</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.after41}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>41 - 50</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.after51}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>51 - 60</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.after60}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>More than 60</QuestionButton2>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
