import { TProductId } from 'models/subscriptions.model'

import { ScreenName, eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { PaymentMethod, PaymentSystem } from '../constants'

export const logSuccessfulPayment = ({
  email,
  productId,
  screenName,
  price,
  currency,
  trialPrice,
  trialPeriodDays,
  subscriptionId,
  discountApplied,
  uuid,
  paymentMethod,
  paymentSystem,
  goal,
  stripeAccountId,
  stripeAccountName,
}: {
  email: string
  productId: TProductId
  screenName: ScreenName
  price: number
  currency?: string
  trialPrice?: number
  trialPeriodDays?: number
  subscriptionId: string
  discountApplied?: string
  uuid: string
  paymentMethod?: PaymentMethod
  paymentSystem: PaymentSystem
  goal: string
  stripeAccountName: string
  stripeAccountId: string
}): void => {
  if (trialPeriodDays) {
    window.fbq(
      'track',
      'StartTrial',
      {
        value: trialPrice,
        currency: 'USD',
        subscription_id: subscriptionId,
        // This field and similar ones below have been added to avoid FB pixel issue. https://github.com/facebook/facebook-nodejs-business-sdk/issues/164
        subscription_sid: subscriptionId,
      },
      { eventID: uuid },
    )
  }
  window.fbq(
    'track',
    'Purchase',
    {
      value: trialPrice || price,
      currency: 'USD',
      subscription_id: subscriptionId,
      subscription_sid: subscriptionId,
    },
    { eventID: uuid },
  )
  window.fbq(
    'track',
    'Subscribe',
    {
      value: trialPrice || price,
      currency: 'USD',
      subscription_id: subscriptionId,
      subscription_sid: subscriptionId,
    },
    { eventID: uuid },
  )

  eventLogger.logPurchaseCompleted({
    email,
    productId,
    screenName,
    priceDetails: {
      price,
      trial: !!trialPrice,
      currency,
    },
    paymentMethod,
    paymentSystem,
    transactionId: subscriptionId,
    goal,
    discountApplied,
    stripeAccountName,
    stripeAccountId,
  })

  googleAnalyticsLogger.logPurchaseCompleted({
    subscriptionId,
    price: trialPrice || price,
    productId,
  })
}
