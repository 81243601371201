import styled from 'styled-components'

import { Color } from 'root-constants/common'

export const StyledAward = {
  Container: styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Border: styled.div`
    aspect-ratio: 58/142;
    min-width: 58px;
  `,
  Title: styled.h1`
    font-weight: 800;
    font-size: 24px;
    color: ${Color.WHITE_100};
    text-align: center;
    line-height: 1;
    padding: 0 0 12px 0;

    strong:first-of-type {
      font-size: 38px;
    }

    strong {
      line-height: 1;
      font-size: 65px;
    }
  `,
}
