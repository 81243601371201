import styled from 'styled-components'

import intymacyImg from 'assets/images/summary-intimacy.webp'
import sexImg from 'assets/images/summary-sex.webp'

import { Color } from 'root-constants/common'

export const StyledSummaryBlock = {
  Wrapper: styled.div`
    margin-bottom: 35px;
  `,
  Title: styled.h2`
    font-weight: 700;
    font-size: 24px;
    color: var(--base-text-color);
    line-height: 36px;
    text-align: center;
    margin-bottom: 14px;
  `,
  Block: styled.div`
    padding: 28px 20px;
    border-radius: 40px;
    background-size: cover;
    background-repeat: no-repeat;

    &[data-is-sex-priority='true'] {
      background-image: url(${sexImg});
    }

    &[data-is-sex-priority='false'] {
      background-image: url(${intymacyImg});
    }
  `,
  List: styled.ul`
    width: 205px;
    line-height: 22px;

    li {
      margin-bottom: 14px;
      color: ${Color.PATTENS_BLUE};
    }

    li:last-child {
      margin-bottom: 0;
    }

    strong {
      display: block;
      font-weight: 800;
      font-size: 13px;
      color: ${Color.LILAC_130};
    }
  `,
}
