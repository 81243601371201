import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton2 } from 'components/QuestionButton2'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { PageId } from 'root-constants/pages'

import { StyledPartnerFriendships as S } from './PartnerFriendships.styles'

const QUESTION =
  'How do you feel about your partner having close friendships with people of the opposite sex?'
const OPTION_VALUES = {
  perfectlyFine: `It's perfectly fine with me`,
  makesLittleUncomfortable:
    'It makes me a little uncomfortable, but I trust my partner',
  expressedConcerns: `I'm very uncomfortable with it and have expressed my concerns to my partner`,
  hardToExpressFeelings: `I'm very uncomfortable with it but it’s hard for me to express my feelings to my partner`,
}

export const PartnerFriendshipsVariant1: React.FC<TPageProps> = ({
  pageId,
}) => {
  const { search } = useLocation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath: `${PageId.JEALOUSY_1}${search}`,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.Title>{QUESTION}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.perfectlyFine}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.perfectlyFine}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.makesLittleUncomfortable}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>
              {OPTION_VALUES.makesLittleUncomfortable}
            </QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.expressedConcerns}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.expressedConcerns}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.hardToExpressFeelings}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>
              {OPTION_VALUES.hardToExpressFeelings}
            </QuestionButton2>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
