import React, { useMemo } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton } from 'components/QuestionButton'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledNewInSex as S } from './NewInSex.styles'

const QUESTION = `What will you do if your partner suggests you try something sexual that you've never tried before?`
const OPTION_VALUES = {
  tryBecauseOfTrust: 'I would probably try it because I trust them',
  notTry: 'I would not do it because I would be too uncomfortable',
  tryBecauseOfSexuality: `I would try it because I couldn't resist their sexuality`,
}

export const NewInSexVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, handleContinue, pageId, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.QuestionTitle>{QUESTION}</S.QuestionTitle>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.tryBecauseOfTrust}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.tryBecauseOfTrust}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.notTry}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>{OPTION_VALUES.notTry}</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.tryBecauseOfSexuality}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>
              {OPTION_VALUES.tryBecauseOfSexuality}
            </QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
