import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  getUserStatusAction,
  sendUserAnswersAction,
} from 'root-redux/actions/user'
import { selectUUID } from 'root-redux/selects/user'

import { StyledPaymentStatus as S } from 'modules/payment/styles'

import successIcon from 'assets/images/success.svg'

type TProps = {
  onNextStep?: () => void
}

export const PaymentSuccess: React.FC<TProps> = ({ onNextStep }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)

  const handleSuccessButtonClick = useCallback(() => {
    if (onNextStep) {
      onNextStep()
      return
    }
    dispatch(getUserStatusAction(uuid))
    dispatch(sendUserAnswersAction(true))
  }, [dispatch, onNextStep, uuid])

  return (
    <S.Wrapper>
      <S.Container>
        <img src={successIcon} width="108" alt="Success" />
        <S.Title>{t('payments.paymentSuccess.title')}</S.Title>
        <S.Subtitle>{t('payments.paymentSuccess.subtitle')}</S.Subtitle>
        <S.Button type="button" onClick={handleSuccessButtonClick}>
          {t('actions.proceedToMyPlan')}
        </S.Button>
      </S.Container>
    </S.Wrapper>
  )
}
