import React, { useMemo, useState } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { StickyButton } from 'components/StickyButton'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledThingsToBeIntimate as S } from './ThingsToBeIntimate.styles'

const QUESTION =
  'What are some things you do to make sure your sex life stays intimate?'
const OPTION_VALUES = {
  talking: 'Spend time talking before and after sex',
  touch: 'Touch each other often outside of the bedroom',
  timeForSex: 'Make sure we have time for sex',
  all: 'All of the above',
}

export const ThingsToBeIntimateVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const [answers, setAnswers] = useState<string[]>([])

  const isButtonsDisabled = useMemo(
    () => answers.includes(OPTION_VALUES.all),
    [answers],
  )
  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })
  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.CHECKBOX,
      onChange: (value, isChecked) => {
        if (isChecked) {
          value === OPTION_VALUES.all
            ? setAnswers([value])
            : setAnswers([...answers, value])
        } else {
          const newValues = answers.filter((item) => item !== value)
          setAnswers(newValues)
        }
      },
    }),
    [answers, pageId],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.QuestionTitle>{QUESTION}</S.QuestionTitle>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.talking}
            disabled={isButtonsDisabled}
            checked={answers.includes(OPTION_VALUES.talking)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.talking}
            </S.QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.touch}
            disabled={isButtonsDisabled}
            checked={answers.includes(OPTION_VALUES.touch)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.touch}
            </S.QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.timeForSex}
            disabled={isButtonsDisabled}
            checked={answers.includes(OPTION_VALUES.timeForSex)}
          >
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.timeForSex}
            </S.QuestionButton>
          </Option>
          <Option {...optionProps} value={OPTION_VALUES.all}>
            <S.QuestionButton hasCheckboxIcon>
              {OPTION_VALUES.all}
            </S.QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
        <StickyButton
          disabled={!answers.length}
          onClick={() => handleContinue(answers)}
        >
          Next
        </StickyButton>
      </S.Column>
    </PageContainer>
  )
}
