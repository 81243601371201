export const BENEFITS_V1 = [
  'subscriptionsV2.benefitsV3.intimacySatisfaction',
  'subscriptionsV2.benefitsV3.sexLife',
  'subscriptionsV2.benefitsV3.newPleasure',
  'subscriptionsV2.benefitsV3.sexCompatibility',
]
export const BENEFITS_V2 = [
  'subscriptionsV2.benefitsV3.communicationIntimacy',
  'subscriptionsV2.benefitsV3.thoughtsFeelings',
  'subscriptionsV2.benefitsV3.presentMindful',
  'subscriptionsV2.benefitsV3.strongerBond',
  'subscriptionsV2.benefitsV3.empathyUnderstanding',
]
