import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import uniqueId from 'lodash/uniqueId'

import { useUserData } from 'hooks/useUserData'

import banner from 'assets/images/payment-trial-v2-banner.webp'

import { Color } from 'root-constants/common'

import { StyledSatisfactionBlockV2 as S } from './SatisfactionBlockV2.styles'
import { RATE_RELATIONSHIP_VALUES } from './constants'

type TProps = {
  className?: string
}

export const SatisfactionBlockV2: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  const { rateRelationship } = useUserData()

  const currentRateValue = useMemo(
    () => RATE_RELATIONSHIP_VALUES[rateRelationship],
    [rateRelationship],
  )

  return (
    <S.Block className={className}>
      <S.ContentContainer>
        <S.LabelContainer>
          <S.Column>
            <S.Label>{t('paymentTrialV1.satisfactionLevel.labelNow')}</S.Label>
          </S.Column>
          <S.Column>
            <S.Label>{t('paymentTrialV1.satisfactionLevel.labelGoal')}</S.Label>
          </S.Column>
        </S.LabelContainer>

        <S.BannerContainer>
          <img src={banner} alt="banner" />
        </S.BannerContainer>

        <S.RateContainer>
          <S.Column>
            <S.RateTitle>
              {t('paymentTrialV1.satisfactionLevel.title')}
            </S.RateTitle>
            <S.ScaleContainer>
              {[...Array(5)].map((el, index) => (
                <S.ScaleDivision
                  key={uniqueId('current_scale_')}
                  color={
                    index < currentRateValue
                      ? Color.VIOLET_110
                      : Color.WHITE_110
                  }
                />
              ))}
            </S.ScaleContainer>
          </S.Column>
          <S.Column>
            <S.RateTitle>
              {t('paymentTrialV1.satisfactionLevel.title')}
            </S.RateTitle>
            <S.ScaleContainer>
              {[...Array(5)].map(() => (
                <S.ScaleDivision
                  key={uniqueId('goal_scale_')}
                  color={Color.VIOLET_110}
                />
              ))}
            </S.ScaleContainer>
          </S.Column>
        </S.RateContainer>
      </S.ContentContainer>
    </S.Block>
  )
}
