import { IAppState } from 'models/store.model'

import { DEFAULT_CURRENCY } from 'root-constants/common'

export const selectSubscription = (state: IAppState) =>
  state.payment.selectedSubscription || null
export const selectSubscriptionId = (state: IAppState) =>
  state.payment.selectedSubscription?.id || ''
export const selectSubscriptionLookupKey = (state: IAppState) =>
  state.payment.selectedSubscription?.lookupKey || ''
export const selectPayPalPlanId = (state: IAppState) =>
  state.payment.selectedSubscription?.payPalPlanId || ''
export const selectCurrency = (state: IAppState) =>
  state.payment.selectedSubscription?.currency.toLowerCase() || DEFAULT_CURRENCY

// main prices
export const selectSubscriptionPeriodQuantity = (state: IAppState) =>
  state.payment.selectedSubscription?.mainPrices.periodQuantity || 0
export const selectSubscriptionPeriodName = (state: IAppState) =>
  state.payment.selectedSubscription?.mainPrices.periodName || ''
export const selectSubscriptionMainPrice = (state: IAppState) =>
  state.payment.selectedSubscription?.mainPrices.fullPrice || 0
export const selectSubscriptionIntroDifAmount = (state: IAppState) =>
  state.payment.selectedSubscription?.introDiff?.discountAmount || 0
export const selectSubscriptionIntroDifPercents = (state: IAppState) =>
  state.payment.selectedSubscription?.introDiff?.discountPercentage || 0

// trial prices
export const selectSubscriptionTrialLookupKey = (state: IAppState) =>
  state.payment.selectedSubscription?.trialLookupKey || ''
export const selectSubscriptionTrialPrice = (state: IAppState) =>
  state.payment.selectedSubscription?.trialPrices.fullPrice || 0
export const selectSubscriptionTrialPeriodDays = (state: IAppState) =>
  state.payment.selectedSubscription?.trialPrices.durationDays || 0
export const selectSubscriptionTrialDailyPrice = (state: IAppState) =>
  state.payment.selectedSubscription?.trialPrices.daily || 0

export const selectHighestIntroDiff = (state: IAppState) =>
  state.payment.highestDiscountPercentage

export const select3DSecureIframeUrl = (state: IAppState) =>
  state.payment.threeDSecureIframeURL
export const selectPaymentClientSecret = (state: IAppState) =>
  state.payment.paymentClientSecret
export const selectCreatedSubscriptionId = (state: IAppState) =>
  state.payment.subscriptionId
export const selectIsPaymentFlowsShown = (state: IAppState) =>
  state.payment.isPaymentFlowsShown
