import React, { useLayoutEffect, useMemo } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton2 } from 'components/QuestionButton2'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useGetFlowGroupInfo } from 'hooks/useGetFlowGroupInfo'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { replaceHistory } from 'browser-history'

import { StyledMainGoals as S } from './MainGoals.styles'

const QUESTION =
  'What is your main goal in improving your spiritual intimacy with your partner?'
const OPTION_VALUES = {
  feelMoreSpiritually: 'To feel more spiritually aligned with my partner',
  expressGratitude: 'To express gratitude and find meaning in life together',
  feelMoreConnected: 'To feel more connected to a higher power together',
  resolveSpiritualConflicts:
    'To resolve any spiritual conflicts or issues in our relationship',
  haveNewExperiences:
    'To have new meaningful spiritual experiences with my partner',
}

export const MainGoalsVariant4: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
  alternativePagePath,
}) => {
  const { isMultipleFlow } = useGetFlowGroupInfo()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  useLayoutEffect(() => {
    if (isMultipleFlow) {
      replaceHistory(alternativePagePath)
    }
  }, [alternativePagePath, isMultipleFlow])

  return isMultipleFlow ? null : (
    <PageContainer>
      <S.Column>
        <S.QuestionTitleVariant2>{QUESTION}</S.QuestionTitleVariant2>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.feelMoreSpiritually}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>
              {OPTION_VALUES.feelMoreSpiritually}
            </QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.expressGratitude}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.expressGratitude}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.feelMoreConnected}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.feelMoreConnected}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.resolveSpiritualConflicts}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>
              {OPTION_VALUES.resolveSpiritualConflicts}
            </QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.haveNewExperiences}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>
              {OPTION_VALUES.haveNewExperiences}
            </QuestionButton2>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
