import styled from 'styled-components'

import { QuestionTitle, baseColumnStyles } from 'common-styles'
import { BIG_COLUMN_WIDTH, MAX_PHONE_WIDTH } from 'root-constants/common'

export const StyledIntellectualDifferences = {
  Column: styled.div`
    ${baseColumnStyles};
    padding: 20px;
    width: 100%;

    @media (min-width: ${MAX_PHONE_WIDTH}px) {
      width: ${BIG_COLUMN_WIDTH}px;
    }
  `,
  QuestionTitle: styled(QuestionTitle)`
    line-height: 28px;
  `,
  Note: styled.span`
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: rgba(237, 237, 255, 0.7);
  `,
}
