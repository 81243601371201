import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { resetErrorAction } from 'root-redux/actions/common'
import {
  selectCurrentVariantCohort,
  selectScreenName,
} from 'root-redux/selects/common'

import { useProductId } from 'hooks/useProductId'
import { useUserData } from 'hooks/useUserData'

import { PaymentMethod } from 'modules/payment/constants'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import icon from 'assets/images/cross-2.svg'
import paymentServicesImage from 'assets/images/payment-services-2.png'

import { goBack } from 'browser-history'

import { PayPalContainer } from '../PayPalContainer'
import { PaymentRequestButton } from '../PaymentRequestButton'
import { StripePaymentForm } from '../StripePaymentForm'
import { TotalAmount } from '../TotalAmount'
import { StyledPaymentFlows as S } from './PaymentFlows.styles'

export const PaymentFlows2: React.FC = () => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const cohort = useSelector(selectCurrentVariantCohort)
  const screenName = useSelector(selectScreenName)

  const productId = useProductId()
  const { usersPriority } = useUserData()

  const handleSelectPayPalPaymentMethod = useCallback(() => {
    eventLogger.logPaymentMethodSelected(PaymentMethod.PAYPAL)
  }, [])

  const handleBack = useCallback(() => {
    dispatch(resetErrorAction())
    googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)
    eventLogger.logPurchaseScreenClosed({
      productId,
      screenName,
      goal: usersPriority,
    })
    goBack()
  }, [cohort, dispatch, pathname, usersPriority, productId, screenName])

  return (
    <>
      <S.TitleWrapper>
        <S.CloseButton
          type="button"
          onClick={handleBack}
          style={{ backgroundImage: `url(${icon})` }}
        />
        <S.Title>Checkout</S.Title>
      </S.TitleWrapper>
      <TotalAmount />
      <S.Form>
        <StripePaymentForm />
      </S.Form>
      <S.Divider>
        <S.DividerText2>OR</S.DividerText2>
      </S.Divider>
      <PayPalContainer onClick={() => handleSelectPayPalPaymentMethod()} />
      <PaymentRequestButton />
      <S.PaymentServiceImage
        width="256px"
        src={paymentServicesImage}
        decoding="async"
        alt="payments services"
      />
      <S.Guarantee>Money-Back Guarantee</S.Guarantee>
      <S.Description>
        By continuing, you are signing up for a subscription that automatically
        renews at the end of the selected period unless you cancel at least 24
        hours before the end of the period. To avoid being charged, cancel your
        subscription by contacting our support at least 24 hours before the end
        of the period. If you are unsure how to cancel, visit our Terms of Use.
        You may wish to take a screenshot of this information for your
        reference.
      </S.Description>
    </>
  )
}
