import styled from 'styled-components'

import { StickyButton } from 'components/StickyButton'

import { QuestionTitle, baseColumnStyles } from 'common-styles'
import { Color } from 'root-constants/common'

export const StyledBreakup = {
  Column: styled.div`
    ${baseColumnStyles};
    padding: 40px 0;
  `,
  Title: styled(QuestionTitle)`
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 28px;

    strong {
      font-weight: 700;
      color: ${Color.LILAC_130};
    }

    @media (min-width: 360px) {
      margin: 0 -10px 28px;
    }
  `,
  Image: styled.div`
    aspect-ratio: 155 / 88;
    margin-bottom: 35px;
    width: 100%;
  `,
  Text: styled.p`
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: var(--base-text-color);
    margin-bottom: 38px;

    strong {
      font-weight: 800;
      color: ${Color.LILAC_130};
    }
  `,
  Button: styled(StickyButton)`
    height: 62px;

    @media (max-height: 620px) {
      position: relative;
      bottom: 0;
    }
  `,
}
