export const ONE_LINK_URL = 'https://upluv.onelink.me/5Qwq/mv3abvne'

export const STEPS_MAP = [
  {
    stepNumber: 1,
    stepDescription: 'downloadApp.firstStep',
  },
  {
    stepNumber: 2,
    stepDescription: 'downloadApp.secondStep',
  },
]
