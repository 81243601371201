import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import { StripeElementStyle } from '@stripe/stripe-js'
import styled, { css } from 'styled-components'

import { Button } from 'components/Button'
import { SvgImage } from 'components/SvgImage'

import warningIcon from 'assets/images/warning.svg'

import { Color } from 'root-constants/common'

const INPUT_PLACEHOLDER_COLOR = 'rgba(10, 50, 77, 0.3)'

export const stripeElementStyle: StripeElementStyle = {
  base: {
    color: Color.DEFAULT_TEXT,
    fontFamily: 'Nunito, sans-serif',
    fontSize: '16px',
    lineHeight: '56px',
    fontWeight: 700,
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: INPUT_PLACEHOLDER_COLOR,
      fontWeight: 400,
    },
  },
  invalid: {
    color: Color.ERROR,
  },
}

const commonInputStyles = css`
  color: ${Color.DEFAULT_TEXT};
  height: 54px;
  background-color: ${Color.LILAC_110};
  line-height: 54px;
  border-radius: 12px;
  border: none;
  font-size: 16px;
  margin-bottom: 12px;
  padding-left: 16px;
`

const commonInputContainerStyles = css`
  position: relative;
  height: 56px;
  margin-bottom: 8px;
  background-color: white;
  border: 1px solid white;
  border-radius: 12px;

  &[data-invalid='true'] {
    border-color: ${Color.ERROR};

    ::before {
      content: url(${warningIcon});
      position: absolute;
      right: 16px;
      top: 18px;
    }
  }
`

export const StyledStripePaymentForm = {
  Form: styled.form``,
  SubmitButton: styled(Button)``,
  InputContainer: styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  `,
  CardNumberContainer: styled.div`
    ${commonInputContainerStyles};
  `,
  CvvExpiryInputContainer: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  CardExpiryContainer: styled.div`
    ${commonInputContainerStyles};
    width: 146px;
  `,
  CardCvvContainer: styled.div`
    ${commonInputContainerStyles};
    width: 146px;

    ::before {
      right: 48px !important;
    }
  `,
  CardSvg: styled(SvgImage)`
    position: absolute;
    right: 16px;
    top: 17px;
    fill: var(--payment-icon-color);
    width: 24px;
  `,
  Label: styled.div`
    padding-bottom: 8px;
    font-size: 13px;
    line-height: 20px;
    color: var(--base-text-color);
  `,
  CardNumberElement: styled(CardNumberElement)`
    ${commonInputStyles};
  `,
  CardExpiryElement: styled(CardExpiryElement)`
    ${commonInputStyles};
  `,
  CardCvcElement: styled(CardCvcElement)`
    ${commonInputStyles};
  `,
  CardholderInput: styled.input`
    ${commonInputStyles};
    width: 100%;
    font-family: 'Nunito', sans-serif;
    outline: none;
    box-shadow: none;
    ::placeholder {
      color: ${INPUT_PLACEHOLDER_COLOR};
    }
  `,
}
