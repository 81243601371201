import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton2 } from 'components/QuestionButton2'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { PageId } from 'root-constants/pages'

import { StyledBestFriend as S } from './BestFriend.styles'

const QUESTION = 'Do you consider your partner your best friend?'
const OPTION_VALUES = {
  yes: 'Yes',
  no: 'No',
}

export const BestFriendVariant1: React.FC<TPageProps> = ({ pageId }) => {
  const { search } = useLocation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath: `${PageId.FELT_LONELY_1}${search}`,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.QuestionTitle>{QUESTION}</S.QuestionTitle>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.yes}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.yes}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.no}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.no}</QuestionButton2>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
