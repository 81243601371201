import React from 'react'

import { StyledProgressBarRounded as S } from './ProgressBarRounded.styles'

type TProps = {
  value: number
  description?: string
  className?: string
}

export const ProgressBarRounded: React.FC<TProps> = ({
  value,
  description,
  className,
}) => {
  return (
    <S.Container className={className}>
      <S.ProgressBar>
        <S.Svg viewBox="0 0 100 100" value={value}>
          <circle r="45" cx="50" cy="50" />
          <circle r="45" cx="50" cy="50" />
        </S.Svg>
        <S.Label>
          {value}
          <strong>%</strong>
        </S.Label>
      </S.ProgressBar>
      {description && (
        <S.TitleWrapper>
          <S.ProgressTitle>{description}</S.ProgressTitle> <S.BlinkingDots />
        </S.TitleWrapper>
      )}
    </S.Container>
  )
}
