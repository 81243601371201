import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { config } from 'pages/presale/constants'

import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useIsInViewport } from 'hooks/useIsInViewport'
import { useUserData } from 'hooks/useUserData'

import { STATUS_TO_MARKUP_MAP } from 'modules/subscriptions/constants'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import upLovePresaleHeart from 'assets/images/uplove-presale-heart-icon.png'

import { goTo } from 'browser-history'
import { PageId } from 'root-constants/pages'

import { UpLovePresale as S } from './Presale.styles'

const { title, subtitle, setup, title2, guarantees, subtitle2, reasons } =
  config

export const Presale1: React.FC<TPageProps> = ({ pageId }) => {
  const buttonElementRef = useRef<HTMLButtonElement>(null)

  const { userGoal, relationshipStatus, gender, age } = useUserData()
  const isBottomButtonVisible = useIsInViewport(buttonElementRef)
  const { currentSubscriptionPageId } = useGetPageInfo()
  const { search } = useLocation()

  const genderAndAgeValue = useMemo(() => {
    if (gender === 'Non-binary') {
      return `For non-binary couples`
    }
    if (gender === 'Female') {
      return `For women in their ${age}s`
    }
    return `For men in their ${age}s`
  }, [gender, age])

  const summary = useMemo(
    () => [
      {
        icon: upLovePresaleHeart,
        text: `Goal: ${userGoal}`,
        id: 1,
      },
      {
        icon: upLovePresaleHeart,
        text: `Relationship status: ${STATUS_TO_MARKUP_MAP[relationshipStatus]}`,
        id: 2,
      },
      {
        icon: upLovePresaleHeart,
        text: 'Adapted for couples & solo activities',
        id: 3,
      },
      {
        icon: upLovePresaleHeart,
        text: genderAndAgeValue,
        id: 4,
      },
    ],
    [userGoal, relationshipStatus, genderAndAgeValue],
  )

  useEffect(() => {
    if (isBottomButtonVisible) {
      eventLogger.logScrollToBottom()
    }
  }, [isBottomButtonVisible])

  useEffect(() => {
    eventLogger.logPresalePageShown({ pageName: pageId })
  }, [pageId])

  const handleContinue = useCallback(() => {
    eventLogger.logPresalePageCompleted({ pageName: pageId })
    goTo({ pathname: currentSubscriptionPageId as PageId, search })
  }, [pageId, currentSubscriptionPageId, search])

  return (
    <S.Wrapper>
      <S.Title>{title}</S.Title>
      <S.Subtitle>{subtitle}</S.Subtitle>
      <S.SetupSummaryWrapper>
        <S.Context>{setup}</S.Context>
        {summary.map(({ icon, text, id }) => (
          <S.SetupSummaryContent key={id}>
            <S.Icon>
              <img src={icon} alt={text} />
            </S.Icon>
            <S.SetupSummaryText>{text}</S.SetupSummaryText>
          </S.SetupSummaryContent>
        ))}
      </S.SetupSummaryWrapper>
      <S.Title>{title2}</S.Title>
      {guarantees.map(({ icon, text, id }) => (
        <S.GuaranteesWrapper key={id}>
          <S.GuaranteesImage>
            <img src={icon} alt={icon} />
          </S.GuaranteesImage>
          <S.GuaranteesText>{text}</S.GuaranteesText>
        </S.GuaranteesWrapper>
      ))}
      <S.Subtitle2>{subtitle2}</S.Subtitle2>
      {reasons.map(({ icon, text, id }) => (
        <S.ReasonsWrapper key={id}>
          <S.Icon>
            <img src={icon} alt={text} />
          </S.Icon>
          <S.ReasonsText>{text}</S.ReasonsText>
        </S.ReasonsWrapper>
      ))}
      <S.Button ref={buttonElementRef} onClick={handleContinue}>
        Get My Plan
      </S.Button>
    </S.Wrapper>
  )
}
