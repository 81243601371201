import styled from 'styled-components'

export const StyledPaywallTimerBlock = {
  Block: styled.div`
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding: 12px;

    strong {
      font-size: 24px;
      font-weight: 800;
      line-height: 28px;
    }
  `,
  Label: styled.span`
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;

    &:first-of-type {
      margin: 0 4px 0 0;
    }
    &:last-of-type {
      margin: 0 0 0 4px;
    }
  `,
}
