import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { StickyContainer } from 'components/StickyContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import banner from 'assets/images/social-proof-banner.png'

import { StyledSocialProof as S } from './SocialProof.styles'

export const SocialProofVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.socialProofV1.title', { lng: 'en' }),
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Column>
        <S.Title>
          <Trans i18nKey="onboarding.socialProofV1.title" />
        </S.Title>
        <S.Banner>
          <img src={banner} alt="social-proof" />
        </S.Banner>
        <StickyContainer>
          <S.StickyButton onClick={() => handleContinue('')}>
            {t('actions.continue')}
          </S.StickyButton>
        </StickyContainer>
      </S.Column>
    </S.Wrapper>
  )
}
