import styled from 'styled-components'

import { Button } from 'components/Button'
import { QuestionButton2 } from 'components/QuestionButton2'

import { QuestionTitle, baseColumnStyles } from 'common-styles'

export const StyledStepsToSpiritualConnections = {
  Column: styled.div`
    ${baseColumnStyles};
    padding: 40px 0 70px;
  `,
  Title: styled(QuestionTitle)`
    margin: 0 -15px 52px;
    line-height: 28px;
  `,
  Button: styled(Button)`
    height: 62px;
  `,
  QuestionButton: styled(QuestionButton2)`
    input:disabled + & {
      background-color: rgba(217, 216, 246, 0.5);
    }
  `,
}
