import React, { useMemo } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton } from 'components/QuestionButton'
import { TitleContainer } from 'components/TitleContainer'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { SexQuantityValue } from 'root-constants/common'

import { StyledSexQuantity as S } from './SexQuantity.styles'

const QUESTION = 'How many times a week do you have sex? '

export const SexQuantityVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, handleContinue, pageId, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <TitleContainer title={<S.QuestionTitle>{QUESTION}</S.QuestionTitle>} />
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={SexQuantityValue.ONCE_IN_A_WHILE}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>Once in a while</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={SexQuantityValue.ONE_TWO}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>1-2 times</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={SexQuantityValue.THREE_FIVE}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>3-5 times</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={SexQuantityValue.SIX}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>More than 6 times</QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={SexQuantityValue.NOT_SAY}
            disabled={isAnswersDisabled}
          >
            <QuestionButton>I prefer not to say</QuestionButton>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
