import styled from 'styled-components'

import { BASE_COLUMN_WIDTH } from 'root-constants/common'

export const StyledSecurity = {
  SecureImage: styled.div`
    aspect-ratio: 83 / 30;
    border-radius: 100px;
    height: 60px;
    margin: 0 auto 26px;
    overflow: hidden;
  `,
  SecureCard: styled.div`
    margin-bottom: 32px;
    padding: 22px 24px 0;
    width: ${BASE_COLUMN_WIDTH}px;
    background-color: var(--garantee-background-color);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
  `,
  SecureItemTitle: styled.h4`
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
  `,
  SecureItemText: styled.p`
    margin-bottom: 32px;
    font-size: 13px;
    line-height: 20px;

    > a {
      color: var(--secure-link-color);
      font-weight: 700;
    }
  `,
}
