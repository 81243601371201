import React, { useMemo } from 'react'

import { Option, OptionType } from 'components/Option'
import { StyledOption } from 'components/Option/Option.styles'
import { PageContainer } from 'components/PageContainer'
import { QuestionButton2 } from 'components/QuestionButton2'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useGetFlowGroupInfo } from 'hooks/useGetFlowGroupInfo'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledDealWithDifferences as S } from './DealWithDifferences.styles'

const QUESTION =
  'How do you and your partner deal with differences in physical intimacy needs or desires?'
const OPTION_VALUES = {
  communicateOpenly:
    'We communicate openly and try to find a solution together',
  avoidDifferences:
    'We avoid discussing differences in physical intimacy needs or desires',
  becomeFrustrated: 'We become frustrated with each other',
  other: 'Other',
}

export const DealWithDifferencesVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
  alternativePagePath,
}) => {
  const { isMultipleFlow } = useGetFlowGroupInfo()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath: isMultipleFlow ? alternativePagePath : nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [pageId, handleChange, handleContinue, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <S.Title>{QUESTION}</S.Title>
        <StyledOption.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.communicateOpenly}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.communicateOpenly}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.avoidDifferences}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.avoidDifferences}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.becomeFrustrated}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.becomeFrustrated}</QuestionButton2>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.other}
            disabled={isAnswersDisabled}
          >
            <QuestionButton2>{OPTION_VALUES.other}</QuestionButton2>
          </Option>
        </StyledOption.OptionsContainer>
      </S.Column>
    </PageContainer>
  )
}
