import styled from 'styled-components'

import { QuestionTitle, baseColumnStyles } from 'common-styles'

export const StyledDiscuss = {
  Column: styled.div`
    ${baseColumnStyles};
    padding: 40px 0;
  `,
  QuestionTitle: styled(QuestionTitle)`
    line-height: 28px;
    margin-bottom: 125px;
  `,
}
