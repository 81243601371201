import { SyntheticEvent, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { GET_SUBSCRIPTION_LIST } from 'root-redux/actions/common'
import {
  selectActionList,
  selectCurrentVariantCohort,
  selectScreenName,
  selectSubscriptionList,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { useSetDefaultSubscription } from 'hooks/useSetDefaultSubscription'

import { setSelectedSubscriptionAction } from 'modules/payment/redux/actions'
import { selectSubscription } from 'modules/payment/redux/selects'

import { ISubscription } from 'models/subscriptions.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { goTo } from 'browser-history'
import { PagePath } from 'root-constants/common'

import { SubscriptionButtonText } from '../pages/constants'

export const usePaywall = (nextPagePath: string) => {
  const dispatch = useDispatch()
  const cohort = useSelector(selectCurrentVariantCohort)
  const selectedSubscription = useSelector(selectSubscription)
  const uuid = useSelector(selectUUID)
  const subscriptionsList = useSelector(selectSubscriptionList)
  const fetchingActionsList = useSelector(selectActionList)
  const screenName = useSelector(selectScreenName)

  useSetDefaultSubscription()

  const hasPrices = useMemo(
    () =>
      !fetchingActionsList?.includes(GET_SUBSCRIPTION_LIST) &&
      !!subscriptionsList.length,
    [fetchingActionsList, subscriptionsList],
  )

  const handleAmplitudePaymentEvents = useCallback(
    (target: HTMLButtonElement) =>
      eventLogger.logPlansPageButtonTapped({
        screenName,
        buttonNumber: +(target.getAttribute('data-button-number') || 1),
        buttonText:
          (target.getAttribute('data-button-text') as SubscriptionButtonText) ||
          SubscriptionButtonText.GET_PLAN_UPPER,
      }),
    [screenName],
  )

  const handleShowPayment = useCallback(
    (event: SyntheticEvent<HTMLButtonElement>) => {
      window.fbq('track', 'AddToCart', {}, { eventID: uuid })
      window.fbq('track', 'InitiateCheckout', {}, { eventID: uuid })
      googleAnalyticsLogger.logPageView(`${PagePath.SUBSCRIPTIONS}/${cohort}`)
      googleAnalyticsLogger.logAddingToCart(
        selectedSubscription as ISubscription,
      )
      googleAnalyticsLogger.logCheckoutStarted(subscriptionsList)

      handleAmplitudePaymentEvents(event.target as HTMLButtonElement)

      goTo(nextPagePath)
    },
    [cohort, nextPagePath, selectedSubscription, uuid, subscriptionsList],
  )

  const handleSelectSubscription = useCallback(
    (subscription?: ISubscription): void => {
      subscription && dispatch(setSelectedSubscriptionAction(subscription))
    },
    [dispatch],
  )

  return {
    hasPrices,
    handleShowPayment,
    handleSelectSubscription,
  }
}
