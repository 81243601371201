import React from 'react'
import { useTranslation } from 'react-i18next'

import { TermsOfUseLink } from 'components/TermsOfUseLink'

import stamp from 'assets/images/guarantee.svg'

import { StyledMoneyBackGuarantee as S } from './MoneyBackGuarantee.styles'

type TProps = {
  text: string
  className?: string
}
export const MoneyBackGuarantee: React.FC<TProps> = ({ className, text }) => {
  const { t } = useTranslation()

  return (
    <S.Wrapper className={className}>
      <S.Content>
        <S.Title>{t('commonComponents.guarantee.title')}</S.Title>
        <S.Text>
          {t(text)} <TermsOfUseLink />.
        </S.Text>
      </S.Content>
      <S.Stamp src={stamp} />
    </S.Wrapper>
  )
}
