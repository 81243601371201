import { useCallback, useEffect, useState } from 'react'

import { IInputFieldState, TInputFieldHook } from 'models/inputField.model'

import { EMAIL_REGEXP, ValidationText } from 'root-constants/common'

export const useEmailInputField = (
  initialValue = '',
  errorMessage: ValidationText | null = null,
  resetErrorMessage: () => void = () => undefined,
): TInputFieldHook => {
  const [email, setEmail] = useState<IInputFieldState>({
    value: initialValue,
    isValid: true,
    validationText: ValidationText.INVALID_EMAIL,
  })

  const validateEmail = useCallback((emailValue: string) => {
    if (!EMAIL_REGEXP.test(emailValue) && emailValue !== '') {
      setEmail((prevState) => ({
        ...prevState,
        value: emailValue,
        isValid: false,
        validationText: ValidationText.INVALID_EMAIL,
      }))
      return
    }

    setEmail((prevState) => ({
      ...prevState,
      value: emailValue,
      isValid: true,
      validationText: '',
    }))
  }, [])

  useEffect(() => {
    if (errorMessage) {
      setEmail((prevState) => ({
        ...prevState,
        isValid: false,
        validationText: errorMessage,
      }))

      resetErrorMessage()
    }
  }, [errorMessage, resetErrorMessage])

  return [email, setEmail, validateEmail]
}
