import styled from 'styled-components'

import { Color } from 'root-constants/common'

export const StyledPlanBlock = {
  Block: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  Title: styled.h2`
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    color: ${Color.LILAC_120};
    margin: 0 0 24px 0;
  `,
  PlanContainer: styled.div`
    width: 100%;
    aspect-ratio: 327/254;
  `,
}
