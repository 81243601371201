import styled from 'styled-components'

import { PageContainer } from 'components/PageContainer'

import { baseColumnStyles } from 'common-styles'

export const StyledPayment = {
  Wrapper: styled(PageContainer)`
    min-height: 100%;
  `,
  Content: styled.div`
    ${baseColumnStyles};
  `,
  ContentV4: styled.div`
    ${baseColumnStyles};
    padding: 40px 0 70px;
  `,
  ThreeDSecureIframe: styled.iframe`
    height: calc(var(--full-height, 100vh) - 50px) !important;
    padding: 15px;
  `,
}
