import styled, { css } from 'styled-components'

import { StyledButtonBase } from 'components/Button/Button.styles'

import { BottomStickyBase, baseColumnStyles } from 'common-styles'
import { BASE_COLUMN_WIDTH, Color } from 'root-constants/common'

const StyledAccountButtonBase = css`
  ${StyledButtonBase};
  color: #fff;
  padding: 0 32px;
  font-size: 18px;
  background-image: none;
  width: 100%;
  display: flex;
  align-items: center;

  :not(:last-child) {
    margin-bottom: 16px;
  }

  img {
    margin-right: 10px;
  }
`

export const StyledAccountButton = {
  Email: styled.button`
    ${StyledAccountButtonBase};
    background-color: ${Color.GREEN};

    img {
      margin-right: 14px;
    }
  `,
  Facebook: styled.button`
    ${StyledAccountButtonBase};
    background-color: #395a98;
  `,
  Apple: styled.button`
    ${StyledAccountButtonBase};
    background-color: ${Color.WHITE_110};
    color: ${Color.BLACK_100};
  `,
}

export const StyledAccount = {
  Column: styled.div`
    ${baseColumnStyles};
    padding: 40px 0;
    color: var(--base-text-color);
  `,
  Title: styled.h1`
    margin-bottom: 12px;
    font-size: 26px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
  `,
  Text: styled.p`
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${Color.LIGHT_TEXT};
  `,
  Buttons: styled.div`
    ${BottomStickyBase};
    width: ${BASE_COLUMN_WIDTH}px;
  `,
}
