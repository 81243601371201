import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserData } from 'hooks/useUserData'

import { StyledPersonalInfoBlock as S } from './PersonalInfoBlock.styles'
import {
  RELATIONSHIP_DURATION_VALUES,
  STATUS_RELATIONSHIP_VALUES,
} from './constants'

type TProps = {
  className?: string
}
export const PersonalInfoBlock: React.FC<TProps> = ({ className }) => {
  const { t } = useTranslation()

  const { relationshipStatus, relationshipDuration } = useUserData()

  const statusValue = useMemo(
    () => STATUS_RELATIONSHIP_VALUES[relationshipStatus],
    [relationshipStatus],
  )

  const durationValue = useMemo(
    () => RELATIONSHIP_DURATION_VALUES[relationshipDuration],
    [relationshipDuration],
  )

  return (
    <S.Block className={className}>
      <S.Column>
        <S.Label>{t('paymentTrialV1.statusLabel')}</S.Label>
        <S.Data>{t(statusValue)}</S.Data>
      </S.Column>
      <S.Separator />
      <S.Column>
        <S.Label>{t('paymentTrialV1.togetherLabel')}</S.Label>
        <S.Data>{t(durationValue)}</S.Data>
      </S.Column>
    </S.Block>
  )
}
