import React from 'react'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import img from 'assets/images/social-proof-2.svg'

import { StyledSocialProof as S } from './SocialProof.styles'

export const SocialProofVariant3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const handleContinue = useNextStep({
    pageId,
    question: 'Do you know that...',
    nextPagePath,
  })

  return (
    <S.Wrapper>
      <S.Column style={{ paddingTop: '37px' }}>
        <S.Img data-variant="3">
          <img src={img} alt="couple" />
        </S.Img>
        <S.Title2>Do you know that...</S.Title2>
        <S.Text2 style={{ marginBottom: '20px' }}>
          most studies show that the average American adult has sex 50 to 70
          times per year, which is about <S.Bold>once to twice a week</S.Bold>
        </S.Text2>
        <S.Text2>
          But <S.Bold>only 44% of people</S.Bold> are fully satisfied with their
          sex lives.*
        </S.Text2>
        <S.Note>* Durex’s Sexual Wellbeing survey</S.Note>
        <S.StickyButton onClick={() => handleContinue('')}>
          Continue
        </S.StickyButton>
      </S.Column>
    </S.Wrapper>
  )
}
