import React, { Suspense } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { shallowEqual, useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'

import { GrowthBookProvider } from '@growthbook/growthbook-react'
import { PrivacyPolicy } from 'pages/privacy-policy'
import { TermsOfUse } from 'pages/terms-of-use'
import { polyfill as smoothScrollPolyfill } from 'smoothscroll-polyfill'
import 'swiper/css'
import 'swiper/css/bundle'

import { LayoutProvider } from 'components/LayoutProvider'
import { Spinner } from 'components/Spinner'

import { selectConfig, selectCurrentVariant } from 'root-redux/selects/common'
import { selectUserStatus } from 'root-redux/selects/user'

import { useFacebookPixelInitialization } from 'hooks/useFacebookPixelInitialization'
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics'
import { growthbook, useGrowthBook } from 'hooks/useGrowthBook'
import { useInitAppConfig } from 'hooks/useInitAppConfig'
import { useScrollFix } from 'hooks/useScrollFix'
import { useSetOptimizeVariantId } from 'hooks/useSetOptimizeVariantId'
import { useSetUserStatus } from 'hooks/useSetUserStatus'
import { useSetUuid } from 'hooks/useSetUuid'
import { useSetVariant } from 'hooks/useSetVariant'

import { setViewportFullHeight } from 'helpers/setViewportFullHeight'

import { browserHistory } from 'browser-history'
import { TOU_AND_PP_LINKS } from 'root-constants/common'
import { PageId } from 'root-constants/pages'

import { CustomRouter } from './CustomRouter'

setViewportFullHeight()
smoothScrollPolyfill()

export const App: React.FC = () => {
  const userStatus = useSelector(selectUserStatus, shallowEqual)
  const variant = useSelector(selectCurrentVariant, shallowEqual)
  const config = useSelector(selectConfig, shallowEqual)

  useInitAppConfig()
  useGoogleAnalytics()
  useFacebookPixelInitialization()
  useSetVariant()
  useSetUuid()
  useSetUserStatus()
  useScrollFix()
  useSetOptimizeVariantId()
  useGrowthBook()

  return (
    <CustomRouter history={browserHistory}>
      <Suspense fallback={<Spinner />}>
        {TOU_AND_PP_LINKS.includes(window.location.pathname) ? (
          <HelmetProvider>
            <Routes>
              <Route path={PageId.TERMS_OF_USE} element={<TermsOfUse />} />
              <Route path={PageId.PRIVACY_POLICY} element={<PrivacyPolicy />} />
            </Routes>
          </HelmetProvider>
        ) : (
          <GrowthBookProvider growthbook={growthbook}>
            {userStatus && variant && config ? <LayoutProvider /> : <Spinner />}
          </GrowthBookProvider>
        )}
      </Suspense>
    </CustomRouter>
  )
}
