import styled from 'styled-components'

import { Color } from 'root-constants/common'

export const StyledDisclaimerV2 = {
  Disclaimer: styled.p`
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    text-align: center;
    text-wrap: balance;
    color: ${Color.VIOLET_121};

    a {
      color: ${Color.VIOLET_121};
      text-decoration: underline;
    }
  `,
}
