import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { GrowthBook } from '@growthbook/growthbook-react'

import { selectOptimizeMap } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { getCurrentEnvironment } from 'helpers/getCurrentEnvironment'

import { eventLogger } from 'services/eventLogger.service'

const PROD_GROWTHBOOK_CLIENT_KEY = 'sdk-Rde0lj3yvYdea6XO'
const DEV_GROWTHBOOK_CLIENT_KEY = 'sdk-Aj1BapjyxbNVqRsq'

const clientKey = getCurrentEnvironment().isProdEnvironment
  ? PROD_GROWTHBOOK_CLIENT_KEY
  : DEV_GROWTHBOOK_CLIENT_KEY

export const growthbook = new GrowthBook({
  enableDevMode: true,
  subscribeToChanges: true,
  apiHost: 'https://cdn.growthbook.io',
  clientKey,
})

export const useGrowthBook = (): void => {
  const uuid = useSelector(selectUUID)
  const experimentsMap = useSelector(selectOptimizeMap)

  useEffect(() => {
    growthbook.loadFeatures()
  }, [])

  useEffect(() => {
    growthbook.setAttributes({
      id: uuid,
    })
  }, [uuid])

  useEffect(() => {
    growthbook.subscribe((experiment, result) => {
      if (!experimentsMap || !result.hashUsed) return

      const experimentKey = experiment.key || ''
      const variantId = String(result.variationId)

      eventLogger.logGrowthbookAbSegmentName({
        variantId,
        experimentKey,
        variantName: experimentsMap[experimentKey]?.[variantId],
      })
    })
  }, [experimentsMap])
}
