import React from 'react'

import { Button } from 'components/Button'
import { PageContainer } from 'components/PageContainer'

import { useGetFlowGroupInfo } from 'hooks/useGetFlowGroupInfo'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import jealousyDescription from 'assets/images/jealousy-description.webp'

import { StyledJealousyDescription as S } from './JealousyDescription.styles'

export const JealousyDescriptionVariant1: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
  alternativePagePath,
}) => {
  const { isMultipleFlow } = useGetFlowGroupInfo()

  const handleContinue = useNextStep({
    pageId,
    question: 'Jealousy is a common emotion in relationships',
    nextPagePath: isMultipleFlow ? nextPagePath : alternativePagePath,
  })

  return (
    <PageContainer>
      <S.Column>
        <S.Title>Jealousy is a common emotion in relationships</S.Title>
        <S.Subtitle>
          but it can be managed through <span>open communication</span> and{' '}
          <span>building trust.</span>
        </S.Subtitle>

        <S.ImageContainer>
          <img src={jealousyDescription} alt="jealousy-description" />
        </S.ImageContainer>
        <S.Percents>78%</S.Percents>

        <S.Description>
          of couples who reported jealousy conflicts said{' '}
          <span>UpLuv helped them</span> to reduce tension in relationships
        </S.Description>
        <Button onClick={() => handleContinue('')}>Continue</Button>
      </S.Column>
    </PageContainer>
  )
}
