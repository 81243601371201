import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Option, OptionType } from 'components/Option'
import { PageContainer } from 'components/PageContainer'
import { TitleContainer } from 'components/TitleContainer'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import genderImg from 'assets/images/gender-2.svg'

import { StyledGender as S } from './Gender.styles'
import { GENDER_MAP, Gender } from './constants'

export const GenderVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: t('onboarding.gender.question', { lng: 'en' }),
    nextPagePath,
  })

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      onChange: (value: string) => {
        handleChange(value, handleContinue)
        setIsAnswersDisabled(true)
      },
    }),
    [handleChange, handleContinue, pageId, setIsAnswersDisabled],
  )

  return (
    <PageContainer>
      <S.Column>
        <TitleContainer
          title={<S.Title>{t('onboarding.gender.question')}</S.Title>}
        />
        <S.Img src={genderImg} height="262" alt="faces" />
        <S.OptionsContainer>
          <Option
            {...optionProps}
            value={Gender.MALE}
            disabled={isAnswersDisabled}
          >
            <S.QuestionButton>{t(GENDER_MAP[Gender.MALE])}</S.QuestionButton>
          </Option>
          <Option
            {...optionProps}
            value={Gender.FEMALE}
            disabled={isAnswersDisabled}
          >
            <S.QuestionButton>{t(GENDER_MAP[Gender.FEMALE])}</S.QuestionButton>
          </Option>
        </S.OptionsContainer>
        <Option
          {...optionProps}
          value={Gender.OTHER}
          disabled={isAnswersDisabled}
        >
          <S.OtherButton>{t(GENDER_MAP[Gender.OTHER])}</S.OtherButton>
        </Option>
      </S.Column>
    </PageContainer>
  )
}
