import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ISelectPlanItemProps } from 'modules/subscriptions/types'

import { CURRENCY_SYMBOLS } from 'root-constants/common'

import { StyledPlanItemVariant3 as S } from './PlanItemVariant3.styles'

export const PlanItemVariant3: React.FC<ISelectPlanItemProps> = ({
  subscription: {
    isDefault,
    currency,
    mainPrices: { periodName, periodQuantity, oldPrices, weekly },
  },
  isSelected,
}) => {
  const { t } = useTranslation()

  const oldPrice = useMemo(
    () => oldPrices.beforeCustomDiscount?.weekly.toFixed(2),
    [oldPrices],
  )

  return (
    <S.PlanItem data-is-selected={isSelected} data-is-default={isDefault}>
      <S.PlanTitle>
        <Trans
          i18nKey="subscriptions.planPeriod"
          values={{ periodName, periodQuantity }}
        />
      </S.PlanTitle>
      {oldPrice && (
        <S.OldPlanPrice>
          {CURRENCY_SYMBOLS[currency]}
          {oldPrice}
        </S.OldPlanPrice>
      )}
      {weekly && (
        <S.PlanPrice data-is-selected={isSelected}>
          {CURRENCY_SYMBOLS[currency]}
          {weekly}
          <S.Period>{t('subscriptions.perWeek')}</S.Period>
        </S.PlanPrice>
      )}
    </S.PlanItem>
  )
}
