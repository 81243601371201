import styled from 'styled-components'

import { QuestionTitle, baseColumnStyles } from 'common-styles'

export const StyledRomance = {
  Column: styled.div`
    ${baseColumnStyles};
    padding: 18px 0;
  `,
  Title: styled(QuestionTitle)`
    line-height: 28px;
    margin-bottom: 96px;

    strong {
      display: block;
      font-weight: 700;
      margin-bottom: 20px;
    }
  `,
}
