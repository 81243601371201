import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { setUtmTags } from 'root-redux/actions/common'
import {
  selectCurrentVariantCohort,
  selectGiaApiKeys,
  selectStripeAccountId,
  selectStripeAccountName,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { getCurrentEnvironment } from 'helpers/getCurrentEnvironment'
import { getQueryParamsFromSearchString } from 'helpers/getQueryParamsFromSearchString'

import { Amplitude } from 'services/amplitude.service'
import { eventLogger } from 'services/eventLogger.service'
import { UserFlowTracker } from 'services/userFlowTracker.service'

import {
  EventLoggerInstanceName,
  TEST_ENV_QUERY_PARAM,
} from 'root-constants/common'

const getGIAAmplitudeOptions = () => {
  const btoaAuthCredentials = 'cHJveHktdXNlcjozQyk9Q3YncTd5L0A4Pjcp'

  return {
    apiEndpoint: getCurrentEnvironment().isProdEnvironment
      ? 'analytics.gismart.xyz/events'
      : 'analytics.gidev.xyz/events',
    headers: {
      Authorization: `Bearer ${btoaAuthCredentials}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    forceHttps: true,
  }
}

export const useGiaFlowTrackerInitialization = () => {
  const { search } = useLocation()
  const dispatch = useDispatch()
  const cohort = useSelector(selectCurrentVariantCohort)
  const uuid = useSelector(selectUUID)
  const giaApiKeys = useSelector(selectGiaApiKeys)
  const stripeAccountId = useSelector(selectStripeAccountId)
  const stripeAccountName = useSelector(selectStripeAccountName)

  const [giaInstance, setGiaInstance] = useState<Amplitude | null>(null)

  const URLParams = new URLSearchParams(search)
  const isTestEnvironment = URLParams.has(TEST_ENV_QUERY_PARAM)
  const { isProdEnvironment } = getCurrentEnvironment()

  useEffect(() => {
    const utmTagsFromUrl = getQueryParamsFromSearchString(search)

    dispatch(setUtmTags(utmTagsFromUrl))

    if (uuid && giaApiKeys) {
      const giaService = new Amplitude({
        apiKey: giaApiKeys.en,
        instanceName: EventLoggerInstanceName.GIA,
        options: getGIAAmplitudeOptions(),
        userId: uuid,
        utmTags: utmTagsFromUrl,
        isTestEnvironment,
        isDevEnvironment: !isProdEnvironment,
      })

      const userFlowTracker = new UserFlowTracker({
        instanceName: EventLoggerInstanceName.USER_FLOW_TRACKER,
        userId: uuid,
      })

      giaService.configure({ cohort })
      userFlowTracker.configure({ cohort })

      eventLogger.init(giaService, userFlowTracker)

      setGiaInstance(giaService)
    }
  }, [
    uuid,
    giaApiKeys,
    search,
    isTestEnvironment,
    isProdEnvironment,
    cohort,
    dispatch,
  ])

  useEffect(() => {
    if (!stripeAccountId || !stripeAccountName) return

    giaInstance?.updateUserProperties(stripeAccountId, stripeAccountName)
  }, [giaInstance, stripeAccountId, stripeAccountName])
}
