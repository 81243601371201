export const getDecoratedTimerValue = (valueInSeconds: number): string => {
  const minutes = Math.trunc(valueInSeconds / 60)
  const seconds = valueInSeconds - minutes * 60
  const get2DigitValue = (value: number): string =>
    value.toString().padStart(2, '0')

  return `${get2DigitValue(minutes)}:${get2DigitValue(seconds)}`
}

export const getDecoratedFractionPricesPart = (price: number) => {
  const getFractionPart = (price - Math.trunc(price)).toFixed(2)

  return getFractionPart.substring(2)
}
